var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"Registers"},[_c('div',{class:{
      SumRegisters: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
      SumRegistersRestrict: _vm.dataPlan.tipoPlano === 'gestao_personalizado',
    }},[_c('button',{class:{
        ItemSumRegisters: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
        ItemSumRegistersRestrict:
          _vm.dataPlan.tipoPlano === 'gestao_personalizado',
      },staticStyle:{"border":"3px ridge #403b74"},attrs:{"name":"customer"},on:{"click":_vm.activeTable}},[_c('span',{staticClass:"titleCountRegisters"},[_vm._v(" Clientes Cadastrados ")]),_c('div',[_c('b-icon-people-fill'),_c('span',{staticClass:"registersCount"},[_vm._v(" "+_vm._s(this.counter.customersCount)+" ")])],1)]),_c('button',{class:{
        ItemSumRegisters: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
        ItemSumRegistersRestrict:
          _vm.dataPlan.tipoPlano === 'gestao_personalizado',
      },staticStyle:{"border":"3px ridge #3e66ae"},attrs:{"name":"supplier"},on:{"click":_vm.activeTable}},[_c('span',{staticClass:"titleCountRegisters"},[_vm._v(" Fornecedores Cadastrados ")]),_c('div',[_c('b-icon-truck'),_c('span',{staticClass:"registersCount"},[_vm._v(" "+_vm._s(this.counter.providerCount)+" ")])],1)]),_c('button',{class:{
        ItemSumRegisters: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
        ItemSumRegistersRestrict:
          _vm.dataPlan.tipoPlano === 'gestao_personalizado',
      },staticStyle:{"border":"3px ridge #48b865"},attrs:{"name":"products"},on:{"click":_vm.activeTable}},[_c('span',{staticClass:"titleCountRegisters"},[_vm._v(" Produtos Cadastrados ")]),_c('div',[_c('b-icon-handbag'),_c('span',{staticClass:"registersCount"},[_vm._v(" "+_vm._s(this.counter.productCount)+" ")])],1)]),(_vm.dataPlan.tipoPlano !== 'gestao_personalizado')?_c('button',{staticClass:"ItemSumRegisters",staticStyle:{"border":"3px ridge #f2a83e"},attrs:{"name":"employee"},on:{"click":_vm.activeTable}},[_c('span',{staticClass:"titleCountRegisters"},[_vm._v(" Funcionários Cadastrados ")]),_c('div',[_c('b-icon-person-lines-fill'),_c('span',{staticClass:"registersCount"},[_vm._v(" "+_vm._s(this.counter.employeeCount)+" ")])],1)]):_vm._e(),(_vm.dataPlan.tipoPlano !== 'gestao_personalizado')?_c('button',{staticClass:"ItemSumRegisters",staticStyle:{"border":"3px ridge #222124"},attrs:{"name":"service"},on:{"click":_vm.activeTable}},[_c('span',{staticClass:"titleCountRegisters"},[_vm._v(" Serviços Cadastrados ")]),_c('div',[_c('b-icon-shop'),_c('span',{staticClass:"registersCount"},[_vm._v(" "+_vm._s(this.counter.servicesCount)+" ")])],1)]):_vm._e()]),_c('div',{class:{
      ContainerBtnRegister: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
      ContainerBtnRegisterRestrict:
        _vm.dataPlan.tipoPlano === 'gestao_personalizado',
    }},[_c('b-button',{class:{
        BtnRegister: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
        BtnRegisterRestrict: _vm.dataPlan.tipoPlano === 'gestao_personalizado',
      },staticStyle:{"background":"#403b74"},attrs:{"name":"customer"},on:{"click":_vm.handleModals}},[_c('b-icon-plus',{attrs:{"scale":"1.5"}}),_vm._v(" Novo Cliente")],1),_c('b-button',{class:{
        BtnRegister: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
        BtnRegisterRestrict: _vm.dataPlan.tipoPlano === 'gestao_personalizado',
      },staticStyle:{"background":"#3e66ae"},attrs:{"name":"supplier"},on:{"click":_vm.handleModals}},[_c('b-icon-plus',{attrs:{"scale":"1.5"}}),_vm._v(" Novo Fornecedor")],1),_c('b-button',{class:{
        BtnRegister: _vm.dataPlan.tipoPlano !== 'gestao_personalizado',
        BtnRegisterRestrict: _vm.dataPlan.tipoPlano === 'gestao_personalizado',
      },staticStyle:{"background":"#48b865"},attrs:{"name":"products"},on:{"click":_vm.handleModals}},[_c('b-icon-plus',{attrs:{"scale":"1.5"}}),_vm._v(" Novo Produto")],1),(_vm.dataPlan.tipoPlano !== 'gestao_personalizado')?_c('b-button',{staticClass:"BtnRegister",staticStyle:{"background":"#f2a83e"},attrs:{"name":"employee"},on:{"click":_vm.handleModals}},[_c('b-icon-plus',{attrs:{"scale":"1.5"}}),_vm._v(" Novo Funcionário")],1):_vm._e(),(_vm.dataPlan.tipoPlano !== 'gestao_personalizado')?_c('b-button',{staticClass:"BtnRegister",staticStyle:{"background":"#222124"},attrs:{"name":"service"},on:{"click":_vm.handleModals}},[_c('b-icon-plus',{attrs:{"scale":"1.5"}}),_vm._v(" Novo Serviço")],1):_vm._e()],1),(this.tableActive.customer)?_c('CustomerTable'):_vm._e(),(this.tableActive.products)?_c('ProductsTable'):_vm._e(),(this.tableActive.supplier)?_c('SupplierTable',{on:{"supplierLenght":function($event){_vm.supplierLenght = $event}}}):_vm._e(),(this.tableActive.employee)?_c('EmployeeTable'):_vm._e(),(this.tableActive.service)?_c('ServiceTable'):_vm._e(),_c('div',{class:_vm.sizeModalRegister},[(_vm.colapseRegister)?_c('div',{class:_vm.modalContainer},[_c('b-button',{staticClass:"closeModalRegister",on:{"click":_vm.modalHeight}},[_vm._v(" X ")]),(this.typeRegister.customer)?_c('Customer',{staticClass:"cardContentModal"}):_vm._e(),(this.typeRegister.employee)?_c('Employee',{staticClass:"cardContentModal"}):_vm._e(),(this.typeRegister.products)?_c('Products',{staticClass:"cardContentModal"}):_vm._e(),(this.typeRegister.supplier)?_c('Supplier',{staticClass:"cardContentModal"}):_vm._e(),(this.typeRegister.service)?_c('Service',{staticClass:"cardContentModal"}):_vm._e()],1):_vm._e()])],1)}
var staticRenderFns = []

export { render, staticRenderFns }