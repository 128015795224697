export default function printReportBills(type, status, idCliente, idFuncionario, idFornecedor,idCategoryBill, idFormaDePagamento, startDate, endDate, landscape = false) {
  const token = localStorage.getItem("token");
  printBillsPerType(token, type, status, idCliente || '', idFuncionario || '', idFornecedor || '', idCategoryBill || '',idFormaDePagamento || '',startDate, endDate, landscape)
}

function printBillsPerType(token, type, status, idCliente, idFuncionario, idFornecedor, idCategoryBill, idFormaDePagamento, startDate, endDate, landscape) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/bills/print/report/find/all/account/or/find/per/type/bill/?auth=${token}&type=${type}&status=${status}&idCliente=${idCliente}&idFuncionario=${idFuncionario}&idFornecedor=${idFornecedor}&idCategoryBill=${idCategoryBill}&idFormaDePagamento=${idFormaDePagamento}&startDate=${startDate}&endDate=${endDate}&landscape=${landscape || ''}`
  );
}
