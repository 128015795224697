class ConvertXml {
  async xmlString (xml) {
    if (xml.type === 'text/xml') {
      const result = await new Promise((resolve) => {
        const xmlString = xml;
        let reader = new FileReader();
        reader.readAsText(xmlString);

        reader.onload = function (e) {
          return resolve(e.target.result);
        };
      });
      return result;
    } else {
      return null
    }
  }

  ObjectPurchase (object) {
    const objectPurchase = {
      fornecedor: {},
      produtos: {},
      purchase: {},
      key: {},
      impostos: {},
      valorNfe: 0,
      valorFrete: 0,
    }

    objectPurchase.fornecedor = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].emit : object.data?.NFe.infNFe[0].emit
    objectPurchase.produtos = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].det : object.data?.NFe.infNFe[0].det
    objectPurchase.purchase = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].ide : object.data?.NFe.infNFe[0].ide
    objectPurchase.key = object.data.nfeProc?.protNFe[0]?.infProt[0]?.chNFe || 0
    objectPurchase.impostos = object.data.nfeProc ? object.data.nfeProc.NFe[0]?.infNFe[0]?.total[0].ICMSTot : object.data?.NFe.infNFe[0].total[0].ICMSTot
    
    objectPurchase.valorNfe = object.data.nfeProc ?
      Number(object.data.nfeProc.NFe[0]?.infNFe[0]?.total[0].ICMSTot[0].vNF[0]) :
      Number(object.data?.NFe.infNFe[0].total[0].ICMSTot[0].vNF[0])

    objectPurchase.valorFrete = object.data.nfeProc ?
      Number(object.data.nfeProc.NFe[0]?.infNFe[0]?.total[0].ICMSTot[0].vFrete[0]) :
      Number(object.data?.NFe.infNFe[0].total[0].ICMSTot[0].vFrete[0])
      
    return objectPurchase
  }
}

export default new ConvertXml()

