import Joi from "joi";

export const ValidadeProvisionService = (data) => {
  const schema = Joi.object({
    data_nfse: Joi.date().required().messages({
      "any.required": "(Data Movimentação) deve ser informado!",
      "date.base": "(Data Movimentação) deve ser informado!"
    }),
    data_execucao: Joi.date().required().messages({
      "any.required": "(Data Execução) deve ser informado!",
      "date.base": "(Data Execução) deve ser informado!"
    }),
    ambiente: Joi.alternatives().try(
      Joi.string().valid('1', '2').messages({
        "string.empty": "(Ambiente) deve ser informado!",
        "any.required": "(Ambiente) deve ser informado!"
      }),
      Joi.number().valid(1, 2).messages({
        "string.empty": "(Ambiente) deve ser informado!",
        "any.required": "(Ambiente) deve ser informado!"
      })
    ).required(),
    idCliente: Joi.string().required().messages({
      "string.empty": "(Cliente) deve ser informado!",
      "any.required": "(Cliente) deve ser informado!",
    }),
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}

export const ValidadeItemService = (data) => {
  const customValidation = (value, helpers) => {
    if (value == "0") {
      return helpers.error("number.base", { message: '(Valor) deve ser informado e não pode ser zero!' });
    }

    return value;
  };

  const schema = Joi.object({
    idServico: Joi.string().uuid({ version: 'uuidv4' }).required().messages({
      "string.empty": "(Serviço) deve ser informado!",
      "any.required": "(Serviço) deve ser informado!"
    }),
    valor: Joi.number().min(0).required().custom(customValidation).messages({
      "number.base": "(Valor) deve ser informado e não pode ser zero!",
      "any.required": "(Valor) deve ser informado e não pode ser zero!",
    })
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}


export const ValidateProvisionServiceBills = (data) => {
  const schema = Joi.object({
    idFormaPagamento: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Forma de pagamento) deve ser selecionada para prosseguir"
    }),
    idContaRecebimento: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Conta de recebimento) deve ser selecionada para prosseguir"
    }),
    idFuncionario: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Funcionário) deve ser selecionado para prosseguir"
    }),
    totalParcelas: Joi.number().min(0).required().messages({
      "number.base": "(Parcelas) deve ser informado e não pode ser vazio!",
      "any.required": "(Parcelas) deve ser informado e não pode ser vazio!",
    }),
    intervaloDias: Joi.number().min(0).required().messages({
      "number.base": "(Interval. de pagamento) deve ser informado e não pode ser vazio!",
      "any.required": "(Interval. de pagamento) deve ser informado e não pode ser vazio!",
    })
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}

export const ValidateProvisionIsSimpleCashierBills = (data) => {
  const schema = Joi.object({
    idFormaPagamento: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Forma de pagamento) deve ser selecionada para prosseguir"
    }),
    idFuncionario: Joi.string().guid({ version: "uuidv4" }).required().messages({
      "string.empty": "(Funcionário) deve ser selecionado para prosseguir"
    }),
    totalParcelas: Joi.number().min(0).required().messages({
      "number.base": "(Parcelas) deve ser informado e não pode ser vazio!",
      "any.required": "(Parcelas) deve ser informado e não pode ser vazio!",
    }),
    intervaloDias: Joi.number().min(0).required().messages({
      "number.base": "(Interval. de pagamento) deve ser informado e não pode ser vazio!",
      "any.required": "(Interval. de pagamento) deve ser informado e não pode ser vazio!",
    })
  })

  const { error } = schema.validate(data, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}