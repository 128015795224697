<template>
  <div id="app">
    <div
      v-if="
        this.$router.currentRoute.path !== '/' &&
        this.$router.currentRoute.path !== '/tester'
      "
    >
      <NavigationBar />

      <ModalDialog
        :nameModal="dataModalDialogGeneral.nameModal"
        :title="dataModalDialogGeneral.title"
        :subTitle="dataModalDialogGeneral.subTitle"
        :footerTitle="dataModalDialogGeneral.footerTitle"
        :leftButtonText="dataModalDialogGeneral.leftButtonText"
        :rightButtonText="dataModalDialogGeneral.rightButtonText"
        :textNextButton="true"
        @clickLeftButton="closeModal"
        @clickRightButton="showModalSafeOpening"
        @clickAwayModal="closeModal"
      />

      <ModalDialog
        nameModal="modalRemainingValueGeneral"
        title="Valor em aberto existente!"
        :subTitle="`Verificamos que restou em aberto do caixa anterior o valor de ${formattedRemainingValue} em aberto!`"
        footerTitle="Deseja iniciar o novo caixa com esse valor ?"
        leftButtonText="Não"
        rightButtonText="Sim"
        @clickLeftButton="cancelOpenWithRemainingValue"
        @clickRightButton="openWithRemainingValue"
        @clickAwayModal="cancelOpenWithRemainingValue"
      />

      <ModalSafeOpening />
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";

import NavigationBar from "./components/Navigation/Index-Navigation.vue";
import ModalDialog from "./components/Modals/ModalDialog.vue";
import ModalSafeOpening from "./components/Modals/ModalSafeOpening.vue";

import ServiceCompany from "./services/serviceCompany";
import SafeService from "./services/serviceSafe";
import ServiceSettings from "./services/serviceSettings";
import ServiceAccountingMessages from "./services/serviceAccountingMessages";
import ServicePlan from "./services/servicePlan";
import ServiceUser from "./services/serviceUser";
import serviceGestao from "./services/serviceGestao";

import { HandleErrors } from "./utils/handleErrors";
import { TypeErrors } from "./utils/enums/TypeErrors";

export default {
  name: "App",
  components: {
    NavigationBar,
    ModalDialog,
    ModalSafeOpening,
  },
  data() {
    return {
      localRemainingValue: null,
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
      "SET_SAFE_REMAINING_VALUE",
      "SET_DATA_CONFIG",
      "SET_ACCOUTING_NUMBER_NEW_MESSAGES",
      "SET_PLAN",
      "SET_IS_ENABLE_MTQ",
    ]),
    async getDataCompany() {
      if (this.$route.path !== "/" && this.$route.path !== "/tester") {
        const company = await ServiceCompany.findCompany();
        this.$store.commit("DATA_COMPANY", company);
      }
    },
    async getContract() {
      const token = localStorage.getItem("token");
      const contract = await serviceGestao.findTerms(token);
      this.$store.commit("DATA_CONTRACT", contract.data.contrato);
    },
    async confirmRemainingValue() {
      const result = await SafeService.findRemainingValue();
      if (result.remainingValue) {
        this.localRemainingValue = result.remainingValue;
        return this.$bvModal.show("modalRemainingValueGeneral");
      }

      this.$bvModal.show("ModalSafeOpening");
    },
    async showModalSafeOpening() {
      await this.confirmRemainingValue();

      this.closeModal();
    },
    async getConfigAtm() {
      try {
        let newAtm;
        const result = await ServiceSettings.findSettingsAtm();

        if (!result?.caixa_automatico) {
          newAtm = {
            idResponsavel: null,
            utilizar: false,
            valorInicial: 0.0,
            horaAbertura: null,
            horaFechamento: null,
          };
        } else {
          newAtm = result.caixa_automatico;
        }

        if (Object.keys(result).length) {
          return this.SET_DATA_CONFIG({
            ...result,
            id: result.id,
            caixa_automatico: newAtm,
          });
        }
      } catch (error) {
        return this.$toast.open({
          message: "Ocorreu um erro ao buscar informações do caixa",
          type: "info",
        });
      }
    },
    async findUserWhereMtqIsEnable() {
      const result = await ServiceUser.findUserWhereMtqIsEnable();

      if (result?.habilitarMtq) {
        this.SET_IS_ENABLE_MTQ(result.habilitarMtq);
      }
    },
    async getNumberOfNewMessages() {
      const numberOfNewMessages =
        await ServiceAccountingMessages.verifyExistsNewMessages();

      this.SET_ACCOUTING_NUMBER_NEW_MESSAGES(numberOfNewMessages);
    },
    async verifyPlanIsActive() {
      let plan;
      try {
        plan = await ServicePlan.verifyPlanIsActive();
        localStorage.setItem("plan", plan.tipoPlano);
        this.SET_PLAN(plan);
      } catch (error) {
        this.SET_PLAN({ ...plan, ativo: false });
        const typeError = HandleErrors(error?.response?.data?.message);

        if (
          (typeError &&
            typeError?.nameError === TypeErrors.NOT_FOUND_SOFTWARE_PLAN) ||
          typeError?.nameError === TypeErrors.NOT_FOUND_FISCAL_DOCUMENTS
        ) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao verificar o seu plano",
          type: "info",
        });
      }
    },

    openWithRemainingValue() {
      this.SET_SAFE_REMAINING_VALUE(this.localRemainingValue);
      this.$bvModal.show("ModalSafeOpening");
      this.$bvModal.hide("modalRemainingValueGeneral");
    },
    cancelOpenWithRemainingValue() {
      this.localRemainingValue = null;
      this.$bvModal.hide("modalRemainingValueGeneral");
      this.$bvModal.show("ModalSafeOpening");
    },
    closeModal() {
      this.$bvModal.hide(this.dataModalDialogGeneral.nameModal);

      this.ALTER_VALUES_MODAL_DIALOG_GENERAL({
        nameModal: "",
        title: "",
        subTitle: "",
        footerTitle: "",
        leftButtonText: "",
        rightButtonText: "",
      });
    },
    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }
      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  computed: {
    ...mapState({
      dataModalDialogGeneral: (state) => state.dataModalDialogGeneral,
      remainingValue: (state) => state.remainingValue,
      dataConfig: (state) => state.dataConfig,
      sintegraIsReady: (state) => state.sintegraIsReady,
      dataPlan: (state) => state.dataPlan,
    }),
    formattedRemainingValue() {
      return this.maskCurrency(this.localRemainingValue);
    },
  },
  created() {
    if (
      this.$router.currentRoute.path !== "/" &&
      this.$router.currentRoute.path !== "/tester"
    ) {
      this.getNumberOfNewMessages();
      setInterval(this.getNumberOfNewMessages, 300000);
    }
  },
  mounted() {
    if (
      this.$router.currentRoute.path !== "/" &&
      this.$router.currentRoute.path !== "/tester"
    ) {
      this.getDataCompany();
      this.verifyPlanIsActive();
      this.getContract();
      this.getConfigAtm();
      this.findUserWhereMtqIsEnable();
      setInterval(this.verifyPlanIsActive, 2 * 60 * 60 * 1000);
    }
  },
};
</script>

<style>
* {
  /* background-color: #212121 !important;
  color: white !important; */
  font-family: Poppins-Medium, sans-serif !important;
}

@font-face {
  font-family: Poppins-Regular;
  src: url("~@/fonts/poppins/Poppins-Regular.ttf");
}

@font-face {
  font-family: Poppins-Bold;
  src: url("~@/fonts/poppins/Poppins-Bold.ttf");
}

@font-face {
  font-family: Poppins-Medium;
  src: url("~@/fonts/poppins/Poppins-Medium.ttf");
}

@font-face {
  font-family: Montserrat-Bold;
  src: url("~@/fonts/montserrat/Montserrat-Bold.ttf");
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #3a4750;
  border-radius: 2px;
}

input[type="date"]::-webkit-calendar-picker-indicator {
  cursor: pointer;
  padding: 5px;
  filter: invert(0.8) brightness(250%) sepia(0%) saturate(10%)
    hue-rotate(240deg);
  background-color: #d5d3d3;
  border-radius: 50%;
}

body {
  /* background: #102558 !important; */
  background-color: rgb(255, 255, 255) !important;
  margin: 0;
  padding: 0;
}
</style>
