<template>
  <div class="GeneralSales">
    <div class="ContainerBtnSales">
      <b-button class="BtnSales" style="background: #3e66ae" @click="handleModals('orcamentoMtq')">
        <b-icon-plus scale="1.5"></b-icon-plus>
        Novo Orçamento MT2</b-button>

      <b-button class="BtnSales" style="background: #48b865" @click="handleModals('vendaMtq')">
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova Venda MT2</b-button>
    </div>

    <div class="lineSearch">
      <div class="searchByMonth">
        <div class="summaryMonthText" style="cursor: context-menu">
          Resumo Do Mês
        </div>
        <div class="pagMonth">
          <b-icon-chevron-left class="Arrows" id="btn-month-prev" @click="updateMonth('prev')"></b-icon-chevron-left>
          <b-button class="month" size="sm" style="
              font-size: 11px;
              text-transform: capitalize;
              font-weight: bold;
              background: #f3f3f3;
            " variant="none" @click="filterSalePerMonth">
            {{ monthName }} - {{ year }}
          </b-button>
          <b-icon-chevron-right class="Arrows" id="btn-month-next" @click="updateMonth('next')"></b-icon-chevron-right>
        </div>
      </div>
    </div>

    <div class="SummarySales">
      <button @click="handleTypeSale(typeSale.orcamento)" class="ItemSummarySales" style="border: 3px ridge #3e66ae">
        Orçamentos MT2 (Em ABerto) <br />
        {{ monthName }} - {{ year }}
        <br />
        {{
          maskCurrency(totalMonthBudgetSquareMeter)
        }}
      </button>
      <button @click="handleTypeSale(typeSale.venda)" class="ItemSummarySales" style="border: 3px ridge #48b865">
        Vendas MT2 (Aprovadas e Faturadas) <br />
        {{ monthName }} - {{ year }}
        <br />
        {{
          maskCurrency(totalMonthSaleSquareMeter)
        }}
      </button>
    </div>

    <div class="searchContainer">
      <div class="searchInputDate">
        <button class="btnPeriod" size="sm">Período</button>
        <div class="dateInput">
          <input type="date" id="date" v-model="startDate" style="
              padding: 0.25rem 0.25rem 0.25rem 0.5rem;
              cursor: pointer;
              border-left: none;
              border-radius: 0px 5px 5px 0px;
            " />
          <input type="date" id="date" v-model="endDate"
            style="padding: 0.25rem 1.25rem 0.25rem 0.25rem; cursor: pointer" />
        </div>
      </div>
      <div class="searchInput">
        <b-form-select class="searchFilter" size="sm" value-field="value" text-field="text" v-model="type"
          :options="filterCombobox"></b-form-select>
        <div v-if="type === 'situacao'">
          <b-form-select class="searchFilterSituation custom-select2" value-field="value" text-field="text"
            v-model="textPesquisa" :options="filterSituationCombobox"></b-form-select>
        </div>
        <div v-else-if="type === 'tipo'">
          <b-form-select class="searchFilterSituation custom-select2" value-field="value" text-field="text"
            v-model="textPesquisa" :options="filterSalePerType"></b-form-select>
        </div>
        <div v-else>
          <b-input class="inputSearch" placeholder="Pesquisar por..." v-model="textPesquisa"
            @keyup.enter="filterSale(page)"></b-input>
        </div>
        <b-button size="sm" @click="filterSale(page)" class="searchBtn">
          <b-icon-search></b-icon-search>
        </b-button>
      </div>
      <div class="searchInputAll" style="justify-content: flex-end">
        <button class="searchAll" @click="clearSearch()">ver tudo</button>
      </div>
    </div>
    <div class="ContentTable">
      <table>
        <thead class="headTable">
          <tr>
            <th>Data</th>
            <th>Entrega</th>
            <th style="width: 5%">Nº</th>
            <th>Cliente</th>
            <th>Funcionário</th>
            <th>N° Nf-e</th>
            <th>Status</th>
            <th>Situação</th>
            <th>Total Devedor</th>
            <th>Total</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody v-if="this.dataLength > 0" style="color: #3a4750">
          <tr style="text-align: center; height: 30px; cursor: context-menu" v-for="dataSearchSale in dataSale"
            :key="dataSearchSale.id">
            <td>
              {{ dataSearchSale.data.split("-").reverse().join("/") }}
            </td>
            <td>
              {{ dataSearchSale.dataEntrega ? dataSearchSale.dataEntrega.split("-").reverse().join("/") : '---' }}
            </td>
            <td style="width: 5%">{{ dataSearchSale.numero }}</td>
            <td>
              {{ dataSearchSale.nomeCliente || "Consumidor Final" }}
            </td>
            <td>
              {{ dataSearchSale.nomeFuncionario || "--" }}
            </td>
            <td v-if="dataSearchSale.responseNote">
              N° {{ dataSearchSale.responseNote.nfe }}
            </td>
            <td v-else>--</td>
            <td class="typeSale2" v-if="dataSearchSale.status === 'Venda'">
              {{ dataSearchSale.status }}
            </td>
            <td class="typeSale3" v-else-if="dataSearchSale.status === 'Orçamento'">
              {{ dataSearchSale.status }}
            </td>
            <td class="situationContainer">
              <div class="situationContent" v-if="dataSearchSale.situacao === 'Faturado(a)'">
                <div class="situationBall" style="background: #3e66ae"></div>
                <span>
                  {{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}
                </span>
              </div>
              <div class="situationContent" v-if="dataSearchSale.situacao === 'Finalizado(a)'">
                <div class="situationBall" style="background: #48b865"></div>
                <span>{{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}</span>
              </div>
              <div class="situationContent" v-if="dataSearchSale.situacao === 'Cancelado(a)'">
                <div class="situationBall" style="background: #9f1313"></div>
                <span>
                  {{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}</span>
              </div>
              <div class="situationContent" v-if="dataSearchSale.situacao === 'Em aberto'">
                <div class="situationBall" style="background: #c5c5c5"></div>
                <span>{{ dataSearchSale.situacao }}</span>
              </div>
              <div class="situationContent" v-if="dataSearchSale.situacao === 'Aprovado(a)'">
                <div class="situationBall" style="background: orange"></div>
                <span>{{ dataSearchSale.situacao.replace(/\(a\)/g, "") }}</span>
              </div>
            </td>
            <td v-if="
              dataSearchSale.totalValorRestante === 0 &&
              dataSearchSale.status === 'Venda'
            " style="color: #48b865">
              <b-icon-check-circle-fill></b-icon-check-circle-fill>
              Pago
            </td>
            <td v-else>
              {{
                dataSearchSale.status === "Venda" &&
                  dataSearchSale.totalValorRestante
                  ? parseFloat(
                    dataSearchSale.totalValorRestante
                  ).toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                  : "---"
              }}
            </td>
            <td>
              {{ maskCurrency(dataSearchSale.valorTotal) }}
            </td>


            <td>
              <div class="btnActions">
                <b-dropdown id="dropdown-1" class="btnActions1" variant="none" size="sm">
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item @click="editSales(dataSearchSale.id, dataSearchSale.status)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="printSimpleReport(dataSearchSale.id)">
                    Imprimir
                  </b-dropdown-item>
                  <b-dropdown-item @click="getIdSale(dataSearchSale.id)" v-b-modal.modal-center3>
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item @click="findAndViewNote(dataSearchSale.idNota)" v-if="
                    dataSearchSale.idNota !== null &&
                    dataSearchSale.situacao === 'Faturado(a)'
                  ">
                    Visualizar NF-e
                  </b-dropdown-item>
                  <b-dropdown-item @click="
                    handleOpenModalAndFindFiscalDocument(
                      'modal-cancel',
                      dataSearchSale
                    )
                    " v-if="
                      dataSearchSale.idNota !== null &&
                      dataSearchSale.situacao === 'Faturado(a)'
                    ">
                    Cancelar NF-e
                  </b-dropdown-item>
                  <b-dropdown-item @click="findBillReportTicket(dataSearchSale.id)" v-if="
                    dataSearchSale.status !== 'Orçamento' &&
                    dataSearchSale.situacao === 'Finalizado(a)'
                  ">
                    Gerar Carnê
                  </b-dropdown-item>
                  <b-dropdown-item @click="transformToNfe(dataSearchSale.id)"
                    v-if="dataSearchSale.status !== 'Orçamento'">
                    Emitir NF-e
                  </b-dropdown-item>
                  <b-dropdown-item @click="handleGenerateSale(dataSearchSale)"
                    v-if="dataSearchSale.status === 'Orçamento'">
                    Gerar Venda
                  </b-dropdown-item>
                  <b-dropdown-item @click="
                    handleDuplicate(dataSearchSale.id, dataSearchSale.status)
                    ">
                    Clonar
                  </b-dropdown-item>
                  <b-dropdown-item v-b-modal.modal-center4 @click="
                    getIdSaleDetails(dataSearchSale.id, dataSearchSale.tipo)
                    ">
                    Ver detalhes
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="11">
            <div class="defaultSearch">
              <span>Não há dados para exibir ...</span>
              <div v-if="spinGeneral" class="loadingLazy">
                <div class="loader"></div>
              </div>
            </div>
          </td>
        </tbody>
      </table>
    </div>
    <b-modal id="modal-center3" hide-footer hide-header centered title="Excluir">
      <div>
        <h6 class="titleModal">Deseja excluir essa movimentação ?</h6>
        <div class="btnModal">
          <button class="btnContent" @click="deleteSale">Deletar</button>
          <button class="btnContentReceive" @click="$bvModal.hide('modal-center3')">
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-cancel" hide-footer hide-header centered title="Excluir">
      <div>
        <h4 class="titleModal" style="text-align: center">
          Deseja cancelar essa NF-e ?
        </h4>
        <div class="btnModalCancel">
          <div class="contentMotivo">
            <label>Descreva o motivo do cancelamento:</label> <span>*</span>
            <b-form-textarea placeholder="Min: 15 caracteres" style="color: black; width: 100% !important"
              v-model="motivo"></b-form-textarea>
          </div>
          <div class="contentBtns">
            <button class="btnContent" @click="cancelNota()">Cancelar</button>
            <button class="btnContentReceive" @click="$bvModal.hide('modal-cancel')">
              Não, Obrigado!
            </button>
          </div>
        </div>
        <span class="alertCancel">
          O prazo máximo para cancelamento de uma NF-e é de até 24 HORAS após a
          concessão da autorização de uso.
        </span>
      </div>
    </b-modal>
    <b-modal id="modal-center4" hide-footer hide-header centered size="lg" style="padding: 30px"
      title="Detalhes MT2/Linear">
      <div class="closeModalDetails" @click="$bvModal.hide('modal-center4')">
        X
      </div>
      <div class="contentModal">
        <div style="display: flex; justify-content: space-between">
          <span><b-icon-file-text></b-icon-file-text> Detalhes da Venda MT2</span>
          <span class="typeSale2">
            {{ detailsSquareMetersSales.status }} N°
            {{ detailsSquareMetersSales.numero }}</span>
        </div>

        <div class="rowModal">
          <div class="saleHeader">
            <div class="saleItem">
              <p><strong>Data da venda</strong></p>
              <p>{{ detailsSquareMetersSales.data }}</p>
            </div>
            <div class="saleItem">
              <p><strong>Data de Entrega</strong></p>
              <p>{{ detailsSquareMetersSales.dataEntrega || "--" }}</p>
            </div>
            <div class="saleItem"></div>
          </div>
        </div>

        <div class="rowModal" style="padding-top: 2px">
          <div class="saleHeader">
            <div class="saleItem">
              <p><strong>Cliente</strong></p>
              <p class="textEllipsis">
                {{ detailsSquareMetersSales.nomeCliente }}
              </p>
            </div>
            <div class="saleItem">
              <p class="textEllipsis"><strong>CPF / CNPJ</strong></p>
              <p v-if="detailsSquareMetersSales.cpfCnpj">
                {{ detailsSquareMetersSales.cpfCnpj }}
              </p>
              <p v-else style="color: #80808087">Não informado</p>
            </div>
            <div class="saleItem">
              <p class="textEllipsis"><strong>Funcionário</strong></p>
              <p v-if="detailsSquareMetersSales.nomeFuncionario">
                {{ detailsSquareMetersSales.nomeFuncionario }}
              </p>
              <p v-else style="color: #80808087">Não informado</p>
            </div>
          </div>
        </div>

        <div class="rowModal" style="padding-top: 2px">
          <div class="saleHeader">
            <div class="saleItem">
              <p><strong>Valor Total</strong></p>
              <p v-if="detailsSquareMetersSales.valorTotal">
                {{
                  returnTotalValue.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </p>
              <p v-else>R$ 0.00</p>
            </div>

            <div class="saleItem">
              <p><strong>Outros Valores</strong></p>
              <p v-if="detailsSquareMetersSales.outrosValores">
                {{
                  detailsSquareMetersSales.outrosValores.toLocaleString(
                    "pt-br",
                    { style: "currency", currency: "BRL" }
                  )
                }}
              </p>
              <p v-else>R$ 0.00</p>
            </div>

            <div class="saleItem">
              <p><strong>Frete</strong></p>
              <p v-if="detailsSquareMetersSales.frete">
                {{
                  detailsSquareMetersSales.frete.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </p>
              <p v-else>R$ 0.00</p>
            </div>
          </div>
        </div>
      </div>
      <div class="contentModal">
        <span><b-icon-box-seam></b-icon-box-seam> Detalhes dos Produtos</span>
        <div class="responsiveItems">
          <div v-for="detailsSale in this.detailsSquareMetersSales.products" :key="detailsSale.id" class="product-item">
            <div class="product-header">
              <div class="product-name-type">
                <div style="width: 70%">
                  <strong>Nome:</strong> {{ detailsSale.nome }}
                </div>
                <div style="width: 30%">
                  <strong>Tipo:</strong>
                  {{ detailsSale.linear === 0 ? "Mt2" : "Linear" }}
                </div>
              </div>
            </div>

            <div class="product-details">
              <div class="product-row">
                <div class="product-detail">
                  <strong>Cor:</strong> {{ detailsSale.corNome || "--" }}
                </div>
                <div class="product-detail">
                  <strong class="textEllipsis">Ambiente:</strong>
                  {{ detailsSale.ambienteNome || "--" }}
                </div>
                <div class="product-detail">
                  <strong>Qtd:</strong> {{ detailsSale.quantidade.toFixed(2) }}
                </div>
              </div>
              <div class="product-row">
                <div class="product-detail">
                  <strong>Perda:</strong>
                  {{ detailsSale.porcentagemPerda.toFixed(2) || "--" }}%
                </div>
                <div class="product-detail">
                  <strong>Altura:</strong> {{ detailsSale.altura.toFixed(2) }}
                </div>
                <div class="product-detail">
                  <strong>Largura:</strong> {{ detailsSale.largura.toFixed(2) }}
                </div>
              </div>
              <div class="product-row">
                <div class="product-detail">
                  <strong>Mt2 Total:</strong> {{ detailsSale.metragem }}
                </div>
                <div class="product-detail">
                  <strong>Valor Unitário:</strong>
                  {{
                    detailsSale.valorUnitario.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </div>
                <div class="product-detail">
                  <strong>Desconto:</strong>
                  {{
                    detailsSale.desconto.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    }) || "--"
                  }}
                </div>
              </div>
              <div class="product-row">
                <div class="product-detail">
                  <strong>Valor Total:</strong>
                  {{
                    detailsSale.valorTotal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </div>
              </div>
              <div class="product-row">
                <div class="product-detail">
                  <strong>Observação:</strong>
                  {{ detailsSale.dadosAdicionais || "--" }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="contentModal" v-if="this.detailsSquareMetersSales.status === 'Venda'">
        <span><b-icon-credit-card-fill></b-icon-credit-card-fill> Duplicatas</span>
        <div class="ContentTableBill">
          <div class="noContentTable" v-if="this.detailsSquareMetersSales.bills == ''">
            Não há duplicatas pagas registradas!
          </div>
          <table style="border-spacing: 1px 0; width: 100%" v-else>
            <thead style="
                color: whitesmoke;
                background: #3a4750;
                padding: 10px;
                height: 35px;
                text-align: center;
                border-spacing: 0;
              ">
              <tr style="width: 100%">
                <th style="width: 10%">Data</th>
                <th style="width: 10%">Data Pagamento</th>
                <th style="width: 10%">Tipo</th>
                <th style="width: 10%">Status</th>
                <th style="width: 10%">Forma de Pagamento</th>
                <th style="width: 10%">Vl Total</th>
                <th style="width: 10%">Vl Pago</th>
                <th style="width: 6%">Excluir</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dataBill in this.detailsSquareMetersSales.bills" :key="dataBill.id" style="
                  text-align: center;
                  height: 60px;
                  border-bottom: 1px solid rgb(218, 218, 218);
                ">
                <td>
                  {{
                    dataBill.data.split(" ")[0].split("-").reverse().join("/")
                  }}
                </td>
                <td v-if="dataBill.dataPagamento">
                  {{
                    dataBill.dataPagamento
                      .split(" ")[0]
                      .split("-")
                      .reverse()
                      .join("/")
                  }}
                </td>
                <td v-else>--</td>
                <td>{{ dataBill.tipo }}</td>
                <td class="contentStatus" v-if="!dataBill.dataPagamento">
                  <b-icon-x-circle-fill class="statusPaymentOpen"></b-icon-x-circle-fill>
                  <span style="width: 50%">Em aberto</span>
                </td>
                <td class="contentStatus" v-else>
                  <b-icon-check-circle-fill class="statusPayment"></b-icon-check-circle-fill>
                  <span style="width: 50%">Pago</span>
                </td>
                <td v-if="dataBill.dataPagamento">
                  {{ dataBill.tipoFormaPagamento }}
                </td>
                <td v-else>--</td>
                <td>
                  {{
                    dataBill.valorTotal.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
                <td>
                  {{
                    dataBill.valorPago.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })
                  }}
                </td>
                <td>
                  <b-icon-x-circle scale="1.5" class="delBill" @click="deleteBill(dataBill.id)" />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-modal>
    <div class="setBtn">
      <button class="itemBtn" :disabled="page == 1" @click="previousPage(page)">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button class="itemBtn" :disabled="this.dataLength < 9" @click="nextPage(page)">
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>
    <div :class="sizeModal">
      <div :class="modalContainer" v-if="colapseModal">
        <b-button class="closeModalSales" @click="modalHeight"> X </b-button>
        <SaleSquareMeter v-if="this.typeMeterDocument.vendaMtq" />

        <SaleSquareMeter v-if="this.typeMeterDocument.orcamentoMtq"
          :statusSquareMeter="salesSquareMeterType.orcamento" />

        <!-- <SaleLinearMeter v-if="this.typeMeterDocument.vendaMtl" />

        <SaleLinearMeter
          v-if="this.typeMeterDocument.orcamentoMtl"
          :statusLinearMeter="salesSquareMeterType.orcamento"
        /> -->
      </div>
    </div>
    <Loading v-if="spinLoading" :text="textToModalLoading" />
  </div>
</template>
<script>
import { DateTime } from "luxon";
import { mapMutations, mapState } from "vuex";
import api from "../../services/axios";

import SaleSquareMeter from "../SaleSquareMeter/Index-SaleSquareMeter.vue";
// import SaleLinearMeter from "../SaleLinearMeter/Index-SaleLinearMeter.vue";
import Loading from "../Loading/Loading.vue";

import ServiceSaleSquareMeter from "../../services/serviceSaleSquareMeter";
import ServiceBill from "../../services/serviceBill";
import ServiceNotaFiscal from "../../services/serviceNotaFiscal";
import ServiceSettings from "../../services/serviceSettings";

import { SaleTypeSituation } from "../../utils/enums/SalesSituationType";
import { TypeSale } from "../../utils/enums/SalesType";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { BillColumnsToSearchById } from "../../utils/enums/BillColumnsToSearchById";
import { SalesSquareMeterType } from "../../utils/enums/SalesSquareMeterType";
import { E_TypesReports } from "../../utils/enums/reports/SaleSquareMeter";
import toastAlertErros from "../../utils/toastAlertErros";
import { HandleErrors } from "../../utils/handleErrors";
import { formatValuesFromSales } from "../../helpers/generalSales";
import printReport from "../../reports/saleSquareMeter";
import { maskCurrency } from "../../utils/maskMoney";

export default {
  components: {
    SaleSquareMeter,
    // SaleLinearMeter,
    Loading,
  },
  props: {
    initialMonth: {
      type: Date,
      default: () => new Date(),
    },
  },
  data () {
    return {
      year: DateTime.local().year,
      maskCurrency: maskCurrency,
      month: DateTime.local(),
      typeSale: TypeSale,
      spinLoading: false,
      spinGeneral: false,
      textPesquisa: "",
      type: "",
      typeSituation: "",
      startDate: "",
      endDate: "",
      colapseModal: false,
      modalContainer: "contentModalDisable",
      dataSale: [],
      totalMonth: 0,
      totalMonthSale: 0,
      totalMonthSaleSquareMeter: 0,
      totalMonthBudgetSquareMeter: 0,
      dataBills: "",
      page: 1,
      dataLength: 0,
      filterCombobox: [
        { value: "nomeCliente", text: "Cliente" },
        { value: "nomeFuncionario", text: "Funcionário" },
        { value: "numero", text: "Nº Operação" },
        { value: "status", text: "Status" },
        { value: "situacao", text: "Situação" },
        { value: "", text: "Selecione..." },
      ],
      filterSituationCombobox: [
        { value: "Cancelado(a)", text: "Cancelado" },
        { value: "Em aberto", text: "Em aberto" },
        { value: "Aprovado(a)", text: "Aprovado" },
        { value: "Finalizado(a)", text: "Finalizado" },
        { value: "Faturado(a)", text: "Faturado" },
        { value: "", text: " Clique para selecionar a situação " },
      ],
      filterSalePerType: [
        { value: "Frente De Caixa", text: "Frente De Caixa" },
        { value: "Venda", text: "Venda" },
        { value: "Orçamento", text: "Orçamento" },
        { value: "", text: " Clique para selecionar o Status " },
      ],
      dataSquareMetersSaleUniqueId: "",
      detailsSquareMetersSales: "",
      returnDataNote: null,
      motivo: "",
      responseNfeWebMania: {
        chave: "",
        danfe: "",
        danfe_etiqueta: "",
        danfe_simples: "",
        log: "",
        modelo: "",
        motivo: "",
        nfe: "",
        serie: "",
        status: "",
        uuid: "",
        xml: "",
      },
      itemSelectedInTableSearch: null,
      salesSquareMeterType: SalesSquareMeterType,
      textToModalLoading: "",
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL",
      "ALTER_TYPE_METER_DOCUMENT",
      "SET_DATA_SEARCH_FROM_BOX_FRONT",
      "SET_DATA_SEARCH_FROM_SALE",
      "SET_DATA_SEARCH_FROM_SALE_SQUARE_METER",
      "SET_DATA_NFE",
    ]),
    getIdSale (idSale) {
      this.dataSquareMetersSaleUniqueId = idSale;
    },

    async getIdSaleDetails (idSale) {
      this.detailsSquareMetersSales = await this.findCompletedSaleSquareMeter(
        idSale
      );

      this.dataSquareMetersSaleUniqueId = idSale;

      const dataSalesFormated = this.detailsSquareMetersSales.data
        .split(" ")[0]
        .split("-")
        .reverse()
        .join("/");

      this.detailsSquareMetersSales.data = dataSalesFormated;
      if (this.detailsSquareMetersSales.dataEntrega) {
        const entregaFormatted = this.detailsSquareMetersSales.dataEntrega
          .split("-")
          .reverse()
          .join("/");
        this.detailsSquareMetersSales.dataEntrega = entregaFormatted;
      }
    },

    async findBillReportTicket (idSale) {
      const token = localStorage.getItem("token");
      const result = await ServiceSettings.findSettingsAtm();
      if (result.juros) {
        this.dataSquareMetersSaleUniqueId = idSale;
        window.open(
          `${process.env.VUE_APP_URL_BACKEND}/bills/print/report/ticket/all/bills/and/format/carne/completed/with/pix/one/${this.dataSquareMetersSaleUniqueId}?auth=${token}&type=idVendaMtq`
        );
      }
    },

    async findBill () {
      this.detailsSquareMetersSales.bills =
        await ServiceBill.getAllBillsByParamsId(
          this.dataSquareMetersSaleUniqueId,
          BillColumnsToSearchById.idVendaMtq
        );
    },

    async deleteBill (id) {
      await ServiceBill.deleteAllRelationshipsFromDuplicate(id);

      this.findBill();
    },

    async deleteSale () {
      try {
        this.spinGeneral = true;

        await ServiceSaleSquareMeter.deleteAll(
          this.dataSquareMetersSaleUniqueId
        );

        await this.filterSale(1);
        await this.filterSalePerMonth();
        this.$bvModal.hide("modal-center3");
        return this.$toast.open({
          message: "Venda removida com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateMessage(error, this.$toast);
      } finally {
        this.spinGeneral = false;
      }
    },

    async filterSale (page) {
      try {
        this.spinGeneral = true;

        const result = await ServiceSaleSquareMeter.search(
          this.textPesquisa,
          this.type,
          page,
          this.startDate,
          this.endDate
        );

        this.dataLength = result.length;
        this.dataSale = result.map((sale) => {
          if (sale.bills.length > 0) {
            const totalValorRestante =
              sale.bills?.reduce((sum, bill) => sum + bill.valorRestante, 0) ||
              0;
            return { ...sale, totalValorRestante };
          }

          return sale;
        });
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao buscar informações",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },

    handleTypeSale (name) {
      this.type = "status";
      this.textPesquisa = name;

      this.filterSale(1);
    },

    async filterSalePerMonth () {
      try {
        const result = await ServiceSaleSquareMeter.filterPerMonth(
          this.month.month,
          this.year
        );

        if (result && Object.keys(result).length) {
          this.totalMonth = result.totalBoxFront;
          this.totalMonthSale = result.totalSale;
          this.totalMonthSaleSquareMeter = result.totalSaleSquareMeter;
          this.totalMonthBudgetSquareMeter = result.totalBudgetSquareMeter;
        }
      } catch (error) {
        console.log(error);
      }
    },

    nextPage () {
      this.filterSale((this.page += 1));
    },

    previousPage () {
      if (this.page === 1) {
        return;
      } else {
        this.filterSale((this.page -= 1));
      }
    },

    async updateMonth (direction) {
      if (direction === "prev") {
        this.month = this.month.minus({ months: 1 });
        this.year = this.month.year;
      } else if (direction === "next") {
        this.month = this.month.plus({ months: 1 });
        this.year = this.month.year;
      }

      this.clearSumMonth();
      await this.filterSalePerMonth();
    },

    async modalHeight () {
      this.colapseModal = !this.colapseModal;
      this.colapseModal
        ? this.ALTER_SIZE_MODAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL("disable");
      this.modalContainer = this.modalContainer
        ? "modalContainer"
        : "contentModalDisable";

      if (!this.colapseModal && this.sizeModal === "disable") {
        this.SET_DATA_SEARCH_FROM_BOX_FRONT({});
        this.SET_DATA_SEARCH_FROM_SALE({});
      }
      await this.filterSalePerMonth();
      this.filterSale(1);
    },

    async editSales (id, status) {
      const data = await this.findSaleSquareMeterById(id);

      this.handleEditSquareMeter(status, data);
    },

    async searchTableDataForSale (idVenda) {
      try {
        const { data } = await api.get(`/sales/${idVenda}`);
        return data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao editar",
          type: "info",
        });
      }
    },

    async findSaleSquareMeterById (idVenda) {
      try {
        return await ServiceSaleSquareMeter.findById(idVenda);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao buscar",
          type: "info",
        });
      }
    },

    async findCompletedSaleSquareMeter (idVenda) {
      try {
        const { data } = await api.get(
          `/sales-square-meter/details/${idVenda}`
        );
        return data;
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao buscar",
          type: "info",
        });
      }
    },

    handleModals (name) {
      this.ALTER_TYPE_METER_DOCUMENT(name);

      this.modalHeight();
    },

    async handleEditSquareMeter (typeDocument, data) {
      const formatedSale = formatValuesFromSales(typeDocument, data);

      this.SET_DATA_SEARCH_FROM_SALE_SQUARE_METER(formatedSale);

      this.handleModals(
        typeDocument === TypeSale.orcamento ? "orcamentoMtq" : "vendaMtq"
      );
    },

    clearSumMonth () {
      this.totalMonth = "R$ 0,00";
      this.totalMonthSaleSquareMeter = "R$ 0,00";
      this.totalMonthBudgetSquareMeter = "R$ 0,00";
    },

    clearSearch () {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
      this.page = 1;
      this.filterSale();
    },

    async transformToNfe (idSale) {
      try {
        const dataNFe = await ServiceSaleSquareMeter.transformWithNota(idSale);

        this.SET_DATA_NFE(dataNFe);

        this.$toast.open({
          message: "Venda transformada em nota fiscal!",
          type: "success",
        });

        this.$router.push({
          path: `/fiscal`,
        });
      } catch (error) {
        if (
          error?.response?.data?.message &&
          error?.response?.data?.message?.includes("Validation failed")
        ) {
          return toastAlertErros.validateErroDoesNotContainFor(
            error,
            this.$toast
          );
        }

        return toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async handleDuplicate (id, status) {
      try {
        const idSaleSquareMeter = await ServiceSaleSquareMeter.duplicate(id);

        await this.editSales(idSaleSquareMeter, status);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao clonar",
          type: "info",
        });
      }
    },

    async sendCoupon (idSale) {
      try {
        this.spinLoading = true;
        this.textToModalLoading = "Aguarde! O Seu cupom está sendo emitido !";

        const data = await ServiceNotaFiscal.sendCoupon(idSale);
        this.responseNfeWebMania.danfe = await data.dataWebMania.danfe;
        this.visualizarNfe(this.responseNfeWebMania.danfe);

        await this.filterSale(1);
        return this.$toast.open({
          message: "Cupom Fiscal emitido com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && typeError?.nameError === TypeErrors.NO_PERMISSIONS) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        return this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ops! Houve algum erro. Verifique os dados e tente novamente",
          type: "info",
        });
      } finally {
        this.spinLoading = false;
        this.textToModalLoading = "";
      }
    },

    visualizarNfe (danfe) {
      window.open(danfe, "_blank", "width=700, height=800, top=500, left=500");
    },

    async findNotaById (idNota) {
      this.returnDataNote = await ServiceNotaFiscal.findNotaById(idNota);
    },

    async handleGenerateSale (budgetSquareMeter) {
      if (budgetSquareMeter.situacao === SaleTypeSituation.cancelado) {
        return this.$toast.open({
          message:
            "Não é possível gerar uma venda a partir de um orçamento cancelado",
          type: "info",
        });
      }

      await this.generateSaleSquareMeter(budgetSquareMeter.id);
    },

    async generateSaleSquareMeter (id) {
      try {
        const saleSquareMeter =
          await ServiceSaleSquareMeter.transforBudgetToSaleSquareMeter(id);

        this.$toast.open({
          message: "Venda gerada com sucesso!",
          type: "success",
        });

        this.handleTransformToSale(saleSquareMeter);
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um problema ao gerar a venda!",
          type: "info",
        });
      }
    },

    handleTransformToSale (saleSquareMeter) {
      this.SET_DATA_SEARCH_FROM_SALE_SQUARE_METER(saleSquareMeter);
      this.handleModals("vendaMtq");
    },

    async findAndViewNote (idNota) {
      const notaReturn = await ServiceNotaFiscal.findNotaById(idNota);
      if (notaReturn.status === "Não Enviada") {
        this.$toast.open({
          message:
            "Houve um erro! Verifique se o documento foi emitido e tente novamente",
          type: "info",
        });
      } else {
        notaReturn.modelo == 1 && (await this.printNf(notaReturn.id));
      }
    },

    async cancelNota () {
      try {
        const dataCancelamento = {
          uuid: this.returnDataNote.response.uuid,
          motivo: this.motivo,
        };
        this.spinLoading = true;
        this.textToModalLoading = "Aguarde! O Seu cupom está sendo cancelado !";

        await ServiceNotaFiscal.cancelNota(
          dataCancelamento,
          this.returnDataNote.id
        );

        this.$toast.open({
          message: "Nota Cancelada!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: "Houve um erro! Verifique e tente novamente",
          type: "info",
        });
      } finally {
        this.spinLoading = false;
        this.textToModalLoading = "";
        this.motivo = "";
      }
    },

    async printNf (id) {
      try {
        const pdfUrl = await ServiceNotaFiscal.printNf(id);

        window.open(pdfUrl, "_blank");
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao abrir o arquivo",
          type: "info",
        });
      }
    },

    printSimpleReport (idSaleSquareMeter) {
      printReport(E_TypesReports.simple, idSaleSquareMeter);
    },

    async handleOpenModalAndFindFiscalDocument (nameModal, dataSearchSale) {
      this.itemSelectedInTableSearch = dataSearchSale;
      this.findNotaById(dataSearchSale.idNota);
      this.openModal(nameModal);
    },

    openModal (nameModal) {
      this.$bvModal.show(nameModal);
    },
  },
  computed: {
    ...mapState({
      sizeModal: (state) => state.sizeModal,
      typeMeterDocument: (state) => state.typeMeterDocument,
      enableMtq: (state) => state.enableMtq,
      dataConfig: (state) => state.dataConfig,
    }),
    monthName () {
      return this.month.toLocaleString({ month: "long", locale: "pt-BR" });
    },
    isStoneIntegration () {
      if (this.dataCompany?.liberars[0]?.integracoes) {
        const stone =
          this.dataCompany?.liberars[0]?.integracoes.cardMachines.find(
            (machine) => machine.text === "STONE"
          );

        return stone.value;
      }

      return false;
    },
    returnTotalValue () {
      return (
        (this.detailsSquareMetersSales?.valorTotal || 0) +
        (this.detailsSquareMetersSales?.frete || 0) +
        (this.detailsSquareMetersSales?.outrosValores || 0)
      );
    },
  },
  async created () {
    this.filterSale(1);
    await this.filterSalePerMonth();
    this.ALTER_SIZE_MODAL("disable");
  },
  beforeDestroy () {
    this.ALTER_SIZE_MODAL("disable");
  },
};
</script>
<style scoped>
.GeneralSales {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 12px;
}

.ContainerBtnSales {
  display: flex;
  align-items: center;
  height: auto;
  margin-top: 70px;
  /* width: 100%; */
  gap: 1rem;
  margin-left: 30px;
}

.BtnSales {
  width: 30%;
  border: none;
  height: 35px;
  border-radius: 3px;
  font-size: 13px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.lineSearch {
  display: flex;
  align-items: center;
  margin-left: 10px;
}

.searchByMonth {
  width: 30%;
  display: flex;
  align-items: center;
  margin: 20px 0px 0px 20px;
  border-radius: 5px;
  font-size: 11px;
  justify-content: space-around;
  border: 1px solid #e3e0e0;
}

.searchFilter {
  width: 110px;
  border-right: none;
  height: 30px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.searchFilterSituation {
  width: 230px;
  border-left: none !important;
  height: 30px;
  border-radius: 0px !important;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  position: relative !important;
}

.searchFilterSituation:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.disableInput {
  display: none;
}

.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
  font-weight: 600;
}

.custom-select2 {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  width: 230px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.searchInput {
  width: 30%;
  display: flex;
}

.searchInputDate {
  width: 30%;
  display: flex;
}

.searchInputAll {
  width: 30%;
  display: flex;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  padding: 5px 20px;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.searchContainer {
  display: flex;
  height: 90px;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

.form-control {
  display: block;
  width: 230px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  height: 30px;
}

.dateInput {
  display: flex;
  gap: 10px;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #e3e0e0;
}

input:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

button:focus {
  box-shadow: none;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 10px;
}

.Arrows {
  border-radius: 5px;
  width: 15px;
  height: 15px;
}

.Arrows:hover {
  background: whitesmoke;
}

.SummarySales {
  display: flex;
  height: 90px;
  /* width: 100%; */
  gap: 1rem;
  margin-top: 10px;
  margin-left: 30px;
  align-items: center;
  padding-bottom: 5px;
  border-bottom: 1px solid #e3e3e3;
}

.ItemSummarySales {
  width: 30%;
  height: 70px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.ItemSummarySales:hover {
  background: #f7f7f7;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 20px 0 20px;
  width: 100%;
  max-width: 100%;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 30px;
}

.ContentTableBill {
  display: flex;
  flex-direction: column;
  overflow: auto;
  font-size: 13px;
  width: 100%;
  max-width: 100%;
  height: auto;
  margin-top: 10px;
  padding: 0 0 10px 0;
  background: #fbfbfb;
  border: 1px solid rgb(218, 218, 218);
  border-radius: 10px;
}

.noContentTable {
  background: #edebeb;
  padding: 10px;
  height: 100%;
  text-align: center;
  letter-spacing: 0.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentStatus {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
}

.contentStatus span {
  cursor: context-menu;
}

.statusPayment {
  background: #eff0f0;
  color: green;
}

.statusPaymentOpen {
  background: #eff0f0;
  color: rgb(132, 132, 132);
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-size: 12px;
  font-weight: normal;
}

td {
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.titleModal {
  font-size: 1rem;
  border-bottom: 1px solid #e6e7e8;
  font-weight: bold;
}

.titleModalBill {
  display: flex;
  justify-content: space-between;
  padding: 0px 0px 5px 0px;
  border-bottom: 1px solid #e6e7e8;
}

.textModalBill {
  font-weight: bold;
  width: 95%;
  text-align: center;
}

.closeModalBill {
  cursor: pointer;
}

.closeModalBill:hover {
  color: red;
}

.btnModal {
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnModalCancel {
  height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.contentBtns {
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 20px;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.contentMotivo {
  font-size: 13px;
  margin-bottom: 20px;
  padding: 10px;
}

.contentMotivo label {
  position: relative;
  top: 10px;
}

.contentMotivo textarea:focus {
  box-shadow: none;
  border: 1px solid rgb(97, 95, 95);
}

.contentMotivo span {
  position: relative;
  top: 10px;
  color: red;
  cursor: context-menu;
}

.contentMotivo ::placeholder {
  font-size: 12px;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.alertCancel {
  font-size: 10px;
  color: rgb(121, 3, 3);
  text-align: center;
  display: flex;
  margin-bottom: -10px;
  cursor: context-menu;
}

.delBill {
  display: flex;
  justify-content: center;
  align-items: center;
  color: red;
  border: none;
  cursor: pointer;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  margin-top: 20px;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModalSales {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModalSales:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnActions1:hover {
  background: rgb(215, 213, 213);
  color: white;
}

.btnActions1 {
  border-radius: 10px;
}

.contentActions {
  color: rgb(82, 78, 78);
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.typeSale {
  color: #403b74;
  font-weight: bold;
}

.typeSale2 {
  color: #48b865;
  font-weight: bold;
}

.typeSale3 {
  color: #3e66ae;
  font-weight: bold;
}

.situationBall {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid rgb(31, 30, 30);
}

.situationContent {
  display: flex;
  align-items: center;
  width: 90px;
  justify-content: flex-start;
  font-weight: bold;
}

.situationContent span {
  margin: 0 0 0 10px;

  font-weight: 600;
}

.situationContainer {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.summaryMonthText {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.month {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.closeModalDetails {
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0px;
  top: 0px;
  color: red;
  border-radius: 2px;
  cursor: pointer;
  transition: ease-in all 0.2s;
  font-size: 18px;
}

.contentModal {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.saleHeader {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
}

.saleItem {
  display: flex;
  flex-direction: column;
  flex: 1 1 30%;
}

.saleItem p {
  margin: 0;
  font-size: 12px;
}

.rowModal {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 20px 0 5px 0;
  justify-content: space-between;
  margin-bottom: 10px;
}

.input-group-supplier {
  flex: 1 1 calc(33.33% - 10px);
}

.input-group-supplier input {
  width: 80%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-top: 5px;
  font-size: 12px;
  background-color: white;
}

.responsiveItems {
  padding-top: 25px;
  padding-bottom: 5px;
  margin-top: 0rem !important;
}

.contentItens {
  margin-top: 1.5rem !important;
}

.input-group-supplier {
  width: 45%;
  position: relative;
}

.user-label {
  position: absolute;
  left: 15px;
  font-size: 11px;
  z-index: 30;
  bottom: 21px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  background: white;
}

.borderInput {
  font-size: 12px !important;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.product-item {
  border-bottom: 1px solid rgb(218, 218, 218);
  margin-bottom: 1rem;
  font-size: 12px;
  width: 100%;
}

.product-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.product-name-type {
  display: flex;
}

.product-name-type {
  width: 100%;
}

.textEllipsis {
  max-width: 235px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.product-details {
  display: flex;
  flex-direction: column;
}

.product-row {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 10px;
}

.product-detail {
  flex: 1 1 30%;
  margin-right: 10px;
}

.product-detail:last-child {
  margin-right: 0;
}

@media screen and (min-width: 758px) and (max-width: 1200px) {
  .GeneralSales {
    margin-left: 60px;
    width: calc(100% - 60px);
    font-size: 11px;
  }

  .searchByMonth {
    width: 40%;
    margin: 10px 0px 0px 10px;
  }

  .form-control {
    width: 140px;
  }

  .ItemSummarySales {
    height: 60px;
    font-size: 11px;
  }

  .SummarySales {
    height: 100px;
    padding-bottom: 10px;
  }

  .BtnSales {
    height: 30px;
    font-size: 11px;
  }

  .searchAll {
    font-size: 11px;
    padding: 5px 9px;
  }

  .searchInputAll {
    width: 18%;
  }

  .searchInputDate {
    width: 45%;
  }

  .btnPeriod {
    width: 60px;
    font-size: 10px;
  }

  .searchFilter {
    width: 110px;
    font-size: 10px;
  }

  .searchContainer {
    height: 70px;
  }

  .setBtn {
    margin: 0 1rem 10px 0;
  }

  .itemBtn {
    width: 18px;
    height: 18px;
  }

  .centerItemBtn {
    width: 18px;
    height: 18px;
  }

  .headTable {
    height: 25px;
  }

  .ContentTable {
    padding: 0px 20px 0 20px;
  }
}

@media screen and (max-width: 700px) {
  .GeneralSales {
    display: flex;
    flex-direction: column;
    margin: auto;
    width: 100%;
    height: 100vh;
    font-size: 10px;
  }

  .ContainerBtnSales {
    margin-top: 55px;
    flex-wrap: wrap;
  }

  .lineSearch {
    display: flex;
    align-items: center;
    margin-left: 0px;
    justify-content: center;
  }

  .searchContainer {
    display: none;
  }

  .BtnSales {
    height: 70px;
  }

  .searchByMonth {
    width: 80%;
    margin: 20px 0px 0px 0px;
  }

  .ItemSummarySales {
    height: 100px;
  }

  .ContentTable {
    height: auto;
    padding: 10px 10px 0 10px;
  }

  .ModalGeneral {
    position: fixed !important;
    z-index: 900;
    top: 53px;
    left: 0;
    width: 100%;
  }

  .disable {
    width: 100%;
    height: 0;
    left: 0;
  }
}
</style>