<template>
  <div
    :style="`width: 100%; ${
      filterIsEnable
        ? 'padding-right: 40px !important'
        : 'padding-right: 20px !important'
    } `"
  >
    <div style="position: relative">
      <div
        :style="`${
          filterIsEnable
            ? 'border-radius: 0'
            : 'border-radius:0 0.25rem 0.25rem 0'
        }`"
        :class="`iconSelectInfinit ${isDisabled && 'iconDisabledInfinit'}`"
        v-if="enableIconCreate"
        @click="createModal"
      >
        <div role="button">
          <b-icon-plus scale="2"></b-icon-plus>
        </div>
      </div>

      <div
        :class="`iconFilterSelectInfinit ${
          isDisabled && 'iconDisabledInfinit'
        }`"
        v-if="filterIsEnable"
        @click="toggleFilter"
        ref="boxClick"
      >
        <div role="button">
          <b-icon-filter scale="1.3"></b-icon-filter>
        </div>
      </div>

      <div
        class="boxFilter"
        ref="box"
        :style="!filterIsOpen && 'display: none'"
      >
        <div class="containerRadio">
          <label>
            <input
              type="radio"
              :name="`radio-${this.typeFilter}`"
              v-model="filterType"
              value="includes"
            />
            <span style="border-radius: 5px 0 0 5px">Contém</span>
          </label>

          <label>
            <input
              type="radio"
              :name="`radio-${this.typeFilter}`"
              v-model="filterType"
              value="start"
            />
            <span style="border-radius: 0 5px 5px 0">Iniciar por</span>
          </label>
        </div>
      </div>

      <label v-if="defaultLabel" class="floatLabel"> {{ label }} </label>
      <label v-else class="defaultLabel"> {{ label }}: </label>

      <v-select
        :value="selected"
        :label="text"
        :options="dataList"
        :filterable="false"
        :loading="loading ? true : false"
        :custom-filter="customFilter"
        :disabled="isDisabled"
        @input="setSelected"
        @open="onOpen"
        @close="onClose"
        @search="searchByWriting"
      >
        <template #list-footer>
          <li v-show="hasNextPage" ref="load" class="loader">
            Loading more options...
          </li>
        </template>

        <template #no-options>
          <div>Resultado não encontrado!</div>
        </template>

        <template #spinner="{ loading }">
          <div
            v-if="loading"
            style="border-left-color: rgba(88, 151, 251, 0.71)"
            class="vs__spinner"
          ></div>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { debounce } from "../../../utils/debounce";

export default {
  name: "InfiniteScroll",
  props: {
    dataInput: {
      type: String,
      default: "",
    },
    isEdit: {
      type: String,
    },
    text: {
      type: String,
      default: "nome",
    },
    value: {
      type: String,
      default: "id",
    },
    onSearch: {
      type: Function,
    },
    label: {
      type: String,
      default: "label",
    },
    enableIconCreate: {
      type: Boolean,
      default: true,
    },
    openCreateModal: {
      type: Function,
    },
    isDisabled: {
      type: Boolean,
    },
    findAll: {
      type: Boolean,
      default: false,
    },
    defaultLabel: {
      type: Boolean,
      default: true,
    },
    clearSelected: {
      type: Boolean,
      default: false,
    },
    searchFields: {
      typeof: Array,
    },
    filterIsEnable: {
      type: Boolean,
      default: true,
    },
    typeFilter: {
      type: String,
      default: "default",
    },
  },
  data() {
    return {
      observer: null,
      limit: 10,
      dataList: [],
      selected: null,
      hasNextPage: false,
      debouncedChanged: debounce(this.search),
      loading: false,
      filterIsOpen: false,
      filterType: "includes",
    };
  },
  mounted() {
    this.observer = new IntersectionObserver(this.infiniteScroll);
    this.removeClearButtonTitle();

    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  created() {
    this.findInitial();
  },
  methods: {
    async search(search = "") {
      this.loading = true;

      if (this.onSearch) {
        this.dataList = await this.onSearch("", search, this.filterType);
      }

      this.loading = false;
    },
    async findInitial() {
      this.loading = true;

      if (this.dataInput) {
        const result = await this.onSearch(this.dataInput, "", this.filterType);
        this.selected = result.find(
          (item) => item[this.value] === this.dataInput
        );

        this.dataList = result;
      } else {
        this.dataList = await this.onSearch("", "", this.filterType);
      }

      this.loading = false;
    },
    async findToEdit() {
      this.loading = true;

      const result = await this.onSearch(this.dataInput, "", this.filterType);
      this.selected = result.find(
        (item) => item[this.value] === this.dataInput
      );

      this.$emit("selectItem", this.selected);
      this.dataList = result;

      this.loading = false;
    },
    async infiniteScroll([{ isIntersecting, target }]) {
      if (isIntersecting) {
        const ul = target.offsetParent;
        const scrollTop = target.offsetParent.scrollTop;
        this.limit += 10;
        await this.$nextTick();
        ul.scrollTop = scrollTop;
      }
    },
    async onOpen() {
      if (this.hasNextPage) {
        await this.$nextTick();
        this.observer.observe(this.$refs.load);
      }
    },
    searchByWriting(search = "") {
      this.debouncedChanged(search);
    },
    setSelected(itemSelect) {
      this.selected = itemSelect;

      if (itemSelect) {
        this.$emit("update:dataInput", itemSelect[this.value]);
        this.$emit("selectItem", itemSelect);
      } else {
        this.$emit("update:dataInput", "");
        this.$emit("selectItem", "");
      }
    },

    onClose() {
      this.observer.disconnect();
    },

    removeClearButtonTitle() {
      this.$nextTick(() => {
        const clearButton = document.querySelector(".vs__clear");
        if (clearButton) {
          clearButton.title = "Remover seleção";
        }
      });
    },

    createModal() {
      if (this.openCreateModal) return this.openCreateModal();

      return;
    },
    customFilter(option, label, search) {
      const searchLower = search.toLowerCase();

      if (this.searchFields && this.searchFields.length) {
        return this.searchFields.some((field) =>
          String(option[field]).toLowerCase().includes(searchLower)
        );
      }

      return option[this.text].toLowerCase().includes(searchLower);
    },

    toggleFilter() {
      this.filterIsOpen = !this.filterIsOpen;
    },

    handleClickOutside(event) {
      const box = this.$refs.box;
      const boxClick = this.$refs.boxClick;

      if (
        box &&
        !box.contains(event.target) &&
        boxClick &&
        !boxClick.contains(event.target)
      ) {
        this.filterIsOpen = false;
      }
    },
  },
  watch: {
    clearSelected() {
      this.selected = "";
    },
    isEdit() {
      this.findToEdit();
    },
    findAll() {
      this.findInitial();
    },
  },
};
</script>

<style>
.loader {
  text-align: center;
  color: #bbbbbb;
  height: 30px !important;
}

.boxFilter {
  display: flex;
  height: 30px;
  justify-content: space-between;
  background-color: white;
  position: absolute;
  top: 33px;
  right: -40px;
  z-index: 500;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 #0000006b;
}

.boxFilter::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 3px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid white;
  z-index: -99999;
}

.vs__dropdown-toggle {
  height: 30px;
}

.vs__search {
  font-size: 12px !important;
  color: #495057 !important;
}

.vs__dropdown-menu {
  font-size: 12px;
}

.vs__selected {
  font-size: 12px !important;
}

.vs__dropdown-toggle {
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.vs1__listbox {
  width: 100%;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.vs__dropdown-menu {
  padding: 0 0 5px 0 !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.vs__dropdown-option--highlight {
  white-space: normal !important;
  word-break: break-all !important;
}

.floatLabel {
  position: absolute;
  left: 3px;
  font-size: 8.5px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  top: -9px;
  left: 7px;
  pointer-events: none;
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  z-index: 9999;
}

.defaultLabel {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 30px;
  left: 0px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
  font-weight: 600;
}

.iconSelectInfinit {
  position: absolute;
  right: -18px !important;
  top: 0 !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 0.25rem 0.25rem 0;
}

.iconFilterSelectInfinit {
  position: absolute;
  right: -38px !important;
  top: 0 !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: whitesmoke;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 0 0.25rem 0.25rem 0;
  border-left: solid 1px rgba(129, 129, 129, 0.26);
}

.iconDisabledInfinit {
  pointer-events: none !important;
}

.containerRadio {
  display: flex;
}

.containerRadio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
}

.containerRadio label input {
  position: absolute;
  left: -9999px;
}

.iconSelectInfinit:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}

.containerRadio label input:checked + span {
  background-color: #f3f2f2;
  color: rgb(0, 0, 0);
}

.containerRadio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00486e;
}

.containerRadio label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 2px;
  transition: 0.25s ease;
  font-size: 11px;
}

.containerRadio label span:hover {
  background-color: #e6e6e6;
}

.containerRadio label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.1em #00486e;
}
</style>