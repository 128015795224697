import api from "../services/axios";

class ServiceSped {
  async generate(startDate, endDate) {
    await api.post(`/sped/generate?startDate=${startDate}&endDate=${endDate}`);
  }

  async findSped() {
    const { data } = await api.get(`/sped`);
    return data
  }

  async downloadFile() {
    try {
      const response = await api.get(`/sped/download/txt`, {
        responseType: "blob",
      });
      const href = URL.createObjectURL(response.data);
      
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'sped.txt');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return null
    } catch (error) {
      const blob = error.response.data;
      
      const jsonData = JSON.parse(await new Response(blob).text())
      throw jsonData 
    }
  }
}

export default new ServiceSped()