<template>
  <b-card>
    <div style="font-size: 12px">
      <div class="title">
        <span>Cadastro de Cliente</span>
        <span class="requiredFields">* Campos Obrigatórios</span>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações
          Gerais</span
        >
        <div class="rowContent">
          <b-form-input
            hidden
            class="col-sm-1"
            v-model="dataCostumer.id"
          ></b-form-input>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.nome"
            />
            <label class="user-label">
              Nome <span class="validRed">*</span>
            </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.cpfCnpj"
              v-mask="maskCpfCnpj"
              maxlength="18"
              @input="checkInputLength"
            />
            <label class="user-label">
              CNPJ/CPF <span class="validRed">*</span>
            </label>
            <button
              class="bcsCep"
              v-if="isInputLengthValid"
              @click="fetchCnpj(dataCostumer.cpfCnpj)"
            >
              <b-spinner
                v-if="spinGeneralCnpj"
                small
                label="Small Spinner"
              ></b-spinner>
              buscar
            </button>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.ie"
            />
            <label class="user-label"> Inscrição Estadual </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.rg"
            />
            <label class="user-label"> Registro Geral </label>
          </div>
        </div>
      </div>
      <div class="rowContainer">
        <span> <b-icon-geo-alt-fill></b-icon-geo-alt-fill> Localização</span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.cep"
            />
            <label class="user-label"> CEP </label>
            <button class="bcsCep" @click="fetchCep(dataCostumer.cep)">
              <b-spinner
                v-if="spinGeneral"
                small
                label="Small Spinner"
              ></b-spinner>
              buscar
            </button>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.endereco"
            />
            <label class="user-label"> Endereço </label>
          </div>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.bairro"
            />
            <label class="user-label"> Bairro </label>
          </div>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.complemento"
            />
            <label class="user-label"> Complemento </label>
          </div>
        </div>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.numero"
            />
            <label class="user-label"> Número </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.cidade"
            />
            <label class="user-label"> Cidade </label>
          </div>

          <div class="selectPlus">
            <label class="fixedSelect2">UF</label>
            <b-form-select
              class="borderInput shadow-none"
              value-field="value"
              text-field="text"
              :options="ufs"
              v-model="dataCostumer.uf"
            >
            </b-form-select>
          </div>
        </div>
      </div>
      <div class="rowContainer">
        <span> <b-icon-telephone-fill></b-icon-telephone-fill> Contatos</span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-mask="maskTelefone"
              v-model="dataCostumer.telefone"
            />
            <label class="user-label"> Telefone/Celular </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.celular"
              v-mask="maskCelular"
            />
            <label class="user-label"> Whatsapp </label>
            <div class="iconSelect">
              <div role="button">
                <img class="whatsapp" :src="whatsapp" />
              </div>
            </div>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataCostumer.email"
            />
            <label class="user-label"> Email </label>
          </div>
        </div>
      </div>
      <div class="rowContainer" style="margin-bottom: 30px">
        <span>
          <b-icon-info-circle></b-icon-info-circle> Outras Informações</span
        >
        <div>
          <div class="rowContent">
            <div class="input-group">
              <input
                type="date"
                class="input borderInput"
                v-model="dataCostumer.dataNascimento"
              />
              <label class="user-label"> Data de Nascimento </label>
            </div>
            <div class="selectPlus">
              <label class="fixedSelect2">Tipo consumidor</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                :options="consumidorFinal"
                v-model="dataCostumer.consumidor_final"
              >
              </b-form-select>
            </div>
            <div class="selectPlus">
              <label class="fixedSelect2">Contribuinte</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                :options="contribuinte"
                v-model="dataCostumer.contribuinte"
              >
              </b-form-select>
            </div>
          </div>
          <div class="rowObservation">
            <div style="width: 100%;">
             <span>Observações</span>
              <textarea
                class="inputTextArea"
                rows="3"
                max-rows="6"
                maxlength="500"
                v-model="dataCostumer.observacao"
              />
            </div>
            <div class="selectPlus">
              <label class="fixedSelect2">Status</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                :options="status"
                v-model="dataCostumer.status"
                :disabled="!this.dataCostumer.id"
              >
              </b-form-select>
            </div>
          </div>
          <div class="btnsContainer">
            <b-button class="btnsSaveNeutral" @click="clearInputs"
              >Novo
              <b-icon-arrow-clockwise class="ml-1"></b-icon-arrow-clockwise
            ></b-button>
            <b-button class="btnsSave" @click="saveOrUpdateCustomer"
              >{{ dataCostumer.id ? "Atualizar" : "Salvar" }}
              <b-icon-person-check class="ml-1"></b-icon-person-check
            ></b-button>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import mixinCustomer from "../../mixins/Customer/Customer";

export default {
  mixins: [mixinCustomer],
};
</script>
<style scoped>
.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.form-group {
  margin-bottom: 0.2rem;
}

span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.iconSelect {
  position: absolute;
  right: 0px !important;
  z-index: 10;
  background-color: rgb(213, 213, 213);
  color: whitesmoke;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: context-menu;
}

.whatsapp {
  width: 20px;
  height: 20px;
}

.bcsCep {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.validRed {
  font-size: 13px;
  color: red;
}

.user-label-ob {
  position: absolute;
  left: 40px;
  font-size: 11px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.validRed::before {
  color: blue;
}
.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 8.9px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
  font-weight: 600;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.input:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTotals input:hover {
  background: #d9d9d9 !important;
  border: 1px solid #262626;
}
.custom-select:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.textarea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTextArea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
  justify-content: space-between;
}

.title span {
  font-weight: 600;
}

.requiredFields {
  font-size: 11px;
  color: red;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.rowContainer span {
  font-weight: 600;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowObservation {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  align-items: center;
  width: 100%;
  height: 80px;
  padding: 10px 0 5px 0;
}

.btnsContainer {
  gap: 20px;
  display: flex;
  justify-content: space-between;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
  font-weight: 600;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #d7d7d7;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-size: 12px;
  font-weight: 600;
}

.input-group {
  width: 30%;
}

.input-group-ob {
  width: 65%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1rem 0 1rem;
}

.card {
  border: none !important;
}

@media screen and (max-width: 700px) {
  .rowContent {
    display: flex;
    width: 100%;
    padding: 5px 0 5px 0;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .rowObservation {
    flex-direction: column;
    height: auto;
  }

  .selectPlus {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }
}
</style>
