<template>
  <div class="General">
    <div class="ContainerBtn">
      <b-button @click="modalHeight" class="Btn" style="background: #3e66ae">
        <b-icon-plus scale="1.5"></b-icon-plus>
        Nova Nota de Compra</b-button
      >
    </div>

    <div class="lineSearch">
      <div class="searchByMonth">
        <div>Resumo Do Mês</div>
        <div class="pagMonth">
          <b-icon-chevron-left
            class="Arrows"
            id="btn-month-prev"
            @click="updateMonth('prev')"
          ></b-icon-chevron-left>
          <b-form-select
            class="searchMonth custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="monthSelected"
            :options="filterMonth"
            @change="monthHasChanged"
          ></b-form-select>
          <b-form-select
            class="searchYear custom-select3"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="yearSelected"
            :options="filterYear"
            @change="monthHasChanged"
          ></b-form-select>
          <b-icon-chevron-right
            class="Arrows"
            style="margin: 0 0 0 7px"
            id="btn-month-next"
            @click="updateMonth('next')"
          ></b-icon-chevron-right>
        </div>
      </div>
    </div>

    <div class="Summary">
      <div class="ItemSummary" style="border: 3px ridge #403b74">
        <span>Produtos adicionados ( {{ monthName }} )</span>
        <span> {{ this.countProducts }} </span>
      </div>
      <div class="ItemSummary" style="border: 3px ridge #3e66ae">
        <span>Notas Importadas ( {{ monthName }} )</span>
        <span> {{ this.dataLength }} </span>
      </div>
      <div class="ItemSummary" style="border: 3px ridge #48b865">
        <span>Valor Total ( {{ monthName }} )</span>
        <span>
          {{
            this.sumPurchase.toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
    </div>
    <div class="ContainerSearchInput">
      <div class="textRegister">
        <span>Notas de Compra Registradas</span>
      </div>
      <div class="searchContainer">
        <div class="searchAvancedContent">
          <button
            v-if="!isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Realizar Pesquisa Avançada
          </button>
          <button
            v-if="isAdvancedSearch"
            class="searchAvanced"
            @click="enableAdvancedSearch"
          >
            Pesquisa por mês
          </button>
        </div>
        <div class="searchInput" v-if="isAdvancedSearch">
          <button class="btnPeriod" size="sm">Período</button>
          <div class="dateInput">
            <input
              type="date"
              id="date"
              v-model="startDate"
              style="padding: 0.25rem 0.25rem 0.25rem 0.5rem; width: 130px"
            />
            <input
              type="date"
              id="date"
              v-model="endDate"
              style="padding: 0.25rem 1rem 0.25rem 0.25rem; width: 140px"
            />
          </div>
        </div>
        <div class="searchInput">
          <b-form-select
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterCombobox"
          ></b-form-select>
          <b-input
            size="sm"
            class="inputSearch"
            v-model="textPesquisa"
            @keyup.enter="filterPerMonth(page)"
          ></b-input>
          <b-button @click="filterPerMonth" size="sm" class="searchBtn">
            <b-icon-search></b-icon-search>
          </b-button>
          <button class="searchAll" @click="filterPerMonth(page)">
            ver tudo
          </button>
        </div>
      </div>
    </div>
    <div class="ContentTable">
      <table>
        <thead
          style="
            color: whitesmoke;
            background: #3a4750;
            text-align: center;
            height: 28px;
          "
        >
          <tr>
            <th>Data</th>
            <th>Nº Nfe</th>
            <th>Fornecedor</th>
            <th>Importada</th>
            <th>Valor Total</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody style="color: #3a4750">
          <tr
            v-for="dataPurchase in dataPurchases"
            :key="dataPurchase.id"
            style="text-align: center; height: 28px"
          >
            <td>{{ dataPurchase.data | moment }}</td>
            <td>{{ dataPurchase.numeroNfe }}</td>
            <td>{{ dataPurchase.razaoSocial }}</td>
            <td v-if="dataPurchase.chaveNfe">
              <b-icon-check-circle-fill
                style="color: green"
                scale="1.5"
              ></b-icon-check-circle-fill>
            </td>
            <td v-else>
              <b-icon-x-circle style="color: red" scale="1.5"></b-icon-x-circle>
            </td>
            <td>
              {{
                (dataPurchase.valorNfe || dataPurchase.valorTotal).toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>
            <td>
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  class="btnActions"
                  size="sm"
                  variant="none"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item
                    v-if="!dataPurchase.chaveNfe"
                    @click="findIdByEdit(dataPurchase.id)"
                  >
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-b-modal.modal-excluir
                    @click="findIdDetails(dataPurchase.id)"
                  >
                    Excluir
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="findIdDetails(dataPurchase.id)"
                    v-b-modal.modal-center2
                    v-if="dataPurchase.chaveNfe"
                  >
                    Ver detalhes
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-modal
      id="modal-center2"
      hide-footer
      hide-header
      centered
      size="lg"
      title="Detalhes"
    >
      <div>
        <h6 class="titleModal">Detalhes da movimentação</h6>
        <div class="contentModal">
          <span> <b-icon-truck></b-icon-truck> Dados Fornecedor</span>
          <div class="rowModal">
            <div class="input-group-supplier">
              <input
                disabled
                class="input borderInput"
                v-model="this.dataSupplier.nomeFantasia"
              />
              <label class="user-label"> Nome Fantasia </label>
            </div>
            <div class="input-group-supplier">
              <input
                disabled
                class="input borderInput"
                v-model="this.dataSupplier.cpfCnpj"
              />
              <label class="user-label"> CNPJ </label>
            </div>
          </div>
        </div>
        <div class="contentModal">
          <span> <b-icon-file-text></b-icon-file-text> Dados da NF-e</span>
          <div class="rowModal">
            <div class="input-group">
              <input
                disabled
                type="date"
                class="input borderInput"
                v-model="this.dataDetails.dataCompra"
              />
              <label class="user-label"> Data da Compra </label>
            </div>
            <div class="input-group">
              <input
                disabled
                class="input borderInput"
                v-model="this.dataDetails.numeroNfe"
              />
              <label class="user-label"> N° NF-e </label>
            </div>
            <div class="input-group">
              <input
                disabled
                class="input borderInput"
                v-model="this.dataDetails.modeloNfe"
              />
              <label class="user-label"> Modelo </label>
            </div>
            <div class="input-group">
              <input
                disabled
                class="input borderInput"
                v-model="this.dataDetails.serieNfe"
              />
              <label class="user-label"> Série </label>
            </div>
            <div class="input-group">
              <input
                disabled
                class="input borderInput"
                v-model="this.dataDetails.valorNfe"
              />
              <label class="user-label"> Valor nota </label>
            </div>
          </div>
          <div class="rowModal">
            <div class="input-group-key">
              <b-form-input
                ref="chaveNfeInput"
                disabled
                class="input borderInput"
                v-model="dataDetails.chaveNfe"
              />
              <label class="user-label"> Chave da NF-e </label>
            </div>
            <button
              class="iconCopy"
              style="border: none"
              @click="copyChaveNfeToClipboard"
            >
              <b-icon-stickies scale="1" class="mr-1"></b-icon-stickies>
              Copiar
            </button>
          </div>
        </div>
        <div class="contentModal">
          <span> <b-icon-box-seam></b-icon-box-seam> Itens Importados </span>
          <div class="ContentTableModal">
            <table style="width: 100%">
              <thead
                style="
                  color: whitesmoke;
                  background: #3a4750;
                  text-align: center;
                  height: 29px;
                "
              >
                <tr style="width: 100%">
                  <th>Nome</th>
                  <th>Quantidade</th>
                  <th>Valor</th>
                  <th>Valor Total</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="product in returnProducts"
                  style="text-align: center; height: 28px"
                  :key="product.id"
                >
                  <td>{{ product.nome }}</td>
                  <td>{{ product.quantidade }}</td>
                  <td>
                    {{
                      product.valorUnitario.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
                    }}
                  </td>
                  <td>
                    {{
                      (
                        product.valorUnitario * product.quantidade
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div class="btnModal">
          <button @click="redirectSefaz" class="btnContent">
            Ir para a Sefaz
          </button>
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center2')"
          >
            Não, Obrigado!
          </button>
        </div>
      </div>
    </b-modal>

    <div class="FooterPagination">
      <div class="setBtn">
        <button
          class="itemBtn"
          :disabled="page == 1"
          @click="previousPage(page)"
        >
          <b-icon-chevron-left></b-icon-chevron-left>
        </button>
        <button class="centerItemBtn">{{ page }}</button>
        <button
          class="itemBtn"
          :disabled="this.dataLength < 9"
          @click="nextPage(page)"
        >
          <b-icon-chevron-right></b-icon-chevron-right>
        </button>
      </div>
    </div>
    <div :class="sizeModal">
      <div :class="modalContainer" v-if="colapseModal">
        <b-button class="closeModalPurchase" @click="modalHeight"> X </b-button>
        <Purchase style="margin-top: 30px" />
      </div>
    </div>

    <b-modal
      id="modal-excluir"
      hide-footer
      hide-header
      centered
      size="md"
      title="Excluir"
    >
      <div>
        <h6 class="textModalBill">Deseja excluir a compra?</h6>
        <div class="ctnLoadDelAll" v-if="spinDelete">
          <b-spinner class="spin"></b-spinner>
          <span>Aguarde ...</span>
        </div>
        <div v-else class="ctnDelAll">
          <button
            class="btnContentConfirmedWhiteDelAll"
            @click="$bvModal.hide('modal-excluir')"
          >
            Não, obrigado!
          </button>
          <button class="btnContentConfirmedDelAll" @click="deleteAll">
            Sim, quero excluir!
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations, mapState } from "vuex";
import { DateTime } from "luxon";
import ServicePurchase from "../../services/servicePurchase";
import serviceProductsPurchase from "../../services/serviceProductsPurchase";
import Purchase from "./Purchase.vue";
import api from "../../services/axios";
import serviceSupplier from "../../services/serviceSupplier";
import moment from "moment";

export default {
  components: {
    Purchase,
  },
  data() {
    return {
      year: DateTime.local().year,
      month: DateTime.local(),
      textPesquisa: "",
      type: "",
      startDate: "",
      endDate: "",
      monthSelected: "",
      yearSelected: "",
      colapseModal: false,
      spinDelete: false,
      isAdvancedSearch: false,
      modalContainer: "contentModalDisable",
      idPurchaseFromModal: null,
      page: 1,
      dataLength: 0,
      sumPurchase: 0,
      countProducts: 0,
      dataPurchases: {},
      dataDetails: {},
      returnProducts: [],
      dataSupplier: {},
      product: {},
      dataSumPurchase: {},
      filterCombobox: [
        { value: "razaoSocial", text: "Fornecedor" },
        { value: "numeroNfe", text: "NF-e" },
        { value: "", text: "Selecione..." },
      ],
      filterMonth: [
        { value: "1", text: "Janeiro" },
        { value: "2", text: "Fevereiro" },
        { value: "3", text: "Março" },
        { value: "4", text: "Abril" },
        { value: "5", text: "Maio" },
        { value: "6", text: "Junho" },
        { value: "7", text: "Julho" },
        { value: "8", text: "Agosto" },
        { value: "9", text: "Setembro" },
        { value: "10", text: "Outubro" },
        { value: "11", text: "Novembro" },
        { value: "12", text: "Dezembro" },
      ],
      filterYear: [
        { value: "2021", text: "2021" },
        { value: "2022", text: "2022" },
        { value: "2023", text: "2023" },
        { value: "2024", text: "2024" },
        { value: "2025", text: "2025" },
        { value: "2026", text: "2026" },
        { value: "2027", text: "2027" },
        { value: "2028", text: "2028" },
        { value: "2029", text: "2029" },
        { value: "2030", text: "2030" },
        { value: "2031", text: "2031" },
        { value: "2032", text: "2032" },
      ],
    };
  },
  methods: {
    ...mapMutations(["ALTER_SIZE_MODAL", "SET_DATA_SEARCH_FROM_PURCHASE"]),

    async modalHeight() {
      this.colapseModal = !this.colapseModal;
      this.colapseModal
        ? this.ALTER_SIZE_MODAL("ModalGeneral")
        : this.ALTER_SIZE_MODAL("disable");
      this.modalContainer = this.modalContainer
        ? "modalContainer"
        : "contentModalDisable";
      if (!this.colapseModal && this.sizeModal === "disable") {
        this.SET_DATA_SEARCH_FROM_PURCHASE({});
      }
      await this.filterPerMonth();
      this.filterPerMonth();
    },

    async enableAdvancedSearch() {
      this.isAdvancedSearch = !this.isAdvancedSearch;
      if (!this.isAdvancedSearch) {
        this.startDate = "";
        this.endDate = "";
      }
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
    },
    async filterPurchase(page) {
      try {
        const data = await ServicePurchase.searchPurchase(
          this.textPesquisa,
          this.type,
          page,
          this.startDate,
          this.endDate
        );
        this.dataLength = data.data.length;
        this.dataPurchase = data.data;
        this.clearSearch();
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao listar as compras",
          type: "info",
        });
      }
    },

    monthHasChanged() {
      this.month = this.month.set({ month: parseInt(this.monthSelected) });
      this.year = this.yearSelected;
      this.filterPerMonth();
    },

    async filterPerMonth() {
      try {
        const { data } = await api.get(
          `/purchase/per/month/search?month=${this.month.month}&year=${
            this.year
          }&q=${encodeURIComponent(this.textPesquisa)}&type=${this.type}&page=${
            this.page
          }&isAdvancedSearch=${this.isAdvancedSearch}&startDate=${
            this.startDate
          }&endDate=${this.endDate}`
        );
        const purchase = data?.data ?? [];
        this.dataPurchases = purchase.purchaseTotal;
        this.dataLength = data.data.purchaseTotal.length;
        this.dataSumPurchase = data.data.purchaseTotal;
        this.countProducts = data.data.countProducts;
        this.sumPurchase = this.getTotal(this.dataSumPurchase);
      } catch (error) {
        console.log(error);
      }
    },

    getTotal(dataPurchase) {
      return dataPurchase.reduce(
        (total, purchase) => total + purchase.valorTotal,
        0
      );
    },

    openModalDeletePurchase(id) {
      this.$bvModal.show("modalConfirmDeletePurchase");
      this.idPurchaseFromModal = id;
    },

    async findIdByEdit(id) {
      this.unique = id;
      const data = await ServicePurchase.findPurchaseById(this.unique);
      data.products = await serviceProductsPurchase.getProductsForGrid(
        this.unique
      );
      this.handleEditPurchase(data);
    },

    handleEditPurchase(dataPurchase) {
      this.SET_DATA_SEARCH_FROM_PURCHASE(dataPurchase);
      this.modalHeight();
    },

    async findIdDetails(id) {
      this.unique = id;
      this.dataDetails = await ServicePurchase.findPurchaseById(this.unique);

      this.dataDetails.valorNfe = this.dataDetails.valorNfe.toLocaleString("pt-br", { style: "currency", currency: "BRL" })
      this.returnProducts = await serviceProductsPurchase.getProductsForGrid(
        this.dataDetails.id
      );
      this.dataSupplier = await serviceSupplier.findById(
        this.dataDetails.idFornecedor
      );
    },

    async deleteAll() {
      this.spinDelete = true;
      try {
        for (const item of this.returnProducts) {
          await serviceProductsPurchase.delete(item.idProduto, item.id);
        }
        await serviceProductsPurchase.deleteAll(this.dataDetails.id);
        this.$bvModal.hide("modal-excluir");
        this.filterPerMonth(1);
        this.filterPerMonth();

        return this.$toast.open({
          message: "Deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: `${error.response}`,
            type: "error",
          });
      } finally {
        this.spinDelete = false;
      }
    },

    async editPurchase(id) {
      try {
        const data = await ServicePurchase.findPurchaseById(id);
        this.$emit("editPurchase", data);
        this.$root.$emit("bv::toggle::collapse", "accordion-dadosCadastrais");
        this.$emit("alterTabIndex", this.tabIndex);
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao ler informações",
            type: "info",
          });
      }
    },

    async redirectSefaz() {
      try {
        window.open(
          "https://www.nfe.fazenda.gov.br/portal/consultaRecaptcha.aspx?tipoConsulta=resumo&tipoConteudo=7PhJ+gAVw2g=",
          "_blank"
        );
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um erro ao redirecionar para a página",
          type: "info",
        });
      }
    },

    copyChaveNfeToClipboard() {
      const inputElement = this.$refs.chaveNfeInput;
      try {
        navigator.clipboard.writeText(inputElement.value);
        this.$toast.open({
          message: "Chave da NF-e copiada para a área de transferência.",
          type: "success",
        });
      } catch (err) {
        this.$toast.open({
          message: "Não foi possível copiar a chave da NF-e.",
          type: "info",
        });
      }
    },

    async updateMonth(direction) {
      if (direction === "prev") {
        this.month = this.month.minus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      } else if (direction === "next") {
        this.month = this.month.plus({ months: 1 });
        this.year = this.yearSelected;
        this.monthSelected = this.month.month;
      }

      await this.filterPerMonth();
    },

    nextPage() {
      this.filterPerMonth((this.page += 1));
    },

    openModals(name) {
      this.$bvModal.show(name);
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.filterPerMonth((this.page -= 1));
      }
    },
  },

  async mounted() {
    this.monthSelected = this.month.month;
    this.yearSelected = this.year;
    await this.filterPerMonth();
  },
  computed: {
    ...mapState({
      sizeModal: (state) => state.sizeModal,
    }),
    monthName() {
      return this.month.toLocaleString({ month: "long", locale: "pt-BR" });
    },
  },
  async created() {
    await this.filterPerMonth();
    this.ALTER_SIZE_MODAL("disable");
    this.SET_DATA_SEARCH_FROM_PURCHASE({});
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  beforeDestroy() {
    this.ALTER_SIZE_MODAL("disable");
  },
};
</script>
<style scoped>
.General {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100% - 100px);
  height: 100vh;
  font-size: 12px;
}

.ContainerBtn {
  width: 30%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 70px 0px 0px 22px;
}

.Btn {
  width: 100%;
  border: none;
  height: 35px;
  border-radius: 3px;
  font-size: 12px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.Summary {
  display: flex;
  height: 70px;
  width: 100%;
  padding-bottom: 10px;
  justify-content: space-around;
  align-items: center;
  border-bottom: 1px solid rgb(235, 235, 235);
}

.ItemSummary {
  width: 30%;
  height: 55px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
}

.ItemSummary span {
  cursor: context-menu;
}

.lineSearch {
  display: flex;
  align-items: center;
  padding: 0px 0px 5px 0px;
}

.searchByMonth {
  width: 30%;
  display: flex;
  align-items: center;
  margin: 10px 0px 10px 20px;
  border-radius: 5px;
  justify-content: space-around;
  border: 1px solid #e3e0e0;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.searchMonth {
  width: 100px;
  height: 30px;
  border-radius: 5px 0px 0px 5px;
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchMonth:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.searchYear {
  width: 50px;
  height: 30px;
  border-radius: 0px 5px 5px 0px;
  border: 1px solid rgb(195, 193, 193);
  font-size: 11px;
  text-align: center;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchYear:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select3 {
  left: 4px;
  -moz-appearance: none;
  appearance: none;
  background: none;
  font-weight: bold;
  padding: 0 !important;
}

.ctnDelAll {
  padding-top: 20px;
  display: flex;
  justify-content: space-around;
}

.ctnLoadDelAll {
  padding-top: 20px;
  display: flex;
  font-size: 14px;
  gap: 20px;
  flex-direction: column;
  align-items: center;
}

.textModalBill {
  font-weight: bold;
  width: 95%;
  text-align: center;
}

.btnContentDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 33%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentWhiteDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 33%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: #eff0f0;
}

.btnContentConfirmedDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 43%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentConfirmedWhiteDelAll {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 43%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: #eff0f0;
}

.Arrows {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}

.Arrows:hover {
  background: whitesmoke;
}

.searchFilter {
  width: 110px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.custom-select {
  left: 4px;
  position: relative;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
}

.ContainerSearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding: 0 20px 0 0;
  max-width: 100%;
}

.textRegister {
  font-weight: bold;
  padding-left: 20px;
}

.searchInput {
  display: flex;
  margin: 5px 0px 5px 0px;
}

.searchContainer {
  display: flex;
  gap: 10px;
  width: 80%;
  justify-content: flex-end;
  align-items: center;
}

.searchAvancedContent {
  display: flex;
  padding: 3px 0px 3px 0;
  align-items: center;
  justify-content: flex-end;
}

.searchAvanced {
  justify-content: center;
  color: #3a4750;
  font-size: 12px;
  height: 30px;
  gap: 10px;
  padding: 0px 20px;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAvanced:hover {
  background: rgb(205, 205, 205);
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(235 235 235);
  padding: 0 10px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  padding: 0rem 1.5rem;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
  width: 180px;
}

.dateInput {
  display: flex;
  gap: 10px;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #e3e0e0;
}

input:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

button:focus {
  box-shadow: none;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 100vh;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 20px 0 20px;
  width: 100%;
  max-width: 100%;
}

.ContentTableModal {
  display: flex;
  flex-direction: column;
  padding: 10px 20px 0 20px;
  width: 100%;
}

tr:nth-child(even) {
  background: #f1f1f1;
}

td {
  text-transform: capitalize;
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.contentModal {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.FooterPagination {
  display: flex;
  align-items: center;
  height: 50px;
  padding: 20px;
  width: 100%;
  justify-content: flex-end;
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin-bottom: 1rem;
  gap: 10px;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  cursor: auto;
}

.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModalPurchase {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModalPurchase:hover {
  background: whitesmoke;
  color: red;
}

.closeModalPurchase:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.rowModal {
  display: flex;
  width: 100%;
  padding: 20px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}
.borderInput {
  font-size: 14px;
  height: 30px;
  border: none;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 15px;
  padding-right: 4px;
  color: #5c5855;
  background: #e8e8e8;
}

.borderInput:focus {
  border: none;
}

.input-group {
  width: 30%;
}

.input-group-supplier {
  width: 45%;
  position: relative;
}

.input-group-key {
  width: 100%;
  position: relative;
}

.user-label {
  position: absolute;
  left: 5px;
  font-size: 11px;
  z-index: 30;
  bottom: 23px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.iconCopy {
  position: absolute;
  right: 30px !important;
  z-index: 10;
  background-color: #c1c1c1;
  color: rgb(0, 0, 0);
  height: 30px;
  width: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.25rem;
  cursor: pointer;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.form-control:focus {
  background-color: #ffffff00;
  box-shadow: none !important;
}

.form-control {
  border-radius: 5px !important;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.typeSale {
  color: #403b74;
  font-weight: bold;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .General {
    margin-left: 60px;
    width: calc(100% - 60px);
    font-size: 11px;
  }
  .ContainerBtn {
    width: 100%;
    padding: 0px;
    margin: 70px 0px 0px 8px;
  }
  .Btn {
    width: 33%;
    height: 30px;
    font-size: 11px;
  }
  .searchByMonth {
    width: 35%;
    margin: 10px 0px 10px 8px;
  }
  .month {
    font-size: 11px;
  }
  .lineSearch {
    padding: 0px;
  }
  .Summary {
    padding-bottom: 10px;
  }
  .ItemSummary {
    width: 32%;
    height: 50px;
    gap: 0px;
  }
  .searchAll {
    font-size: 11px;
    width: 70px;
  }
  .searchFilter {
    width: 100px;
  }
  .textRegister {
    margin: 10px 10px 0px 0px;
    padding-left: 0px;
  }
  .textRegister span {
    padding-left: 5px;
  }
  .searchInput {
    margin: 10px 10px 0px 0px;
  }
  .ContentTable {
    padding: 10px 10px 0 0px;
  }
  .inputSearch {
    width: 50%;
  }
  .setBtn {
    margin: 0 1rem 10px 0;
  }
  .itemBtn {
    width: 18px;
    height: 18px;
  }
  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
  .FooterPagination {
    padding: 0px;
  }
}

@media screen and (max-width: 700px) {
  .General {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    width: 100% !important;
    height: 100vh;
    font-size: 10px;
  }

  .ContainerBtn {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    justify-content: center;
    margin: 60px 0px 0px 0px;
  }

  .ContentTable {
    height: auto;
    padding: 10px 10px 0 10px;
  }

  .ModalGeneral {
    position: fixed !important;
    z-index: 900;
    top: 53px;
    left: 0;
    width: 100%;
  }

  .disable {
    width: 100%;
    height: 0;
    left: 0;
  }

  .ContainerSearchInput {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    width: 100%;
    max-width: 100%;
    flex-direction: column;
  }

  .searchInput {
    display: flex;
    margin: 0px;
    width: 100%;
    flex-direction: column;
  }

  .Btn {
    width: 80%;
    height: 30px;
  }

  .searchByMonth {
    width: 80%;
    display: flex;
    align-items: center;
    margin: 10px 0px;
  }

  .lineSearch {
    display: flex;
    align-items: center;
    padding: 0px 0px 5px 0px;
    justify-content: center;
  }

  .ItemSummary {
    width: 32%;
    height: 80px;
    gap: 0px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 10px;
  } 
}
@media screen and (max-width: 450px){
  .searchAvanced {
    justify-content: center;
    color: #3a4750;
    font-size: 12px;
    height: 62px;
    gap: 10px;
    padding: 0px 20px;
    border: solid 1px #e3e0e0;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
}
</style>