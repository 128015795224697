import api from './axios'

class ServiceCustomer {
  async getCustomersForSelectBox(){
    const { data } = await api.get('/customers/combobox')
    return data
  }

  async findById(id) {
    const { data } = await api.get(`/customers/${id}`);
    return data
  }

  async searchCustomer(text = '', type = 'nome', page = 1) {
    const { data } = await api.get(
      `/customers?q=${encodeURIComponent(text)}&type=${type}&page=${page}`
    );

    return data.data
  }

  async fillComboBoxWithPaginate(id = '', text = '', filterType = 'includes', page = 1) {
    const { data } = await api.get(
      `/customers/combobox/with/paginate?id=${id}&q=${encodeURIComponent(text)}&page=${page}&filterType=${filterType}`
    );

    return data
  }
}

export default new ServiceCustomer
