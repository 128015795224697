export default function printReportSquareMeter(name, id) {
  const token = localStorage.getItem("token");
  const reports = {
    simple: () => simple(id, token),
    rooms: () => rooms(id, token),
    deliveryManifest: () => deliveryManifest(id, token),
    contrato: () => reportContrato(id, token),
  }

  reports[name]()
}

function simple(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/${id}?auth=${token}`
  );
}

function rooms(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/ambiente/${id}?auth=${token}`
  );
}

function deliveryManifest(id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/relatorio/entrega/itens/${id}?auth=${token}`
  );
}

function reportContrato (id, token) {
  window.open(
    `${process.env.VUE_APP_URL_BACKEND}/sales-square-meter/gerar/contrato/${id}?auth=${token}`
  );
}