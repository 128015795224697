<template>
  <div style="max-height: 100px">
    <b-modal id="modalVoucher" hide-footer centered hide-header size="lg">
      <Tabs
        :tabs="tabs"
        :tabSelected="tabSelected"
        :actionToAlterTab="actionToAlterTab"
      >
        <template v-slot:general>
          <div>
            <div class="contentModal">
              <div class="rowContainer">
                <span>
                  <b-icon-building></b-icon-building> Informações do
                  Funcionário:
                </span>
                <div class="rowContent">
                  <div class="searchInputTransfer">
                    <InputSelect
                      label="Funcionário"
                      :items="dataEmployee"
                      valueField="id"
                      textField="nomeFuncionario"
                      :dataSelect.sync="dataVoucher.idFuncionario"
                      :openCreateModal="() => openModals('modalCreateEmployee')"
                      ref="refResponsavel"
                    />
                  </div>

                  <InputMoney
                    label="Salário"
                    type="text"
                    :dataInput.sync="dataVoucher.salario"
                    ref="salario"
                  />

                  <InputMoney
                    label="Comissão"
                    type="text"
                    :dataInput.sync="dataVoucher.comissao"
                    ref="comissao"
                  />
                </div>
              </div>

              <div class="rowContainer">
                <span>
                  <b-icon-info-circle></b-icon-info-circle> Informações do Vale:
                </span>
                <div class="rowContent">
                  <div class="searchInputTransfer">
                    <Input
                      label="Data"
                      type="date"
                      :labelFixed="true"
                      :dataInput.sync="dataVoucher.data"
                      ref="data"
                    />
                  </div>
                  <div class="searchInputTransfer">
                    <div class="selectPlusTotal">
                      <label class="fixedSelect2 styleLabel1"
                        >Conta Recebimento</label
                      >
                      <b-form-select
                        class="borderInput shadow-none"
                        valueField="id"
                        textField="nome"
                        :disabled="blocked"
                        :options="dataAllReceiptBill"
                        v-model="dataVoucher.idContaRecebimento"
                      >
                      </b-form-select>
                    </div>
                  </div>

                  <div class="searchInputTransfer">
                    <div class="selectPlus1">
                      <label class="fixedSelect2"> Forma de Pagamento</label>
                      <b-form-select
                        class="borderInput shadow-none"
                        value-field="id"
                        text-field="tipo"
                        :options="allPaymentsTypes"
                        size="sm"
                        v-model="dataVoucher.idFormaPagamento"
                      >
                      </b-form-select>
                    </div>
                  </div>
                  <InputMoney
                    label="Valor do vale"
                    type="text"
                    :dataInput.sync="dataVoucher.valor"
                    ref="valor"
                  />
                </div>

                <div class="rowContainer">
                  <span>
                    <b-icon-info-circle></b-icon-info-circle> Observações:</span
                  >
                  <div class="rowContent">
                    <div class="input-group1">
                      <b-form-input
                        v-model="dataVoucher.descricao"
                        class="input borderInput"
                      />
                      <label class="user-label1">
                        Digite suas Observações
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              style="width: 100%; display: flex; justify-content: space-between"
            >
              <button
                class="btnsModal"
                variant="info"
                size="sm"
                @click="handleCloseModalAndClear"
              >
                Fechar
              </button>
              <button
                class="btnsModal btnSave"
                variant="info"
                size="sm"
                @click="saveOrUpdate"
              >
                <p>{{ dataVoucher.id ? "Atualizar Vale" : "Lançar Vale" }}</p>
              </button>
            </div>
          </div>
        </template>

        <template v-slot:search>
          <div class="ContainerSearchInput">
            <div class="searchContainer">
              <div class="searchContent">
                <div class="searchInput">
                  <button class="btnPeriod" size="sm">Período</button>
                  <div class="dateInput">
                    <input
                      type="date"
                      id="date"
                      v-model="startDate"
                      style="
                        padding: 0.25rem 0.25rem 0.25rem 0.5rem;
                        cursor: pointer;
                        border-left: none;
                        border-radius: 0px 5px 5px 0px;
                      "
                    />
                    <input
                      type="date"
                      id="date"
                      v-model="endDate"
                      class="dateRight"
                    />
                  </div>
                </div>
                <div class="searchInput">
                  <b-form-select
                    class="searchFilter"
                    size="sm"
                    value-field="value"
                    text-field="text"
                    v-model="type"
                    :options="filterCombobox"
                  ></b-form-select>
                  <div>
                    <b-input
                      class="inputSearch"
                      placeholder="Pesquisar por..."
                      v-model="textPesquisa"
                      @keyup.enter="filterVouchers"
                    ></b-input>
                  </div>
                  <b-button
                    size="sm"
                    @click="filterVouchers()"
                    class="searchBtn"
                  >
                    <b-icon-search></b-icon-search>
                  </b-button>
                </div>
              </div>
            </div>
          </div>
          <div class="ContentTable">
            <table>
              <thead class="headTable">
                <tr>
                  <th>Data</th>
                  <th>Funcionário</th>
                  <th>Descrição</th>
                  <th>Valor Vale</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody style="color: #3a4750; width: 100%">
                <tr
                  v-for="voucher in listTableVouchers"
                  :key="voucher.id"
                  style="text-align: center; height: 40px; cursor: context-menu"
                >
                  <td>{{ voucher.data | moment }}</td>
                  <td>{{ voucher.funcionario }}</td>
                  <td>{{ voucher.descricao }}</td>
                  <td>
                    {{
                      voucher.valor.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td
                    style="
                      text-align: center;
                      padding: 0 !important;
                      display: flex;
                      height: 100%;
                      justify-content: center;
                      gap: 20px;
                      padding: 0.5rem;
                    "
                  >
                    <b-icon-pencil
                      @click="getVoucherFindById(voucher.id)"
                      scale="1"
                      role="button"
                    />

                    <b-icon-x-circle
                      @click="deleteVoucher(voucher.id)"
                      role="button"
                      style="color: red"
                      scale="1"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="setBtnPrint">
            <button class="btnsModal" @click="printVoucher">
              Imprimir Todos
            </button>
          </div>
          <div class="setBtn">
            <button class="itemBtn" @click="previousPage">
              <b-icon-chevron-left></b-icon-chevron-left>
            </button>
            <button class="centerItemBtn">{{ 1 }}</button>
            <button class="itemBtn" @click="nextPage">
              <b-icon-chevron-right></b-icon-chevron-right>
            </button>
          </div>
        </template>
      </Tabs>
      <ModalCreateEmployees
        @getAllNewEmployee="createdNewEmployee = $event"
        :idEmployeeFromSearch="dataVoucher.idFuncionario"
      />
    </b-modal>
  </div>
</template>

<script>
import moment from "moment";
import api from "../../services/axios";
import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import Tabs from "../UI/Tabs/Tab.vue";
import ModalCreateEmployees from "../Modals/ModalCreateEmployees.vue";

import toastAlertErros from "../../utils/toastAlertErros";
import servicePaymentForm from "../../services/servicePaymentForm";
import serviceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import SafeService from "../../services/serviceSafe";
import ServiceVoucher from "../../services/serviceVoucher";
import printReportGeneral from "../../reports/General";
import { mapState } from "vuex";

export default {
  components: {
    Input,
    InputSelect,
    InputMoney,
    ModalCreateEmployees,
    Tabs,
  },
  data() {
    return {
      dataVoucher: {
        id: "",
        idContaRecebimento: "",
        idFormaPagamento: "",
        idFuncionario: "",
        valor: 0,
        salario: 0,
        comissao: 0,
        data: moment().format("YYYY-MM-DD HH:mm:ss"),
        descricao: "",
      },
      tabs: [
        { name: "general", text: "Novo Vale" },
        { name: "search", text: "Pesquisar" },
      ],
      filterCombobox: [
        { value: "nome", text: "Nome" },
        { value: "valor", text: "Valor" },
        { value: "", text: "Selecione..." },
      ],
      dataAllReceiptBill: [],
      allPaymentsTypes: [],
      dataEmployee: [],
      allAccounts: [],
      tabIndexSected: 1,
      totalExtractOrigin: 0,
      createdNewEmployee: null,
      textPesquisa: "",
      type: "",
      account: "",
      startDate: "",
      endDate: "",
      dataLength: 0,
      pag: 1,
      listTableVouchers: [],
      tabSelected: 0,
      actionToAlterTab: false,
            blocked: false,
    };
  },
  methods: {
    async filterVouchers() {
      try {
        const { data } = await api.get(
          `/voucher?q=${encodeURIComponent(this.textPesquisa)}&type=${
            this.type
          }&page=${this.pag}&startDate=${this.startDate}&endDate=${
            this.endDate
          }`
        );
        this.dataLength = data.data.length;
        this.listTableVouchers = data.data;
      } catch (error) {
        toastAlertErros.validateErro(error);
      }
      if (this.dataLength < 9) {
        this.clearSearch();
      }
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
    },

    nextPage() {
      this.filterVouchers((this.pag += 1));
    },

    previousPage() {
      if (this.pag === 1) {
        return;
      } else {
        this.filterVouchers((this.pag -= 1));
      }
    },

    async saveVoucher() {
      try {
        await ServiceVoucher.save(this.dataVoucher);
        this.clear();
        return this.$toast.open({
          message: "Salvo com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.erros}` || "Ocorreu um erro o salvar!",
          type: "info",
        });
      }
    },

    async updateVoucher() {
      try {
        await ServiceVoucher.update(this.dataVoucher);
        this.clear();
        return this.$toast.open({
          message: "Atualizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao atualizar a conta",
          type: "info",
        });
      }
    },

    async deleteVoucher(idVoucher) {
      try {
        await ServiceVoucher.delete(idVoucher);
        this.filterVouchers();
        return this.$toast.open({
          message: "deletado com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message: error.response.data.message,
          type: "info",
        });
      }
    },

    async getVoucherFindById(idVoucher) {
      const result = await ServiceVoucher.findById(idVoucher);
      Object.assign(this.dataVoucher, result);
      this.$refs.refResponsavel.valueItems = result.idFuncionario;
      this.dataVoucher.idFuncionario = result.idFuncionario;
      this.$refs.valor.valueInput = this.dataVoucher.valor;
      this.$refs.salario.valueInput = this.dataVoucher.salario;
      this.$refs.comissao.valueInput = this.dataVoucher.comissao;
      this.$refs.data.valueInput = moment(this.dataVoucher.data).format(
        "YYYY-MM-DD"
      );
      this.tabSelected = 0;
      this.actionToAlterTab = !this.actionToAlterTab;
    },

    async getAllReceiptBill() {
      const result = await serviceAccountReceiptBill.fillComobobox();
      this.dataAllReceiptBill = result;
      const accountPayment = this.dataAllReceiptBill.find(
        (account) => account.nome === "CAIXA GAVETA"
      );
      if (this.isSimpleSafe) {
        this.blocked = true;
        this.dataVoucher.idContaRecebimento = accountPayment.id;
      }
    },

    async saveOrUpdate() {
      this.dataVoucher.id === ""
        ? await this.saveVoucher()
        : await this.updateVoucher();
      this.clear();
      this.filterVouchers();
    },

    clear() {
      this.dataVoucher.id = "";
      this.dataVoucher.idContaRecebimento = "";
      this.dataVoucher.idFormaPagamento = "";
      this.dataVoucher.idFuncionario = "";
      this.dataVoucher.valor = 0;
      this.dataVoucher.salario = 0;
      this.dataVoucher.comissao = 0;
      this.dataVoucher.data = moment().format("YYYY-MM-DD HH =mm =ss");
      this.dataVoucher.descricao = "";

      this.$refs.refResponsavel.valueItems = "";
      this.$refs.valor.valueInput = 0;
      this.$refs.salario.valueInput = 0;
      this.$refs.comissao.valueInput = 0;
    },

    handleCloseModalAndClear() {
      this.clear();
      this.$bvModal.hide("modalVoucher");
    },

    async getExtractOriginValue() {
      this.teste = this.dataAllReceiptBill.filter(
        (account) => account.id === this.dataVoucher.idContaRecebimento
      );
      const objectExtractOrigin = this.allAccounts.filter(
        (extract) => extract.conta === this.teste[0].nome
      );
      this.totalExtractOrigin = objectExtractOrigin[0].valor;
    },

    clearExtractOrigin() {
      this.totalExtractOrigin = 0;
    },

    async getAllPayment() {
      this.allPaymentsTypes = await servicePaymentForm.getAllPayment();
      this.filterFormPaymentBleeding = this.allPaymentsTypes.filter(
        (payment) => payment.tipo === "Dinheiro"
      );
    },

    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    async sumPerAccount() {
      const data = await SafeService.sumPerAccount();
      this.allAccounts = data?.data ?? [];
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    printVoucher() {
      printReportGeneral("voucher", this.type, this.startDate, this.endDate);
    },
  },
  mounted() {
    this.getAllReceiptBill();
    this.listEmployeesSelectBox();
    this.getAllPayment();
    this.sumPerAccount();
    this.filterVouchers();
  },
  watch: {
    createdNewEmployee() {
      this.listEmployeesSelectBox();
    },
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      dataConfig: (state) => state.dataConfig,
    }),
    isSimpleSafe() {
      return this.dataConfig?.caixa_simples ?? true;
    },
  },
};
</script>

<style scoped>
.styleHoverDelete:hover {
  background-color: #ff6347;
  border: solid 1px black;
}

.tabsContentSearch {
  overflow-y: scroll;
  max-height: 300px;
}

.btnsModal {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  margin-top: 20px;
  color: white;
  font-size: 12px;
}

.btnSave {
  background-color: transparent;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.titleCard {
  margin-bottom: 32px !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
}

.table thead th {
  border-bottom: solid 1px transparent;
}

.contentModal {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
  gap: 5px;
  width: 100%;
}

.rowContainer span {
  cursor: context-menu;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.ContainerSearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgb(255 0 0 / 3%) 1%,
    rgb(255 255 255 / 99%) 19%
  );
  border-radius: 5px;
}

.searchContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 20px 0px;
  border-bottom: 1px solid #e3e0e0;
}
.searchContent {
  display: flex;
  width: 100%;
  gap: 10px;
  justify-content: space-between;
  align-items: center;
}

.searchContainer span {
  font-weight: bold;
  font-size: 14px;
  width: 20%;
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  height: 30px;
}

.dateInput {
  display: flex;
  gap: 10px;
  font-size: 12px;
}

.dateRight {
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  cursor: pointer;
}

.searchAvanced {
  justify-content: center;
  color: #3a4750;
  font-size: 12px;
  height: 30px;
  gap: 10px;
  padding: 0px 20px;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAvanced:hover {
  background: rgb(205, 205, 205);
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  width: 80px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
}

.searchInput {
  display: flex;
  justify-content: flex-end;
}

.searchFilter {
  width: 130px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  max-width: 100%;
  height: 55vh;
  margin-top: 5px;
  border: 1px solid #e3e0e0;
  border-radius: 8px;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 29px;
  width: 100%;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-size: 12px;
  font-weight: normal;
}

td {
  font-size: 12px;
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 3.4rem 2.4rem 0;
  gap: 5px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.setBtnPrint {
  display: flex;
  flex-direction: row;
  margin: 0 3.4rem 2.4rem 0;
  gap: 5px;
  bottom: 0;
  left: 4rem;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  font-size: 12px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

.searchInputTransfer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectPlus1 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 10px;
  bottom: 24px;
  left: 8px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(94, 90, 90);
  background-color: white;
  font-weight: 600;
}

.selectPlusTotal {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.borderInput {
  font-size: 11px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050 !important;
}

.input-group1 {
  width: 100%;
  border-radius: 5px;
  position: relative;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 13px;
  font-weight: bold;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 9999;
}

.user-label1 {
  position: absolute;
  left: 0px;
  font-size: 13px;
  font-weight: bold;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 9999;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #e3e0e0;
}

input:focus {
  box-shadow: none !important;
  border: 1px solid #e3e0e0;
}

button:focus {
  box-shadow: none;
}

.rowContentExtract {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.extract {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  gap: 5px;
  width: 70%;
}

.extract p {
  margin-bottom: 0;
}

.btn-extract {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 20px;
  border: none;
  border-radius: 50%;
  color: #5c5855;
}
</style>