<template>
  <div class="containe">
    <div class="forms-containe">
      <div class="signin-signup">
        <form action="#" class="sign-in-form" @submit.prevent>
          <img src="../../assets/logoBG.png" class="image logoIntro" alt="" />
          <div
            style="
              padding-top: 20px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 20px;
              width: 100%;
            "
          >
            <div class="input-field">
              <i><b-icon-envelope-fill font-scale="1.3" /></i>
              <input
                type="text"
                name="email"
                placeholder="Usuário"
                v-model="login.email"
              />
            </div>
            <div class="input-field">
              <i><b-icon-lock-fill font-scale="1.3" /></i>
              <input
                type="password"
                name="pass"
                placeholder="Senha"
                @keyup.enter="sendLogin"
                v-model="login.password"
              />
            </div>
            <span style="font-size: 12px"
              >Esqueceu a Senha? <a href="">Redefinir SENHA</a></span
            >
          </div>
          <button class="btn solid" @click="sendLogin">Entrar</button>
          <div class="social-media">
            <img
              src="../../assets/imgCircle.png"
              class="image logoFooter"
              alt=""
            />
          </div>
        </form>
      </div>
    </div>
    <div
      class="container-reset-password"
      v-if="sectionsEnable.forgotMyPassword"
    >
      <ResetPassword />
    </div>
    <div class="leftBkg">
      <img style="width: 100%; height: 100%" :src="loginLeft" />
    </div>
    <div class="rightBkg">
      <img style="width: 100%; height: 100%" :src="loginRight" />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import ResetPassword from "../ResetPassword/ResetPassword.vue";
import LoginService from "../../services/serviceLogin";
import loginLeft from "../../assets/loginLeft.png";
import loginRight from "../../assets/loginRight.png";
import ServiceMail from "../../services/serviceMail";
import { clearTokens } from "../../utils/clearTokens";
import { ValidateEmailResetPassword } from "../../validators/emailResetPassword";
import { HandleErrors } from "../../utils/handleErrors";
import api from "../../services/axios";
import moment from "moment";

export default {
  components: {
    ResetPassword,
  },
  data() {
    return {
      loginLeft: loginLeft,
      loginRight: loginRight,
      visibilitySaibaMais: "hideLogin",
      visibilityLogin: "showLogin",
      datainiciouso: moment().format("YYYY-MM-DD"),
      isCheck: false,
      isWarning: false,
      hourglass: true,
      infoNewClient: {},
      login: {
        email: "",
        password: "",
      },
      companyTotal: { companyCad: {}, companyLogger: {}, companyMail: {} },
      company: {
        nome: "",
        telefone: "",
        email: "",
        cpfcnpj: "",
        type: 1,
        companyName: "",
        datainiciouso: "",
        datafimuso: "",
      },
      logger: {
        nome: "",
        email: "",
        password: "",
      },
      email: {
        office: "",
        acting: "",
        employee: "",
      },
      sectionsEnable: {
        login: true,
        tester: false,
        forgotMyPassword: false,
      },
      tipo: [
        { value: 1, text: "CPF" },
        { value: 2, text: "CNPJ" },
      ],
      cargo: [
        { value: "", text: "Selecione..." },
        { value: "Analista Contas a Pagar", text: "Analista Contas a Pagar" },
        {
          value: "Analista Contas a Receber",
          text: "Analista Contas a Receber",
        },
        { value: "Analista Faturamento", text: "Analista Faturamento" },
        { value: "Compras/Produção", text: "Compras/Produção" },
        { value: "Contabilidade/Fiscal", text: "Contabilidade/Fiscal" },
        {
          value: "Coordenador/Gerente Financeiro",
          text: "Coordenador/Gerente Financeiro",
        },
        { value: "Diretor Financeiro/CFO", text: "Diretor Financeiro/CFO" },
        { value: "Diretor/Sócio", text: "Diretor/Sócio" },
        { value: "Estagiário/Assistente", text: "Estagiário/Assistente" },
        { value: "TI", text: "TI" },
        { value: "Vendas/Comercial", text: "Vendas/Comercial" },
        { value: "Outros", text: "Outros" },
      ],
      ramo: [
        { value: "", text: "Selecione..." },
        { value: "Alimentação e Bebidas", text: "Alimentação e Bebidas" },
        { value: "Vestuário e calçados", text: "Vestuário e calçados" },
        { value: "Construção", text: "Construção" },
        { value: "Saúde", text: "Saúde" },
        { value: "Educação", text: "Educação" },
        { value: "Serviços pessoais", text: "Serviços pessoais" },
        { value: "Serviços especializados", text: "Serviços especializados" },
        { value: "Informática/Tecnologia", text: "Informática/Tecnologia" },
        { value: "Outro", text: "Outro" },
      ],
      funcionario: [
        { value: "", text: "Selecione..." },
        { value: "1 a 5", text: "De 1 a 5 funcionários" },
        { value: "6 até 15", text: "De 6 até 15 funcionários" },
        { value: "16", text: "Mais de 16 funcionários" },
      ],
      emailToResetPassword: "",
      spinResetPassword: false,
      invalidMessage: "",
      invalidinputEmailToResetPassword: "",
    };
  },
  methods: {
    handleSectionsEnable(name) {
      this.sectionsEnable = {
        login: false,
        tester: false,
        forgotMyPassword: false,
        [name]: true,
      };

      if (name !== "login") this.clearAllInputs();
    },
    async sendLogin() {
      try {
        if (this.login.email === "" || this.login.password === "") {
          return this.$toast.open({
            message: "O campo login e senha devem ser preenchidos",
            type: "info",
          });
        }

        const { token, permissions, userLevel } = await LoginService.logar(
          this.login
        );

        localStorage.setItem("token", token);
        localStorage.setItem("permissions", JSON.stringify(permissions));
        localStorage.setItem("userLevel", userLevel);

        const tokenList = JSON.parse(localStorage.getItem("tokenList"));
        if (tokenList && tokenList.length) {
          tokenList.push({
            token,
            userLevel,
            permissions,
            nameCompany: this.dataCompany.nome,
          });

          localStorage.setItem("tokenList", JSON.stringify(tokenList));
        }

        if (userLevel === "accountant") {
          return this.$router
            .push({
              path: "/area-contador",
            })
            .then(() => {
              location.reload();
            });
        } else if (
          userLevel !== "adm" &&
          permissions?.configuracoes?.registroPonto?.painelPrincipal
        ) {
          return this.$router
            .push({
              path: "/registro-ponto",
            })
            .then(() => {
              location.reload();
            });
        }

        this.$router
          .push({
            path: "/home",
          })
          .then(() => {
            location.reload();
          });
      } catch (error) {
        console.log(error);
        return this.$toast.open({
          message:
            error.response.data.message ||
            "Verifique se o login e senha estão corretos!",
          type: "info",
        });
      }
    },

    async sendMail() {
      await api.post("/mail/info/client", this.infoNewClient);
    },

    async forgotMyPassword() {
      try {
        const isValid = ValidateEmailResetPassword(this.emailToResetPassword);
        if (isValid) {
          return this.handleInputEmailToResetPassword();
        }

        this.spinResetPassword = true;

        await ServiceMail.confirmCode({ email: this.emailToResetPassword });

        this.handleSectionsEnable("login");
        this.emailToResetPassword = "";
        this.$toast.open({
          message: "Email encaminhado",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao enviar o email",
          type: "info",
        });
      } finally {
        this.spinResetPassword = false;
        this.loadingPassword = "";
      }
    },

    handleInputEmailToResetPassword() {
      this.$refs.emailToResetPassword.focus();
      this.invalidinputEmailToResetPassword =
        "invalidinputEmailToResetPassword";
      this.invalidMessage = "* Campo Email deve ser um email válido!";
    },

    clearInvalidInput() {
      this.invalidinputEmailToResetPassword = "";
      this.invalidMessage = "";
    },

    clearAllInputs() {
      this.company = {
        nome: "",
        telefone: "",
        email: "",
        cpfcnpj: "",
        type: 1,
        companyName: "",
        datainiciouso: "",
        datafimuso: "",
      };

      this.logger = {
        nome: "",
        email: "",
        password: "",
      };

      this.email = {
        office: "",
        acting: "",
        employee: "",
      };

      this.emailToResetPassword = "";
    },

    verifyExitsToken() {
      if (!this.getToken) {
        const token = localStorage.getItem("token");
        if (token) {
          return this.$router.push({
            path: "/home",
          });
        }

        clearTokens();
      }
    },

    assingValueCadCompany() {
      let currentDate = moment();
      let dateAfter30Days = currentDate.add(30, "days");
      let dateFormatted = dateAfter30Days.format("YYYY-MM-DD");
      this.logger.nome = this.company.nome;
      this.logger.email = this.company.email;
      this.company.datainiciouso = this.datainiciouso;
      this.company.datafimuso = dateFormatted;
      this.companyTotal.companyCad = this.company;
      this.companyTotal.companyLogger = this.logger;
      this.companyTotal.companyMail = this.email;
      this.infoNewClient = this.companyTotal;
    },

    async redirectAndLogger() {
      this.login.email = this.logger.email;
      this.login.password = this.logger.password;
      this.handleSectionsEnable("login");

      this.$bvModal.hide("modal-loader");
      await this.sendLogin();
    },

    async newCompany() {
      try {
        this.$bvModal.show("modal-loader");
        this.assingValueCadCompany();
        // await this.sendMail();
        await api.post("/gestao/tester", this.companyTotal);
        this.isCheck = true;
        this.hourglass = false;
        this.redirectAndLogger();
      } catch (error) {
        this.hourglass = false;
        this.isCheck = false;
        this.isWarning = true;
        const typeError = HandleErrors(error?.response?.data?.message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao salvar o novo usuario",
          type: "info",
        });
      }
    },
  },
  computed: {
    ...mapState(["dataCompany"]),
    maskCelular() {
      return "(##) #####-####";
    },
    maskcpfcnpj() {
      if (!this.company.cpfcnpj) {
        return "";
      }
      if (this.company.cpfcnpj.length <= 14) {
        return "###.###.###-##";
      }
      if (this.company.cpfcnpj.length > 14) {
        return "##.###.###/####-##";
      } else {
        return "";
      }
    },

    getToken() {
      return this.$route.query.token;
    },
  },
  mounted() {
    this.verifyExitsToken();
  },
};
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: monospace;
}

body,
input {
  font-family: monospace;
}

.containe {
  position: relative;
  width: 100vw !important;
  background-color: white;
  min-height: 100vh;
  overflow: hidden;
}

.forms-containe {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.signin-signup {
  width: 350px;
  height: 80%;
  padding: 20px 30px 5px 30px;
  border: 1px solid #dadada;
  border-radius: 8px;
  display: flex;
  background: rgba(255, 255, 255, 0.775);
  justify-content: center;
  align-content: space-between;
  flex-wrap: wrap;
  z-index: 100;
}

form {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.leftBkg {
  width: 40%;
  height: 50%;
  background: transparent;
  position: absolute;
  bottom: -5px;
  left: -5px;
  z-index: 20;
}

.rightBkg {
  width: 45%;
  height: 50%;
  background: transparent;
  position: absolute;
  top: -70px;
  right: -60px;
  z-index: 20;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.input-group {
  width: 100%;
  padding: 2px 0;
}

.user-label {
  position: absolute;
  left: 5px;
  font-size: 12px;
  z-index: 30;
  bottom: 15px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  background-color: transparent;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #c9c7c7;
  padding: 0 0.1em;
  font-weight: 600;
}

.fixedSelect {
  font-family: Arsenal-Medium, sans-serif;
  font-size: 11px !important;
  position: absolute;
  margin-bottom: 0px;
  padding: 0 5px;
  bottom: 28px;
  left: 10px;
  z-index: 2;
  color: #424146;
  background-color: #f0f0f038;
}

.borderInput {
  width: 100%;
  font-size: 11px;
  background-color: #f0f0f0;
  height: 35px;
  border: none;
  padding: 0 10px;
  border-radius: 10px;
}

.dividerInput {
  display: flex;
  width: 100%;
  gap: 5px;
}

.invalidinputEmailToResetPassword {
  border: solid 1px red !important;
  box-shadow: 0 0 2px 0 rgba(255, 0, 0, 0.613);
}

.title {
  font-size: 2rem;
  color: #444;
  margin: 10px 0;
}

.input-field {
  max-width: 380px;
  width: 100%;
  border: 1px solid #cecece;
  height: 40px;
  padding: 0 10px 0 0;
  border-radius: 10px;
  display: grid;
  grid-template-columns: 15% 85%;
  position: relative;
  align-items: center;
}

.input-field i {
  text-align: center;
  line-height: 40px;
  color: #a9a9a9;
  transition: 0.5s;
  font-size: 12px;
}

.input-field input {
  background: none;
  outline: none;
  border: none;
  line-height: 1;
  font-weight: 600;
  font-size: 12px;
  color: #333;
}

.input-field input::placeholder {
  color: #aaa;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 30px whitesmoke inset !important;
  box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: #575757 !important;
  height: 30px;
}

.social-text {
  padding: 0.7rem 0;
  font-size: 1rem;
}

.social-media {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.btn {
  width: 250px !important;
  background-color: #0051a9;
  border: none;
  outline: none;
  height: 40px;
  border-radius: 10px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 13px;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
}

.btn:hover {
  background-color: #2f5cb0;
}

.image {
  width: 100%;
  transition: transform 1.1s ease-in-out;
  transition-delay: 0.4s;
}

.logo {
  width: 500px !important;
  height: 350px !important;
}

.logoIntro {
  width: 210px !important;
  height: 95px !important;
}

.logoFooter {
  width: 80px !important;
  height: 80px !important;
}

@media screen and (max-width: 700px) {
  .rightBkg {
    width: 50% !important;
    height: 30% !important;
  }
  .leftBkg {
    width: 35% !important;
    height: 20% !important;
  }
}

@media screen and (min-width: 1800px) {
  .signin-signup {
    width: 35%;
    height: 90%;
  }
  form {
    width: 100%;
  }
  .logoIntro {
    width: 300px !important;
    height: 130px !important;
  }
  .btn {
    width: 50% !important;
    font-size: 20px;
    height: 50px;
  }
  .input-field {
    max-width: none;
    width: 70%;
    height: 60px;
  }
  .input-field i {
    font-size: 22px;
  }
  input:-webkit-autofill {
    height: 50px;
  }
  .sign-in-form span {
    font-size: 20px !important;
  }
  .logoFooter {
    width: 100px !important;
    height: 100px !important;
  }
}
</style>