<template>
  <div>
    <b-modal size="lg" id="modalSale" hide-header>
      <template #modal-footer>
        <div style="display: flex; justify-content: space-between; width: 100%">
          <div>
            <button class="btnsSale" @click="closeModal('modalSale')">
              Voltar
            </button>
          </div>
          <!-- <div>
            <button class="btnsSale" @click="print">Imprimir</button>
          </div> -->
          <div>
            <button
              class="btnsSale btnConfirm"
              @click="saveOrUpdateSale(true)"
              :disabled="loadingToSaveOrUpdate"
            >
              <p style="margin: 0; position: relative">
                Salvar

                <b-spinner
                  v-if="loadingToSaveOrUpdate"
                  style="
                    width: 15px;
                    height: 15px;
                    color: gray;
                    position: absolute;
                    right: 10px;
                    top: 10%;
                  "
                />
              </p>
            </button>
          </div>
          <!-- <div>
            <button
              class="btnsSale btnsSaveOrSendNfe"
              @click="handleTranformToNfeAndCloseModal"
            >
              {{ returnTextSaveAndSendNota }}
            </button>
          </div> -->
        </div>
      </template>

      <div>
        <div class="headerSale">
          <p>Venda {{ numberSale || dataSale.numero }}</p>
          <p>{{ totals.total }}</p>
        </div>

        <div style="padding: 0 10px">
          <hr style="margin-top: 0.2rem; margin-bottom: 1rem" />
        </div>
        <div @click="closeModal('modalSale')" class="closeModal">
          <b-icon-x scale="1.3"></b-icon-x>
        </div>
      </div>

      <div class="contentSections">
        <section class="dataCabecalho">
          <div class="contentItens" style="padding: 0 0 0 5px">
            <p class="titleItens">Cliente</p>
            <p class="itens">
              {{ dataSaleForModalSale.customerSelected.nome }}
            </p>
            <span style="font-size:10px;color:red;">
                {{ isCpfInCheckList }}
            </span>
          </div>

          <div class="contentItens" style="align-items: center">
            <p class="titleItens">Data da Venda</p>
            <p class="itens">
              {{ dataSaleForModalSale.data | moment }}
            </p>
          </div>

          <div class="contentItens" style="align-items: center">
            <p class="titleItens">Funcionário</p>
            <p class="itens">
              {{ dataSaleForModalSale.employeeSelected.nomeFuncionario }}
            </p>
          </div>

          <div
            v-if="dataSale.comissao"
            class="contentItens"
            style="align-items: center"
          >
            <p class="titleItens">Valor Comissão</p>
            <p class="itens">
              {{ maskCurrency(dataSale.comissao) }}
            </p>
          </div>
        </section>

        <section class="dataSaleItems responsiveTable">
          <table>
            <thead>
              <tr>
                <th>Produto/Serviço</th>
                <th>Observação</th>
                <th style="text-align: start">Quantid.</th>
                <th style="text-align: center">Valor</th>
                <th style="text-align: end">Subtotal</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(
                  product, indice
                ) in dataSaleForModalSale.formattedProductsSale"
                :key="indice"
              >
                <td>{{ product.nome }}</td>
                <td>{{ product.dadosAdicionais }}</td>
                <td style="text-align: start">{{ product.quantidade }}</td>
                <td style="text-align: center">
                  R$ {{ product.valorUnitario }}
                </td>
                <td style="text-align: end">
                  {{ handleCalculateTotalProdInTable(product) }}
                </td>
              </tr>
            </tbody>
          </table>
        </section>
        <b-row style="margin: 30px 0">
          <div class="contentDataTotals">
            <div class="contentTotals">
              <span class="boxTotals">
                <p>Subtotal (R$)</p>
                <p class="colorItens">{{ totals.totalItems }}</p>
              </span>

              <span class="boxTotals">
                <p>Desconto (R$)</p>
                <p class="colorItens">{{ totals.totalDiscount }}</p>
              </span>

              <span class="dashed"></span>

              <span class="boxTotals contentFreteTotals">
                <p>Frete (R$)</p>
                <p class="frete">{{ totals.additionalValues }}</p>
              </span>

              <span class="boxTotals contentFreteTotals">
                <p>Outros (R$)</p>
                <p class="frete">{{ totals.otherValues }}</p>
              </span>

              <span class="boxTotals totals">
                <p>Total da Venda (R$)</p>
                <p class="colorItens">{{ totals.total }}</p>
              </span>
            </div>
          </div>
        </b-row>

        <hr style="margin: 0" v-if="dataSale.bills.length" />
        <div class="generalContent" v-if="dataSale.bills.length">
          <div class="subTitle">
            <p>Informações Financeiras</p>
          </div>

          <div class="contentTitlesFinancial">
            <div>
              <p class="titleFinancial">Forma de Pagamento</p>
              <p class="subTitleFinancial">
                {{ paymentsDataInfo.typePayment }}
              </p>
            </div>

            <div v-if="!isSimpleSafe">
              <p class="titleFinancial">Conta de Recebimento</p>
              <p class="subTitleFinancial">
                {{ paymentsDataInfo.receiptAccount }}
              </p>
            </div>

            <div>
              <p class="titleFinancial">Intervalo de Parcelas (dias)</p>
              <p class="subTitleFinancial">
                {{ paymentsDataInfo.installmentsInterval }}
              </p>
            </div>

            <div>
              <p class="titleFinancial">Número de Parcelas</p>
              <p class="subTitleFinancial">
                {{ paymentsDataInfo.installmentsNumber }}
              </p>
            </div>

            <div>
              <p class="titleFinancial">Venc. Primeira Parcela</p>
              <p class="subTitleFinancial">
                {{ paymentsDataInfo.expiredFirst }}
              </p>
            </div>
          </div>
        </div>

        <div class="generalContent mt-3" v-if="dataSale.bills.length">
          <div class="subTitle">
            <p>Parcelas</p>
          </div>

          <div
            style="
              background-color: whitesmoke;
              padding: 10px 5px;
              border-radius: 5px;
            "
          >
            <table style="text-align: center">
              <thead>
                <tr>
                  <th style="width: 60px"></th>
                  <th style="width: 150px">Data de vencimento</th>
                  <th style="width: 150px">Data de Pagamento</th>
                  <th>Valor restante</th>
                  <th>Valor pago</th>
                  <th>Descrição</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bill, index) in dataSale.bills" :key="index">
                  <td>{{ index + 1 }}º Venc.</td>
                  <td>
                    {{ bill.data.split(" ")[0].split("-").reverse().join("/") }}
                  </td>
                  <td>
                    {{
                      bill.dataPagamento
                        ? bill.dataPagamento
                            .split(" ")[0]
                            .split("-")
                            .reverse()
                            .join("/")
                        : "--"
                    }}
                  </td>
                  <td>
                    {{
                      bill.dataPagamento
                        ? maskCurrency(0)
                        : maskCurrency(bill.valorRestante)
                    }}
                  </td>
                  <td>
                    {{
                      bill.dataPagamento && !bill.valorPago
                        ? maskCurrency(bill.valorRestante)
                        : maskCurrency(bill.valorPago)
                    }}
                  </td>
                  <td>{{ bill.descricao }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <hr style="margin: 20px 0" v-if="dataSale.dadosAdicionais" />

        <div class="generalContent m-0">
          <div v-if="dataSale.dadosAdicionais != ''">
            <p class="titleObs">Observações Gerais</p>
            <div class="descriptions">{{ dataSale.dadosAdicionais }}</div>
          </div>

          <div v-if="dataSale.observacoesNf != ''">
            <p class="titleObs">Observação de Nota Fiscal</p>
            <div class="descriptions">{{ dataSale.observacoesNf }}</div>
          </div>
        </div>
      </div>

      <b-modal
        id="modal-center"
        size="lg"
        hide-footer
        hide-header
        centered
        title="Emitir"
        @hidden="closePrint"
      >
        <div>
          <h6 class="titleModal">
            {{
              !isOtherPrints ? "Deseja imprimir ?" : "Modelos para impressão"
            }}
          </h6>
          <div class="btnModal" v-if="!isOtherPrints">
            <button class="btnContentReceive" @click="showOtherPrints">
              Impressões
            </button>

            <button
              v-if="
                (isStoneIntegration || isVeroIntegration) &&
                enableReceivePayment
              "
              class="btnContent"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
              @click="openModals('modalSelectMachine2')"
              :disabled="isGenerateOrder"
            >
              Receber Pagamento

              <b-spinner v-if="isGenerateOrder" class="spinnerCreateOrder" />
            </button>

            <button
              v-else
              class="btnContentNfce"
              @click="
                isStoneIntegration || isVeroIntegration
                  ? checkPaymentIsFinished(
                      'Cupom Fiscal enviado para a maquininha',
                      true
                    )
                  : sendCoupon(dataSale.id)
              "
            >
              Emitir NFC-e
            </button>

            <button
              class="btnContent"
              @click="handleTranformToNfeAndCloseModal"
              v-if="dataSale.status === 'Venda'"
            >
              {{ returnTextSaveAndSendNota }}
            </button>
          </div>

          <div class="rowContainer" v-else>
            <span>
              <b-icon-calendar-week></b-icon-calendar-week> Selecione um
              modelo:</span
            >
            <div class="rowContent mt-3">
              <div
                class="containerRadio"
                v-for="(modelPrint, idx) in modelsPrints"
                :key="idx"
              >
                <label @click="selectTypeReport(modelPrint.type)">
                  <input
                    type="radio"
                    name="radio"
                    :checked="modelPrint.value"
                  />
                  <span>{{ modelPrint.text }}</span>
                </label>
              </div>
            </div>

            <div
              class="rowContent"
              style="justify-content: flex-end; padding: 0"
            >
              <button class="btnPrint" @click="printReportSelect">
                Imprimir
              </button>
            </div>
          </div>

          <div v-if="isOtherPrints" class="rowContent mt-3" style="padding: 0">
            <span class="contentClosePrint" @click="closePrint">
              <b-icon-arrow-left scale="1.5" />
              <p>Voltar</p>
            </span>
          </div>

          <a
            class="rejectModal"
            v-if="!spinLoading && !isOtherPrints"
            @click="startNewSale"
            >Não, Obrigado!</a
          >
        </div>
        <Loading v-if="spinLoading" class="spin" />
      </b-modal>
    </b-modal>

    <ModalAwaitPayment
      :idSale="dataSale.id"
      :typeSale="typeSale.venda"
      :typeIntegration="typeIntegration"
      @cancelPayment="closeModal('ModalAwaitPayment')"
    />

    <b-modal
      id="modalSelectMachine2"
      size="sm"
      hide-footer
      hide-header
      centered
      title="Emitir"
    >
      <div>
        <h6 class="titleModalSelectMachine">Selecione a maquininha</h6>
        <div class="contentModalSelectMachine">
          <div style="width: 200px">
            <InputSelect
              label="Maquininhas"
              valueField="value"
              textField="text"
              :items="machineTypes"
              :dataSelect.sync="typeIntegration"
              :dataInput="typeIntegration"
              :enableIconCreate="false"
              ref="machines"
            />
          </div>

          <div style="display: flex; gap: 0.5rem; width: 200px">
            <button
              class="btnCloseModalMachine"
              @click="closeModal('modalSelectMachine2')"
            >
              Voltar
            </button>

            <button class="btnConfirmMachine" @click="generateOrderInMachine">
              Confirmar
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";

import ModalAwaitPayment from "../Modals/ModalAwaitPayment.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";

import serviceNotaFiscal from "../../services/serviceNotaFiscal";
import ServiceMachine from "../../services/serviceMachine";
import ServiceSale from "../../services/serviceSale";

import { OrderMachineStatus } from "../../utils/enums/OrderMachineState";
import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import toastAlertErros from "../../utils/toastAlertErros";
import { E_TypesReports } from "../../utils/enums/reports/Sale";
import { TypeSale } from "../../utils/enums/SalesType";
import { formatObjectToSaveAndUpdate } from "../../helpers/saleAndBudget";

import Loading from "../Loading/Loading.vue";
import DefaultLogo from "../../assets/LogoTeste.jpeg";
import print from "../../reports/sale";

export default {
  components: {
    Loading,
    ModalAwaitPayment,
    InputSelect,
  },
  props: {
    dataSale: {
      type: Object,
    },
    totals: {
      type: Object,
    },
    dataSaleForModalSale: {
      type: Object,
    },
    paymentsDataInfo: {
      type: Object,
    },
    numberSale: {
      type: [Number, String],
    },
  },
  data() {
    return {
      spinLoading: false,
      defaultLogo: DefaultLogo,
      productsSale: {},
      isOtherPrints: false,
      enableReceivePayment: true,
      isGenerateOrder: false,
      typeReportSelected: E_TypesReports.simple,
      modelsPrints: [
        {
          text: "Padrão",
          value: true, 
          type: E_TypesReports.simple
        },
        {
          text: "Recibo 58mm",
          value: false,
          type: E_TypesReports.cupom58mm,
        },
        {
          text: "Contrato",
          value: false,
          type: E_TypesReports.contrato,
        },
      ],
      loadingToSaveOrUpdate: false,
      typeSale: TypeSale,
      typeIntegration: "stone",
      machineTypes: [
        { text: "Stone", value: "stone" },
        { text: "Vero", value: "vero" },
      ],
    };
  },
  methods: {
    ...mapMutations([
      "SET_DATA_NFE",
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
      "SET_SALE_INTERVAL_MACHINE_ID",
    ]),
    async saveOrUpdateSale(showToast) {
      if (!this.dataSale.id) return await this.saveSale(showToast);
      await this.updateSale(showToast);
    },

    async saveSale(showToast = true) {
      try {
        this.loadingToSaveOrUpdate = true;
        const dataSaleFormatted = formatObjectToSaveAndUpdate(this.dataSale);
        const result = await ServiceSale.createWithoutProduct(
          dataSaleFormatted
        );

        if (result.billsOfSale) {
          result.billsOfSale.forEach((bill) => {
            if (bill.dataPagamento) {
              bill.valorPago = bill.valorTotal;
              bill.valorRestante = 0;
            }
          });
        }

        this.$emit("saleSaved", result);
        this.openModals("modal-center");
        if (showToast) {
          return this.$toast.open({
            message: "Venda salva com Sucesso",
            type: "success",
          });
        }
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }

        if (error?.response?.status === 422) {
          this.$toast.open({
            message:
              typeError?.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        } else if (error?.response?.status === 500) {
          this.$toast.open({
            message: "Ocorreu um problema ao salvar a venda",
            type: "info",
          });
        } else {
          this.$toast.open({
            message:
              error?.response?.data?.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        }
      } finally {
        this.loadingToSaveOrUpdate = false;
      }
    },

    async updateSale(showToast = true) {
      try {
        this.loadingToSaveOrUpdate = true;
        const dataSaleFormatted = formatObjectToSaveAndUpdate(this.dataSale);
        const result = await ServiceSale.updateWithoutProduct(
          dataSaleFormatted
        );

        if (result.billsOfSale) {
          result.billsOfSale.forEach((bill) => {
            if (bill.dataPagamento) {
              bill.valorPago = bill.valorTotal;
              bill.valorRestante = 0;
            }
          });
        }

        this.$emit("saleSaved", result);
        this.openModals("modal-center");

        if (showToast) {
          return this.$toast.open({
            message: "Venda atualizada com Sucesso",
            type: "success",
          });
        }
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }

        if (error?.response?.status === 422) {
          this.$toast.open({
            message:
              typeError?.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        } else if (error?.response?.status === 500) {
          this.$toast.open({
            message: "Ocorreu um problema ao salvar a venda",
            type: "info",
          });
        } else {
          this.$toast.open({
            message:
              error?.response?.data?.message ||
              "Verifique se os dados do cliente foram informados",
            type: "info",
          });
        }
      } finally {
        this.loadingToSaveOrUpdate = false;
      }
    },

    async print() {
      if (!this.dataSale.id) return;
      print("reportVendaOrcamento", this.dataSale.id);
    },

    printReportSelect() {
      if (!this.dataSale.id) return;
      print(this.typeReportSelected, this.dataSale.id);
    },

    showOtherPrints() {
      this.isOtherPrints = !this.isOtherPrints;
    },

    async handleTranformToNfeAndCloseModal() {
      const showToast = false;
      if (!this.dataSale.id) await this.saveSale(showToast);

      this.closeModal("modalSale");
      this.transformToNfe();
    },

    async transformToNfe() {
      try {
        const dataNFe = await ServiceSale.transformWithNota(this.dataSale.id);
        this.SET_DATA_NFE(dataNFe);

        this.$toast.open({
          message: "Venda transformada em nota fiscal!",
          type: "success",
        });

        this.$router.push({
          path: `/fiscal`,
        });
      } catch (error) {
        return toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async sendCoupon(idSale) {
      try {
        this.spinLoading = true;

        const data = await serviceNotaFiscal.sendCoupon(idSale);
        const danfe = data.dataWebMania.danfe;
        this.visualizarNfe(danfe);
        return this.$toast.open({
          message: "Cupom Fiscal emitido com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && typeError?.nameError === TypeErrors.NO_PERMISSIONS) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        return this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ops! Houve algum erro. Verifique os dados e tente novamente",
          type: "info",
        });
      } finally {
        this.spinLoading = false;
      }
    },

    async generateOrderInMachine() {
      try {
        this.$bvModal.hide("modalSelectMachine2");
        this.isGenerateOrder = true;

        if (!this.dataSale.bills.length) {
          return this.$toast.open({
            message: "Nenhum pagamento informado",
            type: "info",
          });
        }

        await ServiceMachine.createOrder(
          this.dataSale.id,
          this.typeIntegration
        );

        this.startPaymentInMachine();
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao criar a ordem para a maquininha",
          type: "info",
        });
      } finally {
        this.isGenerateOrder = false;
      }
    },

    async startPaymentInMachine() {
      this.$bvModal.show("ModalAwaitPayment");

      const id = setInterval(this.checkPaymentIsFinished, 5000);
      this.SET_SALE_INTERVAL_MACHINE_ID(id);
    },

    async checkPaymentIsFinished(
      toastMessag = "Pagamento realizado com sucesso!",
      repeatToast = false
    ) {
      try {
        this.spinLoading = true;

        const result = await ServiceMachine.checkIsPaidAndGenerateDocument(
          this.dataSale.id,
          this.typeIntegration
        );

        if (result?.status === OrderMachineStatus.PAID) {
          clearInterval(this.saleIntervalMachineId);
          this.SET_SALE_INTERVAL_MACHINE_ID("");

          this.$bvModal.hide("ModalAwaitPayment");
          this.enableReceivePayment = false;

          if (repeatToast) {
            this.$toast.open({
              message: toastMessag,
              type: "success",
            });
          }

          if (!repeatToast && !this.isShowToastConfirmPayment) {
            this.$toast.open({
              message: toastMessag,
              type: "success",
            });
          }

          this.isShowToastConfirmPayment = true;
        }
      } catch (error) {
        clearInterval(this.saleIntervalMachineId);
        this.SET_SALE_INTERVAL_MACHINE_ID("");
        this.$bvModal.hide("ModalAwaitPayment");

        const typeError = HandleErrors(
          error?.response?.data?.message === "Validation failed"
            ? error?.response?.data?.erros
            : error?.response?.data?.message
        );

        if (repeatToast) {
          this.$toast.open({
            message: "Ocorreu um problema com a emissão do Cupom fiscal",
            type: "info",
          });
        } else if (
          typeError &&
          typeError?.nameError === TypeErrors.ITEM_NF_INVALID
        ) {
          this.enableReceivePayment = false;

          this.$toast.open({
            message: `Pagamento realizado, porém ocorreu um erro com emissão do documento fiscal. 
              <br />  
              <div style="color: yellow!important">ERRO: ${typeError.message}</div>
            `,
            type: "info",
          });
        } else {
          this.$toast.open({
            message:
              typeError?.message ||
              "Ocorreu um problema ao efetuar o pagamento!",
            type: "info",
          });
        }
      } finally {
        this.spinLoading = false;
      }
    },

    async checkPaymentIsPending(id) {
      try {
        const { status, machine } = await ServiceMachine.checkOrder(id);

        if (status === OrderMachineStatus.PENDING && machine) {
          this.startPaymentInMachine();
        } else if (status === OrderMachineStatus.CANCELED) {
          this.enableReceivePayment = true;
        } else if (status === OrderMachineStatus.PAID) {
          this.enableReceivePayment = false;
        }
      } catch (error) {
        this.$toast.open({
          message: "Ocorreu um problema ao efetuar o pagamento!",
          type: "info",
        });
      }
    },

    visualizarNfe(danfe) {
      window.open(danfe, "_blank", "width=700, height=800, top=500, left=500");
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }
      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    handleModalSafeOpening() {
      this.ALTER_VALUES_MODAL_DIALOG_GENERAL({
        nameModal: "modalDialogSafeOpening",
        title: "Caixa ainda não aberto!",
        subTitle:
          "Verificamos que você ainda não iniciou o caixa, para realizar qualquer lançamento, é necessario realizar a abertura do caixa!",
        footerTitle: "Deseja abrir o caixa agora ?",
        leftButtonText: "Não",
        rightButtonText: "Sim",
      });

      this.$nextTick(() => {
        this.openModals(this.dataModalDialogGeneral.nameModal);
      });
    },

    selectTypeReport(typeReport) {
      this.typeReportSelected = typeReport;
    },

    closePrint() {
      this.isOtherPrints = false;
    },

    startNewSale() {
      this.$emit("clearAllSale");
      this.$bvModal.hide("modal-center");
      this.closeModal("modalSale");
    },

    handleCalculateTotalProdInTable(prod) {
      let newTotalValue = 0;

      if (this.dataSale.tipoDesconto === "Porcentagem") {
        const result = (prod.valorTotal * (prod.desconto / 100)).toFixed(2);

        newTotalValue = parseFloat(prod.valorTotal - result);
      } else {
        newTotalValue = prod.valorTotal - prod.desconto;
      }

      return this.maskCurrency(newTotalValue);
    },

    openModals(name) {
      this.$bvModal.show(name);
    },

    closeModal(name) {
      this.$bvModal.hide(name);
    },
  },
  computed: {
    ...mapState({
      dataCompany: (state) => state.dataCompany,
      dataModalDialogGeneral: (state) => state.dataModalDialogGeneral,
      dataSafeIsOpen: (state) => state.dataSafeIsOpen,
      dataConfig: (state) => state.dataConfig,
      saleId: (state) => state.saleId,
      saleIntervalMachineId: (state) => state.saleIntervalMachineId,
    }),
    isCpfInCheckList () {
      if (this.dataSaleForModalSale.customerSelected.nome && !this.dataSaleForModalSale.customerSelected.cpfCnpj) {
        return "Cliente sem CPF no cadastro"
      }

      return ''
    },
    returnTextSaveAndSendNota() {
      if (!this.dataSale.id) {
        return "Salvar e Emitir NF-e";
      } else {
        return "Emitir NF-e";
      }
    },
    isStoneIntegration() {
      if (
        this.dataCompany?.liberars[0]?.integracoes &&
        Object.keys(this.dataCompany?.liberars[0]?.integracoes).length
      ) {
        const stone =
          this.dataCompany?.liberars[0]?.integracoes.cardMachines.find(
            (machine) => machine.text === "STONE"
          );

        return stone.value;
      }

      return false;
    },
    isVeroIntegration() {
      if (
        this.dataCompany?.liberars[0]?.integracoes &&
        Object.keys(this.dataCompany?.liberars[0]?.integracoes).length
      ) {
        const vero =
          this.dataCompany?.liberars[0]?.integracoes.cardMachines.find(
            (machine) => machine.text === "VERO"
          );

        return vero ? vero.value : false;
      }

      return false;
    },
    safeIsOpen() {
      return this.dataSafeIsOpen.safeIsOpen;
    },
        isSimpleSafe() {
      return this.dataConfig?.caixa_simples ?? true;
    },
  },
  watch: {
    safeIsOpen() {
      this.saveOrUpdateSale();
    },
  },

  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  mounted() {
    if (this.saleId && (this.isStoneIntegration || this.isVeroIntegration)) {
      this.checkPaymentIsPending(this.saleId);
    }
  },
};
</script>

<style scoped>
.footerBtn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

thead th {
  border-bottom: 1px solid #d5d3d3;
  color: #afadad;
  font-size: 12px;
  font-weight: normal;
}

td {
  color: black;
  font-size: 12px;
}

.dataSaleItems {
  font-size: 14px;
  margin-top: 25px;
}

table {
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
}

.responsiveTable {
  max-height: 200px;
  overflow-x: auto;
}

.btnsActions {
  width: 150px;
  font-size: 18px;
  margin-top: 0;
  background-color: #0fbf4d;
}

.btnSaveAndSendLeaf {
  width: 200px;
  font-size: 18px;
  margin-top: 0;
  background-color: #00395d;
}

.summaryTitle h5 {
  font-family: Arial, Helvetica, sans-serif;

  color: #00486e;
}

.contentSummary {
  display: flex;
  flex-direction: column;
}

.contentSummary p {
  margin-bottom: 5px;
}

.itensSummary {
  display: flex;
  justify-content: space-between;
}

.company p {
  font-family: Arial, Helvetica, sans-serif;
  color: #00486e;
  font-size: 15px;
}

.contentDataCompany {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dataCompany {
  display: flex;
  align-items: center;
  gap: 20px;
}

.dataCompany img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  border: solid 1px rgb(162, 144, 144);
}

.contentLogo {
  display: flex;
  justify-content: center;
}

.contentTotals {
  width: 370px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.boxTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
  cursor: auto;
}

.boxTotals {
  display: flex;
  align-items: center;
  cursor: auto;
}

.boxTotals .colorItens {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none;
  padding-right: 5px;
  background-color: #f2f3f3;
  border-radius: 5px;
  height: 30px;
  width: 177px;
}

.totals {
  font-size: 13px !important;
  color: #666360 !important;
  font-weight: 600;
}

.contentFreteTotals .frete {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  outline: none;
  padding-right: 5px;
  background-color: #f2f3f3;
  border-radius: 5px;
  height: 30px;
  width: 177px;
}

.spinnerCreateOrder {
  color: white;
  position: absolute;
  right: 5px;
  width: 20px;
  height: 20px;
}

.btnContentNfce {
  font-size: 11px;
  border: 1px solid #403b74;
  border-radius: 3px;
  width: 30%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #403b74;
}

.dataCabecalho {
  width: 100%;
  display: flex;
  background-color: #f7f7f7;
  gap: 20px;
  border-radius: 4px;
}

.contentItens {
  display: flex;
  flex-direction: column;
  width: 30%;
}

.titleItens {
  color: #afadad;
  font-size: 12px;
}

.itens {
  color: black;
  font-size: 14px;
}

.contentSections {
  display: flex;
  flex-direction: column;
  padding: 0 10px;
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.titleModalSelectMachine {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-bottom: 1px solid #e6e7e8;
}

.contentModalSelectMachine {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  gap: 1.3rem;
  height: auto;
  margin-top: 1.3rem;
}

.btnConfirmMachine {
  font-size: 11px;
  border: 1px solid #0fbf4d;
  border-radius: 3px;
  width: 100%;
  height: 28px;
  position: relative;
  background-color: #0fbf4d;
  color: white;
  background-color: #0fbf4d;
  transition: ease-in 0.1s;
}

.btnConfirmMachine:hover {
  background-color: #0c963c;
  transition: ease-in 0.1s;
}

.btnCloseModalMachine {
  font-size: 11px;
  border: 1px solid black;
  border-radius: 3px;
  width: 100%;
  height: 28px;
  position: relative;
  background-color: white;
  color: black;
  background-color: white;
  transition: ease-in 0.1s;
}

.btnCloseModalMachine:hover {
  background-color: rgb(233, 233, 233);
  transition: ease-in 0.1s;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 30%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 30%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.btnPrint {
  font-size: 11px;
  border: 1px solid #0fbf4d;
  border-radius: 3px;
  color: #ffffff;
  background: #0fbf4d;
  box-shadow: 0 1px 2px 1px rgba(0, 0, 0, 0.185);
  transition: ease-in all 0.1s;
}

.btnPrint:hover {
  transition: ease-in all 0.1s;
  background: #0ea241;
}

.contentClosePrint {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.3rem;
  font-size: 11px;
  border-radius: 3px;
  color: #00486e;
  transition: ease-in all 0.1s;
  font-weight: 600;
  cursor: pointer;
}

.contentClosePrint:hover {
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.321);
}

.rejectModal {
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-style: oblique;
  text-decoration: underline;
}

.spin {
  width: 480px;
  height: 100%;
  top: 0;
}

.headerSale {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0 20px 0 10px;
}

.headerSale p {
  font-size: 14px !important;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  color: #666360 !important;
}

.closeModal {
  position: absolute;
  top: 0;
  right: 0;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgba(245, 245, 245, 0.476);
  padding: 1px 2px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.contentDataTotals {
  width: 100%;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.btnsSale {
  border-radius: 5px;
  background-color: transparent;
  width: 200px;
  height: 28px;
  border: none;
  border: solid 1px black;
  color: black;
  font-size: 12px;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.btnsSaveOrSendNfe {
  width: 135px !important;
}

.titleObs {
  color: black;
}

.descriptions {
  outline: none;
  padding: 5px;
  background-color: #f2f3f3;
  border-radius: 5px;
  min-height: 30px;
  height: auto;
  width: 100%;
  font-size: 12px;
  white-space: break-spaces;
}

.generalContent {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.generalContent p {
  font-size: 14px;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

.contentFinancialObservations p {
  color: black;
  font-size: 14px;
}

.contentTitlesFinancial {
  width: 100%;
  display: flex;
  gap: 30px;
  text-align: center;
  margin: 10px 0;
  background-color: whitesmoke;
  padding: 10px 5px;
  border-radius: 5px;
}

.titleFinancial {
  color: #afadad !important;

  font-size: 12px !important;
}

.subTitleFinancial {
  font-size: 12px !important;
}

p {
  margin: 0 !important;
}

*::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}

*::-webkit-scrollbar-track {
  background: transparent;
}

*::-webkit-scrollbar-thumb {
  background-color: #00486e;
  border-radius: 2px;
}

.modal-footer {
  padding: 0.25rem;
}

.subTitle p {
  font-size: 14px;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-top: 20px;
}

.rowContainer span {
  font-weight: 600;
  font-size: 11px;
}

.rowContent {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 10px 0 5px 0;
  gap: 10px;
}

/* INPUT RADIO */

.containerRadio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0.375em;
}

.containerRadio label input {
  position: absolute;
  left: -9999px;
}

.containerRadio label input:checked + span {
  background-color: #f3f2f2;
  color: rgb(0, 0, 0);
}

.containerRadio label input:checked + span:before {
  box-shadow: inset 0 0 0 0.4375em #00486e;
}

.containerRadio label span {
  display: flex;
  align-items: center;
  padding: 0.375em 0.75em 0.375em 0.375em;
  border-radius: 2px;
  transition: 0.25s ease;
}

.containerRadio label span:hover {
  background-color: #e6e6e6;
  border-radius: 2px;
}

.containerRadio label span:before {
  display: flex;
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1.5em;
  height: 1.5em;
  border-radius: 50%;
  margin-right: 0.375em;
  transition: 0.25s ease;
  box-shadow: inset 0 0 0 0.1em #00486e;
}

/* INPUT RADIO */

@media (min-width: 768px) and (max-width: 1200px) {
  .contentTotals {
    width: 351px;
    font-size: 11px;
    gap: 0px;
  }
}
</style>