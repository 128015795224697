<template>
  <div class="General">
    <div class="Summary">
      <div class="titleStatement">
        <div class="pagMonth">
          <span> Movimentações do dia </span>
          <b-icon-chevron-left
            class="Arrows"
            id="btn-day-prev"
            @click="updateDay('prev')"
          ></b-icon-chevron-left>
          <span>
            {{ this.formattedDate.toFormat("dd/MM/yyyy") }}
          </span>
          <b-icon-chevron-right
            class="Arrows"
            id="btn-day-next"
            @click="updateDay('next')"
          ></b-icon-chevron-right>
        </div>
      </div>
      <div class="generalBleeding">
        <div class="Bleeding">
          <div class="BleedingTitle">
            <b-dropdown
              id="dropdown-5"
              class="btnActionsBox"
              variant="none"
              size="sm"
              menu-class="w-100"
              text="Ações do Caixa"
            >
              <b-dropdown-item v-b-modal.modalreceiptLoose>
                Recibo Avulso
              </b-dropdown-item>
              <b-dropdown-item @click="openModals('modalVoucher')">
                Realizar Vale
              </b-dropdown-item>
              <b-dropdown-item v-b-modal.modal-center2
                >Transferência entre contas</b-dropdown-item
              >
              <b-dropdown-item
                @click="findTypePaymentsUsed"
                v-b-modal.modal-ajustAccount
                >Criar Movimentação</b-dropdown-item
              >
              <b-dropdown-item v-b-modal.modal-conferir
                >Conferir contas</b-dropdown-item
              >
            </b-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div class="carroselCtn">
      <div class="StatementOnAccountMoviment">
        <button
          @click="searchPerType"
          class="StatementOnAccountItem"
          name="entrada"
          style="color: #00c26b"
        >
          Total de Entradas
          <p>
            {{
              this.entrySum.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </button>
        <button
          @click="searchPerType"
          class="StatementOnAccountItem"
          name="saida"
          style="color: #c31d1d"
        >
          Total de Saídas
          <p>
            {{
              this.exitSum.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </button>
        <button
          @click="searchPerType"
          class="StatementOnAccountItem"
          name="sangria"
          style="color: #3e66ae"
        >
          Sangria Diária
          <p>
            {{
              this.bleedingSum.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </button>
        <button
          class="StatementOnAccountItem"
          @click="balanceDay"
          style="color: #4c4c4c"
        >
          Saldo Geral
          <p>
            {{
              this.generalBalance.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </button>
        <button
          class="StatementOnAccountItem"
          @click="findTypePaymentsUsed"
          v-b-toggle.sidebar-right
          style="
            color: #f26c4f;
            justify-content: center;
            background: #e9e9e9;
            border: 3px solid #d9d9d9;
          "
        >
          <span
            style="text-transform: uppercase; font-size: 13px; font-weight: 600"
          >
            Realizar Sangria
          </span>
        </button>
      </div>
    </div>
    <div class="ContainerSearchInput">
      <div class="searchContainer">
        <span>Extrato de Movimentações</span>

        <div class="searchContent">
          <div class="searchAvancedContent">
            <button
              v-if="!isAdvancedSearch"
              class="searchAvanced"
              @click="enableAdvancedSearch"
            >
              Realizar Pesquisa Avançada
            </button>
            <button
              v-if="isAdvancedSearch"
              class="searchAvanced"
              @click="enableAdvancedSearch"
            >
              Pesquisa por mês
            </button>
          </div>
          <div class="searchInput" v-if="isAdvancedSearch">
            <button class="btnPeriod" size="sm">Período</button>
            <div class="dateInput">
              <input
                type="date"
                id="date"
                v-model="startDate"
                style="
                  padding: 0.25rem 0.25rem 0.25rem 0.5rem;
                  cursor: pointer;
                  border-left: none;
                  border-radius: 0px 5px 5px 0px;
                "
              />
              <input
                type="date"
                id="date"
                v-model="endDate"
                class="dateRight"
              />
            </div>
          </div>
          <div class="searchInput">
            <b-form-select
              class="searchFilter"
              size="sm"
              value-field="value"
              text-field="text"
              v-model="type"
              :options="filterCombobox"
            ></b-form-select>
            <div v-if="type === 'tipo'">
              <b-form-select
                class="searchFilterSituation custom-select2"
                value-field="value"
                text-field="text"
                v-model="textPesquisa"
                :options="filterPerType"
              ></b-form-select>
            </div>
            <div v-else-if="type === 'status'">
              <b-form-select
                class="searchFilterSituation custom-select2"
                value-field="value"
                text-field="text"
                v-model="textPesquisa"
                :options="filterPerStatus"
              ></b-form-select>
            </div>
            <div v-else>
              <b-input
                class="inputSearch"
                placeholder="Pesquisar por..."
                v-model="textPesquisa"
                @keyup.enter="filterByDayOrPeriod"
              ></b-input>
            </div>
            <b-button
              size="sm"
              @click="filterByDayOrPeriod()"
              class="searchBtn"
            >
              <b-icon-search></b-icon-search>
            </b-button>
          </div>
        </div>
      </div>
    </div>
    <div class="ContentTable">
      <table>
        <thead class="headTable">
          <tr>
            <th>Data</th>
            <th>Tipo</th>
            <th>Cliente / Fornecedor</th>
            <th>Origem</th>
            <th>Meio</th>
            <th>Valor</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody v-if="this.dataLength > 0" style="color: #3a4750; width: 100%">
          <tr
            v-for="safe in listTableSafe"
            :key="safe.id"
            style="text-align: center; height: 30px; cursor: context-menu"
          >
            <td>{{ safe.data | moment }}</td>
            <td v-if="safe.status === 'Receita'" style="color: #48b865">
              {{ safe.status }}
            </td>
            <td v-else-if="safe.status === 'Despesa'" style="color: red">
              {{ safe.status }}
            </td>
            <td v-else-if="safe.status === 'Sangria'" style="color: #f2a83e">
              {{ safe.status }}
            </td>
            <td
              v-else-if="safe.status === 'Transferência'"
              style="color: #3e66ae"
            >
              {{ safe.status }}
            </td>
            <td
              v-else-if="safe.status === 'Suprimento de Gaveta'"
              style="color: #403b74"
            >
              {{ safe.status }}
            </td>
            <td
              v-else-if="safe.status === 'Suprimento de Conta'"
              style="color: #00486e"
            >
              {{ safe.status }}
            </td>
            <td v-else>
              {{ safe.status }}
            </td>

            <td v-if="safe.nome">{{ safe.nome }}</td>
            <td v-if="safe.nomeFornecedor">{{ safe.nomeFornecedor }}</td>
            <td v-if="!safe.nomeFornecedor && !safe.nome">
              Movimentação Interna
            </td>
            <td v-if="safe.numeroVenda">Venda n°{{ safe.numeroVenda }}</td>
            <td v-if="safe.numeroVendaMtq">
              Venda MT n°{{ safe.numeroVendaMtq }}
            </td>
            <td v-if="safe.descricaoConta" class="textEllipsis">
              {{ safe.descricaoConta || "--" }}
            </td>
            <td
              class="textEllipsis"
              v-if="
                !safe.descricaoConta &&
                !safe.numeroVenda &&
                !safe.numeroVendaMtq
              "
            >
              {{ safe.descricao || "--" }}
            </td>
            <td>{{ safe.tipoFormaPag }}</td>
            <td>
              {{
                safe.valor.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </td>

            <td>
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  class="btnActions1"
                  variant="none"
                  size="sm"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item
                    v-if="safe.status !== 'Vale'"
                    @click="findById(safe.id)"
                  >
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="getIdAccount(safe.id)"
                    v-b-modal.modal-center5
                  >
                    Imprimir
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="getDetails(safe.id)"
                    v-b-modal.modal-detailsCashier
                  >
                    Detalhes
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="safe.status !== 'Receita'"
                    @click="getIdAccount(safe.id)"
                    v-b-modal.modal-center
                  >
                    Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="7">
            <div class="defaultSearch">
              <!-- <img style="width: 25%" loading="lazy" :src="Search" /> -->
              <span>Não há dados para exibir ...</span>
            </div>
          </td>
        </tbody>
        <div v-if="spinGeneral" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>
    <b-modal id="modal-center" hide-footer hide-header centered title="Excluir">
      <div>
        <h6 class="titleModal">Deseja excluir essa movimentação ?</h6>
        <div class="btnModal">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center')"
          >
            Não, Obrigado!
          </button>
          <button class="btnContent" @click="deleteSafe()">Deletar</button>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-conferir" hide-footer hide-header centered>
      <div>
        <h6 class="titleModal">Todas as contas</h6>
        <div
          class="contentModal"
          style="
            font-size: 14px;
            display: flex;
            flex-direction: row !important;
            justify-content: space-evenly !important;
            gap: 10px;
            flex-wrap: wrap;
          "
        >
          <button
            v-for="accounts in allAccounts"
            :key="accounts.conta"
            class="checkAccount"
            :name="accounts.conta"
          >
            {{ accounts.conta }} <br />
            {{
              accounts.valor.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </button>
        </div>
        <div class="btnModal">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-conferir')"
          >
            Voltar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-center2"
      hide-footer
      hide-header
      centered
      title="Transferencia"
    >
      <h6 class="titleModal">
        <img class="cashT" :src="Cash" /> Transferência entre contas
      </h6>
      <div class="contentModal">
        <div class="rowContainer">
          <span>
            <b-icon-building></b-icon-building> Selecione as contas:
          </span>
          <div class="rowContent">
            <div class="searchInputTransfer">
              <div class="selectPlus">
                <label class="fixedSelect2 styleLabel1">Origem</label>
                <b-form-select
                  class="borderInput shadow-none"
                  valueField="id"
                  textField="nome"
                  :options="allAccountReceipt"
                  v-model="transfer.origin.idContaRecebimento"
                >
                </b-form-select>
              </div>
              <img class="transfer" :src="Transfer" />
              <div class="selectPlus">
                <label class="fixedSelect2 styleLabel">Destino</label>
                <b-form-select
                  class="borderInput shadow-none"
                  valueField="id"
                  textField="nome"
                  :options="allAccountReceipt"
                  v-model="transfer.destiny.idContaRecebimento"
                >
                </b-form-select>
              </div>
            </div>
          </div>
          <div class="rowContentExtract">
            <div v-if="this.totalExtractOrigin !== 0" class="extract">
              <p>Valor atual :</p>
              <p>
                {{
                  this.totalExtractOrigin.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </p>
              <button @click="clearExtractOrigin" class="btn-extract">
                <b-icon-eye-slash-fill scale="1.8"></b-icon-eye-slash-fill>
              </button>
            </div>
            <div v-else class="extract">
              <p>Valor atual :</p>
              <p>******</p>
              <button @click="getExtractOriginValue" class="btn-extract">
                <b-icon-eye-fill scale="1.8"></b-icon-eye-fill>
              </button>
            </div>
            <div v-if="this.totalExtractDestiny !== 0" class="extract">
              <p>Valor atual :</p>
              <p>
                {{
                  this.totalExtractDestiny.toLocaleString("pt-br", {
                    style: "currency",
                    currency: "BRL",
                  })
                }}
              </p>
              <button @click="clearExtractDestiny" class="btn-extract">
                <b-icon-eye-slash-fill scale="1.8"></b-icon-eye-slash-fill>
              </button>
            </div>
            <div v-else class="extract">
              <p>Valor atual :</p>
              <p>******</p>
              <button @click="getExtractDestinyValue" class="btn-extract">
                <b-icon-eye-fill scale="1.8"></b-icon-eye-fill>
              </button>
            </div>
          </div>
        </div>

        <div class="rowContainer">
          <span>
            <b-icon-info-circle></b-icon-info-circle> Informações da
            transferência:
          </span>
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                class="input borderInput"
                :value="maskCurrency(this.valueTransfer)"
                @input="calculateTransfer($event)"
              />
              <label class="user-label"> Valor </label>
            </div>
            <div class="searchInputTransfer">
              <div class="selectPlus1">
                <label class="fixedSelect2"> Forma de transferência</label>
                <b-form-select
                  class="borderInput shadow-none"
                  value-field="id"
                  text-field="tipo"
                  :options="formPaymentBleeding"
                  v-model="transferFormPayment"
                >
                </b-form-select>
              </div>
            </div>
          </div>
        </div>
        <div class="btnModal">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center2')"
          >
            Não, Obrigado!
          </button>
          <button class="btnContent" @click="assingTransfer">
            Realizar transferencia
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-center5"
      hide-footer
      hide-header
      centered
      title="imprimir"
    >
      <div>
        <h6 class="titleModal">Deseja imprimir essa movimentação ?</h6>
        <div class="btnModal">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-center5')"
          >
            Não, Obrigado!
          </button>
          <button class="btnContent" @click="printAcquittance()">
            Imprimir
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-ajustAccount"
      hide-footer
      hide-header
      centered
      size="lg"
      title="ajustes"
    >
      <h6 class="titleModal">
        <img class="cashT" :src="Cash" /> Criar movimentação no Caixa
      </h6>
      <div class="contentModal" style="gap: 10px">
        <div class="rowContainer">
          <span> <b-icon-building></b-icon-building> Selecione a conta: </span>
          <div class="rowContent">
            <div class="selectPlus1">
              <label class="fixedSelect2"> Tipo da movimentação</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="value"
                text-field="text"
                v-model="dataSafe.tipo"
                :options="filterPerType"
              >
              </b-form-select>
            </div>

            <div class="selectPlus1">
              <label class="fixedSelect2"> Forma de ajuste</label>
              <b-form-select
                class="borderInput shadow-none"
                value-field="id"
                text-field="tipo"
                :options="formPaymentBleeding"
                v-model="dataSafe.idFormaPagamento"
              >
              </b-form-select>
            </div>
            <div class="input-group1" style="width: 30% !important">
              <b-form-input
                class="input borderInput"
                :value="maskCurrency(this.valueTransfer)"
                @input="calculateTransfer($event)"
              />
              <label class="user-label"> Valor </label>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span> <b-icon-info-circle></b-icon-info-circle> Observações:</span>
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                v-model="dataSafe.descricao"
                class="input borderInput"
              />
              <label class="user-label1"> Digite suas Observações </label>
            </div>
          </div>
        </div>
        <div class="btnModal">
          <button
            class="btnContentReceive"
            @click="$bvModal.hide('modal-ajustAccount')"
          >
            Não, Obrigado!
          </button>
          <button class="btnContent" @click="assingAjustAccount">
            Realizar Ajuste
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modal-detailsCashier"
      hide-footer
      hide-header
      size="lg"
      centered
      title="detailsCashier"
    >
      <div>
        <h6 class="titleModalDetails">
          <img class="cash" :src="CashM" /> Detalhes da movimentação
        </h6>
        <h5 class="titleModalNome">
          {{
            this.dataEdit.fornecedor ||
            this.dataEdit.cliente ||
            "Movimentação direta no caixa"
          }}
        </h5>
        <div class="contentModal">
          <div class="rowContainer">
            <div class="rowDetails">
              <div class="celDetails">
                <span class="titlePropsDetails">Data:</span>
                <span>{{ this.dataEdit.data | moment }}</span>
              </div>
              <div class="celDetails">
                <span class="titlePropsDetails">Tipo:</span>
                <span>{{ this.dataEdit.tipo }}</span>
              </div>
              <div class="celDetails">
                <span class="titlePropsDetails">Status:</span>
                <span>{{ this.dataEdit.status }}</span>
              </div>
            </div>
            <div class="rowDetails">
              <div class="celDetails">
                <span class="titlePropsDetails">Valor:</span>
                <span>R$ {{ this.dataEdit.valor }}</span>
              </div>
              <div class="celDetails">
                <span class="titlePropsDetails">Conta:</span>
                <span>{{ this.dataEdit.conta }}</span>
              </div>
              <div class="celDetails">
                <span class="titlePropsDetails">Forma:</span>
                <span>{{ this.dataEdit.tipoFormaPag }}</span>
              </div>
            </div>
            <div class="rowDetails">
              <div class="celDetailsDescription">
                <span class="titlePropsDetails">Detalhes:</span>
                <span class="descriptionPropsDetails">{{
                  this.dataEdit.descricao
                }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="btnModal">
          <button
            class="btnContent"
            @click="$bvModal.hide('modal-detailsCashier')"
          >
            Fechar
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="modalreceiptLoose"
      hide-footer
      hide-header
      centered
      size="lg"
      title="modalreceiptLoose"
    >
      <h6
        class="titleModal"
        style="color: #00486e; padding: 10px; justify-content: center"
      >
        <b-icon-printer-fill scale="2.5" /> Impressão de recibo avulso
      </h6>
      <b-button class="closereceiptLoose" @click="closereceiptLoose">
        X
      </b-button>
      <div class="contentModal" style="gap: 0rem !important">
        <div class="rowContainer">
          <span>
            <b-icon-person-circle></b-icon-person-circle> Selecione o cliente:
          </span>
          <div class="rowContent">
            <Input
              ref="data"
              label="Data"
              type="date"
              style="width: 15%"
              :dataInput.sync="dataLoose.data"
              :labelFixed="true"
            />
            <InputInfinit
              style="width: 65%"
              label="Cliente"
              :isEdit="dataLoose.idCliente"
              :dataInput.sync="dataLoose.idCliente"
              :onSearch="fillCustomerComboBoxWithPaginate"
              :findAll="createdNewCustomer"
              :clearSelected="newOrder"
              @selectItem="handleDataCustomers"
            />
            <div class="input-group1" style="width: 15%; padding-left: 13px">
              <b-form-input
                class="input borderInput"
                :value="maskCurrency(this.dataLoose.valor)"
                @input="calculateLoose($event)"
              />
              <label class="user-label" style="left: 20px; font-size: 11px">
                Valor
              </label>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span> <b-icon-info-circle></b-icon-info-circle> Localização:</span>
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                v-model="dataLoose.referente"
                class="input borderInput"
              />
              <label class="user-label1"> Referente a </label>
            </div>
          </div>
        </div>
        <div class="rowContainer">
          <span> <b-icon-info-circle></b-icon-info-circle> Observações:</span>
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                v-model="dataLoose.observacao"
                class="input borderInput"
              />
              <label class="user-label1"> Digite suas Observações </label>
            </div>
          </div>
        </div>
        <div class="btnModal">
          <button class="btnContentReceive" @click="clearLoose">
            Novo Recibo
          </button>
          <button class="btnContent" @click="generateLoosePrint">
            Imprimir
          </button>
        </div>
      </div>
    </b-modal>
    <div class="setBtn">
      <button class="itemBtn" @click="previousPage" :disabled="this.page === 1">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button class="itemBtn" :disabled="this.dataLength < 9" @click="nextPage">
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>

    <div :class="sizeModalCashier">
      <div :class="modalContainerCashier" v-if="colapseCashier">
        <b-button class="closeModal" @click="modalHeight"> X </b-button>

        <ConfirmValues
          :typePaymentsUsedGrouped="typePaymentsUsedGrouped"
          :typePaymentsPerTypeMov="typePaymentsPerTypeMov"
          :totalsPayments="totalsPayments"
          :totalsEntriesAndExits="totalsEntriesAndExits"
          :idSafeRegister="dataSafeIsOpen.id"
          @clearValues="clearValues"
        />
      </div>
    </div>

    <ModalDialog
      nameModal="modalRemainingValue"
      title="Valor em aberto existente!"
      :subTitle="`Verificamos que restou em aberto do caixa anterior o valor de ${formattedRemainingValue} em aberto!`"
      footerTitle="Deseja iniciar o novo caixa com esse valor ?"
      leftButtonText="Não"
      rightButtonText="Sim"
      @clickLeftButton="cancelOpenWithRemainingValue"
      @clickRightButton="openWithRemainingValue"
    />

    <ModalCreateVoucher
      @getNewReceiptBill="createdNewAccountReceiptBill = $event"
    />

    <b-sidebar id="sidebar-right" width="43vw" right backdrop shadow>
      <h6 class="titleModal" style="justify-content: center" v-if="typeBalance">
        <img class="cash" :src="CashM" /> Sangria do dia:
        {{ this.formattedDate.toFormat("dd/MM/yyyy") }}
      </h6>
      <h6 class="titleModal" style="justify-content: center" v-else>
        <img class="cash" :src="CashM" /> Sangria GERAL
      </h6>
      <div class="radio-inputs">
        <label
          class="radio"
          style="margin-bottom: 0rem !important"
          @click="handleType('dia')"
        >
          <input type="radio" name="radio" checked="" />
          <span class="name">Sangria Diária</span>
        </label>
        <label
          class="radio"
          style="margin-bottom: 0rem !important"
          @click="handleType('geral')"
        >
          <input type="radio" name="radio" />
          <span class="name">Sangria Geral</span>
        </label>
      </div>
      <div class="contentModal" style="gap: 5px" v-if="typeBalance">
        <span class="textNowValue" style="justify-content: center"
          >O saldo Diário é :
          <p class="nowValue">
            {{
              (this.entrySum - this.exitSum - this.bleedingSum).toLocaleString(
                "pt-br",
                {
                  style: "currency",
                  currency: "BRL",
                }
              )
            }}
          </p>
        </span>
        <div style="padding: 0px 0px 0px 30px !important">
          <label class="custom-checkbox">
            <input v-model="isPrint" name="dummy" type="checkbox" />
            <span class="checkmark"></span>
            Imprimir comprovante
          </label>
        </div>
        <div
          class="rowContainer"
          style="margin-bottom: 0px; padding: 11px 30px 5px 30px"
        >
          <span>
            <b-icon-dash-circle></b-icon-dash-circle> Digite o valor que deseja
            retirar:</span
          >
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                :value="maskCurrency(withdraw)"
                @input="calculateSubtotal($event)"
                class="input borderInput"
              />
              <label class="user-label"> Valor </label>
            </div>
          </div>
        </div>
        <div
          class="rowContainer"
          style="margin-bottom: 0px; padding: 11px 30px 5px 30px"
        >
          <span>
            <b-icon-dash-circle></b-icon-dash-circle> Selecione Abaixo a Forma
            de retirada:</span
          >
          <div class="rowContent">
            <div class="selectPlus1">
              <b-form-select
                class="borderInput shadow-none"
                value-field="id"
                text-field="tipo"
                :options="formPaymentBleeding"
                v-model="transferFormPayment"
              >
              </b-form-select>
            </div>
          </div>
        </div>
        <div
          class="rowContainer"
          style="margin-bottom: 0px; padding: 11px 30px 5px 30px"
        >
          <span> <b-icon-info-circle></b-icon-info-circle> Observações:</span>
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                v-model="dataSafe.descricao"
                class="input borderInput"
              />
              <label class="user-label1"> Digite suas Observações </label>
            </div>
          </div>
        </div>
        <div class="remaining" style="justify-content: center">
          <span>O Valor restante será :</span>
          <input class="remainingInput" disabled :value="calculateRemaining" />
        </div>
        <div class="btnModal">
          <button
            style="visibility: hidden"
            class="btnContentReceive"
            @click="$bvModal.hide('modal-sangria')"
          >
            Não, Obrigado!
          </button>
          <button
            v-if="dataSafe.id"
            class="btnContent"
            style="font-size: 13px; width: 35%; height: 32px"
            @click="saveOrUpdateBleending"
          >
            Atualizar Sangria
          </button>
          <button
            v-else
            class="btnContent"
            style="font-size: 13px; width: 35%; height: 32px"
            @click="saveOrUpdateBleending"
          >
            Realizar Sangria
          </button>
        </div>
      </div>
      <div class="contentModal" style="gap: 5px" v-else>
        <span class="textNowValue" style="justify-content: center"
          >O Saldo Geral é de :
          <p class="nowValue">
            {{
              this.generalBalance.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
              })
            }}
          </p>
        </span>
        <div
          class="rowContainer"
          style="margin-bottom: 0px; padding: 11px 30px 5px 30px"
        >
          <span>
            <b-icon-dash-circle></b-icon-dash-circle> Digite o valor que deseja
            retirar:</span
          >
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                :value="maskCurrency(withdraw)"
                @input="calculateSubtotal($event)"
                class="input borderInput"
              />
              <label class="user-label"> Valor </label>
            </div>
          </div>
        </div>
        <div
          class="rowContainer"
          style="margin-bottom: 0px; padding: 11px 30px 5px 30px"
        >
          <span>
            <b-icon-dash-circle></b-icon-dash-circle> Selecione Abaixo a Forma
            de retirada:</span
          >
          <div class="rowContent">
            <div class="selectPlus1">
              <b-form-select
                class="borderInput shadow-none"
                value-field="id"
                text-field="tipo"
                :options="formPaymentBleeding"
                v-model="transferFormPayment"
              >
              </b-form-select>
            </div>
          </div>
        </div>
        <div
          class="rowContainer"
          style="margin-bottom: 0px; padding: 11px 30px 5px 30px"
        >
          <span> <b-icon-info-circle></b-icon-info-circle> Observações:</span>
          <div class="rowContent">
            <div class="input-group1">
              <b-form-input
                v-model="dataSafe.descricao"
                class="input borderInput"
              />
              <label class="user-label1"> Digite suas Observações </label>
            </div>
          </div>
        </div>
        <div class="remaining" style="justify-content: center">
          <span>O Valor restante será :</span>
          <input class="remainingInput" disabled :value="calculateRemaining" />
        </div>
        <div class="btnModal">
          <button
            style="visibility: hidden"
            class="btnContentReceive"
            @click="$bvModal.hide('modal-sangria')"
          >
            Não, Obrigado!
          </button>
          <button
            v-if="dataSafe.id"
            class="btnContent"
            style="font-size: 13px; width: 35%; height: 32px"
            @click="saveOrUpdateBleending"
          >
            Atualizar Sangria
          </button>
          <button
            v-else
            class="btnContent"
            style="font-size: 13px; width: 35%; height: 32px"
            @click="saveOrUpdateBleending"
          >
            Realizar Sangria
          </button>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import "moment/locale/pt";
import api from "../../services/axios";
import Input from "../UI/CustomInputs/Input.vue";
import InputInfinit from "../UI/CustomInputs/InputInfinit.vue";
import ConfirmValues from "./ConfirmValues.vue";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";
import ModalDialog from "../Modals/ModalDialog.vue";
import ServiceSafeRegister from "../../services/serviceSafeRegister";
import SafeService from "../../services/serviceSafe";
import ServiceEmployees from "../../services/serviceEmployees";
import ServiceSettings from "../../services/serviceSettings";
import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import toastAlertErros from "../../utils/toastAlertErros";
import Cash from "../../assets/cash.svg";
import Transfer from "../../assets/transfer.svg";
import CashM from "../../assets/cash-money.svg";
import Clock from "../../assets/clock.svg";
import serviceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import servicePaymentForm from "../../services/servicePaymentForm";
import printReport from "../../reports/sale";
import serviceRegisterBillPayment from "../../services/serviceRegisterBillPayment";
import ModalCreateVoucher from "../Modals/ModalCreateVoucher.vue";
import { DateTime } from "luxon";
import serviceCustomer from "../../services/serviceCustomer";

export default {
  components: {
    ModalDialog,
    ConfirmValues,
    ModalCreateVoucher,
    Input,
    InputInfinit,
  },
  data() {
    return {
      Cash: Cash,
      Transfer: Transfer,
      CashM: CashM,
      Clock: Clock,
      dataEdit: [],
      dataSafe: {
        id: "",
        idRegistroCaixa: "",
        idContaRecebimento: "",
        idFormaPagamento: "",
        tipo: "",
        status: "",
        valor: "",
        descricao: "",
        data: moment().format("YYYY-MM-DD HH:mm:ss"),
      },
      transfer: {
        origin: {
          idRegistroCaixa: "",
          idContaRecebimento: "",
          idFormaPagamento: "",
          tipo: "saida",
          status: "Transferência",
          valor: "",
          descricao: "",
          data: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
        destiny: {
          idRegistroCaixa: "",
          idContaRecebimento: "",
          idFormaPagamento: "",
          tipo: "entrada",
          status: "Transferência",
          valor: "",
          descricao: "",
          data: moment().format("YYYY-MM-DD HH:mm:ss"),
        },
      },
      valueTransfer: 0,
      valueAjustInitial: 0,
      transferFormPayment: "",
      spinGeneral: false,
      createdNewCustomer: false,
      newOrder: false,
      dataUnique: "",
      textPesquisa: "",
      type: "",
      account: "",
      startDate: "",
      endDate: "",
      entrySum: 0,
      exitSum: 0,
      bleedingSum: 0,
      formattedDate: DateTime.now(),
      allAccounts: [],
      dataAccountsLength: 0,
      dataLength: 0,
      page: 1,
      listTableSafe: [],
      employeesFromSelectBox: [],
      filterCombobox: [
        { value: "conta", text: "Conta" },
        { value: "tipo", text: "Tipo de movimentação" },
        { value: "status", text: "Status da movimentação" },
        { value: "tipoFormaPagamento", text: "Tipo de Pagamento" },
        { value: "", text: "Selecione..." },
      ],
      filterPerType: [
        { value: "entrada", text: "Entrada" },
        { value: "saida", text: "Saída" },
        { value: "", text: "Selecione..." },
      ],
      filterPerStatus: [
        { value: "Receita", text: "Receita" },
        { value: "Despesa", text: "Despesas" },
        { value: "Sangria", text: "Sangria" },
        { value: "Sangria Geral", text: "Sangria Geral" },
        { value: "transferencia", text: "Transferência" },
        { value: "suprimento", text: "Suprimento" },
        { value: "", text: "Selecione..." },
      ],
      typePaymentsUsedGrouped: [],
      typePaymentsPerTypeMov: [],
      totalsPayments: 0.0,
      totalsEntriesAndExits: {
        entrada: 0.0,
        saida: 0.0,
      },
      totals: 0.0,
      valueBleeding: 0.0,
      withdraw: 0,
      addSupplies: 0,
      allAccountReceipt: [],
      filterAccountReceipt: [],
      dataSafeRegister: {
        id: "",
        valorInicial: 0,
      },
      formPaymentBleeding: [],
      filterFormPaymentBleeding: "",
      teste: "",
      totalExtractDestiny: 0,
      totalExtractOrigin: 0,
      valueBoxInitial: 0,
      maskCurrency: maskCurrency,
      isAdvancedSearch: false,
      dataLoose: {
        idCliente: "",
        referente: "",
        valor: "",
        observacao: "",
        data: moment().format("YYYY-MM-DD"),
      },
      generalBalance: 0,
      isPrint: false,
      typeBalance: true,
      geralBleedingSum: 0,
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
      "SET_SAFE_REGISTER",
      "SET_SAFE_REMAINING_VALUE",
      "SET_COLAPSE_CASHIER",
      "ALTER_SIZE_MODAL_CASHIER",
      "ALTER_MODAL_CONTAINER_CASHIER",
      "SET_DATA_CONFIG",
    ]),
    async filterSafe() {
      try {
        this.spinGeneral = true;
        const { data } = await api.get(
          `/safe?q=${encodeURIComponent(this.textPesquisa)}&type=${
            this.type
          }&account=${this.account}&page=${this.page}&startDate=${
            this.startDate
          }&endDate=${this.endDate}&isAdvancedSearch=${this.isAdvancedSearch}`
        );
        this.dataLength = data.data.length;
        this.listTableSafe = data.data;
      } catch (error) {
        console.log(error);

        toastAlertErros.validateErro(error);
      } finally {
        this.spinGeneral = false;
      }
      if (this.dataLength < 9) {
        this.clearSearch();
      }
    },

    filterByDayOrPeriod() {
      if (!this.isAdvancedSearch) {
        this.startDate = this.formattedDate.toFormat("yyyy-MM-dd");
        this.filterSafe();
      }
      this.filterSafe();
    },

    balanceDay() {
      this.startDate = this.formattedDate.toFormat("yyyy-MM-dd");
      this.endDate = this.formattedDate.toFormat("yyyy-MM-dd");
      this.filterSafe(1);
    },

    async enableAdvancedSearch() {
      this.isAdvancedSearch = !this.isAdvancedSearch;
      if (!this.isAdvancedSearch) {
        this.startDate = "";
        this.endDate = "";
      }
    },
    async getGeneralBalance() {
      const result = await SafeService.generalBalance();
      this.generalBalance = result.totalEntrada - result.totalSaida;
    },

    getIdAccount(idAccount) {
      this.dataUnique = idAccount;
    },

    async getDetails(idSafe) {
      const data = await SafeService.findSafeById(idSafe);
      this.dataEdit = data;
    },

    async findById(idSafe) {
      this.dataEdit = await SafeService.findById(idSafe);
      if (this.dataEdit.status === "Suprimento de Gaveta") {
        this.$bvModal.show("modal-center3");
        this.dataSafe.id = this.dataEdit.id;
        this.editSafe();
      }
      if (this.dataEdit.status === "Sangria") {
        this.dataSafe.id = this.dataEdit.id;
        this.editSafe();
      }
      if (this.dataEdit.status === "Suprimento de Conta") {
        this.$bvModal.show("modal-ajustAccount");
        this.dataSafe.id = this.dataEdit.id;
        this.editSafe();
      }
      if (
        this.dataEdit.status === "Receita" ||
        this.dataEdit.status === "Despesa" ||
        this.dataEdit.status === "Transferência"
      ) {
        this.editRevenue(idSafe);
      }
    },

    async editRevenue() {
      this.dataBill = await serviceRegisterBillPayment.findByIdRegister(
        this.dataEdit.idRegistro
      );
      this.$nextTick(() => {
        this.$router.push({
          path: `/financeiro/${this.dataBill[0].idConta}`,
        });
      });
    },

    async saveOrUpdateBleending() {
      if (this.dataSafe.id !== "") {
        await this.updateSafeBleending(this.dataSafe);
      } else {
        await this.saveBleending();
      }
      this.getAllDay(this.formattedDate);
    },

    async saveOrUpdateSupplies() {
      if (this.dataSafe.id !== "") {
        await this.updateSafeSupplies(this.dataSafe);
      } else {
        await this.saveSupplies();
      }
      this.getAllDay(this.formattedDate);
    },

    async updateSafeSupplies(dataSafe) {
      try {
        this.dataSafe.valor = this.addSupplies;
        await api.put(`/safe/${dataSafe.id}`, dataSafe);
        this.clearFieldSupplies();
        this.clearFieldBleeding();
        return this.$toast.open({
          message: "Movimentação atualizada com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ops... Houve algum erro!",
            type: "info",
          });
      }
    },

    async updateSafeBleending(dataSafe) {
      try {
        if (this.typeBalance) {
          dataSafe.status = "Sangria";
        }
        if (!this.typeBalance) {
          dataSafe.status = "Sangria Geral";
        }
        this.dataSafe.valor = this.withdraw;
        await api.put(`/safe/${dataSafe.id}`, dataSafe);
        this.clearFieldSupplies();
        this.clearFieldBleeding();
        return this.$toast.open({
          message: "Movimentação atualizada com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ops... Houve algum erro!",
            type: "info",
          });
      }
    },

    async saveBleending() {
      try {
        this.dataSafe.valor = this.withdraw;
        this.dataSafe.idContaRecebimento = this.filterAccountReceipt[0].id;
        this.dataSafe.idFormaPagamento = this.transferFormPayment;
        this.dataSafe.idRegistroCaixa = this.dataSafeRegister.id;
        this.dataSafe.tipo = "saida";
        if (this.typeBalance) {
          this.dataSafe.status = "Sangria";
        }
        if (!this.typeBalance) {
          this.dataSafe.status = "Sangria Geral";
        }

        const id = await SafeService.save(this.dataSafe);
        if (this.isPrint) {
          printReport("acquittance", id.id);
        }

        this.clearFieldBleeding();
        return this.$toast.open({
          message: "Sangria realizada com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        this.$toast.open({
          message: typeError.message || "Ops... Houve algum erro!",
          type: "info",
        });
      }
    },

    editSafe() {
      this.withdraw = this.dataEdit.valor;
      this.addSupplies = this.dataEdit.valor;
      this.valueTransfer = this.dataEdit.valor;
      this.dataSafe.descricao = this.dataEdit.descricao;
      this.dataSafe.idContaRecebimento = this.dataEdit.idContaRecebimento;
      this.dataSafe.idFormaPagamento = this.dataEdit.idFormaPagamento;
      this.dataSafe.idRegistroCaixa = this.dataEdit.idRegistroCaixa;
      this.dataSafe.tipo = this.dataEdit.tipo;
      this.dataSafe.status = this.dataEdit.status;
    },

    async saveSupplies() {
      try {
        this.dataSafe.valor = this.addSupplies;
        this.dataSafe.idContaRecebimento = this.filterAccountReceipt[0].id;
        this.dataSafe.idFormaPagamento = this.filterFormPaymentBleeding[0].id;
        this.dataSafe.idRegistroCaixa = this.dataSafeRegister.id;
        this.dataSafe.tipo = "entrada";
        this.dataSafe.status = "Suprimento de Gaveta";

        await SafeService.save(this.dataSafe);

        this.clearFieldSupplies();
        return this.$toast.open({
          message: "Suprimento de caixa realizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        this.$toast.open({
          message: typeError.message || "Ops... Houve algum erro!",
          type: "info",
        });
      }
    },

    async updateInitial() {
      try {
        if (!this.dataSafeRegister.id)
          return this.$toast.open({
            message:
              "Nenhum caixa em aberto, para realizar lançamentos é necessário que você faça abertura do caixa!",
            type: "info",
          });

        this.dataSafeRegister.valorInicial = this.valueAjustInitial || 0;
        await ServiceSafeRegister.update(this.dataSafeRegister);
        this.getAllDay(this.formattedDate);
        this.clearAjust();
        this.existsSafeRegisterOpen();

        return this.$toast.open({
          message: "Ajuste realizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ops... Houve algum erro!",
            type: "info",
          });
      }
    },

    async assingTransfer() {
      try {
        this.transfer.origin.idRegistroCaixa = this.dataSafeRegister.id;
        this.transfer.origin.idFormaPagamento = this.transferFormPayment;
        this.transfer.origin.valor = this.valueTransfer;
        this.transfer.origin.descricao = "transferencia realizada";

        this.transfer.destiny.idRegistroCaixa = this.dataSafeRegister.id;
        this.transfer.destiny.idFormaPagamento = this.transferFormPayment;
        this.transfer.destiny.valor = this.valueTransfer;
        this.transfer.destiny.descricao = "transferencia recebida";

        const transferArray = [this.transfer.origin, this.transfer.destiny];

        await Promise.all(
          transferArray.map(async (item) => {
            await SafeService.save(item);
          })
        );

        this.clearTransfer();
        return this.$toast.open({
          message: "Tranferência realizada com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        this.$toast.open({
          message: typeError.message || "Ops... Houve algum erro!",
          type: "info",
        });
      }
    },

    clearTransfer() {
      this.transfer.destiny.idContaRecebimento = "";
      this.transfer.origin.idContaRecebimento = "";
      this.transferFormPayment = "";
      this.valueTransfer = 0;
      this.$bvModal.hide("modal-center2");
      this.filterSafe();
      this.filterByDayOrPeriod();
      this.sumPerAccount();
      this.getGeneralBalance();
      this.clearExtractOrigin();
      this.clearExtractDestiny();
    },

    clearAjust() {
      this.valueAjustInitial = 0;
      this.$bvModal.hide("modal-center4");
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    clearAjustAccount() {
      this.dataSafe.idContaRecebimento = "";
      this.dataSafe.tipo = "";
      this.transferFormPayment = "";
      this.dataSafe.idFormaPagamento = "";
      this.dataSafe.descricao = "";
      this.valueTransfer = 0;
      this.$bvModal.hide("modal-ajustAccount");
      this.filterSafe();
      this.filterByDayOrPeriod();
      this.sumPerAccount();
      this.getGeneralBalance();
      this.clearExtractOrigin();
      this.clearExtractDestiny();
    },

    async assingAjustAccount() {
      try {
        this.dataSafe.idRegistroCaixa = this.dataSafeRegister.id;
        this.dataSafe.idContaRecebimento = this.filterAccountReceipt[0].id;
        this.dataSafe.valor = this.valueTransfer;
        this.dataSafe.status = "Suprimento de Conta";
        await SafeService.save(this.dataSafe);
        this.getAllDay(this.formattedDate);
        this.clearAjustAccount();
        return this.$toast.open({
          message: "Ajuste realizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        this.$toast.open({
          message: typeError.message || "Ops... Houve algum erro!",
          type: "info",
        });
      }
    },

    async clearFieldBleeding() {
      this.withdraw = 0;
      this.dataSafe.descricao = "";
      this.transferFormPayment = "";
      this.isPrint = false;
      this.filterSafe();
      this.filterByDayOrPeriod();
      this.sumPerAccount();
      this.getGeneralBalance();
    },

    async clearFieldSupplies() {
      this.addSupplies = 0;
      this.dataSafe.descricao = "";
      this.filterSafe();
      this.filterByDayOrPeriod();
      this.sumPerAccount();
      this.getGeneralBalance();
    },

    async getAllReceiptBill() {
      this.allAccountReceipt = await serviceAccountReceiptBill.fillComobobox();
      this.filterAccountReceipt = this.allAccountReceipt.filter(
        (account) => account.nome === "CAIXA GAVETA"
      );
    },

    async getExtractDestinyValue() {
      this.teste = this.allAccountReceipt.filter(
        (account) => account.id === this.transfer.destiny.idContaRecebimento
      );

      const objectExtractDestiny = this.allAccounts.filter(
        (extract) => extract.conta === this.teste[0].nome
      );

      this.totalExtractDestiny = objectExtractDestiny[0].valor;
    },

    async getExtractOriginValue() {
      this.teste = this.allAccountReceipt.filter(
        (account) => account.id === this.transfer.origin.idContaRecebimento
      );

      const objectExtractOrigin = this.allAccounts.filter(
        (extract) => extract.conta === this.teste[0].nome
      );
      this.totalExtractOrigin = objectExtractOrigin[0].valor;
    },

    async getExtractAjust() {
      this.teste = this.allAccountReceipt.filter(
        (account) => account.id === this.dataSafe.idContaRecebimento
      );

      const objectExtractOrigin = this.allAccounts.filter(
        (extract) => extract.conta === this.teste[0].nome
      );
      this.totalExtractOrigin = objectExtractOrigin[0].valor;
    },

    clearExtractDestiny() {
      this.totalExtractDestiny = 0;
    },
    clearExtractOrigin() {
      this.totalExtractOrigin = 0;
    },

    async getAllPayment() {
      this.formPaymentBleeding = await servicePaymentForm.getAllPayment();
      this.filterFormPaymentBleeding = this.formPaymentBleeding.filter(
        (payment) => payment.tipo === "Dinheiro"
      );
    },

    async sumPerAccount() {
      const data = await SafeService.sumPerAccount();
      this.allAccounts = data?.data ?? [];
      this.dataAccountsLength = data.data.length;
      const caixaGavetaIndex = this.allAccounts.findIndex((account) => {
        return account.conta === "CAIXA GAVETA";
      });

      if (caixaGavetaIndex !== -1) {
        this.allAccounts[caixaGavetaIndex].valor += this.valueBoxInitial;
      }
    },
    searchPerType(event) {
      const button = event.currentTarget;
      const name = button.name;
      if (name === "entrada") {
        this.textPesquisa = name;
        this.type = "tipo";
      } else if (name === "saida") {
        this.type = "tipo";
        this.textPesquisa = name;
      } else if (name === "sangria") {
        this.type = "status";
        this.textPesquisa = name;
      }
      this.startDate = this.formattedDate.toFormat("yyyy-MM-dd");
      this.endDate = this.formattedDate.toFormat("yyyy-MM-dd");
      this.filterSafe(this.page, this.textPesquisa);
    },

    async updateDay(direction) {
      this.isAdvancedSearch = false;
      if (direction === "prev") {
        this.formattedDate = this.formattedDate.minus({ days: 1 });
      } else if (direction === "next") {
        this.formattedDate = this.formattedDate.plus({ days: 1 });
      }
      this.getAllDay(this.formattedDate);
      this.startDate = this.formattedDate.toFormat("yyyy-MM-dd");
      this.endDate = this.formattedDate.toFormat("yyyy-MM-dd");
      this.filterSafe(1);
    },

    searchPerAccount(event) {
      this.account = event.target.name;
      this.filterSafe(this.page, this.account, (this.isAdvancedSearch = true));
      this.isAdvancedSearch = false;
    },

    async deleteSafe() {
      this.getIdAccount(this.dataUnique);
      await api.delete(`/safe/${this.dataUnique}`);
      this.$bvModal.hide("modal-center");
      this.filterSafe(1);
      this.sumPerAccount();
      this.getAllDay(this.formattedDate);
      return this.$toast.open({
        message: "Movimentação deletada!",
        type: "success",
      });
    },

    async existsSafeRegisterOpen() {
      try {
        const safeRegister = await ServiceSafeRegister.existsSafeRegisterOpen();
        this.dataSafeRegister = safeRegister;
        this.valueBoxInitial = this.dataSafeRegister.valorInicial;

        this.SET_SAFE_REGISTER({ ...safeRegister, safeIsOpen: true });
      } catch (error) {
        if (!this.dataConfig.caixa_automatico.utilizar) {
          const typeError = HandleErrors(error?.response?.data?.message);

          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }
      }
    },

    async findTypePaymentsUsed() {
      const result = await SafeService.findPaymentsUsedBySafeRegisterId(
        this.dataSafeIsOpen.id
      );
      this.typePaymentsUsedGrouped = result.safePerTypePayments;
      this.findValueBleeding(this.typePaymentsUsedGrouped);
      this.typePaymentsPerTypeMov = result.typePaymentsPerTypeMov;

      const calculatedTotalValues = {};

      this.typePaymentsUsedGrouped.forEach((payment) => {
        const { tipoMov, total } = payment;
        const multiplier = tipoMov === "entrada" ? 1 : -1;

        if (!calculatedTotalValues.total) {
          calculatedTotalValues.total = 0;
        }

        calculatedTotalValues.total += total * multiplier;
      });

      this.totalsPayments = calculatedTotalValues.total;

      const entriesAndExits = {};

      result.typePaymentsPerTypeMov.forEach((item) => {
        if (!entriesAndExits[item.tipoMov]) {
          entriesAndExits[item.tipoMov] = 0;
        }

        item.tipoMov === "entrada"
          ? (entriesAndExits[item.tipoMov] += item.total)
          : (entriesAndExits[item.tipoMov] -= item.total);
      });

      this.totalsEntriesAndExits = entriesAndExits;
    },

    async handleActiveAtm() {
      try {
        if (!this.dataConfig.id) {
          return await this.saveSettingsAtm();
        }

        return await this.updateAtm();
      } catch (error) {
        console.log(error);
      }
    },

    async saveSettingsAtm() {
      try {
        const newDataConfigAtm = JSON.parse(JSON.stringify(this.dataConfig));
        newDataConfigAtm.caixa_automatico.utilizar = true;

        const id = await ServiceSettings.save(newDataConfigAtm);
        newDataConfigAtm.id = id;

        this.SET_DATA_CONFIG(newDataConfigAtm);

        return this.$toast.open({
          message: "Caixa automatico ativado!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao salvar as configurações do caixa",
            type: "info",
          });
      }
    },

    async updateAtm() {
      try {
        const newDataConfigAtm = JSON.parse(JSON.stringify(this.dataConfig));
        newDataConfigAtm.caixa_automatico.utilizar = this.dataConfig
          .caixa_automatico.utilizar
          ? false
          : true;

        await ServiceSettings.updateAtm(newDataConfigAtm);
        this.SET_DATA_CONFIG(newDataConfigAtm);

        this.$toast.open({
          message: `Caixa automatico ${this.handleActivateOrDeactivate.situationAtm}!`,
          type: this.dataConfig.caixa_automatico.utilizar ? "success" : "info",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um erro ao atualizar as configurações do caixa",
            type: "info",
          });
      }
    },

    // handleTolerance() {
    //   if (
    //     this.dataConfig &&
    //     Object.keys(this.dataConfig).length &&
    //     this.dataConfig.caixa_automatico.horaAbertura
    //   ) {
    //     const currentHours = DateTime.now().toFormat("HH:mm");
    //     const openHours = DateTime.fromFormat(
    //       this.dataConfig.caixa_automatico.horaAbertura,
    //       "HH:mm"
    //     )
    //       .plus({ minutes: 35 })
    //       .toFormat("HH:mm");

    //     console.log(openHours, currentHours, openHours > currentHours);

    //     if (openHours > currentHours) {
    //       return true;
    //     }

    //     return false;
    //   }

    //   return false;
    // },

    findValueBleeding(data) {
      this.valueBleeding = data
        .filter((drawer) => drawer.tipo === "Dinheiro")
        .reduce((total, drawer) => {
          if (drawer.tipoMov === "saida") {
            return total - drawer.total;
          }

          return total + drawer.total;
        }, 0);
    },

    handleOpenModalAtm() {
      this.$bvModal.show("modalAtm");
    },

    calculateSubtotal(event) {
      this.withdraw = mascaraMoeda(event);
    },

    calculateTransfer(event) {
      this.valueTransfer = mascaraMoeda(event);
    },
    calculateLoose(event) {
      this.dataLoose.valor = mascaraMoeda(event);
    },

    handleAtmInitialValue(event) {
      const newDataConfigAtm = this.dataConfig;
      newDataConfigAtm.caixa_automatico.valorInicial = mascaraMoeda(event);

      this.SET_DATA_CONFIG(newDataConfigAtm);
    },

    calculateSuppliesSubtotal(event) {
      this.addSupplies = mascaraMoeda(event);
    },

    calculateAjustInitial(event) {
      this.valueAjustInitial = mascaraMoeda(event);
    },

    handleModalSafeOpening() {
      this.ALTER_VALUES_MODAL_DIALOG_GENERAL({
        nameModal: "modalDialogSafeOpening",
        title: "Caixa ainda não aberto!",
        subTitle:
          "Verificamos que você ainda não iniciou o caixa, para realizar qualquer lançamento, é necessario realizar a abertura do caixa!",
        footerTitle: "Deseja abrir o caixa agora ?",
        leftButtonText: "Não",
        rightButtonText: "Sim",
      });

      this.$nextTick(() => {
        this.openModal(this.dataModalDialogGeneral.nameModal);
      });
    },

    modalHeight() {
      this.SET_COLAPSE_CASHIER(!this.colapseCashier);

      this.colapseCashier
        ? this.ALTER_SIZE_MODAL_CASHIER("ModalGeneral")
        : this.ALTER_SIZE_MODAL_CASHIER("disable");

      this.modalContainerCashier
        ? this.ALTER_MODAL_CONTAINER_CASHIER("modalContainer")
        : this.ALTER_MODAL_CONTAINER_CASHIER("contentModalDisable");
    },

    handleModalCheckSafe() {
      this.findTypePaymentsUsed();
      this.modalHeight();
    },

    openModalCheckSafe() {
      this.closeModal("modal-center");
      this.openModal("modalCheckSafe");
    },

    nextPage() {
      this.filterSafe((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.filterSafe((this.page -= 1));
      }
    },
    async findRemainingValue() {
      const result = await SafeService.findRemainingValue();
      return result.remainingValue;
    },

    async confirmRemainingValue() {
      if (this.dataConfig.caixa_automatico.utilizar) {
        return this.$toast.open({
          message:
            "Caixa automatico ativado, não é possível abrir o caixa manualmente",
          type: "info",
        });
      }

      const remainingValue = await this.findRemainingValue();
      if (remainingValue) {
        this.SET_SAFE_REMAINING_VALUE(remainingValue);
        return this.openModal("modalRemainingValue");
      }

      this.showModalSafeOpening();
    },
    async listEmployeesSelectBox() {
      try {
        const { data } = await ServiceEmployees.getEmployeesForSelectBox();
        this.employeesFromSelectBox = data;
      } catch (error) {
        console.log(error.response);
      }
    },
    cancelOpenWithRemainingValue() {
      this.SET_SAFE_REMAINING_VALUE(null);
      this.closeModal("modalRemainingValue");
      this.showModalSafeOpening();
    },
    openWithRemainingValue() {
      this.closeModal("modalRemainingValue");
      this.showModalSafeOpening();
    },
    showModalSafeOpening() {
      this.openModal("ModalSafeOpening");
    },

    scrollLeft() {
      const carrosselDiv = this.$refs.carrosselItens;
      carrosselDiv.scrollLeft -= 300;
    },

    scrollRight() {
      const carrosselDiv = this.$refs.carrosselItens;
      carrosselDiv.scrollLeft += 300;
    },

    async getAllDay() {
      const data = await SafeService.findDate(
        this.formattedDate.toFormat("dd/MM/yyyy")
      );
      const movements = data?.data ?? [];
      this.entrySum = this.getTotalPerType(movements, "entrada");
      this.exitSum = this.getTotalPerType(movements, "saida");
      this.bleedingSum = this.getTotalPerStatus(movements, "Sangria");
      this.geralBleedingSum = this.getTotalPerStatus(
        movements,
        "Sangria Geral"
      );
    },

    getTotalPerType(movements, type) {
      return movements
        .filter(
          (movement) =>
            movement.tipo === type &&
            movement.status !== "Sangria" &&
            movement.status !== "Sangria Geral"
        )
        .reduce((total, movement) => total + movement.valor, 0);
    },

    getTotalPerStatus(movements, status) {
      return movements
        .filter((movement) => movement.status === status)
        .reduce((total, movement) => total + movement.valor, 0);
    },

    handleType(type) {
      if (type === "dia") {
        this.typeBalance = true;
      } else {
        this.typeBalance = false;
      }
    },
    clearValues() {
      this.typePaymentsUsedGrouped = [];
      this.typePaymentsPerTypeMov = [];
      this.totalsPayments = 0.0;
    },
    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
      this.startDate = "";
      this.endDate = "";
      this.account = "";
    },
    closeModal(idModal) {
      this.$bvModal.hide(idModal);
    },
    openModal(idModal) {
      this.$bvModal.show(idModal);
    },
    printAcquittance() {
      this.getIdAccount(this.dataUnique);
      printReport("acquittance", this.dataUnique);
    },
    async generateLoosePrint() {
      const token = localStorage.getItem("token");
      const response = await api.post(
        `${process.env.VUE_APP_URL_BACKEND}/safe/print/loose`,
        { dataLoose: this.dataLoose },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data) {
        const printWindow = window.open("", "_blank");

        printWindow.document.open();
        printWindow.document.write(response.data);
        printWindow.document.close();

        printWindow.focus();
      }
    },
    clearLoose() {
      this.dataLoose = {
        idCliente: "",
        referente: "",
        valor: "",
        observacao: "",
        data: moment().format("YYYY-MM-DD"),
      };
    },
    closereceiptLoose() {
      this.clearLoose();
      this.$bvModal.hide("modalreceiptLoose");
    },
    async fillCustomerComboBoxWithPaginate(id, q) {
      try {
        return await serviceCustomer.fillComboBoxWithPaginate(id, q);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },
    handleCreatedNewCustomer() {
      this.createdNewCustomer = !this.createdNewCustomer;
    },
    handleDataCustomers(event) {
      this.dataCustomers = [event];
    },
  },
  created() {
    this.startDate = this.formattedDate.toFormat("yyyy-MM-dd");
    this.endDate = this.formattedDate.toFormat("yyyy-MM-dd");
    this.filterSafe(1);
    this.sumPerAccount();
    this.getAllDay(this.formattedDate.toFormat("dd/MM/yyyy"));
  },
  mounted() {
    this.getAllPayment();
    this.getAllReceiptBill();
    this.sumPerAccount();
    this.listEmployeesSelectBox();
    this.getGeneralBalance();
    this.$nextTick(() => {
      this.existsSafeRegisterOpen();
    });
  },
  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      dataModalDialogGeneral: (state) => state.dataModalDialogGeneral,
      dataSafeIsOpen: (state) => state.dataSafeIsOpen,
      remainingValue: (state) => state.remainingValue,
      colapseCashier: (state) => state.colapseCashier,
      modalContainerCashier: (state) => state.modalContainerCashier,
      sizeModalCashier: (state) => state.sizeModalCashier,
      dataConfig: (state) => state.dataConfig,
    }),
    situationSafe() {
      if (this.dataSafeIsOpen?.safeIsOpen) {
        return "ABERTO";
      } else {
        return "FECHADO";
      }
    },
    formattedRemainingValue() {
      return this.maskCurrency(this.remainingValue);
    },
    calculateRemaining() {
      this.entrySum - this.exitSum - this.bleedingSum;
      if (this.typeBalance) {
        const total =
          parseFloat(this.entrySum || 0) -
          parseFloat(this.exit || 0) -
          parseFloat(this.bleedingSum || 0);
        return (total - this.withdraw).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      } else {
        return (this.generalBalance - this.withdraw).toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        });
      }
    },

    calculateSupplies() {
      return (
        this.valueBleeding +
        this.valueBoxInitial +
        this.addSupplies
      ).toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    handleActivateOrDeactivate() {
      if (this.dataConfig.caixa_automatico.utilizar)
        return {
          textBtn: "DESATIVAR!",
          situationAtm: "ATIVADO",
        };
      else
        return {
          textBtn: "ATIVAR!",
          situationAtm: "DESATIVADO",
        };
    },
    handleColorStatusAtm() {
      if (this.dataConfig.caixa_automatico.utilizar) return "atmActivated";
      else return "atmDeactivate";
    },
  },
};
</script>
<style scoped>
.General {
  display: flex;
  flex-direction: column;
  margin-left: 80px;
  width: calc(100% - 100px);
  font-size: 12px;
}

.searchFilter {
  width: 170px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.searchFilterSituation {
  width: 230px;
  border-left: none !important;
  height: 30px;
  border-radius: 0px !important;
  font-size: 11px;
  text-align: center;
  cursor: pointer;
  position: relative !important;
}

.searchFilterSituation:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.situationBall {
  width: 9px;
  height: 9px;
  border-radius: 50%;
  border: 1px solid rgb(31, 30, 30);
}

.situationContent {
  display: flex;
  align-items: center;
  width: 90px;
  justify-content: flex-start;
  font-weight: bold;
}

.situationContent span {
  margin: 0 0 0 10px;

  font-weight: 600;
}

.situationContainer {
  display: flex;
  width: 100%;
  height: 30px;
  justify-content: center;
  align-items: center;
}

.textEllipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/*  */
.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
}

.checkAccount {
  width: 200px;
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: rgba(245, 245, 245, 0.696);
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  text-transform: capitalize;
}
.checkAccount p {
  font-weight: 600;
}

.custom-select2 {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  -moz-appearance: none;
  appearance: none;
  background: none;
}

.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #eee;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0 30px;
  width: 100%;
  margin-bottom: 20px;
  font-size: 13px;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all 0.15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
  background-color: #00486e;
  color: white;
  font-weight: 600;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 11px;
  color: #333;
  transition: color 0.3s;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 14px;
  height: 14px;
  border: 2px solid #333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  transform-style: preserve-3d;
}

.custom-checkbox .checkmark::before {
  content: "\2713";
  font-size: 12px;
  color: transparent;
  transition: color 0.3s, transform 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #000000;
  border-color: #333;
  transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::before {
  color: #f0f0f0;
}

.custom-checkbox:hover {
  color: #666;
}

.custom-checkbox:hover .checkmark {
  border-color: #666;
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.custom-checkbox input[type="checkbox"]:focus + .checkmark {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.custom-checkbox .checkmark,
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  transition: background-color 1.3s, border-color 1.3s, color 1.3s,
    transform 0.3s;
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  width: 100px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
}

.searchInput {
  display: flex;
  justify-content: flex-end;
}

.searchAvanced {
  justify-content: center;
  color: #3a4750;
  font-size: 12px;
  height: 30px;
  gap: 10px;
  padding: 0px 20px;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAvanced:hover {
  background: rgb(205, 205, 205);
}

.searchInputTransfer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
}

.selectPlus {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectPlusTotal {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectEmployee {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.selectPlus1 {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 22px;
  left: 18px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
  font-weight: 600;
}

.styleLabel {
  color: green;
}

.styleLabel1 {
  color: #00486e;
}
/*  */
.ContainerSearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: linear-gradient(
    0deg,
    rgb(255 0 0 / 3%) 1%,
    rgb(255 255 255 / 99%) 19%
  );
  border-radius: 5px;
  padding-left: 2%;
}

.searchContainer {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 0 20px 5px 0px;
  border-bottom: 1px solid #e3e0e0;
}
.searchContent {
  display: flex;
  width: 80%;
  gap: 10px;
  justify-content: flex-end;
  align-items: center;
}

.searchContainer span {
  font-weight: bold;
  font-size: 14px;
  width: 20%;
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

.form-control {
  display: block;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  height: 30px;
}

.dateInput {
  display: flex;
  gap: 10px;
  font-size: 12px;
}

.dateRight {
  padding: 0.25rem 1.25rem 0.25rem 0.25rem;
  cursor: pointer;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
  gap: 5px;
  width: 100%;
}

.rowContainer span {
  cursor: context-menu;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.rowContentExtract {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.rowContentHoursOpenToClose {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 10px 0 5px 5px;
}

.contentFooterAtm {
  display: flex;
  width: 70%;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.extract {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  gap: 5px;
  width: 40%;
}

.extract p {
  margin-bottom: 0;
}

.btn-extract {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 20px;
  border: none;
  border-radius: 50%;
  color: #5c5855;
}

.borderInput {
  font-size: 11px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px !important;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050 !important;
}

.input-group1 {
  width: 100%;
  border-radius: 5px;
  position: relative;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 13px;
  font-weight: bold;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 9999;
}

.user-label1 {
  position: absolute;
  left: 0px;
  font-size: 13px;
  font-weight: bold;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 9999;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #e3e0e0;
}

input:focus {
  box-shadow: none !important;
  border: 1px solid #e3e0e0;
}

button:focus {
  box-shadow: none;
}

.Summary {
  display: flex;
  height: 30px;
  width: 100%;
  margin-top: 60px;
  justify-content: space-between;
  align-items: center;
  padding: 0px 30px 0 30px;
}

.ItemSummary {
  width: 100%;
  height: 60px;
  border-radius: 3px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  border: 1px solid #858585;
  box-shadow: 0px 13px 6px -10px rgba(161, 161, 161, 0.78);
}

.ItemSummary :hover {
  background: #f7f7f7;
}

.pagMonth {
  display: flex;
  align-items: center;
}

.Arrows {
  border-radius: 5px;
  width: 25px;
  padding: 2px;
  height: 20px;
  cursor: pointer;
}

.Arrows:hover {
  background: rgb(219, 219, 219);
}

.CashierBtn {
  display: flex;
  gap: 10px;
  width: 35%;
  height: 60px;
}

.OpenOrClose {
  width: 60%;
  height: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
  cursor: pointer;
  gap: 5px;
}

.OpenOrCloseItem {
  width: 186px;
  height: 45%;
  border-radius: 3px;
  border: 1px solid #858585;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
}

.OpenOrCloseItem span {
  width: 50%;
}

.cash {
  width: 50px;
  height: 50px;
}

.clock {
  width: 40px;
  height: 40px;
}

.closereceiptLoose {
  color: red;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  background: #dddddd;
  border: none;
}

.closereceiptLoose:hover {
  color: red;
  background: #cbcbcb;
  border: none;
}

.cashT {
  width: 40px;
  height: 40px;
}

.alertImg {
  width: 170px;
  height: 170px;
}

.transfer {
  width: 40px;
  height: 40px;
  rotate: 180deg;
}

.svgPadLockClose {
  width: 30px;
  height: 30px;
}

.generalBleeding {
  width: 240px;
  gap: 5px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
}

.BleedingBtn {
  width: 100%;
  height: 35%;
  border-radius: 3px;
  margin-top: 2px;
  border: 1px solid #858585;
  background: whitesmoke;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
}

.Bleeding {
  width: 100%;
  display: flex;
  gap: 5px;
  flex-direction: column;
  text-transform: capitalize;
}
.contentBtn {
  width: 45%;
  gap: 5px;
  height: 85px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  text-transform: capitalize;
}

.BleedingTitle {
  width: 240px;
  min-width: 240px;
  height: 35px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: rgba(245, 245, 245, 0.696);
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.BleedingChk {
  display: flex;
  width: 100%;
  flex-direction: column;
}

.titleStatement {
  font-size: 13px;
  font-weight: bold;
  padding: 15px 0 0 25px;
}

.carroselCtn {
  display: flex;
  gap: 10px;
}

.StatementOnAccount {
  display: flex;
  align-items: center;
  height: 70px;
  width: 100%;
  gap: 1px;
  overflow: scroll;
  overflow-x: scroll;
  overflow-y: hidden;
  transition: transform 0.5s ease-in-out;
}

.StatementOnAccountDefault {
  font-weight: bold;
  font-size: 14px;
  color: gray;
  width: 100%;
  height: 47px;
  display: flex;
  align-items: center;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: rgba(245, 245, 245, 0.696);
  justify-content: center;
}

.StatementOnAccountMoviment {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  width: 100%;
  gap: 1px;
}

.StatementOnAccount ::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

.StatementOnAccount::-webkit-scrollbar-track {
  background: white;
}

.StatementOnAccount::-webkit-scrollbar-thumb {
  background-color: transparent;
  border-radius: 2px;
}

.StatementOnAccountItem {
  width: 240px;
  min-width: 240px;
  height: 47px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  background: rgba(245, 245, 245, 0.696);
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  text-transform: capitalize;
}

.StatementOnAccountItem p {
  font-weight: 600;
}

.StatementOnAccountItem:hover {
  background: rgb(249, 249, 249);
  border-bottom: 3px solid #c2c5c7 !important;
}

.StatementOnAccountItem:focus {
  background: rgb(249, 249, 249);
  border-bottom: 3px solid #818485 !important;
}

.arrowCards {
  border: none;
  padding: 0;
}

.ContentTable {
  display: flex;
  flex-direction: column;
  height: 75vh;
  max-height: 100vh;
  overflow: auto;
  padding: 10px 20px 0 20px;
  width: 100%;
  max-width: 100%;
}

.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 29px;
  width: 100%;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-size: 12px;
  font-weight: normal;
}

td {
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.rowDetails {
  display: flex;
  font-size: 13px !important;
  justify-content: space-between;
}

.celDetails {
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 30%;
  font-size: 12px !important;
}

.celDetailsDescription {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  font-size: 12px !important;
}

.titlePropsDetails {
  font-weight: bold;
}

.descriptionPropsDetails {
  display: flex;
  height: auto;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: auto;
  white-space: pre-wrap;
}

.contentModal {
  font-size: 14px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
}

.contentNot {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.textNowValue {
  display: flex;
  gap: 1rem;
  cursor: context-menu;
}

.nowValue {
  color: rgb(0, 0, 86);
  font-weight: bold;
}

.remaining {
  display: flex;
  align-items: center;
  gap: 20px;
}

.remaining span {
  cursor: context-menu;
}

.remainingInput {
  width: 90px;
  font-weight: bold;
  color: rgb(1, 61, 1);
  border: none;
}

.titleModal {
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  gap: 2rem;
  border-bottom: 3px solid rgb(238, 238, 238);
}

.titleModalDetails {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 10px;
  gap: 2rem;
  border-bottom: 3px solid rgb(238, 238, 238);
}

.titleModalNome {
  display: flex;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 2px solid rgb(238, 238, 238);
  text-transform: uppercase;
  color: #606060;
}

.btnModal {
  width: 100%;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.btnContent {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 30vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

/* ----------INICIO DELETAR BTN-------------- */

.button {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #3a4750;
  color: whitesmoke;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.164);
  cursor: pointer;
  transition-duration: 0.3s;
  overflow: hidden;
  position: relative;
}

.button:hover {
  width: 70px;
  border-radius: 50px;
  transition-duration: 0.3s;
  background-color: rgb(255, 69, 69);
  align-items: center;
}

.button::before {
  position: absolute;
  top: -33px;
  content: "Deletar";
  color: white;
  transition-duration: 0.3s;
  font-size: 2px;
}

.button:hover::before {
  font-size: 10px;
  opacity: 1;
  transform: translateY(30px);
  transition-duration: 0.3s;
}

/* ----------FIM DELETAR BTN-------------- */

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

.ModalGeneral {
  position: fixed !important;
  z-index: 900;
  top: 53px;
  left: 61px;
  height: calc(100% - 53px);
  max-height: calc(100% - 53px);
  overflow: auto;
  width: calc(100% - 62px);
  background: #ffff;
  transition: all 0.7s ease-in-out;
}

.disable {
  position: absolute;
  width: calc(100% - 62px);
  height: 0;
  left: 61px;
  top: 100vh;
  background: #b0b0b000;
  visibility: hidden;
  transition: all 0.7s ease-in-out;
}

.closeModal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  right: 0;
  z-index: 900;
  font-size: 18px;
  height: 30px;
  background: white;
  border: none;
  color: rgb(6, 6, 6);
}

.closeModal:hover {
  background: whitesmoke;
  color: red;
}

.closeModal:focus {
  background: red;
  border: none;
  box-shadow: none;
}

.contentModalDisable {
  display: none;
  background: white;
  opacity: 0;
}

.modalContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnActions1:hover {
  background: rgb(215, 213, 213);
  color: white;
}

.btnActions1 {
  border-radius: 10px;
}

.btnActionsBox {
  color: white !important;
  background: transparent;
  width: 100%;
}

.contentActions {
  color: rgb(82, 78, 78);
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: none !important;
}

.atmActivated {
  background-color: #39c239;
  color: whitesmoke;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  cursor: default;
}

.atmDeactivate {
  background-color: #c23939;
  color: whitesmoke;
  padding: 5px;
  border-radius: 5px;
  font-size: 16px;
  cursor: default;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .General {
    margin-left: 60px;
    width: calc(100% - 60px);
    font-size: 11px;
  }
  .Summary {
    height: 80px;
    margin-top: 65px;
    padding: 0 5px 0 5px;
  }
  .ItemSummary {
    height: 50px;
  }
  .OpenOrClose {
    height: 50px;
  }
  .CashierBtn {
    display: flex;
    gap: 5px;
    width: 50%;
    height: 60px;
  }
  .generalBleeding {
    width: 50%;
  }
  .titleStatement {
    font-size: 11px;
    padding: 10px 0 0 0px;
  }
  .searchContainer {
    padding: 0px;
  }
  .searchContainer span {
    font-size: 11px;
    width: 30%;
  }
  .OpenOrCloseItem {
    width: 160px;
  }
  .BleedingTitle {
    height: 30%;
  }
  .BleedingBtn {
    width: 70%;
    height: 30%;
    margin-top: 5px;
    gap: 5px;
  }
  .carroselCtn {
    gap: 0px;
  }
  .StatementOnAccountItem {
    width: 24%;
    min-width: 24%;
    height: 35px;
    justify-content: center;
  }
  .StatementOnAccount {
    height: 46px;
  }
  .StatementOnAccountMoviment {
    height: 40px;
  }
  .searchContent {
    width: 70%;
  }
  .searchFilter {
    width: 100px;
  }
  .form-control {
    width: 110px;
    padding: 0.375rem 0.5rem;
    font-size: 11px;
    height: 30px;
  }
  .dateInput {
    gap: 2px;
    font-size: 11px;
  }
  .btnPeriod {
    width: 50px;
  }
  .dateRight {
    padding: 0.25rem;
  }
  .searchFilterSituation {
    width: 110px;
  }
  .ContentTable {
    padding: 5px 5px 0 5px;
  }
  .setBtn {
    margin: 0 1rem 10px 0;
  }
  .itemBtn {
    width: 18px;
    height: 18px;
  }
  .headTable {
    height: 25px;
  }
  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .General {
    display: flex;
    flex-direction: column;
    margin-left: 0px;
    width: 100% !important;
    height: 100vh;
    font-size: 11px;
  }

  .Summary {
    display: flex;
    height: auto;
    width: 100%;
    margin-top: 55px;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    padding: 0 5px 0 5px;
  }

  .CashierBtn {
    display: flex;
    gap: 10px;
    width: 100%;
    height: 60px;
  }

  .BleedingBtn {
    width: 90%;
  }
  .BleedingTitle {
    width: 90%;
  }

  .Bleeding {
    width: 50%;
    display: flex;
    gap: 5px;
    height: 85px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .contentBtn {
    width: 50%;
    gap: 0px;
    margin-top: -7px;
    height: 80px;
    align-items: flex-start;
    justify-content: flex-start !important;
  }

  .ItemSummary {
    width: 50%;
    height: 33px;
  }

  .generalBleeding {
    width: 100%;
    gap: 5px;
    height: 85px;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    text-transform: capitalize;
  }

  .StatementOnAccountItem {
    width: 100%;
    min-width: auto;
    height: 50px;
  }

  .titleStatement {
    font-size: 12px;
    padding: 5px 0 0 10px;
  }

  .searchContainer {
    height: auto;
    width: 100%;
    padding: 5px;
    flex-direction: column;
  }

  .searchContainer span {
    font-size: 12px;
    width: 100%;
  }

  .searchContent {
    display: flex;
    width: 100%;
    gap: 5px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .ContentTable {
    padding: 10px;
  }

  .searchInput {
    width: 100%;
    display: flex;
    justify-content: center;
  }
}
</style>