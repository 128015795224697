<template>
  <div>
    <b-modal
      size="lg"
      id="modal-confirmed-service"
      hide-header
      title=""
      @hidden="clearInformPayment"
    >
      <template #modal-footer>
        <div class="footerBtn">
          <b-button
            v-if="handleEnableBtnBack"
            class="footerBtnOk"
            style="width: 25%; !important"
            size="sm"
            @click="openModals('modal-new-service')"
          >
            Voltar</b-button
          >

          <b-button
            v-if="!informPayment"
            class="footerBtnOk"
            size="sm"
            @click="handleInformPayment"
          >
            <span> Informar pagamento </span>

            <b-spinner v-if="spinLaunchBtn" class="spinBtn" />
          </b-button>

          <b-button
            v-else
            class="footerBtnSolo"
            size="sm"
            @click="handleDeleteBills"
          >
            <span> {{ verifyBillsIsSave }} </span>

            <!-- <b-spinner class="spinBtn" /> -->
          </b-button>

          <b-button
            v-if="handleEnableBtnLaunch"
            class="footerBtnLaunchPayment"
            size="sm"
            @click="handleOpenModalBills"
          >
            <span>Gerar parcelas</span>

            <b-spinner v-if="spinLaunchBtn" class="spinBtn" />
          </b-button>

          <b-button class="footerBtnOk" v-b-modal.modal-reports size="sm">
            Imprimir / Emitir NFS-e
          </b-button>
        </div>
      </template>
      <div class="titleSketch">
        <span>RESUMO DA ORDEM DE SERVIÇO </span>
        <div
          @click="$bvModal.hide('modal-confirmed-service')"
          class="closeModal"
        >
          <b-icon-x scale="1.3"></b-icon-x>
        </div>
      </div>
      <div class="SummaryForm">
        <div
          class="containerForm"
          v-if="informPayment && !billsConfirmed.length"
        >
          <div class="generalForm">
            <div class="rowCustom">
              <div class="selectPlus">
                <label class="fixedSelect">Funcionário:</label>
                <b-form-select
                  :class="`borderInput shadow-none ${isInvalidInput}`"
                  size="sm"
                  :options="employees"
                  value-field="id"
                  text-field="nomeFuncionario"
                  v-model="dataBill.idFuncionario"
                  ref="idFuncionario"
                  @blur.native="clearInvalidInput"
                >
                </b-form-select>
              </div>

              <div class="selectPlus">
                <label class="fixedSelect">Forma de pagamento:</label>
                <b-form-select
                  value-field="id"
                  text-field="tipo"
                  :class="`borderInput shadow-none ${isInvalidInput}`"
                  size="sm"
                  :options="typeFormPayments"
                  v-model="dataBill.idFormaPagamento"
                  ref="idFormaPagamento"
                  @blur.native="clearInvalidInput"
                  @change="changeReceiptAccounts"
                >
                </b-form-select>
              </div>

              <div class="selectPlus" v-if="!isSimpleSafe">
                <label class="fixedSelect">Conta de Recebimento:</label>
                <InputSelect
                  :isDisabled="disabledReceitAccount"
                  :isInvalidInput="isInvalidInput"
                  valueField="id"
                  textField="nome"
                  :items="receiptAccounts"
                  :dataSelect.sync="dataBill.idContaRecebimento"
                  :openCreateModal="() => openModals('modalContaRecebimento')"
                  ref="idContaRecebimento"
                  @clearInvalidInput="clearInvalidInput"
                />
              </div>
            </div>

            <div class="rowCustom secondRow">
              <div class="selectPlus">
                <label class="fixedSelect">Venc. 1º parcela:</label>
                <Input
                  :isDisabled="disabledReceitAccount"
                  type="date"
                  ref="refVencimentoPrimeiraParcela"
                  :dataInput.sync="dataBill.data"
                  @clearInvalidInput="clearInvalidInput"
                />
              </div>
              <div class="selectPlus amountInstallments">
                <label class="fixedSelect">Parcelas:</label>
                <Input
                  :isDisabled="disabledReceitAccount"
                  type="number"
                  ref="totalParcelas"
                  :isInvalidInput="isInvalidInput"
                  :dataInput.sync="dataBill.totalParcelas"
                  @clearInvalidInput="clearInvalidInput"
                />
              </div>
              <div class="selectPlus">
                <label class="fixedSelect">Interval. de pagamento:</label>
                <Input
                  :isDisabled="disabledReceitAccount"
                  type="number"
                  ref="intervaloDias"
                  :isInvalidInput="isInvalidInput"
                  :dataInput.sync="dataBill.intervaloDias"
                  @clearInvalidInput="clearInvalidInput"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="generalContent mt-3" v-if="billsConfirmed.length">
          <div class="subTitle">
            <p>Parcelas</p>
          </div>

          <div
            style="
              background-color: whitesmoke;
              padding: 10px 5px;
              border-radius: 5px;
            "
          >
            <table style="text-align: center">
              <thead>
                <tr>
                  <th style="width: 60px"></th>
                  <th style="width: 150px">Data de vencimento</th>
                  <th>Vl. restante</th>
                  <th>VL pago</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(bill, index) in billsConfirmed" :key="index">
                  <td>{{ index + 1 }}º Venc.</td>
                  <td>
                    {{ bill.data.split("-").reverse().join("/") }}
                  </td>
                  <td>
                    {{
                      bill.dataPagamento
                        ? "R$ 0,00"
                        : maskCurrency(bill.valorTotal)
                    }}
                  </td>
                  <td>
                    {{
                      bill.dataPagamento
                        ? maskCurrency(bill.valorTotal)
                        : "R$ 0,00"
                    }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="contentTotals">
          <div class="subTitle">
            <p>Totais</p>
          </div>
          <div class="formPayment">
            <div style="display: flex; justify-content: space-between">
              <label class="totalsValues"> Subtotal (R$) </label>

              <b-form-input
                style="width: 105px"
                disabled
                ref="amount"
                class="styleInput shadow-none"
                :value="maskCurrency(totals.subtotal)"
              ></b-form-input>
            </div>

            <div style="display: flex; justify-content: space-between">
              <label class="totalsValues"> Desconto (R$)</label>

              <b-form-input
                style="width: 105px"
                disabled
                ref="amount"
                class="styleInput shadow-none"
                :value="maskCurrency(totals.desconto)"
              ></b-form-input>
            </div>

            <p class="divider"></p>

            <div style="display: flex; justify-content: space-between">
              <label class="totalsValues"> Total do Serviço (R$) </label>

              <b-form-input
                style="width: 105px"
                disabled
                ref="amount"
                class="styleInput shadow-none"
                :value="maskCurrency(totals.total)"
              ></b-form-input>
            </div>
          </div>
        </div>
      </div>
      <b-modal
        id="modal-reports"
        hide-footer
        hide-header
        centered
        title="Emitir"
      >
        <div>
          <h6 class="titleModal">Deseja Emitir ?</h6>
          <div class="btnModal">
            <button class="btnContentReceive" @click="printReportOrder">
              Imprimir Recibo
            </button>
            <button class="btnContent" @click="handleSendNfseOrPrint">
              {{ isPrint ? "Imprimir NFS-e" : "Emitir NFS-e" }}

              <b-spinner v-if="spinSendNfse" class="spinBtn" />
            </button>
          </div>
          <a class="rejectModal" @click="hideService" v-if="!spinLoading"
            >Não, Obrigado!</a
          >
        </div>
        <Loading v-if="spinLoading" class="spin" />
      </b-modal>

      <b-modal
        id="modal-new-service"
        hide-footer
        hide-header
        centered
        title="Emitir"
      >
        <div>
          <h6 class="titleModal">Deseja iniciar uma nova ordem de serviço ?</h6>
          <div class="btnModal">
            <button class="btnContent" @click="newOrderService">
              Nova ordem de serviço
            </button>
            <button class="btnContentReceive" @click="hideService">
              Não, Obrigado!
            </button>
          </div>
          <span class="importantWarning"
            >*** Ao iniciar uma nova ordem de serviço os dados preenchidos serão
            limpados!</span
          >
        </div>
      </b-modal>
    </b-modal>

    <ModalCreateAccountReceiptBill
      @getNewReceiptBill="getReceiptBillForSelectBox"
    />
    <ModalBills
      :amountBills="billsFromModal"
      :totalValue="returnTotalMonetary"
      :paymentSelected="paymentSelected"
      :receiptBillSelected="receiptBillSelected"
      @confirmedBills="launchBills($event)"
    />
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";

import Loading from "../Loading/Loading.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";
import ModalCreateAccountReceiptBill from "../Modals/ModalCreateAccountReceiptBill.vue";
import ModalBills from "../Sale/Modal-Bills.vue";

import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServicePaymentForm from "../../services/servicePaymentForm";
import ServiceBill from "../../services/serviceBill";
import ServiceNfse from "../../services/serviceNfse";

import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { TypeSituationNfse } from "../../utils/enums/TypeSituationNfse";
import {
  ValidateProvisionServiceBills,
  ValidateProvisionIsSimpleCashierBills,
} from "../../validators/service";
import printReport from "../../reports/sale";

export default {
  props: {
    employees: {
      type: Array,
    },
    totals: {
      type: Object,
    },
    idCliente: {
      type: String,
    },
    idNfse: {
      type: String,
    },
    billsSaved: {
      type: Array,
    },
    newOrder: {
      type: Boolean,
    },
    referenceNumber: {
      type: String,
    },
  },
  components: {
    Loading,
    InputSelect,
    Input,
    ModalCreateAccountReceiptBill,
    ModalBills,
  },
  data() {
    return {
      spinLoading: false,
      receiptAccounts: [],
      typeFormPayments: [],
      dataBill: {
        data: moment().format("YYYY-MM-DD"),
        idFuncionario: "",
        idContaRecebimento: "",
        idFormaPagamento: "",
        totalParcelas: 1,
        intervaloDias: 0,
      },
      isInvalidInput: "",
      spinLaunchBtn: false,
      spinSendNfse: false,
      informPayment: false,
      dueDateBill: moment().format("YYYY-MM-DD"),
      billsFromModal: [],
      billsConfirmed: [],
      paymentSelected: "",
      receiptBillSelected: "",
      disabledReceitAccount: false,
    };
  },
  methods: {
    ...mapMutations([
      "SET_COLAPSE_FISCAL",
      "ALTER_SIZE_MODAL",
      "ALTER_MODAL_CONTAINER_GENERAL",
      "SET_DEFAULT_TYPE_FISCAL",
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
      "SET_DATA_SEARCH_FROM_PROVISION_SERVICE",
    ]),

    async getReceiptBillForSelectBox() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.receiptAccounts = result;

      let accountPayment = this.receiptAccounts.find(
        (account) => account.nome === "CAIXA GAVETA"
      );
      if (this.isSimpleSafe) {
        this.dataBill.idContaRecebimento = accountPayment.id;
        this.receiptBillSelected = accountPayment.nome;
      }
    },

    async getPaymentForSelectBox() {
      const result = await ServicePaymentForm.getAllPayment();
      this.typeFormPayments = result.sort((a, b) => a.cod - b.cod);
    },

    async launchBills(bills) {
      try {
        this.billsConfirmed = bills;

        const formData = new FormData();
        formData.append("data", JSON.stringify(this.billsConfirmed));
        const result = await ServiceBill.save(formData);

        this.billsConfirmed = result?.bills;
        this.$emit("billsConfirmed", this.billsConfirmed);
        return this.$toast.open({
          message: "Lançamento realizado com sucesso!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }

        this.billsConfirmed = [];
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao lançar as parcelas",
          type: "info",
        });
      }
    },

    async sendNfse() {
      try {
        this.spinSendNfse = true;
        const nfse = await ServiceNfse.send(this.idNfse);

        if (nfse && Object.keys(nfse).length) {
          const newNfse = { ...nfse, response: JSON.parse(nfse.response) };
          this.SET_DATA_SEARCH_FROM_PROVISION_SERVICE(newNfse);

          this.print(newNfse.response.pdf_nfse);
          this.$toast.open({
            message: "NFS-e emitida com sucesso!",
            type: "success",
          });

          this.newOrderService();
        }
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao emitir a NFS-e",
          type: "info",
        });
      } finally {
        this.spinSendNfse = false;
      }
    },

    async handleDeleteBills() {
      if (this.billsConfirmed.length && this.billsConfirmed[0].id) {
        return await this.deleteBills();
      }

      this.handleInformPayment();
    },

    async deleteBills() {
      try {
        await ServiceBill.deleteByIdNfse(this.idNfse);
        this.billsConfirmed = [];
        this.$toast.open({
          message: "Parcelas excluidas com sucesso!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao deletar as parcelas",
          type: "info",
        });
      }
    },

    handleSendNfseOrPrint() {
      if (this.isPrint) {
        return this.print(this.dataSearchFromProvisionService.url_pdf);
      }

      return this.sendNfse();
    },

    print(url) {
      window.open(url, "_blank", "width=700, height=800, top=500, left=500");
    },

    printReportOrder() {
      printReport("reportOrder", this.idNfse);
    },

    handleModalSafeOpening() {
      this.ALTER_VALUES_MODAL_DIALOG_GENERAL({
        nameModal: "modalDialogSafeOpening",
        title: "Caixa ainda não aberto!",
        subTitle:
          "Verificamos que você ainda não iniciou o caixa, para realizar qualquer lançamento, é necessario realizar a abertura do caixa!",
        footerTitle: "Deseja abrir o caixa agora ?",
        leftButtonText: "Não",
        rightButtonText: "Sim",
      });

      this.$nextTick(() => {
        this.openModals(this.dataModalDialogGeneral.nameModal);
      });
    },

    closeModal(idModal) {
      this.$bvModal.hide(idModal);
    },

    newOrderService() {
      this.closeModal("modal-confirmed-service");
      this.closeModal("modal-reports");
      this.closeModal("modal-new-service");

      this.$emit("newOrderService");
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },

    handleInformPayment() {
      this.informPayment = !this.informPayment;
      this.cancelBills();
    },

    cancelBills() {
      if (this.billsConfirmed.length && !this.billsConfirmed[0].id) {
        this.billsConfirmed = [];
        this.informPayment = false;
      }

      this.clearDataBill();
    },

    makingThePayment() {
      this.billsFromModal = [];

      const valorPorDuplicata = (
        this.totals.total / this.dataBill.totalParcelas
      )
        .toFixed(2)
        .toLocaleString("pt-br", { minimumFractionDigits: 2 });

      for (let i = 0; i < this.dataBill.totalParcelas; i++) {
        const dueDate =
          i == 0
            ? this.dataBill.data
            : moment(dueDate)
                .add(this.dataBill.intervaloDias, "days")
                .format("YYYY-MM-DD");
        this.billsFromModal.push({
          id: "",
          tipo: "entrada",
          idCliente: this.idCliente,
          idNfse: this.idNfse,
          idFuncionario: this.dataBill.idFuncionario,
          idFormaPagamento: this.dataBill.idFormaPagamento,
          idContaRecebimento: this.dataBill.idContaRecebimento,
          valorTotal: valorPorDuplicata,
          valorPago: 0.0,
          valorRestante: valorPorDuplicata,
          data: dueDate,
          dataCompetencia: moment().format("YYYY-MM-DD"),
          dataPagamento: null,
          descricao: this.referenceNumber
            ? `Duplicata gerada a partir da O.S com o numero de referencia (${this.referenceNumber})`
            : "Duplicata gerada a partir de uma O.S sem identificação",
        });
      }
    },

    handleOpenModalBills() {
      let isValid;
      if (this.isSimpleSafe) {
        isValid = ValidateProvisionIsSimpleCashierBills(this.dataBill);
      } else {
        isValid = ValidateProvisionServiceBills(this.dataBill);
      }

      if (isValid) {
        this.handleFocus(isValid.keyError);
        return this.$toast.open({
          message: isValid.message,
          type: "info",
        });
      }

      this.handleGetTypePaymentAndReceiptBill();
      this.makingThePayment();
      this.openModals("modalBills");
    },

    handleGetTypePaymentAndReceiptBill() {
      if (this.dataBill.idFormaPagamento) {
        const typePayment = this.typeFormPayments.find(
          (payment) => payment.id === this.dataBill.idFormaPagamento
        );

        const receiptBill = this.receiptAccounts.find(
          (receiptBills) => receiptBills.id === this.dataBill.idContaRecebimento
        );

        this.paymentSelected = typePayment.tipo;
        if (!this.isSimpleSafe) {
          this.receiptBillSelected = receiptBill.nome;
        }
        if (this.isSimpleSafe) {
          let accountPayment = this.receiptAccounts.find(
            (account) => account.nome === "CAIXA GAVETA"
          );
          this.dataBill.idContaRecebimento = accountPayment.id;
          this.receiptBillSelected = accountPayment.nome;
        }
      }
    },

    handleFocus(refName) {
      if (refName === "idContaRecebimento") {
        this.$refs.idContaRecebimento.$children[0].focus();
      } else if (refName === "totalParcelas") {
        this.$refs.totalParcelas.$el.children[0].focus();
      } else if (refName === "intervaloDias") {
        this.$refs.intervaloDias.$el.children[0].focus();
      } else {
        this.$refs[refName].focus();
      }

      this.isInvalidInput = "isInvalidInput";
    },

    clearInvalidInput() {
      this.isInvalidInput = "";
    },

    clearDataBill() {
      this.dataBill = {
        data: moment().format("YYYY-MM-DD"),
        idFuncionario: "",
        idContaRecebimento: "",
        idFormaPagamento: "",
        totalParcelas: 1,
        intervaloDias: 0,
      };
    },

    hideService() {
      this.SET_COLAPSE_FISCAL(false);
      this.ALTER_SIZE_MODAL("disable");
      this.ALTER_MODAL_CONTAINER_GENERAL("contentModalDisable");
      this.SET_DEFAULT_TYPE_FISCAL();
      this.$bvModal.hide("modal-confirmed-service");
      this.$bvModal.hide("modal-new-service");
    },

    changeReceiptAccounts() {
      const drawerBox = this.receiptAccounts.find(
        (item) => item.nome === "CAIXA GAVETA"
      );
      const typePayment = this.typeFormPayments.find(
        (payment) => payment.tipo === "Dinheiro"
      );

      if (this.dataBill.idFormaPagamento === typePayment.id) {
        this.dataBill.idContaRecebimento = drawerBox.id;
        this.$refs.idContaRecebimento.valueItems = drawerBox.id;
        this.disabledReceitAccount = true;
      } else {
        this.disabledReceitAccount = false;
      }
    },

    clearInformPayment() {
      this.cancelBills();
    },
  },
  computed: {
    ...mapState([
      "dataConfig",
      "dataModalDialogGeneral",
      "dataSearchFromProvisionService",
    ]),
    verifyBillsIsSave() {
      if (this.billsConfirmed.length && this.billsConfirmed[0].id) {
        return "Excluir parcelas";
      }

      return "Cancelar parcelas";
    },

    handleEnableBtnLaunch() {
      return this.informPayment && !this.billsConfirmed[0]?.id ? true : false;
    },

    handleEnableBtnBack() {
      if (!this.informPayment) {
        return true;
      } else if (this.billsConfirmed[0]?.id) {
        return true;
      } else {
        return false;
      }
    },

    isPrint() {
      if (
        Object.keys(this.dataSearchFromProvisionService).length &&
        this.dataSearchFromProvisionService.status === "Faturado(a)" &&
        this.dataSearchFromProvisionService.response
      ) {
        return true;
      }

      return null;
    },

    handleStatusColor() {
      const colors = [
        {
          status: TypeSituationNfse.faturado,
          style: "background-color: rgb(34, 33, 36)",
        },
        {
          status: TypeSituationNfse.em_andamento,
          style: "background-color: green",
        },
        { status: TypeSituationNfse.cancelado, style: "background-color: red" },
        {
          status: TypeSituationNfse.finalizado,
          style: "background-color: yellow",
        },
        { status: TypeSituationNfse.pendente, style: "background-color: blue" },
      ];

      const result = colors.find(
        (item) => this.dataSearchFromProvisionService.status === item.status
      );

      if (result) return result;
      else if (this.idNfse) return colors[2];
      else return colors[3];
    },

    returnTotalMonetary() {
      return this.totals.total.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    isSimpleSafe() {
      return this.dataConfig?.caixa_simples ?? true;
    },
  },
  watch: {
    billsSaved() {
      if (this.billsSaved.length) {
        const newBillsSave = this.billsSaved.map((bill) => {
          return {
            ...bill,
            data: bill.data.split(" ")[0],
          };
        });

        this.billsConfirmed = newBillsSave;
        this.informPayment = true;
      }
    },
    newOrder() {
      this.billsConfirmed = [];
    },
  },
  mounted() {
    this.getReceiptBillForSelectBox();
    this.getPaymentForSelectBox();
  },
};
</script>

<style scoped>
.titleSketch {
  display: flex;
  flex-direction: row;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  border-bottom: 1px solid rgb(214, 214, 214);
  justify-content: space-between;
  align-items: center;
  padding-bottom: 5px;
  margin-right: 10px;
}

.titleSketch span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 30px;
  left: 0px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
  font-weight: 600;
}

.custom-select:disabled {
  color: black;
  background-color: #d5d3d3;
}

.custom-select {
  background: none;
  text-transform: capitalize;
}

.borderInput {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid #d5d3d3;
  cursor: pointer !important;
}

.borderInputSituation {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: none;
  width: 100px;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.situationContent {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  margin-right: 20px;
}

.situationBall {
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.footerBtn {
  display: flex;
  width: 100%;
  justify-content: space-around;
}

.footerBtnSolo {
  background: #d32613;
  width: 33%;
  border: none;
  font-size: 11px;
  height: 28px;
}

.footerBtnSolo:focus {
  background: #d32613;
  width: 33%;
  border: none;
  font-size: 11px;
  height: 28px;
  box-shadow: none;
}

.footerBtnSolo:hover {
  background: #a71e0f;
  width: 33%;
  border: none;
  font-size: 11px;
  height: 28px;
}

.footerBtnOk {
  position: relative;
  width: 33%;
  font-size: 11px;
  height: 28px;
  background: whitesmoke;
  border: 1px solid green;
  color: green;
  border-radius: 3px;
}

.footerBtnOk:focus {
  position: relative;
  width: 33%;
  font-size: 11px;
  height: 28px;
  background: whitesmoke;
  border: 1px solid green;
  color: green;
  border-radius: 3px;
  box-shadow: none;
}

.footerBtnLaunchPayment {
  position: relative;
  width: 33%;
  font-size: 11px;
  height: 28px;
  background: #48b865;
  border: 1px solid #48b865;
  color: white;
  border-radius: 3px;
}

.footerBtnLaunchPayment:focus {
  position: relative;
  width: 33%;
  font-size: 11px;
  height: 28px;
  background: #48b865;
  border: 1px solid #48b865;
  color: white;
  border-radius: 3px;
}

.footerBtnLaunchPayment:hover {
  position: relative;
  width: 33%;
  font-size: 11px;
  height: 28px;
  background: green;
  border: 1px solid #48b865;
  color: white;
  border-radius: 3px;
}

.SummaryForm {
  display: flex;
  flex-direction: column;
  color: rgb(85, 81, 81);
  padding: 0px 10px;
  gap: 25px;
  margin-top: 20px;
}

.containerForm {
  display: flex;
  color: rgb(85, 81, 81);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.559);
  border-radius: 5px;
  padding: 0.5rem 0 1rem 0;
}

.generalForm {
  display: flex;
  flex-direction: column;
  color: rgb(85, 81, 81);
  padding: 0px 10px;
  gap: 25px;
  margin-top: 20px;
  width: 100%;
}

.rowCustom {
  display: flex;
  gap: 1rem;
}

.contentTotals {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-left: 40%;
  font-size: 11px;
}

.formPayment {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 11px;
  background-color: whitesmoke;
  padding: 10px 10px;
  border-radius: 5px;
}

.input-group-text {
  background: rgb(85, 81, 81);
  color: #fff;
}

.form-group {
  margin-bottom: 0rem !important;
}

.styleInput {
  height: 25px;
  font-size: 11px;
  padding: 0 10px;
  text-align: end;
  border: 1px solid #d5d3d3;
}

.divider {
  border-bottom: 2px dotted #d5d3d3;
}

.spinnerStyled {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: red;
}

/*  Modal confirmação  */

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.btnContent {
  position: relative;
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  color: #ffffff;
  background: #00486e;
}

.btnContentReceive {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.rejectModal {
  cursor: pointer;
  color: black;
  display: flex;
  justify-content: center;
  font-size: 11px;
  font-style: oblique;
  text-decoration: underline;
}

.importantWarning {
  color: red;
  font-size: 10px;
  display: flex;
  justify-content: center;
}

.spin {
  width: 480px;
  height: 100%;
  top: 0;
}

.spinBtn {
  position: absolute;
  right: 5px;
  top: 4px;
  width: 20px;
  height: 20px;
}

/* switch */

.switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #cccaca;
  color: #504d4d;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 65px !important;
  height: 80%;
}

.slider:before {
  position: absolute;
  content: "Não";
  height: 100%;
  width: 45%;
  left: 5%;
  border-radius: 50%;
  background-color: rgb(66, 203, 66);
  color: whitesmoke;
  font-weight: bold;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #cccaca;
  color: #504d4d;
}

.switch input:checked + .slider:before {
  content: "Sim";
  background-color: rgb(66, 203, 66);
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.situation {
  cursor: context-menu;
  font-family: Poppins-Regular, sans-serif !important;
}

.launchPayment {
  height: 30px;
  position: relative;
  margin-top: -10px;
  margin-bottom: 5px !important;
  color: #00486e;
}

.launchPayment label {
  font-weight: 600;
}

.totalsValues {
  display: flex;
  align-items: center;

  margin: 0 !important;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}

.contentLoadingButton {
  position: relative;
}

.generalContent {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  width: 100%;
}

.generalContent p {
  font-size: 14px;
}

.subTitle p {
  font-size: 14px;
}

table {
  border-collapse: separate;
  border-spacing: 0 4px;
  width: 100%;
}

thead th {
  border-bottom: 1px solid #d5d3d3;
  color: #afadad;
  font-size: 12px;
  font-weight: normal;
}

td {
  color: black;
  font-size: 12px;
}

@media screen and (max-width: 992px) {
  .secondRow {
    flex-direction: column;
    gap: 25px;
  }
}
</style>