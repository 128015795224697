<!-- eslint-disable no-debugger -->
<template>
  <b-card>
    <div style="font-size: 12px">
      <div class="title">
        <span>Cadastro de Funcionário</span>
        <span class="requiredFields">* Campos Obrigatórios</span>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações
          Gerais</span
        >
        <div class="rowContent">
          <b-form-input
            hidden
            class="col-sm-1"
            v-model="dadosFuncionario.id"
          ></b-form-input>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.nomeFuncionario"
            />
            <label class="user-label">
              Nome Funcionário <span class="validRed">*</span></label
            >
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.cpf"
              v-mask="maskCpf"
            />
            <label class="user-label"> CPF</label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.rg"
            />
            <label class="user-label"> Registro Geral</label>
          </div>
          <div class="input-group">
            <input
              type="date"
              class="input borderInput"
              v-model="dadosFuncionario.dataNascimento"
            />
            <label class="user-label"> Data Nascimento </label>
          </div>
        </div>
      </div>
      <div class="rowContainer">
        <span> <b-icon-geo-alt-fill></b-icon-geo-alt-fill> Localização</span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.cep"
              v-mask="maskCep"
            />
            <label class="user-label"> CEP</label>
            <button class="bcsCep" @click="fetchCep(dadosFuncionario.cep)">
              <b-spinner
                v-if="spinGeneral"
                small
                label="Small Spinner"
              ></b-spinner>
              buscar
            </button>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.endereco"
            />
            <label class="user-label"> Endereço</label>
          </div>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.cidade"
            />
            <label class="user-label"> Cidade</label>
          </div>
        </div>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.bairro"
            />
            <label class="user-label"> Bairro</label>
          </div>
          <div class="selectPlus">
            <label class="fixedSelect2">UF</label>
            <b-form-select
              class="borderInput shadow-none"
              value-field="value"
              text-field="text"
              :options="ufs"
              v-model="dadosFuncionario.uf"
            >
            </b-form-select>
          </div>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.numero"
            />
            <label class="user-label"> Número</label>
          </div>
        </div>
      </div>
      <div class="rowContainer">
        <span> <b-icon-telephone-fill></b-icon-telephone-fill> Contatos</span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              type="email"
              class="input borderInput"
              v-model="dadosFuncionario.email"
            />
            <label class="user-label"> Email </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.telefone"
              v-mask="maskTelefone"
            />
            <label class="user-label"> Telefone </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.celular"
              v-mask="maskCelular"
            />
            <label class="user-label"> Celular </label>
          </div>
        </div>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-info-circle-fill></b-icon-info-circle-fill> Outras
          Informações</span
        >
        <div class="rowContent">
          <div class="input-group">
            <input
              type="date"
              class="input borderInput"
              v-model="dadosFuncionario.dataAdimissao"
            />
            <label class="user-label"> Data admissão </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.funcao"
            />
            <label class="user-label"> Função </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.carga"
            />
            <label class="user-label"> Carga Horária Semanal </label>
          </div>
          <div class="input-group">
            <InputMoney
              type="text"
              :dataInput.sync="dadosFuncionario.salario"
              ref="salario"
            />
            <label class="user-label-salario">Salário</label>
          </div>
        </div>

        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.ctps"
            />
            <label class="user-label"> CTPS </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.pis"
            />
            <label class="user-label"> PIS </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dadosFuncionario.comissao"
            />
            <label class="user-label"> Comissão </label>
            <button class="commisionBtn">
              <b-icon-percent />
            </button>
          </div>
          <div class="selectPlus">
            <label class="fixedSelect2">Status</label>
            <b-form-select
              class="borderInput shadow-none"
              value-field="value"
              text-field="text"
              :options="status"
              v-model="dadosFuncionario.status"
              :disabled="!this.dadosFuncionario.id"
            >
            </b-form-select>
          </div>
        </div>
        <div class="input-group w-100">
          <input
            required
            class="input borderInput"
            maxlength="500"
            v-model="dadosFuncionario.obs"
          />
          <label class="user-label"> Observações </label>
        </div>
        <div class="btnsContainer">
          <b-button class="btnsSaveNeutral" @click="clear"
            >Novo <b-icon-arrow-clockwise class="ml-1"></b-icon-arrow-clockwise
          ></b-button>
          <b-button class="btnsSave" @click="saveAndUpdateEmployee"
            >{{ dadosFuncionario.id ? "Atualizar" : "Salvar" }}
            <b-icon-check2-circle class="ml-1"></b-icon-check2-circle>
          </b-button>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { mapMutations, mapState } from "vuex";

import api from "../../services/axios";
import ServiceEmployees from "../../services/serviceEmployees";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import toastAlertErros from "../../utils/toastAlertErros";
import { HandleErrors } from "../../utils/handleErrors";
import { handleCpfOrCnpj } from "../../utils/validateCpfCnpj";
import { ufs } from "../../common/index";

export default {
  props: {
    dataEmployee: {
      type: Object,
    },
    idEmployeeFromSearch: {
      type: String,
      default: "",
    },
  },
  components: {
    InputMoney,
  },
  data() {
    return {
      dadosFuncionario: {
        id: "",
        nomeFuncionario: "",
        cpf: "",
        rg: "",
        comissao: "",
        endereco: "",
        numero: "",
        bairro: "",
        cidade: "",
        cep: "",
        uf: "",
        email: "",
        telefone: "",
        celular: "",
        ctps: "",
        funcao: "",
        pis: "",
        carga: "",
        dataAdimissao: "",
        dataNascimento: "",
        status: "Ativo",
        salario: 0,
        obs: '',
      },
      status: [
        { value: "Ativo", text: "Ativo" },
        { value: "Inativo", text: "Inativo" },
      ],
      employee: false,
      spinGeneral: false,
      ufs: ufs,
    };
  },
  methods: {
    ...mapMutations([
      "SET_IS_SEARCH_REGISTERS",
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_EMPLOYEE",
    ]),
    clear() {
      this.dadosFuncionario.id = "";
      this.dadosFuncionario.nomeFuncionario = "";
      this.dadosFuncionario.cpf = "";
      this.dadosFuncionario.rg = "";
      this.dadosFuncionario.comissao = "";
      this.dadosFuncionario.endereco = "";
      this.dadosFuncionario.numero = "";
      this.dadosFuncionario.bairro = "";
      this.dadosFuncionario.cidade = "";
      this.dadosFuncionario.cep = "";
      this.dadosFuncionario.uf = "";
      this.dadosFuncionario.email = "";
      this.dadosFuncionario.telefone = "";
      this.dadosFuncionario.celular = "";
      this.dadosFuncionario.ctps = "";
      this.dadosFuncionario.funcao = "";
      this.dadosFuncionario.pis = "";
      this.dadosFuncionario.carga = "";
      this.dadosFuncionario.dataAdimissao = "";
      this.dadosFuncionario.dataNascimento = "";
      this.dadosFuncionario.salario = "";
      this.dadosFuncionario.obs = "";
      this.$refs.salario.valueInput = "";

      this.SET_DATA_SEARCH_FROM_EMPLOYEE({});
    },

    saveAndUpdateEmployee() {
      this.dadosFuncionario.id !== ""
        ? this.updateEmployee()
        : this.saveEmployee();
    },

    async saveEmployee() {
      try {
        const result = handleCpfOrCnpj(this.dadosFuncionario.cpf, this.$toast);
        if (result && !result.isValid) {
          return result.alert();
        }

        const { data } = await api.post("/employees", this.dadosFuncionario);
        this.dadosFuncionario.id = data.id;

        this.$emit("getAllEmployee", this.employee);
        this.employee = !this.employee;
        this.SET_IS_SEARCH_REGISTERS();
        this.clear();
        return this.$toast.open({
          message: "Funcionário salvo com Sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateErroDoesNotContainFor(error, this.$toast);
      }
    },
    async updateEmployee() {
      try {
        const result = handleCpfOrCnpj(this.dadosFuncionario.cpf, this.$toast);
        if (result && !result.isValid) {
          return result.alert();
        }

        await api.put(
          `/employees/${this.dadosFuncionario.id}`,
          this.dadosFuncionario
        );

        this.$emit("getAllEmployee", this.employee);
        this.employee = !this.employee;
        this.SET_IS_SEARCH_REGISTERS();
        this.clear();
        return this.$toast.open({
          message: "Funcionário Atualizado com Sucesso",
          type: "success",
        });
      } catch (error) {
        console.log(error);
        const message = error?.response?.data?.message;

        const typeError = HandleErrors(message);
        if (typeError && Object.keys(typeError).length) {
          return this.$toast.open({
            message: typeError.message,
            type: "info",
          });
        }

        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateErroDoesNotContainFor(error, this.$toast);
      }
    },

    async findById(id) {
      const employee = ServiceEmployees.findById(id);
      return employee;
    },

    async handleModalCreateCustomer() {
      const employee = await this.findById(this.idEmployeeFromSearch);
      this.SET_DATA_SEARCH_FROM_EMPLOYEE(employee);
      this.assingEditEmployee();
    },

    assingEditEmployee() {
      this.dadosFuncionario.id = this.dataSearchEmployee.id;
      this.dadosFuncionario.nomeFuncionario =
        this.dataSearchEmployee.nomeFuncionario;
      this.dadosFuncionario.cpf = this.dataSearchEmployee.cpf;
      this.dadosFuncionario.rg = this.dataSearchEmployee.rg;
      this.dadosFuncionario.comissao = this.dataSearchEmployee.comissao;
      this.dadosFuncionario.endereco = this.dataSearchEmployee.endereco;
      this.dadosFuncionario.numero = this.dataSearchEmployee.numero;
      this.dadosFuncionario.bairro = this.dataSearchEmployee.bairro;
      this.dadosFuncionario.cidade = this.dataSearchEmployee.cidade;
      this.dadosFuncionario.cep = this.dataSearchEmployee.cep;
      this.dadosFuncionario.uf = this.dataSearchEmployee.uf;
      this.dadosFuncionario.email = this.dataSearchEmployee.email;
      this.dadosFuncionario.telefone = this.dataSearchEmployee.telefone;
      this.dadosFuncionario.celular = this.dataSearchEmployee.celular;
      this.dadosFuncionario.ctps = this.dataSearchEmployee.ctps;
      this.dadosFuncionario.funcao = this.dataSearchEmployee.funcao;
      this.dadosFuncionario.pis = this.dataSearchEmployee.pis;
      this.dadosFuncionario.carga = this.dataSearchEmployee.carga;
      this.dadosFuncionario.status = this.dataSearchEmployee.status;
      this.dadosFuncionario.dataAdimissao = this.dataSearchEmployee.dataAdimissao;
      this.dadosFuncionario.dataNascimento = this.dataSearchEmployee.dataNascimento;
      this.dadosFuncionario.salario = this.dataSearchEmployee.salario;
      this.dadosFuncionario.obs = this.dataSearchEmployee.obs;

      this.$refs.salario.valueInput = this.dataSearchEmployee.salario;
    },
    async fetchCep(cep) {
      try {
        this.spinGeneral = true;
        const formatedCep = cep.replace(/\D/g, "");
        const data = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${formatedCep}`
        );
        this.dadosFuncionario.endereco = data.data.street;
        this.dadosFuncionario.bairro = data.data.neighborhood;
        this.dadosFuncionario.cidade = data.data.city;
        this.dadosFuncionario.uf = data.data.state;
        this.dadosFuncionario.cep = data.data.cep;
      } catch (error) {
        this.$toast.open({
          message:
            "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },
  },
  watch: {
    dataEmployee() {
      Object.assign(this.dadosFuncionario, this.dataEmployee);
    },
  },

  mounted() {
    if (this.dataSearchEmployee.id) {
      this.assingEditEmployee();
    } else if (this.idEmployeeFromSearch) {
      this.handleModalCreateCustomer();
    }
  },
  computed: {
    ...mapState({
      dataSearchEmployee: (state) => state.dataSearchEmployee,
    }),
    maskCpf() {
      return "###.###.###-##";
    },

    maskCelular() {
      return "(##) #####-####";
    },
    maskTelefone() {
      return "(##) ####-####";
    },
    maskCep() {
      return "#####-###";
    },
  },
  beforeDestroy() {
    this.clear();
  },
};
</script>
<style scoped>
.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}

span {
  font-weight: bold;
  cursor: context-menu;
}
.input:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTotals input:hover {
  background: #d9d9d9 !important;
  border: 1px solid #262626;
}
.custom-select:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.textarea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTextArea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.user-label-salario{
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 25px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 8.9px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
  font-weight: 600;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.validRed {
  font-size: 13px;
  color: red;
}

.bcsCep {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.commisionBtn {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  padding: 10px;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  display: flex;
  margin-bottom: 20px;
  margin-right: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
  justify-content: space-between;
}

.title span {
  font-weight: 600;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.rowContainer span {
  font-weight: 600;
}

.requiredFields {
  font-size: 11px;
  color: red;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}

.btnsContainer {
  gap: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;

  font-weight: 600;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #d7d7d7;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-size: 12px;

  font-weight: 600;
}

.input-group {
  width: 30%;
}

.input-group-commission {
  width: 15%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 1rem 0 1rem;
}

.card {
  border: none !important;
}

@media screen and (max-width: 700px) {
  .rowContent {
    display: flex;
    width: 100%;
    padding: 5px 0 5px 0;
    justify-content: space-between;
    gap: 10px;
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .input-group-ob {
    width: 50%;
  }

  .selectPlus {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
  }

  .refFiscalMobile {
    width: 50% !important;
  }

  .btn {
    padding: 0.375rem 0.5rem;
  }
}
</style>
