<template>
  <div class="dashboard">
    <div class="titleComponent">
      <span>Estoque Geral</span>
    </div>
    <div class="container">
      <div class="subtitle" style="color: #403b74">
        <b-icon-search></b-icon-search>
        <span>Pesquisar por:</span>
      </div>
      <div class="searchContainer">
        <div class="searchInput">
          <b-form-select
            class="searchFilter"
            size="sm"
            value-field="value"
            text-field="text"
            v-model="type"
            :options="filterType"
          ></b-form-select>
          <div>
            <b-form-input
              class="inputSearch"
              placeholder="Pesquisar por..."
              v-model="textPesquisa"
              @input="filterProducts"
            ></b-form-input>
          </div>
        </div>
        <div class="selectPlus">
          <label class="fixedSelect"
            >Exibir resultados
            <span style="color: rgb(115, 0, 0)">*</span></label
          >
          <b-form-select
            class="borderInput shadow-none"
            :options="filterCombobox"
            v-model="limit"
            size="sm"
          >
          </b-form-select>
        </div>
        <b-button @click="getProducts(1)" size="sm" class="searchBtn">
          Pesquisar
          <b-icon-search></b-icon-search>
        </b-button>
      </div>
      <div class="subtitleExibition" style="color: #403b74">
        <div>
          <b-icon-box-seam class="mr-2"></b-icon-box-seam>
          <span>Produtos Cadastrados</span>
        </div>
        <span>Exibindo {{ this.exibition }} Produtos</span>
      </div>
      <div class="productsContainer">
        <div class="tableProducts">
          <div class="headProducts">
            <p class="cellMobileCOd">Cód. Referencia</p>
            <p class="cellMobile" style="width: 35%">Nome</p>
            <p class="cellMobileUnd" style="width: 10%">Und.</p>
            <p class="productCost">Vl. Custo</p>
            <p class="productCost">Vl. Venda</p>
            <p class="productCost">Estoque Atual</p>
            <p class="stockAdj">Ajuste No Estoque</p>
          </div>
          <div
            class="rowProducts"
            v-for="(item, index) in productsListGrid"
            :key="item.uuid"
          >
            <p v-b-tooltip.hover.top="item.codReferencia" class="cellMobileCOd">
              {{ item.codReferencia || "--" }}
            </p>
            <p
              v-b-tooltip.hover.top="item.nome"
              class="cellMobile"
              style="width: 35%"
            >
              {{ item.nome }}
            </p>
            <p class="cellMobileUnd" style="width: 10%">
              {{ item.unidade || "-" }}
            </p>
            <p class="value">
              {{
                item.valor.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </p>
            <p class="value">
              {{
                item.valorVenda.toLocaleString("pt-br", {
                  style: "currency",
                  currency: "BRL",
                })
              }}
            </p>
            <p style="width: 10%">{{ item.estoque }}</p>
            <div class="input-group inputNewStock">
              <div>
                <b-form-input
                  required
                  class="input borderInput"
                  v-model="newStock[index]"
                />
                <label class="user-label"> Novo Estoque </label>
              </div>
            </div>
          </div>
          <div class="btnProducts">
            <b-button
              class="BtnSales"
              style="background: #48b865"
              @click="adjustStock()"
            >
              Ajustar Estoque</b-button
            >
          </div>
        </div>
        <div class="setBtn">
          <button
            class="itemBtn"
            :disabled="page == 1"
            @click="previousPage(page)"
          >
            <b-icon-chevron-left></b-icon-chevron-left>
          </button>
          <button class="centerItemBtn">{{ page }}</button>
          <button
            class="itemBtn"
            :disabled="this.exibition < this.limit"
            @click="nextPage(page)"
          >
            <b-icon-chevron-right></b-icon-chevron-right>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import rocket from "../../assets/rocketi.png";
import api from "../../services/axios";
import serviceProductLogs from "../../services/serviceProductLogs";
import ServiceProducts from "../../services/serviceProducts";

export default {
  data() {
    return {
      rocket: rocket,
      type: "",
      startDate: "",
      endDate: "",
      textPesquisa: "",
      exibition: "",
      newStock: [],
      productsListGrid: [],
      limit: 25,
      page: 1,
      filterCombobox: [
        { value: 25, text: "25 produtos" },
        { value: 50, text: "50 produtos" },
        { value: 100, text: "100 produtos" },
        { value: 150, text: "150 produtos" },
      ],
      filterType: [
        { value: "", text: "Selecione" },
        { value: "nome", text: "Nome" },
        { value: "codBarras", text: "Código de Barras" },
        { value: "codReferencia", text: "Código de Referência" },
        { value: "unidade", text: "Unidade" },
      ],
      dataLogsProducts: {
        idProduto: "",
        descricao: "",
        dados: {
          from: "",
          to: "",
        },
      },
    };
  },
  methods: {
    async getProducts(page) {
      const { data } = await api.get(
        `/products/filter/and/all/products/and/ajust/stock?type=${
          this.type
        }&page=${page}&q=${encodeURIComponent(this.textPesquisa)}&limit=${
          this.limit
        }`
      );
      this.productsListGrid = data.data;
      this.exibition = data.data.length;
      this.clearSearch();
    },

    async adjustStock() {
      for (let i = 0; i < this.productsListGrid.length; i++) {
        const item = this.productsListGrid[i];
        let newStockValue = this.newStock[i];

        if (newStockValue !== undefined) {
          if (typeof newStockValue === "string") {
            newStockValue = newStockValue.replace(",", ".");
          }

          newStockValue = parseFloat(newStockValue);

          if (!isNaN(newStockValue)) {
            await this.assignStock(item, newStockValue);
          } else {
            console.error(
              `O valor de estoque fornecido (${newStockValue}) não é válido.`
            );
          }
        }
      }
      this.newStock = [];
    },
    async assignStock(item, newStockValue) {
      this.dataLogsProducts.dados.from = item.estoque;
      this.dataLogsProducts.dados.to = parseFloat(newStockValue);
      this.dataLogsProducts.idProduto = item.id;
      item.estoque = parseFloat(newStockValue);

      const defaultValues = [
        "custoInicial",
        "frete",
        "encargos",
        "lucro",
        "margem1",
        "margem2",
        "margem3",
      ];

      defaultValues.forEach((prop) => {
        item[prop] = item[prop] ?? 0;
      });

      try {
        await ServiceProducts.updateProducts(item);
        await this.assignLogs();

        return this.$toast.open({
          message: "Produto Atualizado com Sucesso",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message: "Houve um erro ao atualizar o produto",
          type: "info",
        });
      }
    },

    async assignLogs() {
      this.dataLogsProducts.descricao = "movManualGeral";
      await serviceProductLogs.save(this.dataLogsProducts);
    },

    async filterProducts() {
      if (this.textPesquisa && this.type === "") {
        const filteredData = this.productsListGrid.filter((item) => {
          return item.nome
            .toLowerCase()
            .includes(this.textPesquisa.toLowerCase());
        });

        this.productsListGrid = filteredData;
        this.exibition = filteredData.length;
      }
      if (this.textPesquisa === "" && this.type === "") {
        this.getProducts(1);
      }
    },

    clearSearch() {
      this.type = "";
      this.startDate = "";
      this.endDate = "";
      this.textPesquisa = "";
    },

    nextPage() {
      this.getProducts((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.getProducts((this.page -= 1));
      }
    },
  },
  mounted() {
    this.getProducts(1);
  },
};
</script>
<style scoped>
.dashboard {
  width: 85%;
  margin-top: 60px;
}

.titleComponent {
  font-weight: bold;
  font-size: 1.2rem;
  color: rgb(75, 75, 75);
  width: 100%;
  display: flex;
  justify-content: center;
}

.titleComponent span {
  cursor: context-menu;
}

.subtitle {
  display: flex;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  gap: 10px;
  margin-bottom: -15px;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.subtitleExibition {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  font-weight: bold;
  width: 100%;
  gap: 10px;
  margin-bottom: -15px;
  border-bottom: 1px solid rgb(206, 206, 206);
}

.container {
  display: flex;
  justify-content: flex-start;
  height: auto;
  flex-direction: column;
  font-size: 13px;
  gap: 20px;
}

.searchContainer {
  display: flex;
  border: 1px solid rgb(206, 206, 206);
  background: #ebebeb;
  border-radius: 2px;
  padding: 20px;
  justify-content: center;
}

.productsContainer {
  display: flex;
  border: 1px solid rgb(206, 206, 206);
  background: #ebebeb;
  border-radius: 2px;
  padding: 20px 20px 50px 20px;
  z-index: 20;
  margin-bottom: 50px;
  position: relative;
}

.stockAdj {
  width: 20%;
  max-width: 20%;
}

.value {
  width: 10%;
  max-width: 10%;
}

.productCost {
  width: 10%;
  max-width: 10%;
}

.searchInput {
  display: flex;
  width: 50%;
  justify-content: flex-start;
}

.form-control {
  display: block;
  width: 230px;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 12px;
  height: 30px;
}

input {
  border: 1px solid #e3e0e0;
  border-radius: 5px;
  height: 30px;
  font-size: 13px;
}

.cellMobileCOd {
  min-width: 10% !important;
  width: 10% !important;
  max-width: 10% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cellMobile {
  min-width: 35% !important;
  max-width: 35% !important;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

input:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.searchBtn {
  display: flex;
  height: 30px;
  background: whitesmoke;
  color: black;
  border-radius: 5px;
  border-color: #e3e0e0;
  font-size: 12px;
  padding: 0 20px;
  gap: 10px;
  align-items: center;
}

.btnPeriod {
  border-radius: 5px 0px 0px 5px;
  border: 1px solid #e3e0e0;
  width: 230px;
  height: 30px;
  font-size: 11px;
  cursor: context-menu;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.dateInput {
  display: flex;
  gap: 10px;
  font-size: 13px;
}

.searchFilter {
  width: 200px;
  border-right: none;
  height: 30px;
  font-size: 12px;
  text-align: center;
  cursor: pointer;
  font-family: Poppins-Regular, sans-serif !important;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid #e3e0e0;
}

.inputSearch {
  position: relative;
  left: -5px;
  border: 1px solid #ced4da;
  border-left: none;
}

.user-label {
  position: absolute;
  left: 50px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  width: 95%;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.inputNewStock {
  width: 200px;
}

.input-group {
  display: flex;
  justify-content: center;
}

.selectPlus {
  position: relative;
  width: 25%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  font-size: 10px;
  bottom: 15px;
  left: 10px;
  background-color: #ffffff;
  padding: 0 0.4em;
}

.tableProducts {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  padding: 10px 20px;
  width: 100%;
  font-size: 13px;
  text-align: center;
  background: #fffcfc;
}

.headProducts {
  display: flex;
  border-bottom: 2px solid #d5d3d3;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  padding-bottom: 10px;
}
.headProducts p {
  text-align: center;
  margin-bottom: 0rem !important;
}

.btnProducts {
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
}

.BtnSales {
  width: 20%;
  border: none;
  height: 28px;
  border-radius: 3px;
  font-size: 12px;
  box-shadow: 0px 12px 4px -10px rgba(142, 142, 142, 0.78);
}

.rowProducts p {
  margin-bottom: 0rem !important;
}

.rowProducts {
  display: flex;
  gap: 5px;
  height: 40px;
  border-bottom: 1px solid #f1efef;
  width: 100%;
  align-items: center;
  text-align: center;
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .dashboard {
    width: 90%;
    margin-left: 30px;
  }
  .container {
    gap: 14px;
  }
  .titleComponent {
    font-size: 1rem;
  }
  .searchContainer {
    padding: 10px;
    justify-content: space-evenly;
  }
  .subtitle {
    font-size: 11px;
  }
  .searchBtn {
    font-size: 11px;
    padding: 0 10px;
    gap: 10px;
  }
  .searchFilter {
    width: 110px;
    font-size: 11px;
  }
  .form-control {
    width: 60px;
    font-size: 11px;
  }
  .subtitleExibition {
    font-size: 11px;
    gap: 0px;
  }
  .productsContainer {
    padding: 10px 5px 35px 5px;
    margin-bottom: 30px;
  }
  .tableProducts {
    padding: 5px;
    font-size: 11px;
  }
  .headProducts {
    font-size: 11px;
    padding-bottom: 0px;
  }
  .rowProducts {
    display: flex;
    gap: 5px;
    align-items: left;
    justify-content: flex-start;
  }
  .user-label {
    left: 0px;
    font-size: 9px;
    bottom: 7px;
  }
  .inputNewStock {
    width: 60px;
  }
  .inputSearch {
    width: 100% !important;
  }
  .btnProducts {
    margin-top: 20px;
  }
  .BtnSales {
    width: 19%;
    height: 28px;
    font-size: 11px;
  }
  .setBtn {
    margin: 0 10px 10px 0;
  }
  .itemBtn {
    width: 18px;
    height: 18px;
  }
  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .dashboard {
    width: 100%;
  }

  .subtitle {
    margin-bottom: 0px;
  }

  .productsContainer {
    padding: 5px 5px 50px 5px;
    margin-bottom: 0px;
  }

  .BtnSales {
    width: 40%;
    height: 25px;
    font-size: 11px;
  }

  .searchContainer {
    padding: 10px 5px;
    gap: 20px;
    flex-direction: column;
    align-items: center;
  }

  .searchInput {
    width: 100%;
    justify-content: center;
  }

  .selectPlus {
    width: 100%;
    gap: 0px;
    align-items: center;
    justify-content: center;
  }

  .searchBtn {
    height: 30px;
    width: 100%;
    font-size: 12px;
    padding: 0 20px;
    gap: 10px;
    margin-top: -10px;
    align-items: center;
    justify-content: center;
  }

  .tableProducts {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    padding: 0px;
    width: 100%;
    font-size: 11px;
  }

  .headProducts {
    font-size: 11px;
    padding-bottom: 5px;
  }

  .inputNewStock {
    width: 30px;
  }

  .user-label {
    display: none;
  }

  .cellMobile {
    width: 35% !important;
  }

  .cellMobileUnd {
    width: 5% !important;
  }

  .rowProducts {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
  }

  .setBtn {
    margin: 0 10px 10px 0;
    gap: 4px;
    bottom: 0;
    right: 0px;
  }

  .itemBtn {
    width: 20px;
    height: 20px;
  }
  .centerItemBtn {
    width: 20px;
    height: 20px;
  }

  .rowProducts {
    display: flex;
    gap: 5px;
    align-items: left;
    justify-content: flex-start;
  }

  .headProducts {
    display: flex;
    font-size: 9px;
    text-align: left;
    padding-bottom: 10px;
    justify-content: space-evenly;
  }

  .cellMobile {
    text-align: center;
    font-size: 9px;
  }

  .productCost {
    width: 10%;
    max-width: 10%;
    display: flex;
  }

  .value {
    width: 14%;
    max-width: 14%;
  }

  .stockAdj {
    width: 10%;
    max-width: 10%;
  }
}

@media screen and (max-width: 450px){
  .searchFilter {
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: flex-start;
    width: 100%;
    margin-right: 10px;
  } 
}


</style>