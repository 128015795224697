<template>
  <div>
    <div class="ContainerSearchInput">
      <div class="textRegister">
        <span>Fornecedores Registrados</span>
      </div>
      <div class="searchInput" style="height: 100%; align-items: flex-end">
        <b-form-select
          v-if="showFilterTypes"
          id="filterType"
          class="searchFilterTypes"
          size="sm"
          value-field="value"
          text-field="text"
          v-model="filterType"
          :options="filterTypes"
        ></b-form-select>

        <b-form-select
          class="searchFilter"
          :style="
            showFilterTypes && `border-left: none; border-radius: 0 0 0 0`
          "
          size="sm"
          value-field="value"
          text-field="text"
          v-model="type"
          :options="filterCombobox"
          @change="handleShowFilterTypes"
        ></b-form-select>

        <b-input
          class="btnSearch"
          size="sm"
          placeholder="Pesquisar"
          v-model="textPesquisa"
          @keyup.enter="readSupplier(page)"
        ></b-input>

        <b-button size="sm" @click="readSupplier(page)" class="searchBtn">
          <b-icon-search></b-icon-search>
        </b-button>
        <div class="searchInput" style="justify-content: flex-end">
          <button class="searchAll" @click="readSupplier(page)">
            ver tudo
          </button>
        </div>
      </div>
    </div>
    <div class="ContentTable">
      <table style="width: 100%">
        <thead class="headTable">
          <tr style="width: 100%">
            <th>Fornecedor</th>
            <th>CPF/CNPJ</th>
            <th>Contato</th>
            <th>Chave Pix</th>
            <th>Cidade</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody
          v-if="this.dataLengthSupplier > 0"
          style="color: #3a4750; width: 100%"
        >
          <tr
            v-for="supplier in readSuppliers"
            class="trTable"
            :key="supplier.id"
          >
            <td>{{ supplier.nomeFantasia }}</td>
            <td>{{ supplier.cpfCnpj || "--" }}</td>
            <td class="wtsTd" @click="redirectWhatsApp(supplier.celular)">
              {{ supplier.celular || "--" }}
              <div v-if="supplier.celular">
                <img class="whatsapp" :src="whatsapp" />
              </div>
            </td>
            <td>{{ supplier.chavePix || "--" }}</td>
            <td>{{ supplier.cidade || "--" }} {{ supplier.uf }}</td>
            <td>
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  text="Ações"
                  menu-class="w-1"
                  class="btnActions"
                  size="sm"
                  variant="none"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item @click="findById(supplier)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item @click="destroySupplier(supplier.id)">
                    Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="6">
            <div class="defaultSearch">
              <span>Não há dados para exibir ...</span>
            </div>
          </td>
        </tbody>
        <div v-if="spinGeneral" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>
    <div class="setBtn">
      <button @click="previousPage" :disabled="page === 1" class="itemBtn">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button
        @click="nextPage"
        :disabled="this.dataLengthSupplier < 9"
        class="itemBtn"
      >
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>
  </div>
</template>

<script>
import api from "../../services/axios";
import toastAlertErros from "../../utils/toastAlertErros";
import { mapMutations, mapState } from "vuex";
import serviceSupplier from "../../services/serviceSupplier";
import whatsapp from "../../assets/whatsapp.svg";

export default {
  data() {
    return {
      whatsapp: whatsapp,
      readSuppliers: [],
      textPesquisa: "",
      type: "",
      startDate: "",
      endDate: "",
      tabIndex: 0,
      page: 1,
      dataLengthSupplier: 0,
      spinGeneral: false,
      filterCombobox: [
        { value: "nome", text: "Nome" },
        { value: "cpfCnpj", text: "CPF / CNPJ" },
        { value: "celular", text: "Contato" },
        { value: "cidade", text: "Cidade" },
        { value: "endereco", text: "Endereco" },
        { value: "", text: "Todos" },
      ],
      filterTypes: [
        { value: "includes", text: "Contém" },
        { value: "start", text: "Inicia por" },
      ],
      filterType: "includes",
      showFilterTypes: false,
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL_REGISTER",
      "ALTER_TYPE_REGISTER",
      "SET_COLAPSE_REGISTER",
      "ALTER_MODAL_CONTAINER_REGISTER",
      "SET_DATA_SEARCH_FROM_SUPPLIER",
    ]),
    async readSupplier(page) {
      try {
        this.spinGeneral = true;
        const {
          data: { data },
        } = await api.get(
          `/providers?q=${encodeURIComponent(this.textPesquisa)}&type=${
            this.type
          }&page=${page}&filterType=${this.filterType}`
        );
        this.dataLengthSupplier = data.length;
        this.readSuppliers = data;
      } catch (error) {
        return this.$toast.open({
          message: "Não foi possível listar os fornecedores",
          type: "warning",
        });
      } finally {
        this.spinGeneral = false;
      }
      if (this.dataLengthSupplier < 8) {
        this.clearSearch();
      }
    },

    clearSearch() {
      this.textPesquisa = "";
      this.type = "";
    },

    async destroySupplier(idSupplier) {
      try {
        await api.delete(`/Providers/${idSupplier}`);
        this.readSupplier(this.page);
        return this.$toast.open({
          message: "Fornecedor deletado com sucesso",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async findById(supplier) {
      try {
        const result = await serviceSupplier.findById(supplier.id);
        this.openModalToReceive(result);
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else
          this.$toast.open({
            message: "Ocorreu um eror ao buscar o fornecedor!",
            type: "info",
          });
      }
    },

    openModalToReceive(supplier) {
      this.SET_DATA_SEARCH_FROM_SUPPLIER(supplier);

      const name = "supplier";
      this.ALTER_TYPE_REGISTER(name);
      this.ALTER_SIZE_MODAL_REGISTER("ModalGeneral");
      this.SET_COLAPSE_REGISTER(true);
      this.ALTER_MODAL_CONTAINER_REGISTER("modalContainer");
    },

    nextPage() {
      this.readSupplier((this.page += 1));
    },

    previousPage() {
      if (this.page === 1) {
        return;
      } else {
        this.readSupplier((this.page -= 1));
      }
    },

    redirectWhatsApp(number) {
      const formatedNumber = number.replace(/\D/g, "");
      const url = `https://api.whatsapp.com/send/?phone=55${formatedNumber}`;
      window.open(url, "_blank");
    },

    handleShowFilterTypes() {
      const types = ["nome", "cpfCnpj", "celular", "endereco", "cidade"];

      if (types.includes(this.type)) {
        this.showFilterTypes = true;
        return;
      }

      this.showFilterTypes = false;
    },
  },
  created() {
    this.readSupplier(1);
  },
  computed: {
    ...mapState({
      isSearchRegisters: (state) => state.isSearchRegisters,
    }),
  },
  watch: {
    async getAllSupplier() {
      await this.readSupplier(1);
    },
    isSearchRegisters() {
      this.readSupplier();
    },
  },
};
</script>

<style scoped>
.ContentTable {
  display: flex;
  flex-direction: column;
  overflow: auto;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}
.headTable {
  color: whitesmoke;
  background: #3a4750;
  text-align: center;
  height: 29px;
}

.trTable {
  text-align: center;
  height: 29px;
  cursor: context-menu;
  font-size: 12px;
}

.whatsapp {
  width: 20px;
  height: 20px;
}

.wtsTd {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 5px;
  cursor: pointer;
}

.wtsTd:hover {
  background: rgb(213, 213, 213);
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-weight: 500;
  font-size: 12px;
}

td {
  text-transform: capitalize;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.searchFilter {
  width: 150px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.searchFilterTypes {
  width: 150px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilterTypes:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
}

.ContainerSearchInput {
  display: flex;
  align-items: center;
  height: 37px;
  width: 100%;
  justify-content: space-between;
  padding: 0 20px;
}

.textRegister span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.searchInput {
  width: 50%;
  display: flex;
}

.searchAll {
  justify-content: center;
  color: #3a4750;
  font-size: 13px;
  padding: 5px 20px;
  font-weight: bold;
  border: solid 1px #e3e0e0;
  border-radius: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.searchAll:hover {
  background: rgb(205, 205, 205);
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

input {
  border: 1px solid rgb(195, 193, 193);
  border-radius: 5px;
  height: 30px;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #c3c1c1;
}

input:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

button:focus {
  box-shadow: none;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.FooterPagination {
  display: flex;
  align-items: center;
  height: 70px;
  padding: 20px;
  width: 100%;
  justify-content: flex-end;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(140, 140, 140);
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}
@media screen and (min-width: 768px) and (max-width: 1200px) {
  .ContainerSearchInput {
    padding: 3px;
  }
  .textRegister {
    font-size: 12px;
  }
  .searchInput {
    width: 60%;
  }
  .searchFilter {
    height: 25px;
    font-size: 11px;
  }
  .form-control-sm {
    padding: 0.25rem 1.2rem;
    height: 25px;
    font-size: 12px;
  }
  .searchBtn {
    right: 10px;
    height: 25px;
    display: flex;
    align-items: center;
  }
  .searchAll {
    font-size: 11px;
    padding: 2px 10px;
  }
  .ContentTable {
    height: 50vh;
    max-height: 50vh;
    padding: 5px;
  }
  .headTable {
    height: 25px;
  }
  .trTable {
    height: 25px;
    font-size: 11px;
  }
  .setBtn {
    margin: 0 1rem 10px 0;
  }

  .itemBtn {
    width: 18px;
    height: 18px;
  }

  .centerItemBtn {
    width: 18px;
    height: 18px;
  }
}

@media screen and (max-width: 700px) {
  .ContainerSearchInput {
    display: flex;
    align-items: center;
    height: 70px;
    gap: 5px;
    width: 100%;
    justify-content: center;
    padding: 0;
    flex-direction: column;
  }

  .searchInput {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 3px 0 0;
  }

  .ContentTable {
    padding: 5px;
  }

  .trTable {
    font-size: 9px;
  }
}
</style>