<template>
  <div class="containerGeralVendaMtq">
    <div>
      <b-row
        class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="align-items: center; justify-content: space-between"
      >
        <div class="titleHeader">
          <p class="title">
            {{
              !budgetToSale &&
              statusSquareMeter === salesSquareMeterType.orcamento
                ? "NOVO ORÇAMENTO MT2 Nº"
                : "NOVA VENDA MT2 Nº"
            }}
            {{ numberSaleSquareMeter || saleSquareMeterFromModal.number }}
          </p>
        </div>

        <div
          style="
            margin-right: 16px;
            display: flex;
            align-items: center;
            gap: 10px;
          "
        >
          <label
            :style="`color: ${alterColorSituation}`"
            class="title m-0 p-0"
            >{{ getNameSituantionById(saleSquareMeter.idSituacao) }}</label
          >
        </div>
      </b-row>

      <b-row
        :class="`${
          statusSquareMeter === salesSquareMeterType.orcamento &&
          'rowResponsive'
        } mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12`"
      >
        <div
          :class="`${
            statusSquareMeter === salesSquareMeterType.orcamento
              ? 'inputResponsive col-sm-2 col-md-2 col-lg-2 col-xl-2'
              : 'col-sm-6 col-md-6 col-lg-2 col-xl-2'
          }`"
        >
          <Input
            ref="data"
            label="Data"
            type="date"
            :dataInput.sync="saleSquareMeter.data"
            :labelFixed="true"
            :isDisabled="handleBlock"
          />
        </div>

        <div
          v-if="statusSquareMeter === salesSquareMeterType.orcamento"
          class="inputResponsive col-sm-2 col-md-2 col-lg-2 col-xl-2"
        >
          <Input
            ref="dataVencimento"
            label="Data Vencimento"
            :labelFixed="true"
            type="date"
            :dataInput.sync="saleSquareMeter.dataVencimento"
            :isDisabled="handleBlock"
          />
        </div>

        <div class="inputResponsive col-sm-2 col-md-2 col-lg-2 col-xl-2">
          <Input
            ref="dataEntrega"
            label="Data Entrega"
            :labelFixed="true"
            type="date"
            :dataInput.sync="saleSquareMeter.dataEntrega"
            :isDisabled="handleBlock"
          />
        </div>

        <div
          :class="`${
            statusSquareMeter === salesSquareMeterType.orcamento
              ? 'inputResponsive col-sm-5 col-md-5 col-lg-6 col-xl-5'
              : 'col-sm-5 col-md-5 col-lg-5 col-xl-6'
          }`"
          size="sm"
        >
          <InputInfinit
            label="Cliente"
            typeFilter="customer"
            :isEdit="saleSquareMeter.idCliente"
            :dataInput.sync="saleSquareMeter.idCliente"
            :onSearch="fillCustomerComboBoxWithPaginate"
            :openCreateModal="() => openModals('modalCreateCustomer')"
            :findAll="createdNewCustomer"
            @selectItem="handleDataCustomers($event)"
            :isDisabled="handleBlock"
          />
        </div>

        <div
          :class="`${
            statusSquareMeter === salesSquareMeterType.orcamento
              ? 'inputResponsive col-sm-4 col-md-4 col-lg-3 col-xl-3 mt-3'
              : 'col-sm-6 col-md-6 col-lg-4 col-xl-4 mt-3'
          } `"
          size="sm"
        >
          <InputSelect
            label="Funcionário"
            ref="refResponsavel"
            :items="dataEmployee"
            valueField="id"
            textField="nomeFuncionario"
            :dataSelect.sync="saleSquareMeter.idFuncionario"
            :openCreateModal="() => openModals('modalCreateEmployee')"
            :isDisabled="handleBlock"
          />
        </div>
      </b-row>

      <div
        v-if="handleBlock"
        :style="`${handleBlock && 'margin-top: 1rem; padding: 0 1rem'}`"
      >
        <p v-if="existsBillPaid" style="color: brown; font-size: 11px">
          * Essa venda contém parcelas pagas, será possível somente a alteração
          da
          <strong>Observação</strong> do item e
          <strong>Observações gerais / Observações de nota fiscal</strong> da
          venda
        </p>
        <p v-else-if="existsNf" style="color: brown; font-size: 11px">
          * Essa venda contém um documento fiscal Emitido/Cancelado, será
          possível somente a alteração do campo <strong>Observação</strong> do
          item e
          <strong>Observações gerais / Observações de nota fiscal</strong> da
          venda
        </p>
      </div>
    </div>

    <hr />

    <div class="contentItens">
      <div class="responsiveItems" style="padding-top: 0">
        <div class="containerItens">
          <b-row
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentTitleItens"
          >
            <div class="titleBillsPayments">
              <p class="title">Informações dos Itens</p>
            </div>
          </b-row>

          <div
            style="
              width: 100%;
              display: flex;
              justify-content: space-between;
              gap: 1rem;
              margin-top: 2.5rem;
            "
          >
            <div style="width: 100%">
              <b-row class="itemsSaleSquareMeter" style="align-items: normal">
                <div class="containerLineOne">
                  <div
                    v-if="lineProduct.tipoProduto === 'normal'"
                    class="containerInputItem"
                    size="sm"
                  >
                    <div
                      style="
                        position: absolute;
                        top: -23px;
                        right: 0;
                        z-index: 0;
                        display: flex;
                        gap: 0.5rem;
                      "
                    >
                      <div
                        v-if="lineProduct.idProduto"
                        style="display: flex; align-items: center; gap: 0.5rem"
                      >
                        <div
                          style="
                            position: relative;
                            position: relative;
                            width: 130px;
                            height: 100%;
                          "
                        >
                          <div class="radio-input">
                            <label>
                              <input
                                type="radio"
                                name="value-radio"
                                value="P1"
                                v-model="lineProduct.tipoPreco"
                                @change="updateProductValue('P1')"
                              />
                              <span>VL . 1</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="value-radio"
                                value="P2"
                                v-model="lineProduct.tipoPreco"
                                @change="updateProductValue('P2')"
                              />
                              <span>VL . 2</span>
                            </label>
                            <label>
                              <input
                                type="radio"
                                name="value-radio"
                                value="P3"
                                v-model="lineProduct.tipoPreco"
                                @change="updateProductValue('P3')"
                              />
                              <span>VL . 3</span>
                            </label>
                            <span class="selection"></span>
                          </div>
                        </div>
                      </div>

                      <button
                        class="btnsComposition"
                        @click="
                          handleShowCompositionItem(
                            lineProduct.idProduto,
                            lineProduct.composicao
                          )
                        "
                      >
                        Composições
                      </button>
                    </div>

                    <div class="inputItem" size="sm">
                      <div class="infinite">
                        <InputInfinit
                          label="Itens"
                          typeFilter="itens"
                          :isEdit="lineProduct.id"
                          :dataInput.sync="lineProduct.idProduto"
                          :onSearch="fillProductsComboBoxWithPaginate"
                          :openCreateModal="
                            () =>
                              handleModalCreateProduct(
                                'modalCreateProduct',
                                lineProduct.idProduto
                              )
                          "
                          :findAll="createdNewProducts"
                          :clearSelected="toggleClearSelect"
                          :searchFields="['nome', 'codRef']"
                          @selectItem="handleSetProductValues"
                          :isDisabled="handleBlock"
                        />

                        <span v-if="lineProduct.estoque >= 0" class="alertStock"
                          >Estoque Atual : {{ lineProduct.estoque }}</span
                        >
                        <span
                          v-if="lineProduct.estoque < 0"
                          class="alertStockNegative"
                          >Estoque Atual : {{ lineProduct.estoque }}</span
                        >
                      </div>
                    </div>
                  </div>

                  <div class="inputItemColors">
                    <InputSelect
                      label="Cores"
                      :items="colors"
                      valueField="id"
                      textField="nome"
                      :dataSelect.sync="lineProduct.idCor"
                      :openCreateModal="() => openModals('modalColors')"
                      ref="refColors"
                      :isDisabled="isActiveReplaceAll || handleBlock"
                    />
                  </div>

                  <div class="inputItemRooms">
                    <InputSelect
                      label="Ambientes"
                      :items="rooms"
                      valueField="id"
                      textField="nome"
                      :dataSelect.sync="lineProduct.idAmbiente"
                      :openCreateModal="() => openModals('modalRooms')"
                      ref="refRooms"
                      :isDisabled="isActiveReplaceAll || handleBlock"
                    />
                  </div>

                  <div class="inputItemQnt">
                    <InputQuant
                      label="Qtd."
                      :labelFixed="true"
                      ref="refQtd"
                      type="text"
                      :dataInput.sync="lineProduct.quantidade"
                      @calculateTotal="handleCalculateTotal"
                      :decimalPlaces="decimalPlaces"
                      isSelectAll
                      :isDisabled="isActiveReplaceAll || handleBlock"
                    />
                  </div>

                  <div
                    style="
                      display: flex;
                      align-items: center;
                      gap: 0.3rem;
                      position: absolute;
                      right: 20px;
                      top: -30px;
                    "
                  >
                    <label style="margin: 0; cursor: pointer" for="isMTL"
                      >Metro Linear</label
                    >
                    <input
                      type="checkbox"
                      id="isMTL"
                      v-model="lineProduct.linear"
                      @change="handleCalculateTotal"
                      :disabled="isActiveReplaceAll || handleBlock"
                    />
                  </div>
                </div>

                <div class="containerLineTwo">
                  <div class="inputItemVlUnit">
                    <InputMoney
                      label="Vl. Unit"
                      labelFixed
                      ref="refVlUnit"
                      type="text"
                      :dataInput.sync="lineProduct.valorUnitario"
                      @calculateTotal="handleCalculateTotal"
                      isSelectAll
                      :isDisabled="handleBlock"
                    />
                  </div>

                  <div class="inputItemHeight">
                    <InputQuant
                      label="Altura"
                      :labelFixed="true"
                      ref="refAlt"
                      type="text"
                      :dataInput.sync="lineProduct.altura"
                      @calculateTotal="handleCalculateTotal"
                      :decimalPlaces="decimalPlaces"
                      :isDisabled="
                        enableEditMt2 || isActiveReplaceAll || handleBlock
                      "
                      isSelectAll
                    />
                  </div>

                  <div class="inputItemWidth">
                    <InputQuant
                      label="Largura"
                      :labelFixed="true"
                      ref="refLarg"
                      type="text"
                      :dataInput.sync="lineProduct.largura"
                      @calculateTotal="handleCalculateTotal"
                      :decimalPlaces="decimalPlaces"
                      :isDisabled="
                        enableEditMt2 || isActiveReplaceAll || handleBlock
                      "
                      isSelectAll
                    />
                  </div>

                  <div class="inputItemLoss">
                    <InputPercentage
                      label="% Perda"
                      :labelFixed="true"
                      ref="refPorcPerda"
                      type="text"
                      :dataInput.sync="lineProduct.porcentagemPerda"
                      @calculateTotal="handleCalculateTotal"
                      isSelectAll
                      :isDisabled="isActiveReplaceAll || handleBlock"
                    />
                  </div>

                  <div>
                    <InputQuant
                      label="MT2 Total"
                      labelFixed
                      type="text"
                      :isDisabled="!enableEditMt2 || handleBlock"
                      :dataInput.sync="lineProduct.metragem"
                      ref="totalMeter"
                      @calculateTotal="handleCalculateTotal"
                      :decimalPlaces="decimalPlaces"
                      isSelectAll
                    />
                  </div>

                  <!-- <div class="inputItemVlTot" v-if="!this.dataConfig.venda_mtq.arredondar_mt2">
                    <InputMoneyNotRounding
                      label="Vl. Total"
                      labelFixed
                      ref="valorTotal"
                      type="text"
                      isDisabled
                      :dataInput.sync="lineProduct.valorTotal"
                      @calculateTotal="handleCalculateTotal"
                    />
                  </div> -->
                  <div class="inputItemVlTot">
                    <InputMoney
                      label="Vl. Total"
                      labelFixed
                      ref="valorTotal"
                      type="text"
                      isDisabled
                      :dataInput.sync="lineProduct.valorTotal"
                      @calculateTotal="handleCalculateTotal"
                    />
                  </div>
                  <div class="inputItemDesc">
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        position: relative;
                      "
                    >
                      <InputMoney
                        v-if="!discountIsCheked"
                        label="Desc"
                        :labelFixed="true"
                        type="text"
                        :dataInput.sync="lineProduct.desconto"
                        ref="discount"
                        @calculateTotal="handleCalculateTotal"
                        isSelectAll
                        :isDisabled="isActiveReplaceAll || handleBlock"
                      />

                      <InputPercentage
                        v-else
                        label="Desc"
                        :labelFixed="true"
                        type="text"
                        :dataInput.sync="lineProduct.desconto"
                        ref="discount"
                        :isDisabled="discountToAllItems || isActiveReplaceAll"
                        @calculateTotal="handleCalculateTotal"
                        isSelectAll
                      />
                    </div>
                  </div>

                  <div class="itemToggleDesc" style="width: 52px; height: 30px">
                    <label class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2">
                      <input
                        type="checkbox"
                        ref="typeDiscount"
                        @click="alterDiscountIsCheked"
                        v-model="discountIsCheked"
                        :disabled="
                          validatorDesc || isActiveReplaceAll || handleBlock
                        "
                      />
                      <div class="slider">
                        <span>R$</span>
                        <span>%</span>
                      </div>
                    </label>

                    <div
                      style="display: none"
                      ref="enableLabelApplyAll"
                      class="tooltipDiscount"
                    >
                      <div class="tooltipItems">
                        <div>
                          <label for="apllyAll">Aplicar em todos</label>
                        </div>
                        <div>
                          <input
                            type="checkbox"
                            id="apllyAll"
                            v-model="discountToAllItems"
                            @change="alterAllChecksDiscount"
                            :disabled="isActiveReplaceAll || handleBlock"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="containerInputObsAndBtn">
                  <div class="inputItemObs">
                    <Input
                      label="Observação"
                      :labelFixed="true"
                      type="text"
                      :dataInput.sync="lineProduct.dadosAdicionais"
                      ref="dadosAdicionais"
                      :isDisabled="isActiveReplaceAll"
                    />
                  </div>

                  <div
                    v-if="!isActiveReplaceAll"
                    class="btnAddMoreItems"
                    :style="productLoading && 'align-items: flex-end'"
                  >
                    <button
                      v-if="!productLoading"
                      style="width: 100px; margin-top: 0.5rem"
                      class="btnsSaleSquareMeter btnAddMoreItems"
                      @click="handleAddMoreItens"
                    >
                      {{ lineProduct.id ? "Atualizar" : "Adicionar" }}
                    </button>

                    <div
                      v-else
                      style="
                        width: 30px;
                        height: 30px;
                        background-color: #00395d;
                        border-radius: 50%;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        margin-top: 8px;
                      "
                    >
                      <b-spinner class="spinProduct" />
                    </div>
                  </div>

                  <div v-else style="display: flex; gap: 0.5rem">
                    <button
                      style="width: 70px; margin-top: 0.5rem"
                      :class="`btnsSaleSquareMeter applyReplaceAll ${
                        isLoadingToReplace && 'blockReplace'
                      }`"
                      @click="applyReplaceAll"
                    >
                      Substituir
                    </button>

                    <button
                      style="width: 70px; margin-top: 0.5rem"
                      class="btnsSaleSquareMeter cancelReplaceAll"
                      @click="cancelReplaceAll"
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              </b-row>
            </div>
          </div>

          <section class="containerTableItens">
            <div class="contentTableItens">
              <table
                class="tableProducts"
                style="width: 100%"
                v-if="tableProducts.length"
              >
                <thead>
                  <tr>
                    <th style="width: 200px">Nome</th>
                    <th style="width: 60px">Alt</th>
                    <th style="width: 60px">Larg</th>
                    <th style="width: 60px">Qnt</th>
                    <th style="width: 120px">Vl. Unit</th>
                    <th style="width: 99px">Desc</th>
                    <th style="width: 120px">Vl. Total</th>
                    <th style="width: 40px"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    :class="`tableItem ${isActiveReplaceAll && 'blockTable'}`"
                    v-for="(product, idx) in tableProducts"
                    :key="product.id"
                    @click="
                      !isLoadingToDelete &&
                        editProductsOfSaleSquareMeter(product)
                    "
                  >
                    <td style="width: 200px">
                      <div
                        style="display: flex; gap: 0.5rem; align-items: center"
                      >
                        <div
                          style="
                            display: flex;
                            flex-direction: column;
                            gap: 0.3rem;
                          "
                        >
                          <span class="contentNumberItem"> {{ idx + 1 }}</span>
                          <span
                            class="contentNumberItem"
                            style="font-size: 0.5rem"
                          >
                            {{ product.linear ? "MTL" : "MT2" }}</span
                          >
                        </div>

                        <div style="width: calc(100% - 20px)">
                          <p class="textEllipsis" style="margin: 0">
                            {{ product.nome }}
                          </p>

                          <p
                            class="textEllipsis"
                            style="margin: 0; font-size: 9px; color: gray"
                          >
                            <span
                              v-b-tooltip.hover.top="product.dadosAdicionais"
                            >
                              {{ product.dadosAdicionais }}
                            </span>
                          </p>
                          <p
                            v-if="product.idAmbiente"
                            class="textEllipsis"
                            style="margin: 0; font-size: 9px; color: gray"
                          >
                            <span
                              v-b-tooltip.hover.top="product.dadosAdicionais"
                            >
                              {{ product.ambienteNome.toUpperCase() }}
                            </span>
                          </p>
                        </div>
                      </div>
                    </td>
                    <td style="width: 60px">{{ product.altura }}</td>
                    <td style="width: 60px">{{ product.largura }}</td>
                    <td style="width: 60px">{{ product.quantidade }}</td>
                    <td style="width: 120px">
                      {{ maskCurrency(product.valorUnitario) }}
                    </td>
                    <td style="width: 100px">
                      {{
                        saleSquareMeter.tipoDesconto === "Porcentagem"
                          ? maskPercentage(product.desconto)
                          : maskCurrency(product.desconto)
                      }}
                    </td>
                    <td style="width: 115px">
                      <p class="textEllipsis" style="margin: 0">
                        {{ handleCalculateTotalInTable(product) }}
                      </p>
                    </td>
                    <td style="width: 40px; position: relative; padding: 0">
                      <span
                        :class="`contentRemoveItem ${
                          (isActiveReplaceAll ||
                            lineProduct.id === product.id ||
                            isLoadingToDelete) &&
                          'blockRemove'
                        }`"
                        @click.stop="removeItens(product.id)"
                        v-b-tooltip.hover.top="'Excluir'"
                      >
                        <b-icon-trash style="color: white" scale="1.5" />
                      </span>

                      <div class="contentCloneAndReplaceItem">
                        <span
                          ref="replaceAll"
                          v-b-tooltip.hover.top="'Substituir todos!'"
                          @click.stop="handleItemSelected(product)"
                          style="
                            width: 25px;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <b-icon-bookmarks-fill
                            style="color: #a8a8a8"
                            scale="1.5"
                          />
                        </span>

                        <span
                          v-b-tooltip.hover.top="'Clonar'"
                          @click.stop="handleDuplicate(product)"
                          style="
                            width: 25px;
                            height: 25px;
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          "
                        >
                          <b-icon-back style="color: #a8a8a8" scale="1.5" />
                        </span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>

              <div v-else class="contentNotItems">
                <p>Nenhum produto adicionado...</p>
              </div>
            </div>
          </section>
        </div>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="contentDataTotals col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="contentTotals">
            <span class="inputTotals">
              <p>Total de Itens (R$)</p>
              <input disabled type="text" v-model="totalOfItens" />
            </span>

            <span class="inputTotals">
              <p>Desconto (R$)</p>
              <input disabled type="text" v-model="totalOfDiscount" />
            </span>

            <span class="dashed"></span>

            <span class="inputTotals contentFreteTotals">
              <p>Frete (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(saleSquareMeter.frete)"
                @input="handleFreteMonetaryValue($event)"
                :disabled="handleBlock"
              />
            </span>

            <span class="inputTotals contentFreteTotals">
              <p>Outros (R$)</p>
              <input
                style="color: #5c5855"
                type="text"
                :value="maskCurrency(saleSquareMeter.outrosValores)"
                @input="handleOtherValuesMonetaryValue($event)"
                :disabled="handleBlock"
              />
            </span>

            <span class="inputTotals">
              <p v-if="saleSquareMeter.status === salesSquareMeterType.venda">
                Total da Venda (R$)
              </p>
              <p v-else>Total do Orçamento (R$)</p>
              <input disabled type="text" v-model="totalValue" />
            </span>
          </div>
        </div>
      </b-row>

      <hr v-if="saleSquareMeter.status === salesSquareMeterType.venda" />

      <div
        class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        v-if="saleSquareMeter.status === salesSquareMeterType.venda"
      >
        <b-row
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12"
          style="align-items: center"
        >
          <div class="titleBillsPaymentsLater">
            <p class="title">Informações de Pagamento</p>
            <label class="custom-checkbox">
              <input v-model="fixedSalary" name="dummy" type="checkbox" />
              <span class="checkmark"></span>
              Vencimento fixo?
            </label>
          </div>
        </b-row>

        <b-row class="contentBills mt-3" size="sm">
          <div class="selectPlus col-sm-6 col-md-4 col-lg-4 col-xl-4">
            <label class="fixedSelect">Forma de pagamento</label>
            <b-form-select
              value-field="id"
              text-field="tipo"
              class="borderInput shadow-none"
              :options="allPaymentsTypes"
              v-model="dataBillSquareMeter.idFormaPagamento"
              size="sm"
              @change="selectPayment"
            >
            </b-form-select>
          </div>

          <div class="receiptAccount col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <InputSelect
              label="Conta de recebimento"
              valueField="id"
              textField="nome"
              :isDisabled="blocked"
              :items="allAccountReceiptBills"
              :dataSelect.sync="dataBillSquareMeter.idContaRecebimento"
              :openCreateModal="() => openModals('modalContaRecebimento')"
              ref="refContaDeRecebimento"
            />
          </div>

          <div
            class="paymentInterval col-sm-6 col-md-4 col-lg-3 col-xl-2"
            v-if="!fixedSalary"
          >
            <Input
              label="Interval. de pagamento"
              :labelFixed="true"
              type="text"
              :dataInput.sync="intervaloDias"
              ref="refIntervaloDePagamento"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="fistBill col-sm-6 col-md-3 col-lg-2 col-xl-2">
            <Input
              label="Venc. 1º parcela"
              :labelFixed="true"
              type="date"
              :dataInput.sync="dataBillSquareMeter.data"
              ref="refVencimentoPrimeiraParcela"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>

          <div class="amountBills col-sm-6 col-md-3 col-lg-2 col-xl-1">
            <Input
              label="Parcelas"
              :labelFixed="true"
              type="text"
              :dataInput.sync="totalParcelas"
              ref="refTotalParcelas"
              :isDisabled="paymentMoneyIsSelected"
            />
          </div>
        </b-row>

        <b-row class="contentBills">
          <div
            class="col-sm-12 col-md-12 col-lg-12 col-xl-12 d-flex justify-content-end"
          >
            <button
              class="btnsSaleSquareMeter btnGenerateBills"
              @click="handleGenerateBillsAndOpenModalBills"
            >
              {{ verifyIfBillIsRegistred }}
            </button>
          </div>
        </b-row>
      </div>

      <hr />

      <b-row class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div class="col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações Gerais</p>

            <p class="observacoes">
              (Insira aqui observações que serão importantes para você)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="saleSquareMeter.dadosAdicionais"
          />
        </div>

        <div class="mt-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div style="display: flex; gap: 3px; align-items: center">
            <p class="obsGeneral">Observações de Nota Fiscal</p>

            <p class="observacoes">
              (Insira aqui, observações que sairão na Nota Fiscal do cliente)
            </p>
          </div>

          <textarea
            class="inputTextArea"
            rows="3"
            max-rows="6"
            v-model="saleSquareMeter.observacoesNf"
          />
        </div>
      </b-row>

      <hr />

      <b-row class="mb-4 col-sm-12 col-md-12 col-lg-12 col-xl-12">
        <div
          class="col-sm-12 col-md-12 col-lg-12 col-xl-12 contentButtonsActions"
        >
          <div>
            <button
              v-if="
                saleSquareMeter.id &&
                saleSquareMeter.idSituacao !==
                  getSpecificSituantionId(squareMeterSituationType.cancelado)
              "
              class="btnsSaleSquareMeter btnCancelSaleSquareMeter"
              @click="cancelSaleSquareMeter"
            >
              Cancelar {{ showTextSaleOrBudget }}
            </button>

            <button
              class="btnsSaleSquareMeter btnReopenSaleSquareMeter"
              v-if="
                saleSquareMeter.id &&
                saleSquareMeter.idSituacao ===
                  getSpecificSituantionId(squareMeterSituationType.cancelado)
              "
              @click="reOpen"
            >
              Reabrir {{ showTextSaleOrBudget }}
            </button>
          </div>

          <div style="display: flex; gap: 10px">
            <div>
              <button
                class="btnsSaleSquareMeter btnConfirm"
                @click="handleDataToSendModalSquareMeter"
              >
                Finalizar
              </button>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <ModalCreateAccountReceiptBill
      @getNewReceiptBill="createdNewAccountReceiptBill = $event"
    />
    <ModalBillsSquareMeter
      :saleIsCanceled="saleIsCanceled"
      :amountBills="billsFromModal"
      :totalValue="totalValue"
      :paymentSelected="paymentSelected"
      :receiptBillSelected="receiptBillSelected"
      :rangeOfDays="intervaloDias"
      :verifyBillIsPaid="verifyBillIsPaid"
      @isDropBills="isDropBills = $event"
      @confirmedBills="saleSquareMeter['bills'] = $event"
      @clearBillsDataAfterRemove="isRemovedBills = $event"
    />
    <ModalSaleSquareMeter
      :allSituations="allSituations"
      :paymentsDataInfo="paymentsDataInfo"
      :dataSaleSquareMeter="saleSquareMeter"
      :squareMeterForModal="squareMeterForModal"
      :totals="objectTotal"
      :numberSale="numberSaleSquareMeter"
      @isPaydBill="isPaydBill = $event"
      @saleSquareMeterSaved="saleSquareMeterFromModal = $event"
      @transformToSale="handleTransformToSale"
      @clearAllSaleSquareMeter="clearAll"
    />
    <ModalCreateCustomer
      @getAllNewCustomer="handleCreatedNewCustomer"
      :idCustomerFromSearch="saleSquareMeter.idCliente"
    />
    <ModalCreateEmployees @getAllNewEmployee="createdNewEmployee = $event" />
    <ModalCreateProducts
      @getAllNewProduct="handleCreatedNewProduct"
      :idProductFromSearch="idProductFromSearch"
    />
    <ModalComposition
      :compositions="compositionItems"
      :idProduct="lineProduct.idProduto"
      :forceUpdateSelect="toggleToUpdate"
      @findCompositions="handleItemFromModalComposition($event)"
      isSquareMeter
    />
    <ModalCreateColors @getNewColors="getAllColors" />
    <ModalCreateRooms @getNewRooms="getAllRooms" />
  </div>
</template>

<script>
import { mixinSalePerMeterSquare } from "../../mixins/SalePerMeterSquare/SalePerMeterSquare";

export default {
  mixins: [mixinSalePerMeterSquare],
};
</script>

<style scoped>
#dadosVendaStyle {
  border: none !important;
}

.containerLineOne,
.containerLineTwo {
  width: 100%;
  display: flex;
  gap: 15px;
  position: relative;
}

.containerInputItem {
  width: 50%;
  position: relative;
  min-width: 320px;
}

.inputItem {
  width: 100%;
  min-width: 320px;
  position: relative;
}

.inputItemColors {
  width: 23%;
}

.inputItemRooms {
  width: 23%;
}

.inputItemQnt {
  flex-shrink: 1;
  max-width: 8%;
}

.inputItemVlUnit,
.inputItemHeight,
.inputItemWidth,
.inputItemLoss,
.inputItemVlTot,
.inputItemDesc {
  width: 33.33%;
}

.inputItemObs {
  width: 50%;
  margin-top: 0.5rem;
}

.itemToggleDesc {
  width: 12% !important;
  position: relative;
}

.input:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTotals input:hover {
  background: #d9d9d9 !important;
  border: 1px solid #262626;
}
.custom-select:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.textarea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTextArea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}

.containerInputObsAndBtn {
  display: flex;
  gap: 15px;
  align-items: center;
}

.containerTableItens {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
  flex-wrap: wrap-reverse;
}

.contentTableItens {
  width: 100%;
  height: 242px;
  border: dashed 1px #c1c1c1;
  border-radius: 5px;
  padding: 0.3rem 0.5rem;
}

.tableProducts {
  display: table;
  width: 100%;
  border-spacing: 0;
  margin-top: 0.5rem;
}

.tableProducts thead tr th {
  padding: 0 0.5rem;
  font-weight: normal;
}

.tableProducts thead,
tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.tableItem {
  height: 50px;
  background-color: whitesmoke;
  box-shadow: 0 1px 3px rgb(0 0 0 / 24%);
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  position: relative;
  transition: ease 0.2s;
}

.tableItem:hover {
  background-color: rgb(218, 218, 218);
  transition: ease 0.2s;
}

.tableItem td {
  text-align: start !important;
  padding: 0 0.5rem;
}

.textEllipsis {
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}

.spinProduct {
  color: white;
  width: 20px;
  height: 20px;
}

.contentNumberItem {
  height: 18px;
  width: 26px;
  padding: 0 0.3rem;
  min-width: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: gray;
  color: white;
}

.contentRemoveItem {
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0 5px 5px 0;
  background-color: brown;
  box-shadow: -1px 0 2px rgb(0 0 0 / 24%);
}

.blockRemove {
  background-color: rgb(144, 85, 85);
  pointer-events: none !important;
  cursor: default !important;
}

.blockReplace {
  background-color: #0fbf4d84 !important;
  pointer-events: none !important;
  cursor: default !important;
}

.blockTable {
  background-color: rgb(218, 218, 218);
  pointer-events: none !important;
  cursor: default !important;
}

.contentCloneAndReplaceItem {
  position: absolute;
  right: 30px;
  top: 0;
  height: 100%;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.3rem;
}

.contentNotItems {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.contentNotItems p {
  font-size: 16px;
  color: #c1c1c1;
}

.infinite {
  position: relative;
}

.cardDadosVendas {
  padding-bottom: 15px !important;
  border-radius: 5px !important;
  background-color: rgba(255, 255, 255, 0.788) !important;
  box-shadow: inset 0 0 0.5em rgb(255, 255, 255), 0 0 0.5em rgba(5, 5, 5, 0.171);
}

.tamanhoBotaoOpenCard {
  color: black;
  font-size: 16px;
  height: 30px;
  display: flex;
  align-items: center;
  /* color: white; */
}

.cardDadosVendasBorda {
  border-radius: 5px !important;
  margin-top: 25px !important;
  margin-bottom: 20px;
  /* background-color: #6a88bd !important; */
  /* color: white; */
}

.tamanhoCards {
  width: 100%;
  display: flex;
  /* background-color: #6a88bd !important; */
  /* box-shadow: 2px 2px 4px 0px black !important ; */
}

.cardDadosVendas {
  padding: 0px 16px 0px 16px !important;
}

.acoesBtns {
  padding: 40px 0 10px 40px;
  margin: 0;
  width: 100%;
  border: none;
}

.btnsGeral {
  display: flex;
  justify-content: space-around;
}

.containerAllCard {
  margin-top: 10px;
}

.containerCheckBox {
  display: flex;
  height: 32px !important;
  margin-top: 31px;
  align-items: center !important;
  background-color: #e9ecef !important;
  border: solid 1px rgb(216, 215, 215);
  border-radius: 5px;
  padding-left: 15px !important;
}

.chkVendaOrcamento {
  margin-top: 18px;
}

.radio-input input {
  display: none;
}

.radio-input {
  position: absolute;
  right: 0;
  top: -2px;
  display: flex;
  --container_width: 130px;
  align-items: center;
  border-radius: 5px;
  background-color: #fff;
  color: #000000;
  width: var(--container_width);
  overflow: hidden;
  border: 1px solid rgba(53, 52, 52, 0.226);
}

.radio-input label {
  width: 100%;
  padding: 2px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
  font-size: 11px;
  margin-bottom: 0px !important;
}

.selection {
  display: none;
  position: absolute;
  height: 100%;
  width: calc(var(--container_width) / 3);
  z-index: 0;
  left: 0;
  top: 0;
  transition: 0.15s ease;
}

.radio-input label:has(input:checked) {
  color: #fff;
}

.radio-input label:has(input:checked) ~ .selection {
  background-color: #3a4750;
  display: inline-block;
}

.radio-input label:nth-child(1):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 0 / 3));
}

.radio-input label:nth-child(2):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 1 / 3));
}

.radio-input label:nth-child(3):has(input:checked) ~ .selection {
  transform: translateX(calc(var(--container_width) * 2 / 3));
}

.containerGeralVendaMtq {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-top: 2rem;
  margin-left: 2rem;
}

.iconFormaPagamento {
  display: flex;
  justify-content: space-between;
}

.btnFormaPagamento {
  cursor: pointer;
}

p {
  margin: 0;
}

.titleHeader {
  margin-left: 16px;
}

.btnsComposition:hover {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.353);
  background-color: rgb(116, 116, 116);
  transition: ease-in all 0.1s;
}

.btnsComposition {
  border-radius: 5px;
  width: auto;
  height: 20px;
  border: none;
  color: white;
  font-size: 9px;
  background-color: #a8a8a8;
  transition: ease-in all 0.1s;
}

.btnsSaleSquareMeter {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 30px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;
}

.btnAddMoreItems {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 11px;
  color: #333;
  transition: color 0.3s;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 14px;
  height: 14px;
  border: 2px solid #333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  transform-style: preserve-3d;
}

.custom-checkbox .checkmark::before {
  content: "\2713";
  font-size: 12px;
  color: transparent;
  transition: color 0.3s, transform 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #000000;
  border-color: #333;
  transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::before {
  color: #f0f0f0;
}

.custom-checkbox:hover {
  color: #666;
}

.custom-checkbox:hover .checkmark {
  border-color: #666;
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.custom-checkbox input[type="checkbox"]:focus + .checkmark {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.custom-checkbox .checkmark,
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  transition: background-color 1.3s, border-color 1.3s, color 1.3s,
    transform 0.3s;
}

.btnGenerateBills {
  background-color: transparent;
  border: solid 1px black;
  color: black;
}

.btnCancelSaleSquareMeter {
  background-color: #ff3131;
}

.btnReopenSaleSquareMeter {
  background-color: #cac64e;
}

.cancelReplaceAll {
  background-color: brown;
  border: solid 1px white;
  color: white;
  font-size: 10.5px !important;
  transition: ease-in all 0.1s;
}

.cancelReplaceAll:hover {
  background-color: rgb(134, 34, 34);
  transition: ease-in all 0.1s;
}

.applyReplaceAll {
  background-color: #0fbf4d;
  border: solid 1px white;
  color: white;
  font-size: 10.5px !important;
  transition: ease-in all 0.1s;
}

.applyReplaceAll:hover {
  background-color: #0ca241;
  transition: ease-in all 0.1s;
}

.isDisabled {
  background-color: #e1e1e1;
}

.btnConfirm {
  background-color: whitesmoke;
  border: solid 1px #0fbf4d;
  color: #0fbf4d;
}

.alertStock {
  font-size: 10px;
  color: #3e66ae;
  padding-left: 10px;
}

.alertStockNegative {
  font-size: 10px;
  color: #ff1d08;
  padding-left: 10px;
}

.inputDesc {
  width: 8.3% !important;
}

.labelInputs p {
  font-weight: 100 !important;
  color: #00395d;
  padding: 0;
}

.iconPlus {
  position: absolute;
  right: 20px;
  top: 0;
}

.switch {
  height: 100%;
  margin: 0;
  font-size: 14px;
  position: relative;
  display: inline-block;
}

.containerItens {
  margin-top: 1.5rem;
  padding: 0 15px;
}

.contentTitleItens {
  align-items: center !important;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #d9d9d9;
  color: #ffffff;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 50px !important;
  height: 90%;
  font-size: 8px;
}

.slider:before {
  position: absolute;
  content: "R$";
  font-size: 10px;
  height: 82%;
  width: 44%;
  left: 6%;
  border-radius: 50px;
  background-color: #00aa62;
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  color: white;
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: #00aa62;
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.contentDataTotals {
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  flex-wrap: wrap;
}

.contentFreteTotals input {
  background-color: white !important;
  border: solid 1px #d9d9d9;
}

.dashed {
  border-bottom: 1px dashed #b1aaa5;
  width: 100%;
  margin: 10px 0;
}

.inputTotals {
  display: flex;
  align-items: center;
  gap: 30px;
  color: #666360;
}

.inputTotals input {
  border: none;
  outline: none;
  text-align: end;
  padding-right: 5px;
  background-color: #d9d9d9;
  border-radius: 5px;
  height: 30px;

  border: solid 1px #d9d9d9;
}

.contentTotals {
  width: 400px;
  display: flex;
  flex-direction: column;
  text-align: right;
  align-items: flex-end;
  font-size: 14px;
  gap: 5px;
}

.itemsSaleSquareMeter {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;
  margin-left: 0;
}

.observacoes {
  font-size: 12px;
  color: #d5d3d3;
  font-style: italic;
}

.title {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.contentIconRemoveItems {
  position: absolute;
  right: -8px;
  top: -12px;
  color: rgb(255, 51, 51);
}

.tooltipDiscount {
  z-index: 3;
  width: 150px;
  padding: 5px;
  font-size: 12px;
  position: absolute;
  color: black;
  border-radius: 5px;
  top: -25px;
  right: -10px;
}

.tooltipItems {
  display: flex;
  gap: 5px;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 886px) {
  .contentTableItens {
    width: 100%;
  }
}

@media screen and (max-width: 1300px) {
  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .inputItemHeight,
  .inputItemWidth,
  .inputItemLoss,
  .inputItemVlUnit,
  .inputItemVlTot,
  .inputItemDesc {
    width: 33.33%;
  }

  .inputItemQnt {
    width: 80px;
  }
}

@media screen and (max-width: 1199px) {
  .amountBills {
    margin-top: 1rem !important;
  }
}

@media screen and (max-width: 991px) {
  .fistBill {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 769px) {
  .tableProducts tbody {
    display: block;
    height: 200px;
    overflow-y: scroll;
    overflow-x: hidden;
    margin-top: 0.5rem;
    padding: 5px !important;
  }
}

@media screen and (max-width: 800px) {
  .containerLineOne {
    flex-wrap: wrap;
    margin-bottom: 10px;
  }

  .containerInputItem,
  .inputItem {
    width: 100%;
  }

  .inputItemColors,
  .inputItemRooms {
    flex: 1;
  }

  .inputItemQnt {
    width: 200px !important;
    max-width: 100px;
  }

  .inputItemVlUnit,
  .inputItemHeight,
  .inputItemWidth,
  .inputItemLoss,
  .inputItemVlTot,
  .inputItemDesc {
    width: 23%;
  }

  .itemToggleDesc {
    width: 12% !important;
    position: relative;
  }
}

@media screen and (max-width: 768px) {
  .paymentInterval {
    margin-top: 1rem !important;
  }

  .tooltipDiscount {
    right: -85px;
    top: -28px;
  }

  .tableProducts tbody {
    display: block;
    height: 200px;
    margin-top: 0.5rem;
    padding: 5px !important;
  }

  .contentTableItens {
    overflow-x: auto;
  }
}

@media screen and (max-width: 575px) {
  .receiptAccount {
    margin-top: 1rem !important;
  }

  .containerLineTwo {
    flex-wrap: wrap;
    align-items: center;
  }

  .inputItemVlUnit,
  .inputItemHeight,
  .inputItemWidth {
    flex: 1 0 100px;
  }

  .inputItemLoss,
  .inputItemVlTot,
  .inputItemDesc {
    flex: 1 0 100px;
    margin-top: 5px;
  }

  .itemToggleDesc {
    width: 12% !important;
    position: relative;
  }
}

@media screen and (max-width: 475px) {
  .containerInputItem,
  .inputItem {
    width: 100%;
    min-width: 100% !important;
  }
}

@media screen and (max-width: 400px) {
  .itemsSaleSquareMeter {
    margin: 0 !important;
  }

  .btnAddMoreItems {
    width: 100% !important;
  }

  .inputItemQnt {
    width: 60px;
    max-width: 60px;
  }

  .inputItem {
    width: 100%;
    min-width: 100% !important;
  }

  .inputItemObs {
    width: 100%;
  }
}

.contentItens {
  margin-top: 1.5rem !important;
}

.contentButtonsActions {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.titleBillsPayments {
  font-size: 20px;
}

.titleBillsPaymentsLater {
  display: flex;
  font-size: 20px;
  width: 100%;
  justify-content: space-between;
}

.contentBills {
  margin-right: 16px;
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

.inputSelectSituation {
  border: solid 1px #d5d3d3;
  padding: 3px;
  border-radius: 0.25rem;
  outline: none;
  color: #5c5855;
  font-size: 12px;
}

.obsGeneral {
  font-size: 14px;
  color: #666360;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  bottom: 20px;
  left: 12px;
  z-index: 2;
  background-color: #ffff;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  padding: 0 0.2em;
}

.custom-select:disabled {
  color: black;
  background-color: #d5d3d3;
}

.custom-select {
  background: none;
  text-transform: capitalize;
}

.borderInput {
  border-radius: 3px;
  height: 28px;
  font-size: 12px;
  padding: 0 10px;
  border: 1px solid #d5d3d3;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .containerGeralVendaMtq {
    margin-left: 0rem;
  }

  .containerItens {
    padding: 0 30px;
  }

  .contentTitleItens {
    padding: 0 !important;
  }

  .responsiveItems {
    padding-top: 30px;
    padding-bottom: 10px;
    margin-top: 0rem !important;
  }
  .contentIconRemoveItems {
    right: 10px;
  }
  .contentItens {
    margin-top: 0rem !important;
  }

  .itemsSaleSquareMeter {
    margin-left: 0px;
    margin-right: 0px;
  }

  .btnsSaleSquareMeter {
    width: 140px;
    margin-top: 15px;
    font-size: 10px;
  }
  .contentTotals {
    font-size: 11px;
  }
  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    padding-right: 0px;
    padding-left: 10px;
  }
  .row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
    align-items: center;
    align-content: center;
    justify-content: flex-start;
  }
  .col-md-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }
  .col-md-5 {
    flex: 0 0 32%;
    max-width: 32%;
  }
}

@media (min-width: 576px) and (max-width: 1200px) {
  .col-sm-6 {
    flex: 0 0 32%;
    max-width: 32%;
  }

  .containerItens {
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 700px) {
  .containerGeralVendaMtq {
    margin-left: 0rem;
  }

  .containerTableItens {
    padding-left: 15px;
  }

  .containerItens {
    padding: 0;
    margin: 0 15px 0 0;
  }

  .contentTitleItens {
    margin-left: 0 !important;
    padding-left: 16px !important;
  }

  .tooltipDiscount {
    right: -15px;
    top: -23px;
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12,
  .col,
  .col-auto,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm,
  .col-sm-auto,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md,
  .col-md-auto,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg,
  .col-lg-auto,
  .col-xl-1,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl,
  .col-xl-auto {
    position: relative;
    width: 100%;
    padding-right: 0px;
    padding-left: 15px;
  }

  .row {
    gap: 10px;
  }

  .infinite {
    padding-right: 15px;
  }

  .responsiveItems {
    padding-top: 25px;
    padding-bottom: 5px;
    margin-top: 0rem !important;
  }

  .inputTotals {
    gap: 10px;
  }

  .obsGeneral {
    width: 80%;
  }

  .itemsSaleSquareMeter {
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px !important;
    padding-left: 16px;
    margin-right: 16px;
    width: 100%;
  }

  .contentIconRemoveItems {
    right: -20px;
  }
}

@media screen and (max-width: 547px) {
  .tooltipDiscount {
    right: -85px;
    top: -30px;
  }

  .containerInputObsAndBtn {
    margin-top: 5px;
  }
}
</style>
