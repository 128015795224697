import api from "./axios"

class serviceSintegra {
  async download() {
    try {
      const response = await api({
        url: '/sintegra',
        method: 'GET',
        responseType: 'blob',
      });

      if (response.data.type === 'application/json') {
        const blob = response.data;

        const jsonData = JSON.parse(await new Response(blob).text())
        return jsonData.message;
      }

      const href = URL.createObjectURL(response.data);
      const link = document.createElement('a');
      link.href = href;
      link.setAttribute('download', 'sintegra.txt');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      return null
    } catch (error) {
      const blob = error.response.data;
      
      const jsonData = JSON.parse(await new Response(blob).text())
      throw jsonData 
    }
  }

  async existsLogs() {
    const { data } = await api.get("/sintegra/check/exists-logs")
    return data
  }
}

export default new serviceSintegra()