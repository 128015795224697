import moment from "moment";
import { v4 } from 'uuid'

class AssigningValuesToTheObject {
  assigningValues (object) {
    const supplierTotal = {
      supplier: {
        id: "",
        nomeFantasia: "xFant" in object.fornecedor[0] ? object.fornecedor[0].xFant[0] : object.fornecedor[0]?.xNome[0],
        razaoSocial: "xNome" in object.fornecedor[0] ? object.fornecedor[0]?.xNome[0] : object.fornecedor[0].xFant[0],
        cpfCnpj: object.fornecedor[0]?.CNPJ[0] || "",
        ie: object.fornecedor[0]?.IE[0] || "",
        endereco: object.fornecedor[0].enderEmit[0]?.xLgr[0] || "",
        numero: object.fornecedor[0].enderEmit[0]?.nro[0] || "",
        bairro: object.fornecedor[0].enderEmit[0]?.xBairro[0] || "",
        cidade: object.fornecedor[0].enderEmit[0]?.xMun[0] || "",
        uf: object.fornecedor[0].enderEmit[0]?.UF[0] || "",
        telefone: "fone" in object.fornecedor[0].enderEmit[0] ? object.fornecedor[0].enderEmit[0]?.fone[0] : "",
      },
      products: [],
      purchase: {
        id: "",
        idEmpresa: "",
        idFornecedor: "",
        dataCompra: moment().format("YYYY-MM-DD"),
        numeroNfe: object.purchase[0].nNF[0],
        modeloNfe: object.purchase[0].mod[0],
        serieNfe: object.purchase[0].serie[0],
        dadosAdicionais: null,
        chaveNfe: object?.key[0],
        cfop: "",
        baseIcms: object.impostos[0]?.vBC[0],
        vIcms: object.impostos[0]?.vICMS[0],
        vIpi: object.impostos[0]?.vIPI[0],
        vOutros: object.impostos[0]?.vOutro[0],
        valorNfe: object.valorNfe,
        valorFrete: object.valorFrete
      }
    }

    supplierTotal.products = object.produtos.map((e) => {
      const icmsInfo = e.imposto[0].ICMS[0];
      let bcIcms, bcIcmsST, pIcms, cst;

      bcIcms = this.getPercentageIcms(icmsInfo, 'vBC');
      bcIcmsST = this.getPercentageIcms(icmsInfo, 'vBCST');
      pIcms = this.getPercentageIcms(icmsInfo, 'pICMS')
      cst = this.getCst(icmsInfo)

      return {
        id: v4(),
        nome: e.prod[0].xProd[0],
        valor: parseFloat(e.prod[0].vUnCom[0]),
        valorVenda: 0.0,
        valorVenda2: 0.0,
        valorVenda3: 0.0,
        margem1: 0.0,
        margem2: 0.0,
        margem3: 0.0,
        lucro: 0.0,
        frete: 0.0,
        encargos: 0.0,
        custoInicial: 0.0,
        unidade: "UND",
        estoque: e.prod[0].qCom[0],
        estoqueMin: 0,
        quantidade: e.prod[0].qCom[0],
        ncm: e.prod[0].NCM[0],
        desconto: "vDesc" in e.prod[0] ? e.prod[0].vDesc[0] : 0,
        bcIcms: bcIcms ? bcIcms : 0,
        bcIcmsST: bcIcmsST ? bcIcmsST : 0,
        pIcms: pIcms,
        bcIpi: "IPI" in e.imposto[0] ? "IPITrib" in e.imposto[0].IPI[0] ? e.imposto[0].IPI[0].IPITrib[0].vBC[0] : 0 : 0,
        cst,
        codigo: e.prod[0].cProd[0],
        status: "Ativo"
      };
    });

    return supplierTotal
  }

  ObjectPurchase (object) {
    const objectPurchase = {
      fornecedor: {},
      produtos: {},
      purchase: {},
      key: {},
      impostos: {},
      valorNfe: 0,
      valorFrete: 0,
    }

    objectPurchase.fornecedor = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].emit : object.data?.NFe.infNFe[0].emit
    objectPurchase.produtos = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].det : object.data?.NFe.infNFe[0].det
    objectPurchase.purchase = object.data.nfeProc ? object.data.nfeProc.NFe[0].infNFe[0].ide : object.data?.NFe.infNFe[0].ide
    objectPurchase.key = object.data.nfeProc?.protNFe[0]?.infProt[0]?.chNFe || 0
    objectPurchase.impostos = object.data.nfeProc ? object.data.nfeProc.NFe[0]?.infNFe[0]?.total[0].ICMSTot : object.data?.NFe.infNFe[0].total[0].ICMSTot

    objectPurchase.valorNfe = object.data.nfeProc ?
      Number(object.data.nfeProc.NFe[0]?.infNFe[0]?.total[0].ICMSTot[0].vNF[0]) :
      Number(object.data?.NFe.infNFe[0].total[0].ICMSTot[0].vNF[0])

    objectPurchase.valorFrete = object.data.nfeProc ?
      Number(object.data.nfeProc.NFe[0]?.infNFe[0]?.total[0].ICMSTot[0].vFrete[0]) :
      Number(object.data?.NFe.infNFe[0].total[0].ICMSTot[0].vFrete[0])

    return objectPurchase
  }

  getPercentageIcms (value, key) {
    const result = Object.keys(value).map(item => {
      return key in value[item][0] ? parseFloat(value[item][0][key][0]) : 0
    })[0]

    return result
  }

  getCst (cst) {
    return Object.keys(cst)[0].match(/\d+/g)[0]
  }
}

export default new AssigningValuesToTheObject()