<template>
  <b-card>
    <div style="font-size: 12px">
      <div class="title">
        <span>Informações da Transportadora</span>
        <span class="requiredFields">* Campos Obrigatórios</span>
      </div>
      <div class="rowContainer">
        <span>
          <b-icon-person-lines-fill></b-icon-person-lines-fill> Informações
          Gerais</span
        >
        <div class="rowContent">
          <b-form-input hidden class="col-sm-1"></b-form-input>

          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.nome"
            />
            <label class="user-label">
              Nome <span class="validRed">*</span></label
            >
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.cpfCnpj"
              v-mask="maskCpfCnpj"
              maxlength="18"
            />
            <label class="user-label">
              CPF / CNPJ <span class="validRed">*</span></label
            >
            <button
              class="bcsCep"
              @click="fetchCnpj(dataShippingCompany.cpfCnpj)"
            >
              <b-spinner
                v-if="spinGeneralCnpj"
                small
                label="Small Spinner"
              ></b-spinner>
              buscar
            </button>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.ie"
            />
            <label class="user-label"> Inscrição Estadual</label>
          </div>
        </div>
      </div>

      <div class="rowContainer">
        <span> <b-icon-geo-alt-fill></b-icon-geo-alt-fill> Localização</span>
        <div class="rowContent">
          <div class="input-group" style="width: 30%">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.endereco"
            />
            <label class="user-label"> Endereço </label>
          </div>
          <div class="input-group" style="width: 25%">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.bairro"
            />
            <label class="user-label"> Bairro </label>
          </div>
          <div class="input-group" style="width: 25%">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.complemento"
            />
            <label class="user-label"> Complemento </label>
          </div>
          <div class="input-group" style="width: 14.3%">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.numero"
            />
            <label class="user-label"> Nº </label>
          </div>
        </div>

        <div class="rowContent">
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.cidade"
            />
            <label class="user-label"> Cidade </label>
          </div>

          <div class="input-group" style="width: 20%">
            <input
              required
              class="input borderInput"
              maxlength="2"
              v-model="dataShippingCompany.uf"
            />
            <label class="user-label"> UF </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.cep"
              v-mask="maskCep"
            />
            <label class="user-label"> CEP </label>
            <button class="bcsCep" @click="fetchCep(dataShippingCompany.cep)">
              <b-spinner
                v-if="spinGeneral"
                small
                label="Small Spinner"
              ></b-spinner>
              buscar
            </button>
          </div>
        </div>
      </div>

      <div class="rowContainer">
        <span> <b-icon-telephone-fill /> Contatos</span>
        <div class="rowContent">
          <div class="input-group">
            <input
              required
              type="email"
              class="input borderInput"
              v-model="dataShippingCompany.email"
            />
            <label class="user-label"> Email </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.telefone"
              v-mask="maskTelefone"
            />
            <label class="user-label"> Telefone </label>
          </div>
          <div class="input-group">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.celular"
              v-mask="maskCelular"
            />
            <label class="user-label"> Celular </label>
          </div>
        </div>
      </div>

      <div class="rowContainer">
        <span> <b-icon-truck /> Veículo</span>
        <div class="rowContent">
          <div class="input-group" style="width: 15%">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.placa"
              maxlength="7"
            />
            <label class="user-label"> Placa </label>
          </div>
          <div class="input-group" style="width: 10%">
            <input
              required
              class="input borderInput"
              maxlength="2"
              v-model="dataShippingCompany.uf_veiculo"
            />
            <label class="user-label"> UF </label>
          </div>
          <div class="input-group" style="width: 15%">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.rntc"
              maxlength="20"
            />
            <label class="user-label"> RNTC </label>
          </div>
          <div class="input-group" style="width: 15%">
            <input
              required
              class="input borderInput"
              :value="maskCurrency(dataShippingCompany.seguro)"
              @input="calculateValue($event)"
            />
            <label class="user-label"> Seguro </label>
          </div>
          <div class="input-group" style="width: 37.4%">
            <input
              required
              class="input borderInput"
              v-model="dataShippingCompany.observacoes"
            />
            <label class="user-label"> Observações </label>
          </div>
        </div>
      </div>

      <div class="btnsContainer">
        <b-button class="btnsSaveNeutral" @click="clear"
          >Novo <b-icon-arrow-clockwise class="ml-1"
        /></b-button>
        <b-button class="btnsSave" @click="handleSaveOrUpdate"
          >{{ dataShippingCompany.id ? "Atualizar" : "Salvar" }}
          <b-icon-check2-circle class="ml-1"
        /></b-button>
      </div>
    </div>
  </b-card>
</template>

<script>
import api from "../../services/axios";
import ServiceShippingCompany from "../../services/serviceShippingCompany";
import { removeSpecialCharacters } from "../../utils/removeSpecialCharacters";
import { mascaraMoeda } from "../../utils/maskMoney";
import { INITIAL_STATE_SHIPPING_COMPANY } from "../../initialStates/shippingCompany";

export default {
  props: {
    shippingCompanyFound: {
      type: Object,
    },
  },
  components: {},
  data() {
    return {
      dataShippingCompany: {
        id: "",
        nome: "",
        cpfCnpj: "",
        ie: "",
        endereco: "",
        numero: "",
        complemento: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
        email: "",
        telefone: "",
        celular: "",
        observacao: "",
        placa: "",
        uf_veiculo: "",
        rntc: "",
        seguro: 0,
      },
      spinGeneral: false,
      spinGeneralCnpj: false,
      isNewRegistration: false,
    };
  },
  methods: {
    async save() {
      try {
        this.dataShippingCompany.id = await ServiceShippingCompany.save(
          this.dataShippingCompany
        );

        this.isNewRegistration = !this.isNewRegistration;
        this.$emit("newRegistration", this.isNewRegistration);
        this.$toast.open({
          message: "Registro salvo com sucesso !",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.erros ||
            "Ocorreu um erro ao salvar a transportadora",
          type: "info",
        });
      }
    },

    async update() {
      try {
        await ServiceShippingCompany.update(this.dataShippingCompany);

        this.isNewRegistration = !this.isNewRegistration;
        this.$emit("newRegistration", this.isNewRegistration);
        this.$toast.open({
          message: "Registro atualizado com sucesso !",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.erros ||
            "Ocorreu um erro ao atualizar a transportadora",
          type: "info",
        });
      }
    },

    handleSaveOrUpdate() {
      this.dataShippingCompany.id ? this.update() : this.save();
    },

    async fetchCep(cep) {
      try {
        this.spinGeneral = true;
        const formattedCep = removeSpecialCharacters(cep);

        if (!formattedCep || formattedCep.length !== 8) {
          return this.$toast.open({
            message: "CEP deve ser informado e deve ser um cep valido !",
            type: "info",
          });
        }

        const { data } = await api.get(
          `https://brasilapi.com.br/api/cep/v1/${formattedCep}`
        );

        this.dataShippingCompany.endereco = data.street;
        this.dataShippingCompany.bairro = data.neighborhood;
        this.dataShippingCompany.cidade = data.city;
        this.dataShippingCompany.uf = data.state;
        this.dataShippingCompany.cep = data.cep;
      } catch (error) {
        this.$toast.open({
          message:
            "Ocorreu um erro ao buscar as informações , verifique o CEP e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneral = false;
      }
    },

    async fetchCnpj(cnpj) {
      try {
        this.spinGeneralCnpj = true;
        const formatedCnpj = removeSpecialCharacters(cnpj);

        if (!formatedCnpj || formatedCnpj.length !== 14) {
          return this.$toast.open({
            message:
              "A busca funciona somente para CNPJ e não pode ser vazio !",
            type: "info",
          });
        }

        const { data } = await api.get(
          `https://brasilapi.com.br/api/cnpj/v1/${formatedCnpj}`
        );

        this.assignAfterFetchCnpj(data);
      } catch (error) {
        this.$toast.open({
          message:
            "Ocorreu um erro ao buscar as informações , verifique o CNPJ e tente novamente",
          type: "info",
        });
      } finally {
        this.spinGeneralCnpj = false;
      }
    },

    calculateValue(event) {
      this.dataShippingCompany.seguro = mascaraMoeda(event.target.value) || 0;
    },

    clear() {
      this.dataShippingCompany = JSON.parse(
        JSON.stringify(INITIAL_STATE_SHIPPING_COMPANY)
      );
    },

    assignAfterFetchCnpj(data) {
      if (data.nome_fantasia) {
        this.dataShippingCompany.nome = data.nome_fantasia;
      } else {
        this.dataShippingCompany.nome = data.razao_social;
      }

      this.dataShippingCompany.endereco = data.logradouro;
      this.dataShippingCompany.numero = data.numero;
      this.dataShippingCompany.bairro = data.bairro;
      this.dataShippingCompany.cidade = data.municipio;
      this.dataShippingCompany.uf = data.uf;
      this.dataShippingCompany.telefone = data.ddd_telefone_1;
      this.dataShippingCompany.cep = data.cep;
    },
    maskCurrency(valor, locale = "pt-BR") {
      if (!valor) {
        valor = 0;
      }

      return parseFloat(valor).toLocaleString(locale, {
        style: "currency",
        currency: "BRL",
      });
    },
  },
  computed: {
    maskCpfCnpj() {
      if (!this.dataShippingCompany.cpfCnpj) {
        return "";
      }

      if (this.dataShippingCompany.cpfCnpj.length <= 14) {
        return "###.###.###-##";
      }
      
      if (this.dataShippingCompany.cpfCnpj.length > 14) {
        return "##.###.###/####-##";
      } else {
        return "";
      }
    },
    maskCelular() {
      return "(##) #####-####";
    },
    maskTelefone() {
      return "(##) ####-####";
    },
    maskCep() {
      return "#####-###";
    },
  },
  watch: {
    shippingCompanyFound() {
      if (
        this.shippingCompanyFound &&
        Object.keys(this.shippingCompanyFound).length &&
        this.shippingCompanyFound.id
      ) {
        Object.assign(this.dataShippingCompany, this.shippingCompanyFound);
      }
    },
  },
};
</script>
<style scoped>
.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.25rem;
  font-size: 12px;
  line-height: 1.3;
  border-radius: 0.2rem;
}
span {
  font-weight: bold;
  cursor: context-menu;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
  font-weight: 600;
}

.selectPlus {
  position: relative;
  width: 30%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 30px;
  left: 0px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: transparent;
}
.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 11px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 11px !important;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: none;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.validRed {
  font-size: 13px;
  color: red;
}

.bcsCep {
  display: flex;
  height: 30px;
  position: absolute;
  right: 0px;
  font-size: 11px;
  z-index: 2;
  cursor: pointer;
  color: black;
  border-radius: 5px;
  border: none;
  background: #c3c3c3;
  align-items: center;
}

.title {
  font-size: 16px;
  color: rgb(95, 95, 95);
  display: flex;
  margin-bottom: 20px;
  border-bottom: 1px solid rgb(230, 230, 230);
  justify-content: space-between;
}

.title span {
  font-weight: 600;
}

.requiredFields {
  font-size: 11px;
  color: red;
}

.rowContainer {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 5px;
}

.rowContainer span {
  font-weight: 600;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  gap: 20px;
}

.btnsContainer {
  gap: 20px;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.btnsSave {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: white;
  font-size: 12px;

  font-weight: 600;
}

.btnsSaveNeutral {
  border-radius: 5px;
  background-color: #d7d7d7;
  width: 165px;
  border: none;
  margin-top: 30px;
  color: rgb(0, 0, 0);
  font-size: 12px;

  font-weight: 600;
}

.input-group {
  width: 100%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 0 0 0;
}

.card {
  border: none !important;
}

@media screen and (max-width: 992px) {
  .rowContent {
    flex-wrap: wrap;
    flex-direction: column;
  }

  .input-group {
    width: 100% !important;
  }
}
</style>
