import { render, staticRenderFns } from "./SupplierTable.vue?vue&type=template&id=1579120e&scoped=true&"
import script from "./SupplierTable.vue?vue&type=script&lang=js&"
export * from "./SupplierTable.vue?vue&type=script&lang=js&"
import style0 from "./SupplierTable.vue?vue&type=style&index=0&id=1579120e&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1579120e",
  null
  
)

export default component.exports