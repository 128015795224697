import api from "./axios";

class ServiceNatureOps {
  async save(nature) {
    const { data } = await api.post("/nature", nature)
    return data.id;
  }

  async update(nature) {
    await api.put(`/nature/${nature.id}`, nature);
  }

  async delete(id) {
    await api.delete(`/nature/${id}`);
  }

  async findById(id) {
    const { data } = await api.get(`/nature/${id}`);
    return data;
  }

  async fillComobobox() {
    const { data } = await api.get(`/nature/combobox`);
    return data;
  }
}

export default new ServiceNatureOps()