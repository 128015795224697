<template>
  <div class="contentGeneral">
    <div class="contentTitle">
      <p class="title">{{ title }}</p>
    </div>

    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 contentCards">
      <b-card class="pb-4">
        <div class="contentGeneralTitle">
          <p class="titleLauchInformation">Informações do lançamento</p>
        </div>

        <div class="contentDivider">
          <div class="divider"></div>
        </div>

        <b-row class="mt-3 cards col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <div class="col-sm-4 col-md-4 col-lg-3 col-xl-2 mb-3">
            <Input
              label="Data de Competência"
              type="date"
              :dataInput.sync="dataBill.dataCompetencia"
              :labelFixed="true"
              ref="dataCompetencia"
            />
          </div>

          <div
            v-if="isProvider"
            class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3"
          >
            <InputSelect
              label="Fornecedor"
              :items="allSupplier"
              valueField="id"
              textField="nomeFantasia"
              :dataSelect.sync="dataBill.idFornecedor"
              :openCreateModal="() => openModals('modalCreateSupplier')"
              ref="refFornecedor"
              @changed="makeInstallments"
            />
          </div>

          <div v-else class="col-sm-6 col-md-6 col-lg-4 col-xl-3 mb-3">
            <InputInfinit
              label="Cliente"
              :isEdit="dataBill.idCliente"
              :dataInput.sync="dataBill.idCliente"
              :onSearch="fillCustomerComboBoxWithPaginate"
              :openCreateModal="() => openModals('modalCreateCustomer')"
              :findAll="createdNewCustomer"
              @selectItem="handleDataCustomers($event)"
            />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-4 mb-3">
            <Input
              :label="
                typeInstallmentSelected !== 1
                  ? 'Observações Gerais'
                  : 'Descrição'
              "
              type="text"
              :dataInput.sync="dataBill.descricao"
              :labelFixed="true"
              ref="refDescricao"
            />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-4 col-xl-1 mb-3">
            <InputMoney
              label="Valor"
              type="text"
              ref="refValorTotal"
              :dataInput.sync="dataBill.valorTotal"
              :labelFixed="true"
            />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-2 mb-3">
            <Input
              label="Código de referência"
              type="text"
              :dataInput.sync="dataBill.codigoReferencia"
              :labelFixed="true"
              ref="refCodigoReferencia"
            />
          </div>

          <div
            v-if="isProvider"
            class="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
          >
            <InputSelect
              label="Categoria"
              valueField="id"
              textField="nome"
              :items="allCategoryBills"
              :dataSelect.sync="dataBill.idCategoria"
              :openCreateModal="() => openModals('modalCategoriaConta')"
              ref="refCategoria"
            />
          </div>

          <div
            v-if="isProvider"
            class="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3"
          >
            <Input
              label="Número nota fiscal"
              type="text"
              :dataInput.sync="dataBill.numNfe"
              :labelFixed="true"
              ref="numNfe"
            />
          </div>
        </b-row>
      </b-card>

      <b-card>
        <div
          v-if="!dataBill.id"
          style="
            margin-left: 32px;
            width: 100%;
            display: flex;
            align-items: center;
            position: relative;
          "
        >
          <div>
            <p class="repeatlaunch" style="margin: 0px; color: #666360">
              Repetir lançamento?
            </p>
          </div>

          <label
            style="margin-bottom: 0px"
            ref="hideDiscount"
            class="switch col-sm-2 col-md-2 col-lg-2 col-xl-2"
          >
            <input
              type="checkbox"
              ref="typeDiscount"
              v-model="activateMultipleReleases"
              @change="handleRepeatAndInstallments"
            />
            <div class="slider">
              <span style="font-size: 14px">✘</span>
              <span style="font-size: 14px">✔</span>
            </div>
          </label>
        </div>

        <div class="contentGeneralTitle mt-3">
          <p class="titleLauchInformation">Condição de pagamento</p>
        </div>

        <div class="contentDivider">
          <div class="divider"></div>
        </div>

        <b-row
          class="conditionPay cards mt-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
        >
          <div class="fistBill col-sm-4 col-md-2 col-lg-2 col-xl-2 mb-3">
            <Input
              :label="
                !activateMultipleReleases && typeInstallmentSelected !== 1
                  ? 'Venc. 1º parcela'
                  : 'Vencimento'
              "
              :labelFixed="true"
              type="date"
              :dataInput.sync="dataBill.data"
              ref="dataVencimento"
            />
          </div>

          <div class="col-sm-6 col-md-6 col-lg-3 col-xl-3 mb-3">
            <div class="selectPlus">
              <label class="fixedSelect">Forma de pagamento</label>
              <b-form-select
                value-field="id"
                text-field="tipo"
                class="borderInput shadow-none"
                :options="listTypesPaymentsSelectBox"
                v-model="dataBill.idFormaPagamento"
                size="sm"
                @change="selectPayment"
              >
              </b-form-select>
            </div>
          </div>

          <div class="receiptAccount col-sm-6 col-md-4 col-lg-3 col-xl-3 mb-3">
            <InputSelect
              :label="`${label}`"
              valueField="id"
              textField="nome"
              :items="allAccountReceiptBills"
              :dataSelect.sync="dataBill.idContaRecebimento"
              :isDisabled="this.blocked"
              :openCreateModal="() => openModals('modalContaRecebimento')"
              ref="refContaDeRecebimento"
              @changed="
                handleAlterReceiptAccountBills(dataBill.idContaRecebimento)
              "
            />
          </div>

          <div
            class="col-sm-6 col-md-3 col-lg-2 col-xl-2 mb-3"
            :hidden="handleShowInstallments"
          >
            <div class="selectPlus">
              <label class="fixedSelect">Parcelamento</label>
              <b-form-select
                value-field="value"
                text-field="text"
                class="borderInput shadow-none"
                :options="typeInstallment"
                v-model="typeInstallmentSelected"
                size="sm"
                @change="handleTypeInstallmentsSelected"
              >
              </b-form-select>
            </div>
          </div>

          <div
            v-if="
              (activateMultipleReleases && !fixedSalary) ||
              typeInstallmentSelected !== 1
            "
            :class="{ disabledNone: fixedSalary }"
            class="paymentInterval col-sm-3 col-md-4 col-lg-3 col-xl-1 mb-3"
          >
            <Input
              label="Intervalo"
              :labelFixed="true"
              type="text"
              v-if="!fixedSalary"
              :dataInput.sync="intervaloDias"
              @changed="makeInstallments"
            />
          </div>

          <div
            v-if="activateMultipleReleases"
            class="amountBills col-sm-6 col-md-3 col-lg-2 col-xl-1"
          >
            <Input
              label="Parcelas"
              :labelFixed="true"
              type="text"
              :dataInput.sync="totalParcelas"
              ref="refTotalParcelas"
            />
          </div>

          <div
            style="padding: 0 !important"
            class="col-sm-6 col-md-3 col-lg-2 col-xl-2"
          >
            <label class="custom-checkbox">
              <input v-model="fixedSalary" name="dummy" type="checkbox" />
              <span class="checkmark"></span>
              Vencimento fixo?
            </label>
          </div>
        </b-row>

        <div class="mt-5 contentTab" v-if="typeInstallmentSelected === 1">
          <div class="tabs">
            <button
              :class="`customTabs ${tabs ? 'tabSelected' : ''}`"
              name="observation"
              @click="selectTab"
            >
              Observação
            </button>

            <button
              :class="`customTabs ${!tabs ? 'tabSelected' : ''}`"
              name="attachment"
              @click="selectTab"
            >
              Anexo
            </button>
          </div>

          <div :hidden="!tabs" class="contentDescription">
            <div class="description">
              <p>Observações</p>
            </div>

            <textarea
              class="inputTextArea"
              rows="3"
              max-rows="6"
              v-model="dataBill.observacoes"
            />
            <p class="descriptionObs">
              Descreva aqui observações importantes referente a esse lançamento
            </p>
          </div>

          <div :hidden="tabs" style="padding-bottom: 20px">
            <AttachFiles
              ref="attachedFiles"
              @removeFile="handleRemoveFile($event)"
            />
          </div>
        </div>
      </b-card>

      <b-card
        class="contentInstallments"
        v-if="!activateMultipleReleases && typeInstallmentSelected !== 1"
      >
        <div class="contentGeneralTitle">
          <p class="titleLauchInformation">Parcelas</p>
        </div>

        <div class="contentDivider">
          <div class="divider"></div>
        </div>

        <b-row class="mt-3 cards col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <Installments
            :amountBills="installments"
            :totalValue="dataBill.valorTotal"
            :confirmedInstallments="confirmedInstallments"
            :idAccountReceipt="idAccountReceiptFromInstallments"
            :idTypePayment="idTypePaymentFromInstallments"
            :isProvider="isProvider"
            :paymentTypes="listTypesPaymentsSelectBox"
            :receiptAccounts="allAccountReceiptBills"
            @changed="installments = $event"
          />
        </b-row>
      </b-card>
    </div>

    <div :class="actionBarFixed ? 'contentGeneralButtons' : 'mt-3'">
      <div class="contentButtons">
        <div>
          <button class="btnsFinancial btnBack" @click="goBack">Voltar</button>
        </div>

        <div>
          <button
            :disabled="spinGeneral"
            class="btnsFinancial btnConfirm"
            @click="saveAndUpdateBill"
          >
            <b-spinner v-if="spinGeneral" class="spin"></b-spinner>
            Salvar
          </button>
        </div>
      </div>
    </div>

    <ModalCreateCustomer
      @getAllNewCustomer="handleCreatedNewCustomer"
      :idCustomerFromSearch="dataBill.idCliente"
    />
    <ModalCreateSupplier
      @getAllNewSupplier="getAllSuppliers"
      :idSupplierFromSearch="dataBill.idFornecedor"
    />
    <ModalCreateAccountReceiptBill @getNewReceiptBill="getAllReceiptBill" />
    <ModalCreateCategoryBill
      v-if="isProvider"
      @getNewCategoryBill="getAllCategoryBill"
    />
  </div>
</template>

<script>
import ModalCreateCustomer from "../Modals/ModalCreateCustomer.vue";
import ModalCreateSupplier from "../Modals/ModalCreateSupplier.vue";
import ModalCreateAccountReceiptBill from "../Modals/ModalCreateAccountReceiptBill.vue";
import ModalCreateCategoryBill from "../Modals/ModalCreateCategoryBill.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import InputInfinit from "../UI/CustomInputs/InputInfinit.vue";
import Installments from "../Bill/Installments.vue";
import AttachFiles from "../AttachFiles/AttachFiles.vue";

import api from "../../services/axios";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ServiceBill from "../../services/serviceBill";
import ServiceBillFile from "../../services/serviceBillFile";
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServiceCategoryBill from "../../services/serviceCategoryBill";
import ServiceSupplier from "../../services/serviceSupplier";
import ServiceCustomer from "../../services/serviceCustomer";

import { tipoArquivo, typesInstallment } from "../../common/index";
import { INITIAL_STATE_FILE } from "../../initialStates/files";
import toastAlertErros from "../../utils/toastAlertErros";

import { mapMutations, mapState } from "vuex";
import serviceSettings from "../../services/serviceSettings";

export default {
  components: {
    ModalCreateCustomer,
    ModalCreateSupplier,
    ModalCreateAccountReceiptBill,
    ModalCreateCategoryBill,
    InputSelect,
    Input,
    InputMoney,
    InputInfinit,
    AttachFiles,
    Installments,
  },
  props: {
    searchAccountsReceive: {
      type: Object,
    },
    title: {
      type: String,
      default: "Nova Receita",
    },
    type: {
      type: String,
      default: "entrada",
    },
    label: {
      type: String,
      default: "Conta de Recebimento",
    },
    isProvider: {
      type: Boolean,
      default: false,
    },
    actionBarFixed: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      spinGeneral: false,
      blocked: false,
      dataBill: {
        id: "",
        tipo: this.type,
        idCliente: null,
        idFornecedor: null,
        idFuncionario: null,
        idFormaPagamento: null,
        idContaRecebimento: null,
        idCategoria: null,
        valorTotal: 0.0,
        valorPago: 0.0,
        valorRestante: 0.0,
        data: moment().format("YYYY-MM-DD"),
        dataCompetencia: moment().format("YYYY-MM-DD"),
        descricao: "",
        observacoes: "",
        codigoReferencia: "",
        numNfe: "",
        arquivos: [
          {
            id: "",
            idConta: "",
            file: "",
            tipoArquivo: "",
            descricao: "",
            keyAws: "",
            urlArquivo: "",
          },
        ],
      },
      intervaloDias: 30,
      totalParcelas: 1,
      tabs: true,
      typeFile: tipoArquivo,
      activateMultipleReleases: false,
      fixedSalary: false,
      listTypesPaymentsSelectBox: [],
      typeInstallment: typesInstallment,
      dataCustomers: [],
      listEmployeeSelectBox: [],
      allAccountReceiptBills: [],
      allCategoryBills: [],
      allSupplier: [],
      typeInstallmentSelected: 1,
      installments: [],
      confirmedInstallments: false,
      idAccountReceiptFromInstallments: null,
      idTypePaymentFromInstallments: null,
      createdNewCustomer: false,
      typeCashier: "",
      idSimpleCashier: "",
    };
  },
  methods: {
    ...mapMutations([
      "ALTER_SIZE_MODAL_FINANCIAL",
      "SET_COLAPSE_FINANCIAL",
      "SET_DATA_SEARCH_FROM_BILL",
      "SET_IS_SEARCH_BILLS",
    ]),

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    goBack() {
      this.ALTER_SIZE_MODAL_FINANCIAL("disable");
      this.SET_COLAPSE_FINANCIAL(false);
      this.SET_DATA_SEARCH_FROM_BILL({});
    },

    selectPayment() {
      this.blocked = false;
      const moneyPayment = this.listTypesPaymentsSelectBox.find(
        (formPayment) => formPayment.id === this.dataBill.idFormaPagamento
      );

      if (moneyPayment.tipo === "Dinheiro") {
        this.blocked = true;
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );

        this.dataBill.idContaRecebimento = accountPayment.id;
        this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }

      if (this.typeCashier) {
        this.blocked = true;
      }
      this.handleAlterTypePaymentBill(this.dataBill.idFormaPagamento);
    },

    saveAndUpdateBill() {
      this.dataBill.id !== "" ? this.updateBill() : this.saveBill();
    },

    async saveBill() {
      try {
        this.spinGeneral = true;
        if (
          this.activateMultipleReleases ||
          this.typeInstallmentSelected !== 1
        ) {
          const bills = this.handleRepeatReleaseOrInstallments();
          const billsWithFiles = this.assingFilesToBills(bills);

          const formData = new FormData();
          if (this.getFiles) {
            this.getFiles.forEach(({ file }) => formData.append(`files`, file));
          }
          formData.append(`data`, JSON.stringify(billsWithFiles));
          await ServiceBill.save(formData);

          this.SET_IS_SEARCH_BILLS();
          this.goBack();

          return this.$toast.open({
            message: "Lançamentos realizados com sucesso!",
            type: "success",
          });
        } else {
          const formData = this.handleFormatBillsAndReturnFormData();
          await ServiceBill.save(formData);

          this.SET_IS_SEARCH_BILLS();
          this.goBack();

          return this.$toast.open({
            message: "Conta resgistrada!",
            type: "success",
          });
        }
      } catch (error) {
        console.log(error);
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else if (error.response.data.erros.length) {
          return toastAlertErros.validateErro(error, this.$toast);
        } else toastAlertErros.validateMessage(error, this.$toast);
      } finally {
        this.spinGeneral = false;
      }
    },

    async updateBill() {
      try {
        const formData = this.handleFormatBillsAndReturnFormData();
        await ServiceBill.update(this.dataBill.id, formData);

        this.SET_IS_SEARCH_BILLS();
        this.goBack();

        return this.$toast.open({
          message: "Conta Atualizada!",
          type: "success",
        });
      } catch (error) {
        const message = error?.response?.data?.message;
        if (message && message.includes("NOT_ALLOWED"))
          this.$toast.open({
            message: message.split(":")[1],
            type: "info",
          });
        else if (error.response.data.erros.length) {
          return toastAlertErros.validateErro(error, this.$toast);
        } else toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async fillCustomerComboBoxWithPaginate(id, q, filterType) {
      try {
        return await ServiceCustomer.fillComboBoxWithPaginate(
          id,
          q,
          filterType
        );
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },

    handleDataCustomers(event) {
      this.dataCustomers = [event];
    },

    handleCreatedNewCustomer() {
      this.createdNewCustomer = !this.createdNewCustomer;
    },

    async getEmployeesForSelectBox() {
      const { data } = await api.get("/employees/combobox/fill");
      this.listEmployeeSelectBox = data.data;
    },

    async getTypesPaymentsSelectBox() {
      try {
        const { data } = await api.get("/payments/combobox");
        this.listTypesPaymentsSelectBox = data.sort((a, b) => a.cod - b.cod);
      } catch (error) {
        console.log(error);
      }
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.allAccountReceiptBills = result;
      const accountPayment = this.allAccountReceiptBills.find(
        (account) => account.nome === "CAIXA GAVETA"
      );
      if (this.typeCashier) {
        this.blocked = true;
        this.dataBill.idContaRecebimento = accountPayment.id;
        this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }
    },

    async getAllCategoryBill() {
      const result = await ServiceCategoryBill.fillComobobox();
      this.allCategoryBills = result;
    },

    async getAllSuppliers() {
      const result = await ServiceSupplier.fillComobobox();
      this.allSupplier = result;
    },

    async handleRemoveFile(idFile) {
      await ServiceBillFile.deleteFile(idFile);
    },

    handleRepeatReleaseOrInstallments() {
      if (this.activateMultipleReleases) {
        if (this.fixedSalary) {
          return this.generateFixedReleases();
        } else {
          return this.generateMultipleReleases();
        }
      }

      return this.installments;
    },

    makeInstallments() {
      if (this.typeInstallmentSelected !== 1) {
        if (!this.dataBill.data) {
          this.dataBill.data = this.dataBill.dataCompetencia;
          this.$refs.dataVencimento.valueInput = this.dataBill.dataCompetencia;
        }

        this.installments = [];

        const formattedTotalValue = this.dataBill.valorTotal;

        const valorPorDuplicata = (
          formattedTotalValue / this.typeInstallmentSelected
        )
          .toFixed(2)
          .toLocaleString("pt-br", { minimumFractionDigits: 2 });

        if (this.fixedSalary) {
          for (let i = 0; i < this.typeInstallmentSelected; i++) {
            const dataVencimento =
              i === 0
                ? this.dataBill.data
                : moment(this.dataBill.data)
                    .add(i, "months")
                    .format("YYYY-MM-DD");

            this.installments.push({
              id: "",
              tipo: this.type,
              idCliente: this.dataBill.idCliente,
              idFornecedor: this.dataBill.idFornecedor,
              idCategoria: this.dataBill.idCategoria,
              idFuncionario: this.dataBill.idFuncionario,
              idFormaPagamento: this.dataBill.idFormaPagamento,
              valorTotal: valorPorDuplicata,
              valorPago: 0.0,
              valorRestante: valorPorDuplicata,
              data: dataVencimento,
              dataCompetencia: moment().format("YYYY-MM-DD"),
              dataPagamento: null,
              descricao: "",
              numNfe: this.dataBill.numNfe,
              observacoes: this.dataBill.descricao,
              idContaRecebimento: this.dataBill.idContaRecebimento,
            });
          }
        } else {
          for (let i = 0; i < this.typeInstallmentSelected; i++) {
            const dataVencimento =
              i == 0
                ? this.dataBill.data
                : moment(dataVencimento)
                    .add(this.intervaloDias, "days")
                    .format("YYYY-MM-DD");
            this.installments.push({
              id: "",
              tipo: this.type,
              idCliente: this.dataBill.idCliente,
              idFornecedor: this.dataBill.idFornecedor,
              idCategoria: this.dataBill.idCategoria,
              idFuncionario: this.dataBill.idFuncionario,
              idFormaPagamento: this.dataBill.idFormaPagamento,
              valorTotal: valorPorDuplicata,
              valorPago: 0.0,
              valorRestante: valorPorDuplicata,
              data: dataVencimento,
              dataCompetencia: moment().format("YYYY-MM-DD"),
              dataPagamento: null,
              descricao: "",
              numNfe: this.dataBill.numNfe,
              observacoes: this.dataBill.descricao,
              idContaRecebimento: this.dataBill.idContaRecebimento,
            });
          }
        }
      }
    },

    handleRepeatAndInstallments() {
      this.typeInstallmentSelected = 1;
    },

    handleTypeInstallmentsSelected() {
      this.makeInstallments();
      this.confirmedInstallments = !this.confirmedInstallments;
    },

    handleAlterReceiptAccountBills(idReceipAccount) {
      this.idAccountReceiptFromInstallments = idReceipAccount;
    },

    handleAlterTypePaymentBill(idTypePayment) {
      this.idTypePaymentFromInstallments = idTypePayment;
    },

    formatDate() {
      this.searchAccountsReceive.data = moment(
        this.searchAccountsReceive.data
      ).format("YYYY-MM-DD");

      if (this.searchAccountsReceive.dataPagamento !== null)
        this.searchAccountsReceive.dataPagamento = moment(
          this.searchAccountsReceive.dataPagamento
        ).format("YYYY-MM-DD");
      else return;
    },

    changeValueUsingKeyUpEvent() {
      const changedValue = this.dataBill.valorTotal - this.dataBill.valorPago;

      this.dataBill.valorRestante = changedValue;
    },

    selectTab(event) {
      if (event.target.name === "attachment") {
        this.tabs = false;
      } else {
        this.tabs = true;
      }
    },

    generateMultipleReleases() {
      const bills = [];
      for (let i = 0; i < this.totalParcelas; i++) {
        const dataVencimento =
          i == 0
            ? this.dataBill.data
            : moment(dataVencimento)
                .add(this.intervaloDias, "days")
                .format("YYYY-MM-DD");

        bills.push({
          id: "",
          tipo: this.type,
          idCliente: this.dataBill.idCliente,
          idFornecedor: this.dataBill.idFornecedor,
          idFuncionario: this.dataBill.idFuncionario,
          idFormaPagamento: this.dataBill.idFormaPagamento,
          valorTotal: this.dataBill.valorTotal,
          valorPago: 0.0,
          valorRestante: this.dataBill.valorTotal,
          data: dataVencimento,
          dataCompetencia: moment().format("YYYY-MM-DD"),
          dataPagamento: null,
          idCategoria: this.dataBill.idCategoria,
          codigoReferencia: this.dataBill.codigoReferencia,
          descricao: this.dataBill.descricao,
          observacoes: this.dataBill.observacoes,
          numNfe: this.dataBill.numNfe,
          idContaRecebimento: this.dataBill.idContaRecebimento,
        });
      }

      return bills;
    },

    generateFixedReleases() {
      const bills = [];

      for (let i = 0; i < this.totalParcelas; i++) {
        const dataVencimento =
          i === 0
            ? this.dataBill.data
            : moment(this.dataBill.data).add(i, "months").format("YYYY-MM-DD");

        bills.push({
          id: "",
          tipo: this.type,
          idCliente: this.dataBill.idCliente,
          idFornecedor: this.dataBill.idFornecedor,
          idFuncionario: this.dataBill.idFuncionario,
          idFormaPagamento: this.dataBill.idFormaPagamento,
          valorTotal: this.dataBill.valorTotal,
          valorPago: 0.0,
          valorRestante: this.dataBill.valorTotal,
          data: dataVencimento,
          dataCompetencia: moment().format("YYYY-MM-DD"),
          dataPagamento: null,
          idCategoria: this.dataBill.idCategoria,
          codigoReferencia: this.dataBill.codigoReferencia,
          descricao: this.dataBill.descricao,
          observacoes: this.dataBill.observacoes,
          numNfe: this.dataBill.numNfe,
          idContaRecebimento: this.dataBill.idContaRecebimento,
        });
      }

      return bills;
    },

    handleFormatBillsAndReturnFormData() {
      const formattedBills = this.formatBillsFromSaveOrUpdate();

      const formData = new FormData();
      this.getFiles.forEach(({ file }) => formData.append(`files`, file));
      formData.append(`data`, JSON.stringify({ ...formattedBills }));

      return formData;
    },

    formatBillsFromSaveOrUpdate() {
      const newDataBills = {
        ...this.dataBill,
        arquivos: this.getFiles,
        valorPago: this.dataBill.valorPago,
        valorRestante: this.dataBill.id
          ? this.dataBill.valorRestante
          : this.dataBill.valorTotal,
        valorTotal: this.dataBill.valorTotal,
      };

      const newFilesBills = newDataBills.arquivos.map((file) => {
        return {
          ...file,
        };
      });

      const formattedFileBills = newFilesBills.filter((file) => {
        if (file.tipoArquivo || file.file) {
          Reflect.deleteProperty(file, "idList");
          Reflect.deleteProperty(file, "file");

          return {
            ...file,
            idConta: this.dataBill.id,
          };
        }
      });

      newDataBills.arquivos = formattedFileBills;
      return newDataBills;
    },

    handleRefsAfterSearchBill() {
      if (!this.dataSearchFromBill.idCliente) {
        this.$refs.refFornecedor.valueItems =
          this.dataSearchFromBill.idFornecedor;
        this.$refs.refCategoria.valueItems =
          this.dataSearchFromBill.idCategoria;
      }

      this.dataBill.dataCompetencia =
        this.dataSearchFromBill.dataCompetencia.split(" ")[0];
      this.$refs.dataCompetencia.valueInput =
        this.dataSearchFromBill.dataCompetencia.split(" ")[0];
      this.dataBill.data = this.dataSearchFromBill.data.split(" ")[0];
      this.$refs.dataVencimento.valueInput =
        this.dataSearchFromBill.data.split(" ")[0];

      this.$refs.refDescricao.valueInput = this.dataSearchFromBill.descricao;
      this.$refs.refCodigoReferencia.valueInput =
        this.dataSearchFromBill.codigoReferencia;

      this.dataBill.idFormaPagamento = this.dataSearchFromBill.idFormaPagamento;
      this.$refs.refContaDeRecebimento.valueItems =
        this.dataSearchFromBill.idContaRecebimento;
      this.$refs.refValorTotal.valueInput = this.dataSearchFromBill.valorTotal;

      if (this.$refs.numNfe)
        this.$refs.numNfe.valueInput = this.dataSearchFromBill.numNfe;

      if (this.dataBill.arquivos.length) {
        this.$refs.attachedFiles.files = this.dataBill.arquivos;

        this.$nextTick(() => {
          this.$refs.attachedFiles.files.forEach((file, index) => {
            this.$refs.attachedFiles.$refs.refTipoArquivo[index].valueItems =
              file.tipoArquivo;

            this.$refs.attachedFiles.$refs.refDescricao[index].valueInput =
              file.descricao;
          });
        });
      }
    },

    async assigningSearchValuesToTheBill() {
      if (!this.dataSearchFromBill?.arquivos?.length) {
        const idList = uuidv4();
        const arquivos = [{ ...INITIAL_STATE_FILE, idList }];

        Object.assign(this.dataBill, { ...this.dataSearchFromBill, arquivos });
      } else {
        const arquivos = this.dataSearchFromBill.arquivos.map((file) => {
          const idList = uuidv4();

          return {
            ...file,
            idList,
          };
        });

        Object.assign(this.dataBill, { ...this.dataSearchFromBill, arquivos });
      }
      this.handleRefsAfterSearchBill();
    },

    assignValuesToThePurchase() {
      if (
        this.dataSearchFromBill.idFornecedor &&
        this.dataSearchFromBill.valorTotal
      ) {
        this.dataBill.idFornecedor = this.dataSearchFromBill.idFornecedor;
        this.dataBill.valorTotal = this.dataSearchFromBill.valorTotal;
        this.dataBill.descricao = this.dataSearchFromBill.descricao;
        this.dataBill.numNfe = this.dataSearchFromBill.numNfe;

        this.$refs.refDescricao.valueInput = this.dataSearchFromBill.descricao;
        this.$refs.refFornecedor.valueItems =
          this.dataSearchFromBill.idFornecedor;
        this.$refs.refValorTotal.valueInput =
          this.dataSearchFromBill.valorTotal;
      } else if (
        this.dataSearchFromBill.idCliente &&
        this.dataSearchFromBill.valorTotal
      ) {
        this.dataBill.idCliente = this.dataSearchFromBill.idCliente;
        this.dataBill.valorTotal = this.dataSearchFromBill.valorTotal;

        this.$refs.refValorTotal.valueInput =
          this.dataSearchFromBill.valorTotal;
      }
    },

    assingFilesToBills(bills) {
      return bills.map((bill) => {
        return {
          ...bill,
          arquivos: this.dataBill.arquivos,
        };
      });
    },

    async getConfig() {
      const result = await serviceSettings.findSettingsAtm();

      if (Object.keys(result).length) {
        this.typeCashier = result.caixa_simples;
      }
    },
  },

  computed: {
    ...mapState({
      dataSearchFromBill: (state) => state.dataSearchFromBill,
    }),
    getFiles() {
      if (this.$refs.attachedFiles) return this.$refs.attachedFiles.files;
      else return null;
    },
    handleShowInstallments() {
      if (this.activateMultipleReleases) return true;
      else if (this.dataBill.id) return true;
      else return false;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
  mounted() {
    this.dataBill.arquivos[0].idList = uuidv4();
    this.getTypesPaymentsSelectBox();
    this.getConfig();
    this.getEmployeesForSelectBox();
    this.getAllReceiptBill();
    this.getAllCategoryBill();
    if (this.isProvider) {
      this.getAllSuppliers();
    }

    if (this.dataSearchFromBill.id) {
      this.assigningSearchValuesToTheBill();
    }

    this.assignValuesToThePurchase();
  },
  watch: {
    searchAccountsReceive() {
      if (this.searchAccountsReceive.tipo === "entrada") {
        this.formatDate();
        Object.assign(this.dataBill, this.searchAccountsReceive);
      } else {
        return;
      }
    },
  },
};
</script>

<style scoped>
#dadosContasStyle {
  border: 1px solid rgb(45, 129, 6);
  color: black !important;
}

.iconFormaPagamentoReceber {
  display: flex;
  justify-content: space-between;
}

.btnFormaPagamentoReceber {
  cursor: pointer;
}

.tamanhoCardsContasReceber {
  width: 100% !important;
  display: flex;
  color: black;
  background-color: #ffffff !important;
}

.disabledNone {
  display: none;
}

.tamanhoBotaoOpenCardContas {
  color: rgb(54, 180, 12);
  font-size: 14px;
  height: 30px;
  display: flex;
  align-items: center;
}

.contentCards {
  padding: 1rem !important;
  font-size: 12px;
  background-color: whitesmoke !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-control-sm {
  height: 28px;
  padding: 0.25rem 0.5rem;
  font-size: 12px;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.custom-select-sm {
  height: 28px;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 12px;
}

.form-group {
  margin-bottom: 0.4rem;
}
.input:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTotals input:hover {
  background: #d9d9d9 !important;
  border: 1px solid #262626;
}
.custom-select:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.textarea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTextArea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.contentGeneral {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contentTitle {
  width: 100%;
  padding: 10px;
}

.title {
  margin: 0 !important;
  color: #666360;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.card-body {
  padding-bottom: 14px !important;
  padding-top: 20px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.cards {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.contentGeneralTitle {
  margin-left: 32px;
}

.titleLauchInformation {
  margin: 0 !important;
  color: #666360;
  font-size: 14px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  letter-spacing: 0.3px;
}

.contentDivider {
  padding: 8px 30px;
}

.conditionPay {
  display: flex;
  flex-wrap: nowrap;
}

.divider {
  width: 100%;
  border-bottom: solid 1px #6663605a;
}

.switch {
  font-size: 14px;
}

.switch input:checked + .slider {
  background-color: #d9d9d9;
  color: white;
}

.switch input:checked + .slider:before {
  background-color: #00aa62;
  transform: translateX(100%);
  color: white;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: -12px;
  left: 10px;
  bottom: 0;
  background-color: #d9d9d9;
  color: black;
  border-radius: 50px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 55px !important;
  height: 27px;
  font-size: 8px;
}

.slider:before {
  position: absolute;
  content: "";
  font-size: 13px;
  height: 23px;
  width: 25.6px !important;
  left: 2px;
  border-radius: 50px;
  background-color: white;
  color: black;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
  font-weight: bold;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
}

.switch input:checked + .slider {
  background-color: #d9d9d9;
  color: black;
}

.switch input:checked + .slider:before {
  content: "";
  background-color: white;
  transform: translateX(100%);
  color: black;
}

.customTabs {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 200px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  color: #666360;
}

.tabSelected {
  color: rgba(84, 188, 233, 0.975);
  border-bottom: solid 3px rgba(63, 143, 178, 0.975);
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

/* TABS */

.contentDescription {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.description p {
  margin: 0px;
  color: #666360;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.descriptionObs {
  color: #666360a3;
}

.contentGeneralButtons {
  width: calc(100% - 60px);
  position: fixed;
  z-index: 99;
  bottom: 0px;
  right: 0px;
  background-color: #ffff;
  padding: 0.7% 0px;
  box-shadow: -2px -2px 4px 0 rgba(0, 0, 0, 0.102);
}

.contentButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
}

.btnsFinancial {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  border: none;
  color: white;
  font-size: 12px;
  height: 30px;
}

.btnConfirm {
  border: solid 1px #0fbf4d;
  color: white;
  background-color: #0fbf4d;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.spin {
  height: 20px;
  width: 20px;
  color: white;
}

.btnBack {
  border: solid 1px rgba(63, 143, 178, 0.975);
  color: rgba(63, 143, 178, 0.975);
  background-color: whitesmoke;
}

.contentTab {
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
  padding: 16px 32px 32px 32px;
}

.tabs {
  display: flex;
  gap: 10px;
  border-bottom: solid 1px #6663605a;
}

.selectPlus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
}

.fixedSelect {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 13px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  color: #5c5855;
  padding-left: 5px;
  cursor: pointer;
}

/*  */
.contentInformationBilll {
  width: 30%;
}

.informationBillTitle {
  margin: 0 !important;
  font-weight: bold;
  color: #666360;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.repeatlaunch {
  margin: 0px;
  color: #666360;
}

.contentInstallments {
  margin-bottom: 3rem;
}

.custom-checkbox {
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  font-size: 11px;
  color: #333;
  transition: color 0.3s;
}

.custom-checkbox input[type="checkbox"] {
  display: none;
}

.custom-checkbox .checkmark {
  width: 14px;
  height: 14px;
  border: 2px solid #333;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
  transition: background-color 0.3s, border-color 0.3s, transform 0.3s;
  transform-style: preserve-3d;
}

.custom-checkbox .checkmark::before {
  content: "\2713";
  font-size: 12px;
  color: transparent;
  transition: color 0.3s, transform 0.3s;
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  background-color: #000000;
  border-color: #333;
  transform: scale(1.1) rotateZ(360deg) rotateY(360deg);
}

.custom-checkbox input[type="checkbox"]:checked + .checkmark::before {
  color: #f0f0f0;
}

.custom-checkbox:hover {
  color: #666;
}

.custom-checkbox:hover .checkmark {
  border-color: #666;
  background-color: #f0f0f0;
  transform: scale(1.05);
}

.custom-checkbox input[type="checkbox"]:focus + .checkmark {
  box-shadow: 0 0 3px 2px rgba(0, 0, 0, 0.2);
  outline: none;
}

.custom-checkbox .checkmark,
.custom-checkbox input[type="checkbox"]:checked + .checkmark {
  transition: background-color 1.3s, border-color 1.3s, color 1.3s,
    transform 0.3s;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .contentGeneral {
    margin-top: 5px;
    width: calc(100% - 50px);
  }

  .contentGeneralButtons {
    width: calc(100% - 50px);
  }

  .title {
    font-size: 14px;
  }
  .contentCards {
    font-size: 11px;
    gap: 5px;
  }
  .col-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12,
  .col-xl-12 {
    padding-right: 0px;
    padding-left: 10px;
  }

  .contentInstallments {
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 700px) {
  .contentGeneralButtons {
    bottom: 0;
    width: 100%;
  }

  .conditionPay {
    display: flex;
    flex-wrap: nowrap;
    flex-direction: column;
  }

  .custom-checkbox {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    font-size: 11px;
    color: #333;
    transition: color 0.3s;
    margin-top: 25px;
    margin-left: 15px;
  }
}
</style>
