<template>
  <div class="d-flex justify-content-center container-geral">
    <div class="container-components">
      <b-card style="font-size: 12px; margin-top: 10px; padding: 1.5rem 0">
        <div class="generalContent">
          <div>
            <div class="rowContainer">
              <span>
                <b-icon-gear-fill></b-icon-gear-fill> Documentos Fiscais
              </span>
              <div class="rowContent">
                <div class="selectPlus">
                  <label class="fixedSelect2">Ambiente de Emissão</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    v-model="dataSettings.emissao_fiscal.ambiente"
                    :options="ambiente"
                  ></b-form-select>
                </div>
                <b-button class="btnInutilizar" v-b-modal.modal-inutilizar
                  >Inutilizar Numeração</b-button
                >
              </div>
            </div>

            <div style="display: flex; gap: 10px">
              <div class="rowContainer">
                <span>
                  <b-icon-bag-check-fill></b-icon-bag-check-fill> Vendas
                </span>
                <div class="rowContent">
                  <div class="selectPlus">
                    <label class="fixedSelect2"
                      >Bloquear estoque negativo</label
                    >
                    <b-form-select
                      class="borderInput shadow-none"
                      v-model="dataSettings.venda.bloqueio_estoque"
                      :options="blockStock"
                    ></b-form-select>
                  </div>

                  <div class="selectPlus">
                    <label class="fixedSelect2">Casas Decimais</label>
                    <b-form-select
                      class="borderInput shadow-none"
                      value-field="value"
                      text-field="text"
                      :options="decimalPlacesOptions"
                      v-model="dataSettings.venda_mtq.casas_decimais"
                    >
                    </b-form-select>
                  </div>

                  <InputPercentage
                    v-if="enableMtq"
                    label="Porcentagem de perda"
                    type="text"
                    :dataInput.sync="dataSettings.venda_mtq.porcentagem_perda"
                    ref="porcentagem_perda"
                  />
                </div>

                <div class="rowContent input-group" style="width: 100%">
                  <textarea
                    style="resize: none; height: 100px; width: 100%"
                    required
                    placeholder="Preencha aqui uma observação para ficar salva por padrão nas suas vendas em (Observações Gerais)"
                    class="borderInput"
                    v-model="dataSettings.venda.dados_adicionais_padrao"
                  />
                </div>

                <span>
                  <p style="margin: 1rem 0 0rem 0">Mais configurações</p>
                </span>

                <div
                  class="rowContent input-group"
                  style="width: 100%; justify-content: space-between"
                >
                  <div style="display: flex; flex-direction: column">
                    <div style="display: flex; gap: 0.5rem">
                      <label style="margin: 0; user-select: none" for="editMt2"
                        >Habilitar edição MT2</label
                      >
                      <input
                        id="editMt2"
                        type="checkbox"
                        v-model="dataSettings.venda_mtq.edicao_mt2"
                      />
                    </div>
                  </div>
                  <!-- <div style="display: flex; flex-direction: column">
                    <div style="display: flex; gap: 0.5rem">
                      <label
                        style="margin: 0; user-select: none"
                        for="arredondarMt2"
                        >Arredondar Vl Total MT2</label
                      >
                      <input
                        id="arredondarMt2"
                        type="checkbox"
                        v-model="dataSettings.venda_mtq.arredondar_mt2"
                      />
                    </div>
                  </div> -->
                </div>
                <div class="rowContent input-group" style="width: 100%">
                  <div style="display: flex; flex-direction: column">
                    <div style="display: flex; gap: 0.5rem">
                      <label
                        style="margin: 0; user-select: none"
                        for="valueUnit"
                        >Habilitar valor unitário MT2</label
                      >
                      <input
                        id="valueUnit"
                        type="checkbox"
                        v-model="dataSettings.venda_mtq.valueUnit"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="rowContainer">
                <span> <b-icon-cart3></b-icon-cart3> Orçamento </span>
                <div class="rowContent input-group" style="width: 100%">
                  <textarea
                    style="resize: none; height: 100px; width: 100%"
                    required
                    placeholder="Preencha aqui uma observação para ficar salva por padrão nos seus orçamentos em (Observações Gerais)"
                    class="borderInput"
                    v-model="dataSettings.orcamento.dados_adicionais_padrao"
                  />
                </div>
                <span> <b-icon-receipt></b-icon-receipt> Orçamento MT² </span>
                <div class="rowContent input-group" style="width: 100%">
                  <textarea
                    style="resize: none; height: 100px; width: 100%"
                    required
                    placeholder="Preencha aqui uma observação para ficar salva por padrão nos seus orçamentos MT² em (Observações Gerais)"
                    class="borderInput"
                    v-model="dataSettings.orcamento_mtq.dados_adicionais_padrao"
                  />
                </div>
              </div>
            </div>

            <div style="display: flex; gap: 10px">
              <div class="rowContainer">
                <span> <b-icon-tools></b-icon-tools> Vendas MT² </span>
                <div class="rowContent input-group" style="width: 100%">
                  <textarea
                    style="resize: none; height: 100px; width: 100%"
                    required
                    placeholder="Preencha aqui uma observação para ficar salva por padrão nas suas vendas MT² em (Observações Gerais)"
                    class="borderInput"
                    v-model="dataSettings.venda_mtq.dados_adicionais_padrao"
                  />
                </div>

                <span>
                  <p style="margin: 1rem 0 0rem 0">Mais configurações</p>
                </span>

                <div
                  class="rowContent input-group"
                  style="width: 100%; justify-content: space-between"
                >
                  <div style="display: flex; flex-direction: column">
                    <div style="display: flex; gap: 0.5rem">
                      <label
                        style="margin: 0; user-select: none"
                        for="keepItems"
                        >Manter itens ao adicionar</label
                      >
                      <input
                        id="keepItems"
                        type="checkbox"
                        v-model="dataSettings.venda_mtq.manter_itens"
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div class="rowContainer">
                <span>
                  <b-icon-receipt></b-icon-receipt> Observações NF-e
                </span>
                <div class="rowContent input-group" style="width: 100%">
                  <textarea
                    style="resize: none; height: 100px; width: 100%"
                    required
                    placeholder="Preencha aqui uma observação para ficar salva por padrão em suas notas em (Informações complementares)"
                    class="borderInput"
                    v-model="dataSettings.nfe.dados_adicionais_padrao"
                  />
                </div>
              </div>
            </div>

            <div class="rowContainer">
              <span> <b-icon-cash></b-icon-cash> Financeiro </span>
              <div class="rowContent">
                <div class="selectPlus1">
                  <label class="fixedSelect2">Tipo de Chave</label>
                  <b-form-select
                    class="borderInput shadow-none"
                    value-field="value"
                    text-field="text"
                    :options="typesKeyPix"
                    v-model="dataSettings.tipoChave"
                  >
                  </b-form-select>
                </div>
                <div class="input-group">
                  <input
                    required
                    class="input borderInput"
                    v-model="dataSettings.chavePix"
                    type="text"
                  />
                  <label class="user-label"> Chave Pix </label>
                </div>
                <InputPercentage
                  label="Juros"
                  type="text"
                  style="width: 15%"
                  :dataInput.sync="dataSettings.juros"
                  ref="juros"
                />

                <InputPercentage
                  label="Multa"
                  type="text"
                  style="width: 15%"
                  :dataInput.sync="dataSettings.multa"
                  ref="multa"
                />
                <div class="contentActiveVirtualManager">
                  <label for="active-virtual-manager">
                    Caixa Simplificado
                    <input
                      type="checkbox"
                      id="active-virtual-manager"
                      v-model="dataSettings.caixa_simples"
                    />
                  </label>
                </div>
              </div>
            </div>

            <div class="contentCertificateAndVirtualManager">
              <div class="rowContainer">
                <span> <b-icon-star-fill /> Gerente virtual</span>
                <div class="rowContentVirtualManager">
                  <div class="contentEmailAndResume">
                    <div class="input-group" style="width: 100%">
                      <input
                        required
                        :class="`input borderInput ${isInvalidInput}`"
                        v-model="dataSettings.gerente_virtual.email"
                        type="text"
                        ref="email"
                        @blur="clearInvalidInput"
                      />
                      <label class="user-label"> Email </label>
                    </div>

                    <div class="selectPlus">
                      <label class="fixedSelect2">Tipo de relatório</label>
                      <b-form-select
                        :class="`borderInput shadow-none ${isInvalidInput}`"
                        value-field="value"
                        text-field="text"
                        :options="resumeTypes"
                        v-model="dataSettings.gerente_virtual.periodo"
                        ref="periodo"
                        @blur="clearInvalidInput"
                        disabled
                      >
                      </b-form-select>
                    </div>
                  </div>

                  <div class="contentActiveVirtualManager">
                    <label for="active-virtual-manager">
                      Gerente virtual
                      <input
                        type="checkbox"
                        id="active-virtual-manager"
                        v-model="dataSettings.gerente_virtual.ativo"
                      />
                    </label>
                  </div>
                </div>

                <div class="rowContentVirtualManager">
                  <div>
                    <p style="color: rgb(62, 102, 174)">
                      * Ao ativar o <b>gerente virtual</b>, você receberá emails
                      com base no <b>tipo de resumo</b> informado com um resumo
                      das suas movimentações!
                    </p>
                  </div>
                </div>
              </div>

              <div class="rowContainer containerCertificate">
                <span>
                  <b-icon-file-earmark-arrow-up-fill></b-icon-file-earmark-arrow-up-fill>
                  Certificado</span
                >

                <div v-if="isLoading" class="contentLoading">
                  <b-spinner label="Spinning" class="loading" />
                </div>

                <div v-else>
                  <div
                    class="rowContent"
                    v-if="
                      isEnableUpdateCertificate || !certificateExpirationTime
                    "
                  >
                    <div
                      style="
                        width: 100%;
                        display: flex;
                        align-items: center;
                        gap: 1rem;
                        flex-wrap: wrap;
                      "
                    >
                      <div class="contentInputFile">
                        <label
                          for="inputFile"
                          class="labelInputFile"
                          @dragover="handleDragOver"
                          @drop="handleDrop($event)"
                        >
                          <span class="textSelectFile">
                            <b-icon-paperclip scale="1.5" />
                            <p class="m-0 p-0">Escolha seu certificado</p>
                          </span>
                          <span class="textDropFile">{{ getNameFile() }}</span>
                        </label>
                        <input
                          type="file"
                          id="inputFile"
                          name="inputFile"
                          @change="handleFileChange($event)"
                          accept=".pfx"
                        />
                      </div>

                      <div style="width: 100%">
                        <InputPassword
                          label="Senha"
                          :labelFixed="true"
                          :dataInput.sync="dataCertificate.password"
                          style="width: 100%"
                        />
                      </div>

                      <div class="contentBtnsCertificate">
                        <div>
                          <b-button
                            class="btnGeneral btnSendCertificate"
                            size="sm"
                            @click="sendCertificate"
                            >Carregar Certificado</b-button
                          >
                        </div>

                        <div v-if="dataSettings.nome_certificado">
                          <b-button
                            class="btnGeneral btnCancel"
                            size="sm"
                            @click="cancelUpdateCertificate"
                            >Cancelar</b-button
                          >
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="rowContent rowCertificate" v-else>
                    <div>
                      <div class="containerCertificateExpiration mb-3">
                        <div style="font-size: 14px">
                          {{ dataSettings.nome_certificado }}
                        </div>

                        <div class="contentCertificateExpiration">
                          <span>Dias para vencimento: </span>
                          <p>
                            {{ this.certificateExpirationTime }}
                          </p>
                        </div>
                      </div>

                      <div>
                        <label
                          class="btnsUpdateCertificate"
                          for="updateCertificate"
                        >
                          Atualizar Certificado
                          <input
                            type="checkbox"
                            id="updateCertificate"
                            style="display: none"
                            v-model="isEnableUpdateCertificate"
                            @click="enableSendCertificate"
                          />
                        </label>
                      </div>
                    </div>
                  </div>

                  <div class="rowCotent mt-2">
                    <p style="color: #e01515; font-size: 13px">
                      {{ invalidPayloadText }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div style="width: 100%">
              <div class="mt-2">
                <b-button
                  class="btnGeneral"
                  size="sm"
                  @click="handleSaveOrUpdate"
                  >Salvar</b-button
                >
              </div>
            </div>
          </div>
          <b-modal
            id="modal-inutilizar"
            hide-footer
            hide-header
            centered
            size="lg"
            title="inutilizar"
          >
            <div>
              <h6 class="titleModal"></h6>
              <div class="contentModal">
                <div class="rowContainer">
                  <span> <b-icon-cash></b-icon-cash> Inutilização </span>
                  <div class="rowContent">
                    <div class="selectPlus1">
                      <label class="fixedSelect2">Modelo</label>
                      <b-form-select
                        class="borderInput shadow-none"
                        value-field="value"
                        text-field="text"
                        :options="modeloInutilizar"
                        v-model="dataInutilizar.modelo"
                      >
                      </b-form-select>
                    </div>
                    <div class="selectPlus1">
                      <label class="fixedSelect2">Ambiente</label>
                      <b-form-select
                        class="borderInput shadow-none"
                        value-field="value"
                        text-field="text"
                        :options="ambienteInutilizar"
                        v-model="dataInutilizar.ambiente"
                      >
                      </b-form-select>
                    </div>
                    <div class="input-group">
                      <input
                        required
                        class="input borderInput"
                        v-model="dataInutilizar.serie"
                        type="text"
                      />
                      <label class="user-label"> Serie do documento </label>
                    </div>
                    <div class="input-group">
                      <input
                        required
                        class="borderInput"
                        v-model="dataInutilizar.sequencia"
                        type="text"
                      />
                      <label class="user-label"> Sequência (Numeração) </label>
                    </div>
                  </div>
                  <div class="rowContent">
                    <div class="input-group" style="width: 100%">
                      <input
                        required
                        class="input borderInput"
                        v-model="dataInutilizar.motivo"
                        type="text"
                      />
                      <label class="user-label"> Motivo </label>
                    </div>
                  </div>
                  <div
                    style="
                      display: flex;
                      flex-direction: column;
                      padding-top: 50px;
                      color: #141479;
                    "
                  >
                    <p>
                      * Motivo (Justificativa da inutilização) - Minímo 15
                      caracteres
                    </p>
                    <p>
                      ** Sequência da numeração - Para inutilizar vários números
                      coloque igual ao exemplo Ex.: 101-109
                    </p>
                    <p>
                      *** Atenção: A inutilização de número de NF-e tem a
                      finalidade de permitir que o emissor comunique à SEFAZ,
                      até o décimo dia do mês subsequente, os números de NF-e
                      que não serão utilizados em razão de ter ocorrido uma
                      quebra de sequência da numeração da NF-e. A inutilização
                      de número só é possível caso a numeração ainda não tenha
                      sido utilizada em nenhuma NF-e (autorizada, cancelada ou
                      denegada). Exemplo: a NF-e nº 100 e a nº 110 foram
                      emitidas, mas a faixa 101 a 109, por motivo de ordem
                      técnica, não foi utilizada antes da emissão da nº 110.
                    </p>
                  </div>
                </div>
                <div class="btnModal-action-nfe">
                  <button
                    class="btnContentReceive-action-nfe"
                    @click="$bvModal.hide('modal-inutilizar')"
                  >
                    Não, Obrigado!
                  </button>
                  <button @click="inutilizarPut" class="btnContent-action-nfe">
                    Inutilzar numerações
                  </button>
                </div>
              </div>
            </div>
          </b-modal>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import InputPercentage from "../../components/UI/CustomInputs/InputPercentage.vue";
import InputPassword from "../../components/UI/CustomInputs/InputPassword.vue";

import ServiceWebMania from "../../services/serviceWebMania";
import ServiceSettings from "../../services/serviceSettings";

import { ValidateVirtualManager } from "../../validators/virtualManager";
import { convertFileToBase64 } from "../../utils/convertFileToBase64";
import {
  resumeTypes,
  typesKeyPix,
  decimalPlacesOptions,
} from "../../common/index";
import {} from "../../common/index";
import serviceNotaFiscal from "../../services/serviceNotaFiscal";

export default {
  components: {
    InputPercentage,
    InputPassword,
  },
  data() {
    return {
      imgBase64: null,
      textLogo: "Click me",
      classImg: "logo_img",
      alert: alert,
      imgFromBackend: null,
      dataCertificate: {
        base64: null,
        password: null,
      },
      dataSettings: {
        id: null,
        nome_certificado: null,
        gerente_virtual: {
          email: "",
          ativo: false,
          periodo: "diario",
        },
        juros: 0,
        multa: 0,
        chavePix: "",
        tipoChave: 1,
        emissao_fiscal: {
          ambiente: "2",
        },
        venda: {
          bloqueio_estoque: "1",
          dados_adicionais_padrao: "",
        },
        venda_mtq: {
          casas_decimais: "2",
          porcentagem_perda: "0",
          dados_adicionais_padrao: "",
          edicao_mt2: false,
          arredondar_mt2: "",
          valueUnit: false,
          manter_itens: false,
        },
        orcamento: {
          dados_adicionais_padrao: "",
        },
        orcamento_mtq: {
          dados_adicionais_padrao: "",
        },
        nfe: {
          dados_adicionais_padrao: "",
        },
        caixa_simples: false,
        caixa_automatico: {
          idResponsavel: "",
          valorInicial: "",
          horaAbertura: "",
          horaFechamento: "",
          utilizar: "",
        },
      },
      ambiente: [
        { text: "Produção", value: "1" },
        { text: "Homologação", value: "2" },
      ],
      ambienteInutilizar: [
        { text: "Produção", value: "1" },
        { text: "Homologação", value: "2" },
      ],
      modeloInutilizar: [
        { text: "NF-e", value: "1" },
        { text: "NFC-e", value: "2" },
      ],
      blockStock: [
        { text: "Não", value: "1" },
        { text: "Sim", value: "2" },
      ],
      dataInutilizar: {
        sequencia: "",
        motivo: "",
        ambiente: "1", // 1 - Produção ou 2 - Homologação
        serie: "1",
        modelo: "1",
      },
      certificateExpirationTime: null,
      fileName: null,
      isEnableUpdateCertificate: false,
      invalidPayloadText: "",
      isLoading: false,
      objectPlan: {},
      resumeTypes: resumeTypes,
      typesKeyPix: typesKeyPix,
      decimalPlacesOptions: decimalPlacesOptions,
      isInvalidInput: "",
      spinGeneral: false,
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_CONFIG"]),
    async save() {
      try {
        const id = await ServiceSettings.save(this.dataSettings);
        this.dataSettings.id = id;

        this.SET_DATA_CONFIG(this.dataSettings);
        this.$toast.open({
          message: "Configurações salvas!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao salvar as configurações",
          type: "info",
        });
      }
    },

    async update() {
      try {
        await ServiceSettings.update(this.dataSettings);
        this.SET_DATA_CONFIG(this.dataSettings);

        this.$toast.open({
          message: "Configurações Atualizadas!",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao atualizar as configurações",
          type: "info",
        });
      }
    },

    async sendCertificate() {
      try {
        if (!this.valdatePayloadCertificate()) {
          return;
        }

        this.dataSettings.nome_certificado = this.getNameFile();
        await ServiceWebMania.sendCertificado({
          ...this.dataCertificate,
          dataSettings: this.dataSettings,
        });

        this.isEnableUpdateCertificate = false;
        this.invalidPayloadText = "";
        return this.$toast.open({
          message: "Certificado carregado com sucesso!",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            error.response.data.message || "Erro ao carregar o certificado",
          type: "success",
        });
      }
    },

    async findCertificateExpirationTime() {
      try {
        this.isLoading = true;
        this.certificateExpirationTime =
          await ServiceWebMania.findCertificateExpirationTime();

        if (!this.certificateExpirationTime)
          this.isEnableUpdateCertificate = true;
      } catch (error) {
        this.$toast.open({
          message: "Erro ao buscar tempo de expiração do certificado",
          type: "info",
        });
      } finally {
        this.isLoading = false;
      }
    },

    async verifyExistsSetitngs() {
      const result = await ServiceSettings.findSettingsAtm();

      if (result && Object.keys(result).length) {
        this.dataSettings.id = result.id;
        this.dataSettings.nome_certificado = result?.nome_certificado;
        if (result?.gerente_virtual) {
          this.dataSettings.gerente_virtual = result?.gerente_virtual;
        }

        if (result?.venda_mtq) {
          this.dataSettings.venda_mtq = result?.venda_mtq;
          this.dataSettings.venda_mtq.arredondar_mt2 =
            result?.venda_mtq.arredondar_mt2;

          this.dataSettings.venda_mtq.manter_itens =
            result?.venda_mtq.manter_itens;
        }

        if (result?.orcamento_mtq) {
          this.dataSettings.orcamento_mtq = result?.orcamento_mtq;
        }

        if (result?.orcamento) {
          this.dataSettings.orcamento = result?.orcamento;
        }

        if (result?.nfe) {
          this.dataSettings.nfe = result?.nfe;
        }

        this.$refs.juros.valueInput = result?.juros;
        this.dataSettings.juros = result?.juros;
        this.dataSettings.multa = result?.multa;
        this.dataSettings.chavePix = result?.chavePix;
        this.dataSettings.caixa_simples = result?.caixa_simples;
        this.dataSettings.caixa_automatico =
          result?.caixa_automatico || this.dataSettings.caixa_automatico;
        this.dataSettings.tipoChave = result?.tipoChave;
        this.dataSettings.venda.dados_adicionais_padrao =
          result?.venda?.dados_adicionais_padrao;
        this.dataSettings.orcamento.dados_adicionais_padrao =
          result?.orcamento?.dados_adicionais_padrao;
        this.dataSettings.nfe.dados_adicionais_padrao =
          result?.nfe?.dados_adicionais_padrao;
        this.dataSettings.emissao_fiscal.ambiente =
          result?.emissao_fiscal?.ambiente;
        this.dataSettings.venda.bloqueio_estoque =
          result?.venda.bloqueio_estoque;

        this.$refs.multa.valueInput = result?.multa;

        if (this.enableMtq) {
          this.$refs.porcentagem_perda.valueInput =
            result?.venda_mtq.porcentagem_perda;
        }

        if (!result?.venda_mtq.arredondar_mt2) {
          this.dataSettings.venda_mtq.arredondar_mt2 =
            result?.venda_mtq.arredondar_mt2;
        }
      }
    },

    handleSaveOrUpdate() {
      if (this.dataSettings.gerente_virtual.ativo) {
        const isNotValid = ValidateVirtualManager(
          this.dataSettings.gerente_virtual
        );

        if (isNotValid && Object.keys(isNotValid).length) {
          this.handleFocus(isNotValid.keyError);

          return this.$toast.open({
            message: isNotValid.message,
            type: "info",
          });
        }
      }

      if (!this.dataSettings.venda_mtq.casas_decimais) {
        this.dataSettings.venda_mtq.casas_decimais = 2;
      }

      this.dataSettings.id ? this.update() : this.save();
    },

    handleFocus(refName) {
      this.$refs[refName].focus();

      this.isInvalidInput = "isInvalidInput";
    },

    cancelUpdateCertificate() {
      this.isEnableUpdateCertificate = false;
      this.invalidPayloadText = "";
    },

    handleDragOver(event) {
      event.preventDefault();
    },

    async handleDrop(event) {
      event.preventDefault();
      this.dataCertificate.base64 = await convertFileToBase64(
        event.target.files[0]
      );
      this.fileName = event.target.files[0].name;
    },

    async handleFileChange(event) {
      this.dataCertificate.base64 = await convertFileToBase64(
        event.target.files[0]
      );
      this.fileName = event.target.files[0].name;
    },

    clearInvalidInput() {
      this.isInvalidInput = "";
    },

    valdatePayloadCertificate() {
      if (!this.dataCertificate.base64) {
        this.invalidPayloadText = "* Certificado deve ser selecionado!";
        return false;
      } else if (!this.dataCertificate.password) {
        this.invalidPayloadText = "* Senha do certificado deve ser preenchida!";
        return false;
      }

      return true;
    },

    enableSendCertificate() {
      this.isEnableUpdateCertificate = true;
    },

    getNameFile() {
      if (this.fileName) {
        return this.fileName;
      }

      return "Ou arraste e solte aqui";
    },
    async inutilizarPut() {
      try {
        const data = await serviceNotaFiscal.inutilizar(this.dataInutilizar);
        if (data.error) {
          this.$toast.open({
            message: data.error,
            type: "info",
          });
        } else {
          this.$toast.open({
            message: data.log.xMotivo,
            type: "success",
          });
          this.clearInutilizar();
        }
      } catch (error) {
        this.$toast.open({
          message: "Houve um erro! Verifique a numeração e tente novamente",
          type: "info",
        });
      }
    },
    clearInutilizar() {
      this.dataInutilizar = {
        sequencia: "",
        motivo: "",
        ambiente: "1",
        serie: "1",
        modelo: "1",
      };
      this.$bvModal.hide("modal-inutilizar");
    },
  },
  computed: {
    ...mapState({
      dataConfig: (state) => state.dataConfig,
      enableMtq: (state) => state.enableMtq,
    }),
  },
  mounted() {
    this.findCertificateExpirationTime();
    this.verifyExistsSetitngs();
  },
};
</script>

<style scoped>
.container-geral {
  width: 100% !important;
}

.container-components {
  width: 87%;
  transition: all 0.7s ease-in-out;
}

.generalContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

@media screen and (max-width: 1028px) {
  .generalContent {
    flex-direction: column;
    gap: 20px;
  }
}

span {
  font-weight: bold;
  cursor: context-menu;
  font-size: 13px;
}
.input:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.borderInput:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTotals input:hover {
  background: #d9d9d9 !important;
  border: 1px solid #262626;
}
.custom-select:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.textarea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.inputTextArea:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}
.user-label {
  position: absolute;
  left: 10px;
  font-size: 11px;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.input {
  font-size: 12px !important;
  width: 100%;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.1em;
}

.borderInput {
  font-size: 12px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #505050;
}

.rowContainer {
  position: relative;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 6px;
  width: 100%;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  gap: 10px;
}

.rowContentVirtualManager {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  gap: 1rem;
}

.contentEmailAndResume {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.btnGeneral {
  border-radius: 5px;
  background-color: whitesmoke;
  width: 165px;
  border: none;
  color: rgb(29, 120, 21);
  font-size: 12px;
  border: 1px solid rgb(29, 120, 21);
}

.btnGeneral:hover {
  background-color: rgb(233, 255, 223);
  color: rgb(29, 120, 21);
}

.btnInutilizar {
  border-radius: 5px;
  background-color: whitesmoke;
  border: none;
  color: rgb(82, 82, 82);
  font-size: 12px;
  border: 1px solid #d5d3d3;
}

.btnInutilizar:hover {
  background-color: rgb(224, 224, 224);
  color: rgb(82, 82, 82);
}

.titleModal {
  font-size: 12px;
  border-bottom: 1px solid #e6e7e8;
}

.btnModal-action-nfe {
  margin-top: 10px;
  margin-bottom: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.contentModal {
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  font-size: 13px;
  flex-direction: column;
  padding: 5px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
}

.rowModal {
  display: flex;
  width: 100%;
  padding: 20px 0 5px 0;
  justify-content: space-between;
  gap: 10px;
}
.btnContent-action-nfe {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
}

.btnContent-details-action-nfe {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 20%;
  height: 28px;
  position: relative;
  color: #ffffff;
  background: #00486e;
  display: flex;
  justify-content: center;
  align-items: center;
}

.btnContentReceive-action-nfe {
  font-size: 11px;
  border: 1px solid #00486e;
  border-radius: 3px;
  width: 45%;
  height: 28px;
  position: relative;
  color: #00486e;
  background: white;
}

.btnCancel {
  background-color: rgb(172, 55, 55);
  color: white;
}

.btnCancel:hover {
  background-color: rgb(144, 45, 45);
  color: white;
}

.btnsUpdateCertificate {
  text-align: center;
  padding: 0.5rem;
  border-radius: 5px;
  background-color: whitesmoke;
  width: 100%;
  border: none;
  color: rgb(29, 120, 21);
  font-size: 12px;
  border: 1px solid rgb(29, 120, 21);
  cursor: pointer;
}

.btnsUpdateCertificate:hover {
  background-color: rgb(233, 255, 223);
  color: rgb(29, 120, 21);
}

.input-group {
  width: 30%;
}

.card-body {
  flex: 1 1 auto;
  min-height: 1px;
  padding: 0 0 0 1rem;
  padding-left: 0 !important;
}

.card {
  border: none !important;
}

/* INPUT FILE */

.contentBtnsCertificate {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.contentInputFile {
  display: flex;
  gap: 5px;
  align-items: center;
}

input[type="file"] {
  display: none;
}

.labelInputFile {
  width: 400px;
  min-width: 400px;
  display: flex;
  align-items: center;
  gap: 5px;
  border: dashed 1px #c1c1c1;
  border-radius: 5px;
  cursor: pointer !important;
  margin: 0;
}

.textSelectFile {
  display: flex;
  align-items: center;
  gap: 5px;
  margin-bottom: 0;
  background-color: #c1c1c156;
  border-radius: 5px;
  font-weight: bold;
  font-size: 11px;
  padding: 5.75px 9px;
  cursor: pointer;
}

.textSelectFile p {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.textDropFile {
  max-width: 230px !important;
  overflow: hidden;
  font-size: 13px;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-weight: normal;
  cursor: pointer;
}

/* INPUT FILE */

.contentCertificateAndVirtualManager {
  width: 100%;
  display: flex;
  gap: 1rem;
}

.containerCertificate {
  width: 40%;
  min-width: 420px;
}

.contentCertificateExpiration {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 0.5rem;
  background-color: rgb(62, 102, 174);
  color: white;
  padding: 0.25rem;
  border-radius: 5px;
}

.contentCertificateExpiration span {
  font-weight: normal;
}

.contentCertificateExpiration p {
  padding: 0 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
  margin: 0;
  font-weight: normal;
  background-color: rgb(187, 31, 31);
  border-radius: 5px;
  font-size: 14px;
}

.fixedSelect2 {
  position: absolute;
  margin-bottom: 0px;
  font-size: 9.5px;
  bottom: 22px;
  left: 11px;
  z-index: 2;
  text-transform: capitalize;
  color: rgb(85, 81, 81);
  background-color: white;
}

.rowCertificate {
  justify-content: center;
}

.containerCertificateExpiration {
  max-width: 280px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 2.5px 0 rgba(0, 0, 0, 0.545);
}

.contentLoading {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 180px;
}

.loading {
  width: 100px;
  height: 100px;
}

.contentActiveVirtualManager {
  min-width: 179px;
  display: flex;
}

.contentActiveVirtualManager label {
  margin: 0;
  display: flex;
  align-items: center;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  color: #00395d;
  border-radius: 5px;
  gap: 15px;
  color: #00395d;
  background-color: whitesmoke;
  box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.135);
  padding: 5px;
  cursor: pointer;
}

.contentActiveVirtualManager input {
  width: 18px !important;
  height: 18px !important;
  cursor: pointer;
}

.selectPlus {
  position: relative;
  width: 40%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.selectPlus1 {
  position: relative;
  width: 20%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  align-items: center;
}

.decimalPlaces {
  width: 20%;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .container-components {
    width: 100%;
  }
  .generalContent {
    gap: 5px;
  }
  .rowContainer {
    padding: 5px;
  }
  .rowContainer span {
    font-size: 12px;
  }
  .rowContent {
    padding: 5px 0 5px 0;
    gap: 5px;
  }
  .containerCertificateExpiration {
    gap: 0px;
    padding: 5px;
  }
  .btnsUpdateCertificate {
    padding: 5px;
    font-size: 11px;
  }
}

@media screen and (max-width: 1150px) and (min-width: 930px) {
  .rowContentVirtualManager {
    flex-direction: column;
  }

  .selectPlus {
    min-width: 120px;
  }
}

@media screen and (max-width: 930px) and (min-width: 750px) {
  .rowContentVirtualManager {
    flex-direction: column;
  }

  .contentEmailAndResume {
    flex-direction: column;
  }

  .selectPlus {
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .container-components {
    width: 100%;
  }

  .rowContentVirtualManager {
    flex-direction: column;
  }

  .selectPlus {
    min-width: 120px;
  }
}

@media screen and (max-width: 750px) {
  .contentCertificateAndVirtualManager {
    flex-direction: column;
  }

  .containerCertificate,
  .contentInputFile,
  .labelInputFile {
    width: 100%;
    min-width: 100%;
  }

  .decimalPlaces {
    width: 20%;
  }
}

@media screen and (max-width: 430px) {
  .contentBtnsCertificate div,
  .btnSendCertificate,
  .btnCancel {
    width: 100% !important;
  }

  .contentBtnsCertificate {
    gap: 1rem;
  }

  .rowContentVirtualManager {
    flex-direction: column;
  }

  .contentEmailAndResume {
    flex-direction: column;
  }

  .selectPlus {
    width: 100%;
  }
}

@media screen and (max-width: 650px) {
  .rowContent {
    flex-direction: column;
  }

  .input-group {
    width: 100%;
  }

  .selectPlus1 {
    width: 100%;
  }
}
</style>