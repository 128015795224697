import { mascaraMoeda } from "../utils/maskMoney";
import { removeReference } from "../utils/removeReference";

export const formatObjectToSaveAndUpdate = (payload) => {
  let bills = [];
  let newDataSaleSquareMeter;

  newDataSaleSquareMeter = removeReference(payload)

  const newProd = payload?.products?.map((prod) => {
    return {
      ...prod
    }
  })

  const products = formatValuesProducts(newProd);

  if (payload.bills && payload.bills.length) {
    bills = removeReference(payload.bills)
  }

  Reflect.deleteProperty(newDataSaleSquareMeter, "products");
  Reflect.deleteProperty(newDataSaleSquareMeter, "bills");

  const saleSquareMeter = removeReference(newDataSaleSquareMeter)

  return {
    products,
    saleSquareMeter,
    bills
  };
}

export const formatValuesBills = (bills) => {
  const formattedBills = bills.map((bill) => {
    return {
      ...bill,
      valorTotal: mascaraMoeda(bill.valorTotal),
      valorPago: mascaraMoeda(bill.valorPago),
      valorRestante: mascaraMoeda(bill.valorRestante),
    };
  });

  return formattedBills;
}


const formatValuesProducts = (products) => {
  const prodSelected = products?.filter((prod) => prod.idProduto);
  if (prodSelected?.length) {
    const formattedProducts = products.map((product) => {
      Reflect.deleteProperty(product, 'idList')

      return {
        ...product,
        valorUnitario: product.valorUnitario,
        desconto: product.desconto,
      };
    });

    return formattedProducts;
  }

  return null;
}