<template>
  <div>
    <div class="ContainerSearchInput">
      <div class="textRegister">
        <span>Transportadoras Registradas</span>
      </div>
      <div class="searchInput">
        <b-form-select
          class="searchFilter"
          size="sm"
          value-field="value"
          text-field="text"
          v-model="type"
          :options="filterTypes"
          @change="clearText"
        ></b-form-select>
        <b-input
          v-if="type !== 'cpfCnpj'"
          size="sm"
          placeholder="Pesquisar"
          v-model="textSearch"
          class="inputSearch"
        ></b-input>
        <b-input
          v-else
          size="sm"
          placeholder="Pesquisar"
          v-model="textSearch"
          v-mask="handleMaskCpfCnpj"
          maxlength="18"
          class="inputSearch"
        ></b-input>
        <b-button size="sm" @click="search(page)" class="searchBtn">
          <b-icon-search></b-icon-search>
        </b-button>
      </div>
    </div>
    <div class="ContentTable">
      <table style="width: 100%">
        <thead
          style="
            color: whitesmoke;
            background: #3a4750;
            text-align: center;
            height: 29px;
          "
        >
          <tr style="width: 100%">
            <th>Nome</th>
            <th>CPF/CNPJ</th>
            <th>Ações</th>
          </tr>
        </thead>
        <tbody
          v-if="shippingCompanys.length > 0"
          style="color: #3a4750; width: 100%"
        >
          <tr
            v-for="shippingCompany in shippingCompanys"
            style="text-align: center; height: 28px; cursor: context-menu"
            :key="shippingCompany.id"
          >
            <td>{{ shippingCompany.nome }}</td>
            <td>{{ formattedToCpfCnpj(shippingCompany.cpfCnpj) || "--" }}</td>
            <td>
              <div class="btnActions">
                <b-dropdown
                  id="dropdown-1"
                  text="Ações"
                  menu-class="w-1"
                  class="btnActions"
                  size="sm"
                  variant="none"
                >
                  <template #button-content>
                    <b-icon-gear-fill class="contentActions"></b-icon-gear-fill>
                  </template>
                  <b-dropdown-item @click="findById(shippingCompany.id)">
                    Editar
                  </b-dropdown-item>
                  <b-dropdown-item
                    @click="deleteShppingCompany(shippingCompany.id)"
                  >
                    Excluir
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <td colspan="5">
            <div class="defaultSearch">
              <span>Não há dados para exibir ...</span>
            </div>
          </td>
        </tbody>
        <div v-if="spinGeneral" class="loadingLazy">
          <div class="loader"></div>
        </div>
      </table>
    </div>
    <div class="setBtn">
      <button :disabled="page === 1" class="itemBtn" @click="previous">
        <b-icon-chevron-left></b-icon-chevron-left>
      </button>
      <button class="centerItemBtn">{{ page }}</button>
      <button
        :disabled="!shippingCompanys.length"
        class="itemBtn"
        @click="next"
      >
        <b-icon-chevron-right></b-icon-chevron-right>
      </button>
    </div>
  </div>
</template>

<script>
import ServiceShippingCompany from "../../services/serviceShippingCompany";
import { removeSpecialCharacters } from "../../utils/removeSpecialCharacters";
export default {
  props: {
    isForSearch: {
      type: Boolean,
    },
  },

  data() {
    return {
      textSearch: "",
      type: null,
      shippingCompanys: [],
      page: 1,
      filterTypes: [
        { value: "nome", text: "Nome" },
        { value: "cpfCnpj", text: "CPF/CNPJ" },
        { value: "cidade", text: "Cidade" },
        { value: "placa", text: "Placa" },
        { value: null, text: "Todos" },
      ],
      spinGeneral: false,
    };
  },
  methods: {
    async search(page = 1) {
      try {
        let newtTextSearch = this.textSearch;
        if (this.type === "cpfCnpj") {
          newtTextSearch = removeSpecialCharacters(this.textSearch) || "";
        }

        this.shippingCompanys = await ServiceShippingCompany.search(
          newtTextSearch,
          this.type,
          page
        );
      } catch (error) {
        this.$toast({
          message:
            error?.response?.data?.message ||
            "Ocorreu um erro ao realizar a busca",
          type: "info",
        });
      }
    },
    async findById(id) {
      try {
        const result = await ServiceShippingCompany.findById(id);
        this.$emit("shippingCompanyFound", result);
      } catch (error) {
        this.$toast({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao editar",
        });
      }
    },
    async deleteShppingCompany(id) {
      try {
        await ServiceShippingCompany.delete(id);
        await this.search();
      } catch (error) {
        this.$toast({
          message:
            error?.response?.data?.message || "Ocorreu um erro ao deletar",
        });
      }
    },
    async next() {
      if (!this.shippingCompanys.length) {
        return;
      }

      this.page += 1;
      await this.search(this.page);
    },
    async previous() {
      if (this.page === 1) {
        return;
      }

      this.page -= 1;
      await this.search(this.page);
    },
    formattedToCpfCnpj(value) {
      if (value && value.length === 11) {
        return value.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})$/, "$1.$2.$3-$4");
      }

      return value.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})$/,
        "$1.$2.$3/$4-$5"
      );
    },
    clearText() {
      this.textSearch = "";
    },
  },
  computed: {
    handleMaskCpfCnpj() {
      if (!this.textSearch) {
        return "";
      }
      if (this.textSearch.length <= 14) {
        return "###.###.###-##";
      }
      if (this.textSearch.length > 14) {
        return "##.###.###/####-##";
      } else {
        return "";
      }
    },
  },
  watch: {
    isForSearch() {
      this.search();
    },
  },
  mounted() {
    this.search();
  },
};
</script>

<style scoped>
.ContentTable {
  display: flex;
  flex-direction: column;
  height: 45vh;
  max-height: 45vh;
  overflow: auto;
  padding: 10px;
  width: 100%;
  max-width: 100%;
}

tr:nth-child(even) {
  background: #f2f3f3;
}

th {
  font-weight: 500;
  font-size: 12px;
}

td {
  text-transform: capitalize;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
}

.btnActions {
  height: 25px;
  display: flex;
  justify-content: center;
}

.wtsTd {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding-top: 5px;
  cursor: pointer;
}

.wtsTd:hover {
  background: rgb(213, 213, 213);
}

.dropdown-menu {
  font-size: 12px;
  min-width: 5rem !important;
  width: 5rem !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  color: red;
  background: red;
  text-align: center;
  list-style: none;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  margin: 0.125rem 0 0;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.searchFilter {
  width: 110px;
  border-right: none;
  height: 30px;
  font-size: 11px;
  text-align: center;
}

.searchFilter:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

.custom-select {
  left: 4px;
  position: relative;
  border-right: none;
  border-radius: 5px 0px 0px 5px;
  border: 1px solid rgb(195, 193, 193);
  -moz-appearance: none;
  appearance: none;
}

.ContainerSearchInput {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 50px;
  width: 100%;
  padding-left: 2%;
}

.textRegister span {
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  cursor: default;
}

.searchInput {
  display: flex;
}

.form-control-sm {
  padding: 0.25rem 1.5rem;
  height: 30px;
}

input {
  border: 1px solid rgb(195, 193, 193);
  border-radius: 5px;
  height: 30px;
}

.inputSearch {
  width: 100%;
}

.searchBtn {
  right: 10px;
  height: 30px;
  position: relative;
  background: whitesmoke;
  color: black;
  border-radius: 0px 5px 5px 0px;
  border-left: none;
  border-color: #c3c1c1;
}

input:focus {
  box-shadow: none;
  border: 1px solid rgb(195, 193, 193);
}

button:focus {
  box-shadow: none;
}

.pagMonth {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.defaultSearch {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 20vh;
  margin-top: 20px;
  justify-content: center;
  align-items: center;
}

.defaultSearch span {
  font-size: 1rem;
  font-weight: bold;
  color: gray;
}

.loadingLazy {
  position: absolute;
  bottom: 20px;
  height: 50vh;
  width: calc(100% - 100px);
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgb(140, 140, 140);
}

.setBtn {
  display: flex;
  flex-direction: row;
  margin: 0 1rem 1rem 0;
  gap: 10px;
  bottom: 0;
  right: 0;
  position: absolute;
}

.itemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #3a4750;
}

.itemBtn:hover {
  background: #3a4750;
  color: whitesmoke;
}

.itemBtn:disabled {
  opacity: 0;
}

.centerItemBtn {
  display: flex;
  text-align: center;
  align-content: center;
  justify-content: center;
  align-items: center;
  width: 25px;
  height: 25px;
  font-weight: bold;
  border: 1px solid rgb(175, 174, 174);
  border-radius: 50%;
  color: #1b3159;
  cursor: auto;
}

.loader {
  animation: rotate 1s infinite;
  height: 50px;
  width: 50px;
  opacity: 0.5;
}

.loader:before,
.loader:after {
  border-radius: 50%;
  content: "";
  display: block;
  height: 20px;
  width: 20px;
}

.loader:before {
  animation: ball1 1s infinite;
  background-color: #403b74;
  box-shadow: 30px 0 0 #f2a83e;
  margin-bottom: 10px;
}

.loader:after {
  animation: ball2 1s infinite;
  background-color: #3e66ae;
  box-shadow: 30px 0 0 #48b865;
}

@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(0.8);
    -moz-transform: rotate(0deg) scale(0.8);
  }

  50% {
    -webkit-transform: rotate(360deg) scale(1.2);
    -moz-transform: rotate(360deg) scale(1.2);
  }

  100% {
    -webkit-transform: rotate(720deg) scale(0.8);
    -moz-transform: rotate(720deg) scale(0.8);
  }
}

@keyframes ball1 {
  0% {
    box-shadow: 30px 0 0 #f2a83e;
  }

  50% {
    box-shadow: 0 0 0 #f2a83e;
    margin-bottom: 0;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #f2a83e;
    margin-bottom: 10px;
  }
}

@keyframes ball2 {
  0% {
    box-shadow: 30px 0 0 #48b865;
  }

  50% {
    box-shadow: 0 0 0 #48b865;
    margin-top: -20px;
    -webkit-transform: translate(15px, 15px);
    -moz-transform: translate(15px, 15px);
  }

  100% {
    box-shadow: 30px 0 0 #48b865;
    margin-top: 0;
  }
}

@media screen and (max-width: 992px) {
  .ContainerSearchInput {
    flex-direction: column;
    margin-bottom: 20px;
  }
}
</style>