<template>
  <b-modal size="lg" id="ModalLaunchValue" hide-header @hidden="closeModal">
    <template #modal-footer>
      <div class="footerBtn">
        <div>
          <button class="btns btnBack" @click="closeModal">Voltar</button>
        </div>

        <div>
          <button
            class="btns btnConfirm"
            @click="confirmLaunch"
            style="position: relative"
          >
            {{ isProvider ? "Lançar pagamento" : "Lançar recebimento" }}
            <span
              v-if="spinLoadingActionsButton"
              style="position: absolute; right: 5px"
              ><b-spinner small
            /></span>
          </button>
        </div>
      </div>
    </template>

    <div>
      <div class="headerModalBills">
        <p>{{ isProvider ? "Informar pagamento" : "Informar recebimento" }}</p>
        <div @click="closeModal" class="closeModal">
          <b-icon-x scale="1.6"></b-icon-x>
        </div>
      </div>

      <hr style="margin-top: 0" />
    </div>

    <div class="mt-3">
      <div
        class="mt-3 d-flex flex-wrap col-sm-12 col-md-12 col-lg-12 col-xl-12"
      >
        <div class="col-sm-6 col-md-6 col-lg-3 col-xl-4">
          <InputMoney
            label="Valor da parcela"
            type="text"
            :dataInput.sync="dataBill.valorTotal"
            labelFixed
            isDisabled
          />
        </div>

        <div class="datePayment col-sm-6 col-md-6 col-lg-3 col-xl-4">
          <Input
            label="Data do pagamento"
            labelFixed
            type="date"
            :dataInput.sync="dataLaunchPayment.datePayment"
          />
        </div>

        <div class="datePayment col-sm-6 col-md-6 col-lg-3 col-xl-4">
          <Input
            label="Data de vencimento"
            labelFixed
            type="date"
            :isDisabled="true"
            :dataInput.sync="dataLaunchPayment.dataMatury"
          />
        </div>
      </div>
      <div
        class="mt-4 d-flex flex-wrap col-sm-12 col-md-12 col-lg-12 col-xl-12"
      >
        <div class="typePayment col-sm-6 col-md-6 col-lg-3 col-xl-4">
          <InputSelect
            label="Formas de pagamento"
            valueField="id"
            textField="tipo"
            :items="listTypesPaymentsSelectBox"
            :dataSelect.sync="dataLaunchPayment.idTypePayment"
            :enableIconCreate="false"
          />
        </div>

        <div class="receiptBill col-sm-6 col-md-6 col-lg-3 col-xl-4">
          <InputSelect
            :label="`${label}`"
            valueField="id"
            textField="nome"
            :isDisabled="this.blocked"
            :items="allAccountReceiptBills"
            :dataSelect.sync="dataLaunchPayment.idReceiptAccount"
            :openCreateModal="() => openModals('modalContaRecebimento')"
            ref="contaRecebimento"
          />
        </div>

        <div class="description col-sm-5 col-md-5 col-lg-5 col-xl-4">
          <Input
            label="Descrição"
            type="text"
            :dataInput.sync="dataLaunchPayment.description"
            :labelFixed="true"
          />
        </div>
      </div>
      <div
        class="monetaryValues d-flex flex-wrap col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-top: 40px"
      >
        <div class="col-sm-4 col-md-4 col-lg-3 col-xl-3">
          <div class="input-group">
            <InputMoney
              :label="isProvider ? 'Valor pago' : 'Valor recebido'"
              type="text"
              :dataInput.sync="dataLaunchPayment.price"
              labelFixed
            />
          </div>
        </div>
        <div class="discount col-sm-4 col-md-4 col-lg-3 col-xl-3">
          <div class="input-group">
            <InputMoney
              label="Desconto"
              type="text"
              :dataInput.sync="dataLaunchPayment.discount"
              labelFixed
            />
          </div>
        </div>
        <div class="tariffsRow d-flex flex-wrap" style="right: 0">
          <label style="height: 100%" class="switch">
            <input type="checkbox" v-model="feesIsChecked" />
            <div class="slider">
              <span>R$</span>
              <span>%</span>
            </div>
          </label>
        </div>
        <div
          v-if="feesIsChecked"
          class="tariffs col-sm-4 col-md-4 col-lg-3 col-xl-3"
        >
          <div class="input-group">
            <InputPercentage
              label="Juros por dia"
              type="text"
              :dataInput.sync="dataLaunchPayment.tariffs"
              ref="juros"
              labelFixed
            />
          </div>
        </div>
        <div v-else class="tariffs col-sm-4 col-md-4 col-lg-3 col-xl-3">
          <div class="input-group">
            <InputMoney
              label="Juros"
              type="text"
              ref="juros"
              :dataInput.sync="dataLaunchPayment.tariffs"
              labelFixed
            />
          </div>
        </div>
        <div class="tariffsRow d-flex flex-wrap">
          <label style="height: 100%" class="switch">
            <input type="checkbox" v-model="tariffIsChecked" />
            <div class="slider">
              <span>R$</span>
              <span>%</span>
            </div>
          </label>
        </div>

        <div
          v-if="tariffIsChecked"
          class="penalty col-sm-4 col-md-4 col-lg-3 col-xl-3"
        >
          <div class="input-group">
            <InputPercentage
              label="Multa"
              type="text"
              :dataInput.sync="dataLaunchPayment.penalty"
              ref="multa"
              labelFixed
            />
          </div>
        </div>
        <div v-else class="penalty col-sm-4 col-md-4 col-lg-3 col-xl-3">
          <div class="input-group">
            <InputMoney
              label="Multa"
              type="text"
              :dataInput.sync="dataLaunchPayment.penalty"
              ref="multa"
              labelFixed
            />
          </div>
        </div>
      </div>
      <div
        v-if="formattedValuePaidOrReceived.daysLate"
        class="infoTariffs d-flex flex-wrap col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="margin-top: 30px"
      >
        <span
          >Dias de Atraso:
          {{ formattedValuePaidOrReceived.daysLate }} dias</span
        >
        <span
          >Juros Total:
          {{
            formattedValuePaidOrReceived.interestRatePerDay.toLocaleString(
              "pt-br",
              {
                style: "currency",
                currency: "BRL",
              }
            )
          }}
        </span>
        <span
          >Multa:
          {{
            parseFloat(
              formattedValuePaidOrReceived.penaltyAmount
            ).toLocaleString("pt-br", {
              style: "currency",
              currency: "BRL",
            })
          }}
        </span>
      </div>
      <b-row
        class="mt-3 d-flex col-sm-12 col-md-12 col-lg-12 col-xl-12"
        style="justify-content: flex-end"
      >
        <div
          style="
            display: flex;
            gap: 30px;
            background-color: #00c2585e;
            box-shadow: 0px 0px 5px 0px #00000040;
            padding: 10px 16px;
            border-radius: 5px;
          "
        >
          <div>
            <p class="informationBillTitle" style="text-align: end">
              Valor em aberto
            </p>
            <div style="display: flex; gap: 3px">
              <p class="totals">R$ {{ calculateOpentotal }}</p>
            </div>
          </div>

          <div>
            <p class="informationBillTitle" style="text-align: end">
              {{ isProvider ? "Total Pago" : "Total Recebido" }}
            </p>
            <div style="display: flex; gap: 3px">
              <p class="totals">
                {{ formattedValuePaidOrReceived.remainingValue }}
              </p>
            </div>
          </div>
        </div>
      </b-row>
    </div>

    <ModalCreateAccountReceiptBill @getNewReceiptBill="getAllReceiptBill" />
  </b-modal>
</template>

<script>
import { mapState, mapMutations } from "vuex";
import moment from "moment";
import ModalCreateAccountReceiptBill from "../Modals/ModalCreateAccountReceiptBill.vue";
import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";
import InputPercentage from "../UI/CustomInputs/InputPercentage.vue";

import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServicePaymentForm from "../../services/servicePaymentForm";
import ServiceCategoryBill from "../../services/serviceCategoryBill";
import ServiceBill from "../../services/serviceBill";

import { HandleErrors } from "../../utils/handleErrors";
import { TypeErrors } from "../../utils/enums/TypeErrors";
import { ValidateBillsWhenLaunchValue } from "../../validators/bills";
import { mascaraMoeda, maskCurrency } from "../../utils/maskMoney";
import serviceSettings from "../../services/serviceSettings";

export default {
  props: {
    dataBill: {
      type: Object,
      default: null,
    },
    label: {
      type: String,
      default: "Conta de Recebimento",
    },
  },
  components: {
    ModalCreateAccountReceiptBill,
    Input,
    InputSelect,
    InputMoney,
    InputPercentage,
  },
  data() {
    return {
      dataLaunchPayment: {
        id: null,
        dataMatury: this.dataBill.dataMaturity.split(" ")[0],
        price: this.dataBill.valorRestante,
        description: null,
        datePayment: moment().format("YYYY-MM-DD"),
        idTypePayment: "",
        idReceiptAccount: "",
        penalty: 0,
        discount: 0,
        tariffs: 0,
        typePenalty: null,
        typeTariffs: null,
      },
      feesIsChecked: false,
      tariffIsChecked: false,
      allAccountReceiptBills: [],
      listTypesPaymentsSelectBox: [],
      spinLoadingActionsButton: false,
      maskCurrency: maskCurrency,
      daysLate: 0,
      interestRatePerDay: 0,
      totalAmountWithInterest: 0,
      blocked: false,
    };
  },
  methods: {
    ...mapMutations([
      "SET_DATA_SEARCH_FROM_BILL",
      "SET_IS_SEARCH_BILLS",
      "ALTER_VALUES_MODAL_DIALOG_GENERAL",
    ]),
    async confirmLaunch() {
      try {
        this.spinLoadingActionsButton = true;
        const isNotValid = this.validateBillsLaunchValue();
        if (isNotValid) {
          return this.$toast.open({
            message: isNotValid,
            type: "info",
          });
        }

        const formData = this.handleFormatValuesForLaunch();
        await ServiceBill.launchPayment(formData);

        this.refreshScreen();
        this.closeModal();
        this.SET_IS_SEARCH_BILLS();

        return this.$toast.open({
          message: "Pagamento realizado!",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        if (!this.dataConfig.caixa_automatico.utilizar) {
          if (
            typeError &&
            Object.keys(typeError).length &&
            TypeErrors.NO_SAFE_REGISTER_OPEN.includes(typeError?.nameError)
          ) {
            return this.handleModalSafeOpening();
          }
        }

        return this.$toast.open({
          message: typeError?.message || `${error?.response?.data.message}`,
          type: "info",
        });
      } finally {
        this.spinLoadingActionsButton = false;
      }
    },

    async refreshScreen() {
      const bill = await ServiceBill.findDetailedBillById(this.dataBill.id);
      this.SET_DATA_SEARCH_FROM_BILL(bill);
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.allAccountReceiptBills = result;
      if (this.isSimpleSafe) {
        this.blocked = true;
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );
        this.dataLaunchPayment.idReceiptAccount = accountPayment.id;
        this.$refs.contaRecebimento.valueItems = accountPayment.id;
      }
    },

    async getTypesPaymentsSelectBox() {
      try {
        const result = await ServicePaymentForm.getAllPayment();
        this.listTypesPaymentsSelectBox = result.sort((a, b) => a.cod - b.cod);
      } catch (error) {
        console.log(error);
      }
    },

    async getAllCategoryBill() {
      const result = await ServiceCategoryBill.fillComobobox();
      this.allCategoryBills = result;
    },

    handleModalSafeOpening() {
      this.ALTER_VALUES_MODAL_DIALOG_GENERAL({
        nameModal: "modalDialogSafeOpening",
        title: "Caixa ainda não aberto!",
        subTitle:
          "Verificamos que você ainda não iniciou o caixa, para realizar qualquer lançamento, é necessario realizar a abertura do caixa!",
        footerTitle: "Deseja abrir o caixa agora ?",
        leftButtonText: "Não",
        rightButtonText: "Sim",
      });

      this.$nextTick(() => {
        this.openModals(this.dataModalDialogGeneral.nameModal);
      });
    },

    handleFormatValuesForLaunch() {
      const formattedFiles = this.formatFilesForLaunchAccountsValus();
      if (this.formattedValuePaidOrReceived.daysLate) {
        const newDataLaunchPayment = {
          ...this.dataLaunchPayment,
          id: this.dataBill.id,
          billFiles: formattedFiles,
          price: this.dataLaunchPayment.price,
          discount: this.dataLaunchPayment.discount,
          tariffs: this.feesIsChecked
            ? (this.dataLaunchPayment.tariffs / 100) *
              this.formattedValuePaidOrReceived.daysLate *
              this.dataLaunchPayment.price
            : parseFloat(this.dataLaunchPayment.tariffs) *
              this.formattedValuePaidOrReceived.daysLate,
          penalty: this.tariffIsChecked
            ? parseFloat(this.dataLaunchPayment.penalty / 100) *
              this.dataLaunchPayment.price
            : this.dataLaunchPayment.penalty,
          typePenalty: this.tariffIsChecked ? "Porcentagem" : "Real",
          typeTariffs: this.feesIsChecked ? "Porcentagem" : "Real",
        };

        const formData = new FormData();
        formattedFiles.forEach(({ file }) => formData.append(`files`, file));
        formData.append(`data`, JSON.stringify({ ...newDataLaunchPayment }));

        return formData;
      }
      const newDataLaunchPayment = {
        ...this.dataLaunchPayment,
        id: this.dataBill.id,
        billFiles: formattedFiles,
        price: this.dataLaunchPayment.price,
        discount: this.dataLaunchPayment.discount,
        tariffs: this.feesIsChecked
          ? (this.dataLaunchPayment.tariffs / 100) *
            this.dataLaunchPayment.price
          : parseFloat(this.dataLaunchPayment.tariffs),
        penalty: this.tariffIsChecked
          ? parseFloat(this.dataLaunchPayment.penalty / 100) *
            this.dataLaunchPayment.price
          : this.dataLaunchPayment.penalty,
        typePenalty: this.tariffIsChecked ? "Porcentagem" : "Real",
        typeTariffs: this.feesIsChecked ? "Porcentagem" : "Real",
      };

      const formData = new FormData();
      formattedFiles.forEach(({ file }) => formData.append(`files`, file));
      formData.append(`data`, JSON.stringify({ ...newDataLaunchPayment }));

      return formData;
    },

    validateBillsLaunchValue() {
      if (this.formattedValuePaidOrReceived.daysLate) {
        const newDataLaunchPayment = {
          ...this.dataLaunchPayment,
          id: this.dataBill.id,
          price: this.dataLaunchPayment.price,
          discount: this.dataLaunchPayment.discount,
          tariffs: this.feesIsChecked
            ? (this.dataLaunchPayment.tariffs / 100) *
              this.formattedValuePaidOrReceived.daysLate *
              this.dataLaunchPayment.price
            : parseFloat(this.dataLaunchPayment.tariffs) *
              this.formattedValuePaidOrReceived.daysLate,
          penalty: this.formattedValuePaidOrReceived.penaltyAmount.toFixed(2),
          typePenalty: this.tariffIsChecked ? "Porcentagem" : "Real",
          typeTariffs: this.feesIsChecked ? "Porcentagem" : "Real",
        };
        return ValidateBillsWhenLaunchValue(newDataLaunchPayment);
      }
      const newDataLaunchPayment = {
        ...this.dataLaunchPayment,
        id: this.dataBill.id,
        price: this.dataLaunchPayment.price,
        discount: this.dataLaunchPayment.discount,
        tariffs: this.dataLaunchPayment.tariffs,
        penalty: this.dataLaunchPayment.penalty,
        typePenalty: this.tariffIsChecked ? "Porcentagem" : "Real",
        typeTariffs: this.feesIsChecked ? "Porcentagem" : "Real",
      };

      return ValidateBillsWhenLaunchValue(newDataLaunchPayment);
    },

    formatFilesForLaunchAccountsValus() {
      const newBills = { ...this.dataBill };

      const newFiles = newBills.arquivos.map((file) => {
        return {
          ...file,
        };
      });

      const formattedFileBills = newFiles.filter((file) => {
        if ((!file.id && file.tipoArquivo) || file.file) {
          Reflect.deleteProperty(file, "idList");

          return {
            ...file,
            idConta: this.dataBill.id,
          };
        }
      });

      return formattedFileBills;
    },

    formatMoney(event, value) {
      this.dataLaunchPayment[value] = mascaraMoeda(event.target.value);
    },

    closeModal() {
      this.$emit("closeModalLauch", false);
      this.$bvModal.hide("ModalLaunchValue");
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    async verifyExistsSetitngs() {
      const maturityDate = moment(this.dataLaunchPayment.dataMatury);
      const paymentDate = moment(this.dataLaunchPayment.datePayment);
      if (paymentDate.isAfter(maturityDate)) {
        const daysLate = paymentDate.diff(maturityDate, "days");
        if (daysLate) {
          const result = await serviceSettings.findSettingsAtm();
          if (result && Object.keys(result).length) {
            this.dataLaunchPayment.tariffs = result?.juros || 0;
            this.dataLaunchPayment.penalty = result?.multa || 0;

            await this.$nextTick();

            if (this.$refs.juros) {
              this.$refs.juros.valueInput = result.juros;
            }
            if (this.$refs.multa) {
              this.$refs.multa.valueInput = result.multa;
            }
          }
        }
      }
    },

    calculatePenalty() {
      return this.tariffIsChecked
        ? (this.dataLaunchPayment.penalty / 100) * this.dataLaunchPayment.price
        : parseFloat(this.dataLaunchPayment.penalty);
    },

    calculateInterest() {
      const maturityDate = moment(this.dataLaunchPayment.dataMatury);
      const paymentDate = moment(this.dataLaunchPayment.datePayment);
      const daysLate = paymentDate.isAfter(maturityDate)
        ? paymentDate.diff(maturityDate, "days")
        : 0;

      let interestRate;
      if (this.feesIsChecked) {
        interestRate =
          (this.dataLaunchPayment.tariffs / 100) *
          this.dataLaunchPayment.price *
          (daysLate || 1);
      } else {
        interestRate = this.dataLaunchPayment.tariffs * (daysLate || 1);
      }

      return interestRate;
    },

    valueInPorcentage() {
      const maturityDate = moment(this.dataLaunchPayment.dataMatury);
      const paymentDate = moment(this.dataLaunchPayment.datePayment);
      const daysLate = paymentDate.isAfter(maturityDate)
        ? paymentDate.diff(maturityDate, "days")
        : 0;

      const interestRatePerDay = this.calculateInterest();
      const penaltyAmount = this.calculatePenalty();
      let totalAmountWithInterest = this.dataLaunchPayment.price;
      const remainingValue =
        totalAmountWithInterest +
        interestRatePerDay +
        penaltyAmount -
        (this.dataLaunchPayment.discount || 0);

      const totalTariffs =
        totalAmountWithInterest - this.dataLaunchPayment.price;

      return {
        remainingValue: remainingValue.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        daysLate: daysLate,
        totalTariffs: totalTariffs,
        penaltyAmount: penaltyAmount,
        interestRatePerDay: interestRatePerDay.toFixed(2),
      };
    },

    valueInReal() {
      const maturityDate = moment(this.dataLaunchPayment.dataMatury);
      const paymentDate = moment(this.dataLaunchPayment.datePayment);
      const daysLate = paymentDate.isAfter(maturityDate)
        ? paymentDate.diff(maturityDate, "days")
        : 0;

      const interestRatePerDay = this.calculateInterest();
      const penaltyAmount = this.calculatePenalty();
      let totalAmountWithInterest = this.dataLaunchPayment.price;

      const remainingValue =
        totalAmountWithInterest +
        interestRatePerDay +
        penaltyAmount -
        (this.dataLaunchPayment.discount || 0);

      const totalTariffs =
        totalAmountWithInterest - this.dataLaunchPayment.price;

      return {
        remainingValue: remainingValue.toLocaleString("pt-br", {
          style: "currency",
          currency: "BRL",
        }),
        daysLate: daysLate,
        totalTariffs: totalTariffs,
        penaltyAmount: penaltyAmount,
        interestRatePerDay: interestRatePerDay,
      };
    },
  },
  computed: {
    ...mapState({
      dataModalDialogGeneral: (state) => state.dataModalDialogGeneral,
      dataConfig: (state) => state.dataConfig,
    }),

    formattedValuePaidOrReceived() {
      if (this.tariffIsChecked) {
        return this.valueInPorcentage();
      } else {
        return this.valueInReal();
      }
    },

    calculateOpentotal() {
      const openTotal =
        parseFloat(this.dataBill.valorRestante) -
        (parseFloat(this.dataLaunchPayment.price) || 0);

      return openTotal.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },

    isProvider() {
      if (this.dataBill.nomeFornecedor) {
        return true;
      }

      return false;
    },
    isSimpleSafe() {
      return this.dataConfig?.caixa_simples ?? true;
    },
  },
  mounted() {
    this.getTypesPaymentsSelectBox();
    this.getAllReceiptBill();
    this.getAllCategoryBill();
    this.verifyExistsSetitngs();
  },
};
</script>

<style scoped>
.btns {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 28px;
  border: none;
  color: white;
  font-size: 12px;
}

.btnBack {
  border: solid 1px rgba(63, 143, 178, 0.975);
  color: rgba(63, 143, 178, 0.975);
  background-color: whitesmoke;
}

.btnConfirm {
  border: solid 1px #0fbf4d;
  color: white;
  background-color: #0fbf4d;
}

.informationBillTitle {
  margin: 0 !important;
  color: #666360;
  font-size: 12px;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.infoTariffs {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  font-size: 12px;
  font-weight: 600;
  padding-right: 30px;
  color: #666360;
}

.footerBtn {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
}

.headerModalBills {
  display: flex;
  align-items: center;
  width: 100%;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 10px 1rem;
}

.headerModalBills p {
  font-size: 14px !important;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  color: #666360 !important;
  margin: 5px 0px;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgb(255, 153, 153);
  cursor: pointer;
  padding: 5px 10px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.tariffsRow {
  height: 30px;
  padding-top: 5px;
  bottom: 30px;
  position: relative;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
}

.textCheck {
  font-size: 12px;
  font-weight: bold;
  margin-bottom: 0rem;
  position: absolute;
  right: 120px;
  color: #666360;
}

.discount {
  display: flex;
  flex-direction: column;
}

.switch {
  font-size: 12px;
  position: relative;
  display: inline-block;
}

.user-label {
  position: absolute;
  left: 10px;
  font-size: 12px;
  bottom: 5px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  z-index: 300;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 100px;
  bottom: 0;
  background-color: #e7e6e6;
  font-weight: bold;
  color: #000000;
  border-radius: 25px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  transition: 0.4s;
  width: 60px !important;
  height: 100%;
}

.slider:before {
  position: absolute;
  content: "R$";
  height: 90%;
  width: 45%;
  left: 5%;
  font-weight: bold;
  border-radius: 50%;
  background-color: rgb(66, 203, 66);
  color: white;
  display: grid;
  align-content: center;
  justify-content: center;
  transition: 0.4s;
}

.slider:after {
  content: "";
  position: absolute;
  z-index: -1;
  font-weight: bold;
}

.switch input:checked + .slider {
  background-color: #e7e6e6;
  color: rgb(0, 0, 0);
}

.switch input:checked + .slider:before {
  content: "%";
  background-color: rgb(66, 203, 66);
  transform: translateX(100%);
  color: white;
  font-weight: bold;
}

.switch input {
  display: none;
}

/* INPUT */

.labelFixed {
  position: absolute;
  left: 3px;
  font-size: 11px;
  bottom: 13px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.input {
  font-size: 12px !important;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.2em;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #a9cff4;
}

.textReal {
  display: flex;
  align-items: center;
  margin-top: 7px;
  color: #666360;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.totals {
  font-size: 20px;
  letter-spacing: 0.3px;
  font-weight: 600;
  color: black;
}

/* MEDIAS */

@media screen and (max-width: 1199px) {
  .description {
    margin-top: 30px;
  }
}

@media screen and (max-width: 991px) {
  .discount,
  .receiptBill,
  .typePayment,
  .description {
    margin-top: 30px;
  }
}

@media screen and (max-width: 575px) {
  .monetaryValues,
  .discount,
  .receiptBill,
  .typePayment,
  .description,
  .datePayment,
  .penalty,
  .tariffs {
    margin-top: 20px !important;
  }
}
</style>