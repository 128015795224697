<template>
  <b-modal size="lg" id="modalProductSaleComposition" hide-footer hide-header>
    <div class="summaryForm">
      <button
        class="closeModal"
        @click="$bvModal.hide('modalProductSaleComposition')"
      >
        <b-icon-x scale="1.3"></b-icon-x>
      </button>

      <div>
        <div :class="isEnableStyleCard && 'rowContainer'">
          <div style="display: flex; justify-content: space-between">
            <span>
              <b-icon-ui-checks />
              Composições do item
            </span>
          </div>

          <div class="rowContent mt-2" style="justify-content: flex-start">
            <span class="btnAddMoreItems" @click="addMoreItems"
              >+ Adicionar</span
            >
          </div>

          <div class="contentComposition">
            <div
              class="rowContent"
              v-for="(composition, i) in compositionItems.filter(
                (item) => item.tipoProduto === 'composicao'
              )"
              :key="composition.idList"
            >
              <div class="rowContent" style="width: 40%">
                <InputSelect
                  label="item"
                  :items="productsFromSelectBox"
                  textField="nome"
                  valueField="id"
                  :dataSelect.sync="compositionItems[i].idProduto"
                  @changed="getComposition(compositionItems[i].idProduto, i)"
                  ref="refProductComposition"
                  :enableIconCreate="false"
                />
              </div>

              <div class="rowContent" style="width: 20%">
                <Input
                  label="Quantidade"
                  labelFixed
                  type="number"
                  :dataInput.sync="compositionItems[i].quantidade"
                  ref="quantidade"
                  @changed="getComposition"
                />
              </div>

              <div class="rowContent" style="width: 20%">
                <InputMoney
                  label="Vl. Unit"
                  labelFixed
                  ref="refVlUnit"
                  type="text"
                  :dataInput.sync="compositionItems[i].valorUnitario"
                  @changed="getComposition"
                />
              </div>

              <div class="rowContent" style="width: 40%">
                <Input
                  label="Informações adicionais"
                  labelFixed
                  :dataInput.sync="compositionItems[i].dadosAdicionais"
                  ref="dadosAdicionais"
                  @changed="getComposition"
                />
              </div>

              <div class="rowContent" style="width: auto">
                <div
                  @click="handleRemoveItens(composition.idList, composition.id)"
                >
                  <b-icon-x-circle
                    style="color: red"
                    role="button"
                    scale="1.3"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { v4 as uuidv4 } from "uuid";

import Input from "../UI/CustomInputs/Input.vue";
import InputSelect from "../UI/CustomInputs/InputSelect.vue";
import InputMoney from "../UI/CustomInputs/InputMoney.vue";

import ServiceProducts from "../../services/serviceProducts";
import ServiceProductSale from "../../services/serviceProductSale";
import ServiceProductSaleSquareMeter from "../../services/serviceProductSaleSquareMeter";

import { INITIAL_STATE_PRODUCTS as INITIAL_STATE_PRODUCTS_SALE } from "../../initialStates/sale";
import { INITIAL_STATE_PRODUCTS as INITIAL_STATE_PRODUCTS_SQUARE } from "../../initialStates/saleSquareMeter";
import { removeReference } from "../../utils/removeReference";

export default {
  props: {
    isEnableStyleCard: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Composições",
    },
    clear: {
      type: Boolean,
    },
    compositions: {
      type: Array,
    },
    idProduct: {
      type: String,
    },
    forceUpdateSelect: {
      type: Boolean,
    },
    isSquareMeter: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Input,
    InputSelect,
    InputMoney,
  },
  data() {
    return {
      productsFromSelectBox: [],
      compositionItems: [INITIAL_STATE_PRODUCTS_SALE],
    };
  },
  methods: {
    async fillCombobox() {
      const result = await ServiceProducts.fillCombobox();
      this.productsFromSelectBox = result.data;
    },

    async handleRemoveItens(idList, productOfSaleId) {
      try {
        this.removeItensInArray(idList);

        if (productOfSaleId) {
          await this.removeProduct(productOfSaleId);
          this.$toast.open({
            message: "Composição removida!",
            type: "success",
          });
        }

        this.getComposition();
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao remover a composição",
          type: "info",
        });
      }
    },

    async removeProduct(productOfSaleId) {
      if (!this.isSquareMeter)
        return await ServiceProductSale.deleteComposition(productOfSaleId);

      await ServiceProductSaleSquareMeter.deleteComposition(productOfSaleId);
    },

    addMoreItems() {
      const initialState = !this.isSquareMeter
        ? INITIAL_STATE_PRODUCTS_SALE
        : INITIAL_STATE_PRODUCTS_SQUARE;

      this.compositionItems = [
        ...this.compositionItems,
        {
          ...initialState,
          idList: uuidv4(),
          tipoProduto: "composicao",
        },
      ];
    },

    removeItensInArray(idList) {
      if (this.compositionItems.length > 1) {
        this.compositionItems = this.compositionItems.filter(
          (item) => item.idList !== idList
        );

        return;
      }

      const initialState = !this.isSquareMeter
        ? INITIAL_STATE_PRODUCTS_SALE
        : INITIAL_STATE_PRODUCTS_SQUARE;

      this.compositionItems = [
        {
          ...initialState,
          tipoProduto: "composicao",
          idList: uuidv4(),
        },
      ];
    },

    resetComposition() {
      const initialState = !this.isSquareMeter
        ? INITIAL_STATE_PRODUCTS_SALE
        : INITIAL_STATE_PRODUCTS_SQUARE;

      this.compositionItems = [
        {
          ...initialState,
          tipoProduto: "composicao",
          idList: uuidv4(),
        },
      ];
    },

    getComposition(idComposition, index) {
      if (idComposition === this.idProduct) {
        this.compositionItems[index].idProduto = "";
        this.$refs.refProductComposition[index].valueItems = "";

        this.$toast.open({
          message:
            "Não é possível adicionar o item final como composição a ele mesmo",
          type: "info",
        });
      }

      const formattedComposition = removeReference(
        this.compositionItems
      ).filter((item) => {
        if (item.idProduto) {
          Reflect.deleteProperty(item, "idList");
          Reflect.deleteProperty(item, "idRefProduto");

          return {
            ...item,
          };
        }
      });

      const items = {
        composition: formattedComposition,
      };

      this.$emit("findCompositions", items);
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },
  },
  watch: {
    clear() {
      this.resetComposition();
    },
    forceUpdateSelect() {
      if (!this.compositions || !this.compositions?.length) {
        return this.resetComposition();
      }

      this.compositionItems = removeReference(this.compositions);
      this.$nextTick(() => {
        this.compositionItems.forEach((composition, i) => {
          if (
            this.$refs.refProductComposition &&
            this.$refs.refProductComposition.length
          )
            this.$refs.refProductComposition[i].valueItems =
              composition.idProduto;
        });
      });
    },
  },
  mounted() {
    this.fillCombobox();
    this.compositionItems = this.compositionItems.map((item) => {
      return {
        ...item,
        idList: uuidv4(),
      };
    });
  },
};
</script>

<style scoped>
.summaryForm {
  display: flex;
  flex-direction: column;
  color: #00486e;
}

.summaryForm label {
  margin-bottom: 0px;
  font-weight: bold;
}

.btnBack {
  border-radius: 5px;
  background-color: #00395d;
  border: none;
  margin-top: 30px;
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  font-size: 13px;
  width: 150px;
  font-size: 18px;
  margin-top: 0;
}

.closeModal {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1000;
  color: rgb(255, 153, 153);
  cursor: pointer;
  border: 1px solid rgb(238, 238, 238);
  padding: 2px 3px;
  border-radius: 4px;
}

.closeModal:hover {
  color: red;
  background: rgba(245, 245, 245, 0.764);
}

.rowContainer {
  position: relative;
  border: 1px solid rgb(230, 230, 230);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px 10px 5px 10px;
  background: #f9f9f94d;
  margin-bottom: 10px;
  transition: ease-in all 0.3s;
}

.rowContainer span {
  font-weight: 600;
}

.rowContent {
  display: flex;
  width: 100%;
  padding: 10px 0 5px 0;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  transition: ease-in all 0.3s;
}

.btnAddMoreItems {
  font-size: 10px;
  padding: 0.3rem 0.8rem;
  color: black;
  cursor: pointer !important;
  background-color: #c3c3c3;
  border-radius: 5px;
  user-select: none;
}

.btnAddMoreItems:hover {
  background-color: #b6b6b6;
}

.contentComposition {
  max-height: 250px;
  overflow-y: auto;
  padding-right: 1rem;
  margin-top: 0.5rem;
  transition: ease-in all 0.3s;
}
</style>