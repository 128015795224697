<template>
  <div class="contentGeneral">
    <div class="contentTitle">
      <p class="title">{{ enableValueForReceiptOrPayment.title }}</p>
    </div>

    <div class="col-md-12 col-sm-12 col-lg-12 col-xl-12 contentCards">
      <b-card>
        <div class="contentGeneralTitle">
          <p class="titleLauchInformation">
            {{ enableValueForReceiptOrPayment.subTitle }}
          </p>
          <p class="titleLauchInformation">
            {{ dataBill.numeracao }}° parcela de {{ dataBill.totalContas }}
          </p>
        </div>

        <div class="contentDivider">
          <div class="divider"></div>
        </div>

        <section
          style="
            margin: 0 32px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 0.5rem;
          "
        >
          <div
            style="
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: space-between;
            "
          >
            <div v-if="isProvider" class="contentLaunchValuesBilllSm">
              <p class="informationBillTitle">Fornecedor</p>
              <p class="subTitles">
                {{ dataBill.nomeFornecedor }}
              </p>
            </div>

            <div v-if="isProvider" class="contentLaunchValuesBilllSm">
              <p class="informationBillTitle">Razão Social</p>
              <p class="subTitles">
                {{ dataBill.razaoFornecedor }}
              </p>
            </div>

            <div v-else class="contentLaunchValuesBilllSm">
              <p class="informationBillTitle">Cliente</p>
              <p class="subTitles">
                {{ dataBill.nomeCliente }}
              </p>
            </div>

            <div v-if="dataBill.idVenda" class="contentLaunchValuesBilllSm10">
              <p class="informationBillTitle">Tipo de receita</p>
              <p class="informationBillSubTitle">Venda</p>
            </div>

            <div v-if="dataBill.idVenda" class="contentLaunchValuesBilllSm10">
              <p class="informationBillTitle">Nº Venda</p>
              <p
                class="informationBillSubTitle"
                style="color: rgba(63, 143, 178, 0.975)"
              >
                {{ dataBill.numeroVenda }}
              </p>
            </div>

            <div class="contentLaunchValuesBilllSm14">
              <p class="informationBillTitle">Data de competência</p>
              <p class="informationBillSubTitle">
                {{ dataBill.dataCompetencia | moment }}
              </p>
            </div>
          </div>
          <div
            style="
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: space-between !important;
            "
          >
            <div
              v-if="dataBill.codigoReferencia"
              class="contentLaunchValuesBilll"
            >
              <p class="informationBillTitle">Código de referencia</p>
              <p class="informationBillSubTitle">
                {{ dataBill.codigoReferencia }}
              </p>
            </div>

            <div
              v-if="isProvider && dataBill.nomeCategoria"
              class="contentLaunchValuesBilll"
            >
              <p class="informationBillTitle">Categoria</p>
              <p class="informationBillSubTitle">
                {{ dataBill.nomeCategoria }}
              </p>
            </div>
            <div class="contentLaunchValuesBilll">
              <p class="informationBillTitle">Vencimento</p>
              <p
                class="informationBillSubTitle"
                style="color: rgba(63, 143, 178, 0.975)"
              >
                {{ dataBill.data | moment }}
              </p>
            </div>
          </div>
          <div style="display: flex; width: 100%; flex-wrap: wrap">
            <div
              v-if="isProvider && dataBill.chavePix"
              class="contentLaunchValuesBilllLg10"
            >
              <p class="informationBillTitle">Chave Pix</p>
              <p class="informationBillSubTitle">
                {{ dataBill.chavePix }}
              </p>
            </div>
            <div v-if="dataBill.descricao" class="contentLaunchValuesBilllLg">
              <p class="informationBillTitle">Descrição</p>
              <p class="informationBillSubTitle">
                {{ dataBill.descricao }}
              </p>
            </div>
          </div>
          <div
            v-if="dataBill.observacoes"
            style="display: flex; width: 100%; flex-wrap: wrap"
          >
            <div class="contentLaunchDescriptionValues">
              <p class="informationBillTitle">Observações</p>
              <p class="informationBillSubTitle">
                {{ dataBill.observacoes }}
              </p>
            </div>
          </div>

          <div
            style="
              width: 100%;
              display: flex;
              background: #c1c1c156;
              border-radius: 4px;
              padding: 5px 20px;
              flex-direction: column;
              align-items: flex-end;
            "
          >
            <div>
              <p class="informationBillTitle">Valor Total</p>
            </div>
            <div style="display: flex; gap: 3px">
              <p
                style="
                  display: flex;
                  align-items: center;
                  margin-top: 7px;
                  margin-bottom: 0;
                  color: #666360;
                  letter-spacing: 0.3px;
                "
              >
                R$
              </p>
              <p
                class="informationBillSubTitle"
                style="font-size: 20px; margin: 0; letter-spacing: 0.3px"
              >
                {{
                  dataBill.valorTotal.toLocaleString("pt-br", {
                    minimumFractionDigits: 2,
                  })
                }}
              </p>
            </div>
          </div>
        </section>
      </b-card>

      <b-card>
        <div class="contentGeneralTitle">
          <p class="titleLauchInformation">
            {{ enableValueForReceiptOrPayment.titleDetailedBill }}
          </p>
        </div>

        <div class="contentDivider">
          <div class="divider"></div>
        </div>

        <section
          style="
            margin: 0 32px;
            display: flex;
            gap: 20px;
            flex-direction: column;
            justify-content: space-between;
            margin-top: 0.5rem;
          "
        >
          <div>
            <table class="tableBillDetails">
              <thead>
                <tr>
                  <th>Data</th>
                  <th>Tipo do pagamento</th>
                  <th>Conta</th>
                  <th>Valor</th>
                  <th>Juros/Multa</th>
                  <th>Desconto</th>
                  <th class="text-center">Situação</th>
                  <th colspan="2"></th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(register, index) in dataBill.registers"
                  :style="
                    dataBill.registers.length - 1 === index &&
                    !dataBill.valorRestante &&
                    'border-bottom: none'
                  "
                  :key="register.id"
                >
                  <td class="tdBill">{{ register.dataPagamento }}</td>
                  <td class="tdBill">{{ register.tipoPagamento }}</td>
                  <td class="tdBill">{{ register.contaRecebimento }}</td>
                  <td class="tdBill">
                    {{
                      register.valor.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td class="tdBill">
                    {{
                      (
                        totalAdditionalsValues.multa +
                        totalAdditionalsValues.juros
                      ).toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td class="tdBill">
                    {{
                      register.desconto.toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                      })
                    }}
                  </td>
                  <td class="tdBill">
                    <p
                      class="situationBill"
                      :style="`background-color: ${
                        register.tipo === 'Parcial' || register.tipo === 'Total'
                          ? 'rgb(108 237 126 / 40%)'
                          : 'rgb(0 207 255 / 45%)'
                      }`"
                    >
                      {{
                        register.tipo === "Parcial" || register.tipo === "Total"
                          ? "Recebido"
                          : register.tipo
                      }}
                    </p>
                  </td>
                  <td class="tdActions">
                    <b-dropdown
                      id="dropdown-1"
                      menu-class="w-1"
                      size="sm"
                      variant="none"
                    >
                      <template #button-content>
                        <b-icon-gear-fill />
                      </template>
                      <b-dropdown-item @click="soFindById(register.id)">
                        Imprimir recibo
                      </b-dropdown-item>
                      <b-dropdown-item
                        @click="openModalRegisterReversal(register.id)"
                      >
                        Estornar
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>

                <tr v-if="dataBill.valorRestante" style="border-bottom: none">
                  <td class="tdBill">{{ dataBill.data | moment }}</td>
                  <td class="tdBill">{{ dataBill.formaPagamento }}</td>
                  <td class="tdBill">{{ dataBill.contaRecebimento }}</td>
                  <td class="tdBill">
                    {{ dataBill.valorRestante | maskCurrencyWithSymbol }}
                  </td>
                  <td class="tdBill">
                    <b-icon-dash-circle scale="1.3" />
                  </td>
                  <td class="tdBill">
                    <b-icon-dash-circle scale="1.3" />
                  </td>
                  <td v-if="dataBill.situacao === 'Vencido'" class="tdBill">
                    <p class="situationBillVencido">
                      {{ dataBill.situacao }}
                    </p>
                  </td>
                  <td v-else class="tdBill">
                    <p
                      class="situationBill"
                      :style="`background-color: ${
                        dataBill.situacao === billSituation.pagoParcial ||
                        dataBill.situacao === billSituation.emAberto
                          ? 'rgb(237 222 108 / 64%)'
                          : 'rgb(108 237 126 / 40%)'
                      }`"
                    >
                      {{
                        dataBill.situacao === billSituation.pago
                          ? "Recebido"
                          : "Em aberto"
                      }}
                    </p>
                  </td>
                  <td class="tdActions">
                    <b-dropdown
                      id="dropdown-1"
                      menu-class="w-1"
                      size="sm"
                      variant="none"
                    >
                      <template #button-content>
                        <b-icon-gear-fill />
                      </template>
                      <b-dropdown-item
                        v-if="dataBill.situacao === billSituation.pago"
                      >
                        Imprimir recibo
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="dataBill.situacao === billSituation.pago"
                      >
                        Estornar
                      </b-dropdown-item>
                      <b-dropdown-item
                        v-if="
                          dataBill.situacao === billSituation.emAberto ||
                          dataBill.situacao === billSituation.pagoParcial || dataBill.situacao === billSituation.vencido
                        "
                        @click="openModalLauchValues"
                      >
                        {{
                          dataBill.idFornecedor
                            ? "Informar pagamento"
                            : "Informar recebimento"
                        }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>

          <div class="contentCardDetails">
            <section class="contentValuesDetails">
              <div>
                <div class="textValue">Valor em aberto (R$)</div>
                <div class="totalValuesDetailsToOpen">
                  {{ dataBill.valorRestante | maskCurrency }}
                </div>
              </div>
              <div>
                <div class="textValue">
                  {{ isProvider ? "Valor pago (R$)" : "Valor recebido (R$)" }}
                </div>
                <div class="totalValuesDetailsReceipt">
                  {{ totalReceiptValue }}
                </div>
              </div>
            </section>

            <section class="contentSubValuesDetails">
              <div class="subValuesDetails">
                <p class="othersValus">Parcelas (R$):</p>
                <p class="remainingValue">
                  {{ dataBill.valorRestante | maskCurrency }}
                </p>
                <p class="valuePaid">
                  {{ dataBill.valorPago | maskCurrency }}
                </p>
              </div>

              <div class="subValuesDetails">
                <p class="othersValus">Descontos (R$):</p>
                <p class="remainingValue">
                  {{ "0,00" | maskCurrency }}
                </p>
                <p class="valuePaid">
                  {{ totalAdditionalsValues.desconto | maskCurrency }}
                </p>
              </div>

              <div class="subValuesDetails">
                <p class="othersValus">Juros (R$):</p>
                <p class="remainingValue">
                  {{ "0,00" | maskCurrency }}
                </p>
                <p class="valuePaid">
                  {{ totalAdditionalsValues.juros | maskCurrency }}
                </p>
              </div>

              <div class="subValuesDetails">
                <p class="othersValus">Multas (R$):</p>
                <p class="remainingValue">
                  {{ "0,00" | maskCurrency }}
                </p>
                <p class="valuePaid">
                  {{ totalAdditionalsValues.multa | maskCurrency }}
                </p>
              </div>
            </section>
          </div>
        </section>
      </b-card>

      <b-card>
        <div class="contentGeneralTitle">
          <p class="titleLauchInformation">Observações / Anexos</p>
        </div>

        <div class="contentDivider">
          <div class="divider"></div>
        </div>

        <div class="contentTab mt-3">
          <div class="tabs">
            <button
              :class="`customTabs ${tabs ? 'tabSelected' : ''}`"
              name="observation"
              @click="selectTab"
            >
              Observação
            </button>

            <button
              :class="`customTabs ${!tabs ? 'tabSelected' : ''}`"
              name="attachment"
              @click="selectTab"
            >
              Anexos
            </button>
          </div>

          <div :hidden="!tabs" class="contentDescription">
            <div class="description">
              <p>Observações</p>
            </div>

            <textarea
              class="inputTextArea"
              rows="3"
              max-rows="6"
              disabled
              v-model="dataBill.observacoes"
            />
            <p class="descriptionObs">
              Descreva aqui observações importantes referente a esse pagamento
            </p>
          </div>
          <div :hidden="tabs">
            <AttachFiles
              ref="attachedFiles"
              @removeFile="handleRemoveFile($event)"
              :disabledInput="true"
            />
          </div>
        </div>
      </b-card>
    </div>

    <div class="contentGeneralButtons">
      <div class="contentButtons">
        <div>
          <button class="btnsFinancial btnBack" @click="goBack">Voltar</button>
        </div>

        <div>
          <button
            v-if="dataBill.valorRestante > 0"
            class="btnsFinancial btnConfirm"
            @click="openModalLauchValues"
          >
            {{
              dataBill.idFornecedor
                ? "Informar pagamento"
                : "Informar recebimento"
            }}
          </button>
        </div>
      </div>
    </div>

    <b-modal
      id="ModalRegisterReversal"
      hide-footer
      hide-header
      centered
      @hidden="closeModalRegisterReversal"
    >
      <div>
        <div class="contentTitleModalReversal">
          <h6 class="titleModalRegisterReversal">
            {{ isProvider ? "Estornar Pagamento" : "Estornar Recebimento" }}
          </h6>
        </div>

        <section
          style="
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 20px;
            margin-top: 20px;
            margin-bottom: 30px;
            padding: 0 10px;
          "
        >
          <div style="width: 100%0">
            <p class="subTitleModalRegisterReversal">
              O {{ isProvider ? "pagamento" : "recebimento" }} será removido e
              seu valor ficará em aberto.
            </p>
          </div>

          <div style="width: 100%0">
            <p class="footerTitleModalRegisterReversal">
              Deseja realmente estornar esse
              {{ isProvider ? "pagamento" : "recebimento" }} ?
            </p>
          </div>
        </section>

        <div class="btnsModalActions">
          <button
            class="btnsFinancial btnBack"
            @click="closeModalRegisterReversal"
          >
            Não
          </button>
          <button
            class="btnsFinancial btnConfirm"
            @click="registerReversal"
            style="position: relative"
          >
            Sim, estornar
            <span
              v-if="spinLoadingActionsButton"
              style="position: absolute; right: 5px"
              ><b-spinner small
            /></span>
          </button>
        </div>
      </div>
    </b-modal>

    <ModalLaunchValue
      :label="
        dataBill.idFornecedor ? 'Conta de Pagamento' : 'Conta de Recebimento'
      "
      @closeModalLauch="modalLaunchIsOpen = $event"
      v-if="modalLaunchIsOpen"
      :dataBill="dataBillToModalLauch"
    />
  </div>
</template>

<script>
import ModalLaunchValue from "../Modals/ModalLaunchValue.vue";
import AttachFiles from "../AttachFiles/AttachFiles.vue";

import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import ServiceBillFile from "../../services/serviceBillFile";
import ServiceRegisterBillPayment from "../../services/serviceRegisterBillPayment";
import { BillSituation } from "../../utils/enums/BillSituation";
import { tipoArquivo } from "../../common/index";
import { INITIAL_STATE_FILE } from "../../initialStates/files";

import { mapMutations, mapState } from "vuex";
import printReport from "../../reports/sale";
export default {
  components: {
    ModalLaunchValue,
    AttachFiles,
  },
  props: {
    type: {
      type: String,
      default: "entrada",
    },
  },
  data() {
    return {
      dataBill: {
        id: "",
        tipo: this.type,
        idCliente: null,
        idFornecedor: null,
        idFuncionario: null,
        idFormaPagamento: null,
        idContaRecebimento: null,
        nomeCliente: "",
        nomeFornecedor: "",
        idCategoria: null,
        valorTotal: 0.0,
        valorPago: 0.0,
        valorRestante: 0.0,
        data: "",
        dataCompetencia: moment().format("YYYY-MM-DD"),
        descricao: "",
        observacoes: "",
        codigoReferencia: "",
        registers: [],
        arquivos: [
          {
            id: "",
            idConta: "",
            file: "",
            tipoArquivo: "",
            descricao: "",
            keyAws: "",
            urlArquivo: "",
          },
        ],
      },
      dataLaunchPayment: {
        id: null,
        price: 0.0,
        description: null,
        datePayment: moment().format("YYYY-MM-DD"),
      },
      billSituation: BillSituation,
      tabs: true,
      typeFile: tipoArquivo,
      dataBillToModalLauch: null,
      modalLaunchIsOpen: false,
      spinLoadingActionsButton: false,
      idRegisterSelected: null,
    };
  },
  methods: {
    ...mapMutations([
      "SET_DATA_SEARCH_FROM_BILL",
      "ALTER_TYPE_FINANCIAL",
      "ALTER_SIZE_MODAL_FINANCIAL",
      "SET_COLAPSE_FINANCIAL",
      "ALTER_MODAL_CONTAINER_FINANCIAL",
      "SET_IS_SEARCH_BILLS",
    ]),

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    goBack() {
      this.SET_DATA_SEARCH_FROM_BILL({});
      this.ALTER_SIZE_MODAL_FINANCIAL("disable");
      this.SET_COLAPSE_FINANCIAL(false);
    },

    selectTab(event) {
      if (event.target.name === "attachment") {
        this.tabs = false;
      } else {
        this.tabs = true;
      }
    },

    async handleRemoveFile(idFile) {
      await ServiceBillFile.deleteFile(idFile);
    },

    async registerReversal() {
      try {
        this.spinLoadingActionsButton = true;
        const detailedBill = await ServiceRegisterBillPayment.registerReversal(
          this.idRegisterSelected,
          this.dataBill.id
        );

        this.SET_DATA_SEARCH_FROM_BILL(detailedBill);
        this.SET_IS_SEARCH_BILLS();

        this.closeModalRegisterReversal();
      } catch (error) {
        console.log(error);
      } finally {
        this.spinLoadingActionsButton = false;
      }
    },

    openModalRegisterReversal(idRegister) {
      this.idRegisterSelected = idRegister;
      this.$bvModal.show("ModalRegisterReversal");
    },

    async soFindById(id) {
      try {
        this.printProofOfAcquittance(id);
      } catch (error) {
        return this.$toast.open({
          message: "Ocorreu um erro ao buscar a conta!",
          type: "error",
        });
      }
    },

    printProofOfAcquittance(idRegister) {
      printReport("proofOfAcquittance", idRegister);
    },

    closeModalRegisterReversal() {
      this.$bvModal.hide("ModalRegisterReversal");
      this.idRegisterSelected = null;
    },

    handleRefsAfterOpenLaunchAccounts() {
      this.dataLaunchPayment.price = this.dataBill.valorRestante;

      if (this.dataBill.arquivos.length) {
        this.$refs.attachedFiles.files = this.dataBill.arquivos;

        this.$nextTick(() => {
          this.$refs.attachedFiles.files.forEach((file, index) => {
            this.$refs.attachedFiles.$refs.refTipoArquivo[index].valueItems =
              file.tipoArquivo;

            this.$refs.attachedFiles.$refs.refDescricao[index].valueInput =
              file.descricao;
          });
        });
      }
    },

    assigningSearchValuesToTheBill() {
      if (!this.dataSearchFromBill?.arquivos?.length) {
        const idList = uuidv4();
        const arquivos = [{ ...INITIAL_STATE_FILE, idList }];

        Object.assign(this.dataBill, { ...this.dataSearchFromBill, arquivos });
      } else {
        const arquivos = this.dataSearchFromBill.arquivos.map((file) => {
          const idList = uuidv4();

          return {
            ...file,
            idList,
          };
        });

        Object.assign(this.dataBill, { ...this.dataSearchFromBill, arquivos });
      }

      this.handleRefsAfterOpenLaunchAccounts();
    },

    openModalLauchValues() {
      this.dataBillToModalLauch = {
        id: this.dataBill.id,
        dataMaturity: this.dataBill.data,
        valorTotal: this.dataBill.valorTotal,
        formasDePagamentos: null,
        contasDeRecebimentos: null,
        valorTotalRegistros: this.dataBill.valorTotalRegistros,
        valorRestante: this.dataBill.valorRestante,
        nomeFornecedor: this.dataBill.nomeFornecedor,
        arquivos: this.$refs.attachedFiles.files,
      };

      this.modalLaunchIsOpen = true;

      this.$nextTick(() => {
        this.$bvModal.show("ModalLaunchValue");
      });
    },
  },

  computed: {
    ...mapState({
      dataSearchFromBill: (state) => state.dataSearchFromBill,
    }),

    isProvider() {
      if (this.dataBill.nomeFornecedor) {
        return true;
      }

      return false;
    },

    enableValueForReceiptOrPayment() {
      if (this.dataBill.nomeFornecedor) {
        return {
          title: "Detalhes da despesa",
          subTitle: "Informações do pagamento",
          titleDetailedBill: "Informações detalhadas do pagamento",
        };
      }

      return {
        title: "Detalhes da receita",
        subTitle: "Informações de lançamento",
        titleDetailedBill: "Informações detalhadas do recebimento",
      };
    },

    totalReceiptValue() {
      const totalValueReceipt = this.dataBill.registers.reduce(
        (oldValue, item) => {
          const { valor, desconto, juros, multa } = item;

          let updatedValue = oldValue + (valor - desconto);

          return (updatedValue += juros + multa);
        },
        0
      );

      return totalValueReceipt.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });
    },

    totalAdditionalsValues() {
      const totals = this.dataBill.registers.reduce(
        (oldValue, item) => {
          const { multa, juros, desconto } = item;

          return {
            multa: (oldValue.multa += multa),
            juros: (oldValue.juros += juros),
            desconto: oldValue.desconto + desconto,
          };
        },
        { multa: 0, juros: 0, desconto: 0 }
      );

      return totals;
    },

    handleDataSearchFromBill() {
      return this.dataSearchFromBill.registers;
    },
  },

  filters: {
    moment: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
    maskCurrencyWithSymbol: function (value) {
      return value?.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },
    maskCurrency: function (value) {
      return value?.toLocaleString("pt-br", { minimumFractionDigits: 2 });
    },
  },
  mounted() {
    this.dataBill.arquivos[0].idList = uuidv4();

    if (this.dataSearchFromBill.id) {
      this.assigningSearchValuesToTheBill();
    }
  },
  watch: {
    handleDataSearchFromBill: {
      handler: function () {
        this.assigningSearchValuesToTheBill();
      },
      deep: true,
    },
  },
};
</script>

<style scoped>
.contentCards {
  padding: 1rem;
  font-size: 12px;
  background-color: whitesmoke !important;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.contentGeneral {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.contentTitle {
  width: 100%;
  padding: 10px;
}

.title {
  margin: 0 !important;
  font-weight: 600;
  color: #666360;
  font-size: 16px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.cards {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.contentGeneralTitle {
  display: flex;
  margin: 0 32px;
  justify-content: space-between;
}

.titleLauchInformation {
  margin: 0 !important;
  font-weight: bold;
  color: #666360;
  font-size: 14px;
  letter-spacing: 0.3px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.contentDivider {
  padding: 8px 30px;
}

.divider {
  width: 100%;
  border-bottom: solid 1px #6663605a;
}

.customTabs {
  background-color: transparent;
  border: none;
  padding: 0;
  width: 200px;

  font-weight: 600;
  color: #666360;
}

.tabSelected {
  color: rgba(84, 188, 233, 0.975);
  border-bottom: solid 3px rgba(63, 143, 178, 0.975);
}

.inputTextArea {
  width: 100%;
  border: none;
  outline: none;
  text-decoration: none;
  background-color: white;
  color: #5c5855;
  border-radius: 5px;
  height: 60px;
  padding-left: 5px;
  border: solid 1px #d9d9d9;
  resize: none;
}

/* TABS */

.contentDescription {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.description {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.description p {
  margin: 0px;
  color: #666360;
}

.descriptionObs {
  color: #666360a3;
}

.contentGeneralButtons {
  width: calc(100% - 60px);
  position: fixed;
  z-index: 99;
  bottom: 0px;
  right: 0px;
  background-color: #ffff;
  padding: 0.7% 0px;
  box-shadow: -2px -2px 4px 0 rgba(0, 0, 0, 0.102);
}

.contentButtons {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 32px;
}

.btnsFinancial {
  border-radius: 5px;
  background-color: #00395d;
  width: 165px;
  height: 30px;
  border: none;
  color: white;
  font-size: 12px;
}

.btnConfirm {
  border: solid 1px #0fbf4d;
  color: white;
  background-color: #0fbf4d;
}

.btnBack {
  border: solid 1px rgba(63, 143, 178, 0.975);
  color: rgba(63, 143, 178, 0.975);
  background-color: whitesmoke;
}

.contentTab {
  display: flex;
  gap: 15px;
  flex-direction: column;
  width: 100%;
  padding: 16px 32px 32px 32px;
}

.tabs {
  display: flex;
  gap: 10px;
  border-bottom: solid 1px #6663605a;
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0px 10px;
}

th {
  padding: 5px 15px;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  font-size: 11px;
}

.tableBillDetails {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0px 10px;
}

.tableBillDetails tr {
  border-bottom: 1px solid #c1c1c156;
}

td {
  padding: 5px 10px;
}

thead {
  background-color: #c1c1c156;
}

.card-body {
  padding-bottom: 10px !important;
  padding-top: 10px !important;
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.tdBill {
  padding-left: 15px;
}

.tdActions {
  text-align: end;
}

.situationBill {
  display: flex;
  color: #075638;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  border-radius: 5px;
  height: 24px;
}

.situationBillVencido {
  display: flex;
  color: whitesmoke;
  background: #ff1d08;
  justify-content: center;
  align-items: center;
  margin: 0;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  border-radius: 5px;
  height: 24px;
}

/*  */
.contentInformationBilll {
  width: 30%;
}

.contentLaunchValuesBilll {
  max-width: 20%;
  flex: 0 0 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentLaunchValuesBilllSm {
  max-width: 20%;
  flex: 0 0 20%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentLaunchValuesBilllSm14 {
  max-width: 14%;
  flex: 0 0 14%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentLaunchValuesBilllSm10 {
  max-width: 10%;
  flex: 0 0 10%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentLaunchValuesBilllMd {
  max-width: 33.6%;
  flex: 0 0 33.6%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentLaunchValuesBilllLg {
  max-width: 50%;
  flex: 0 0 50%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentLaunchValuesBilllLg10 {
  max-width: 40%;
  flex: 0 0 40%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.contentLaunchDescriptionValues {
  flex: 0 0 33.6%;
}

.subTitles {
  letter-spacing: 0.3px;
  color: rgba(63, 143, 178, 0.975);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.informationBillTitle {
  margin: 0 !important;
  font-weight: bold;
  color: #666360;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  font-size: 12px;
  letter-spacing: 0.3px;
}

.dropdown-menu li {
  font-size: 12px;
  width: 100% !important;
  padding: 0 !important;
  display: flex;
  text-align: center;
  list-style: none;
  border: none;
}

.contentCardDetails {
  width: 100%;
  padding: 16px 16px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  border: solid 1px #6663605a;
  border-radius: 5px;
  box-shadow: 0px 0px 1px 0px;
}

.contentValuesDetails {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 20px;
}

.contentSubValuesDetails {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.subValuesDetails {
  display: flex;
  width: 100%;
  display: flex;
  gap: 29px;
  justify-content: flex-end;
}

.subValuesDetails p {
  margin-bottom: 5px;
}

.othersValus {
  color: rgb(188 188 188);
}

.remainingValue {
  font-size: 14px;
  width: 100px;
  text-align: end;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.valuePaid {
  font-size: 14px;
  width: 103.16px;
  text-align: end;

  font-weight: 600;
}

.totalValuesDetailsToOpen {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: #ff1d08;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.totalValuesDetailsReceipt {
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  color: #27d850;
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
}

.textValue {
  font-weight: 600;
  font-family: Poppins-Regular, sans-serif !important;
  color: #666360;
}

.btnsModalActions {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.titleModalRegisterReversal {
  font-family: Poppins-Regular, sans-serif !important;
  margin: 0;
}

.subTitleModalRegisterReversal {
  color: #767676;
  font-size: 14px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0;
}

.footerTitleModalRegisterReversal {
  color: #00496e;
  font-size: 13px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  margin: 0;
}

.contentTitleModalReversal {
  align-items: center;
  font-size: 1rem;
  padding: 10px;
  background-color: #00496e;
  border-radius: 5px;
  color: white;
  margin: 0;
}
</style>
