<template>
  <div class="input-group">
    <input
      required
      :type="type"
      :disabled="isDisabled"
      :class="`input col-sm-12 col-md-12 col-lg-12 col-xl-12 borderInput ${isInvalidInput} ${textAlign}`"
      :value="maskCurrency(valueInput)"
      @input="valueSelected($event)"
      :placeholder="placeholder"
      @blur="clearInvalidInput"
      ref="inputMoney"
      @focus="selectAll"
    />
    <label
      :style="`background-color: ${labelBackground}`"
      :class="`${labelFixed ? 'labelFixed' : 'user-label'}  ${
        !label && 'labelFixedDisabled'
      }`"
    >
      {{ label }}
    </label>
  </div>
</template>

<script>
import { mascaraMoeda, maskCurrency } from "../../../utils/maskMoney";
export default {
  props: {
    label: {
      type: [String, Number],
    },
    labelFixed: {
      type: Boolean,
    },
    type: {
      type: String,
    },
    dataInput: {
      type: [String, Number, Function],
    },
    isDisabled: {
      type: Boolean,
    },
    alterTotalValue: {
      type: [String, Number],
    },
    textAlign: {
      type: String,
    },
    change: {
      type: Function,
    },
    labelBackground: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    isInvalidInput: {
      type: String,
    },
    isSelectAll: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      valueInput: "",
      maskCurrency: maskCurrency,
    };
  },
  created() {
    this.valueInput = this.dataInput;
  },
  methods: {
    valueSelected(event) {
      this.valueInput = mascaraMoeda(event.target.value);

      this.$emit("update:dataInput", this.valueInput);
      this.$emit("calculateTotal", this.valueInput);
      this.$emit("changed");
    },
    clearInvalidInput() {
      this.$emit("clearInvalidInput");
    },
    selectAll() {
      if (this.isSelectAll) {
        this.$refs?.inputMoney?.select();
      }
    },
  },
};
</script>

<style scoped>
.user-label {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 7px;
  pointer-events: none;
  transform: translateY(0.5rem);
  transition: 150ms cubic-bezier(0.4, 0, 0.2, 1);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
}

.labelFixed {
  position: absolute;
  left: 3px;
  font-size: 11px;
  font-family: Poppins-Regular, sans-serif !important;
  font-weight: 600;
  bottom: 13px;
  pointer-events: none;
  transform: translateY(-50%) scale(0.8);
  white-space: nowrap !important;
  text-overflow: ellipsis;
  color: #666360;
  height: 12px;
  background-color: #ffff;
  padding: 0 0.2em;
}

.input {
  font-size: 12px !important;
}

.input:hover {
  background: #d9d9d9;
  border: 1px solid #262626;
}

.inputDisabled {
  background-color: #fafafa;
  pointer-events: none;
  color: #949290 !important;
}

.labelFixedDisabled {
  padding: 0 !important;
}

.input:focus ~ label,
input:valid ~ label {
  transform: translateY(-50%) scale(0.8);
  background-color: #ffffff;
  padding: 0 0.2em;
  font-weight: 600;
}

.borderInput {
  font-size: 14px;
  height: 30px;
  border: 1px solid #d5d3d3;
  border-radius: 5px;
  outline: none;
  transition: ease-in 0.1s all;
  padding-left: 5px;
  padding-right: 4px;
  color: #5c5855;
}

.borderInput:focus {
  border: 1px solid #a9cff4;
}

.isInvalidInput:focus {
  border: solid 1px rgba(204, 21, 21, 0.583) !important;
  transition: ease-in all 0.1s !important;
  box-shadow: 0 0 2px 1px rgba(204, 21, 21, 0.583) !important;
}
</style>