<template>
  <div class="d-flex justify-content-center container-geral">
    <div class="container-components">
      <SalePerSquareMeter
        @getAllSales="getAllSales = $event"
        :statusSquareMeter="statusSquareMeter"
      />
    </div>
  </div>
</template>

<script>
import { SalesSquareMeterType } from "../../utils/enums/SalesSquareMeterType";
import SalePerSquareMeter from "./SaleSquareMeter.vue";
export default {
  props: {
    statusSquareMeter: {
      type: String,
      default: SalesSquareMeterType.venda,
    },
  },
  components: {
    SalePerSquareMeter,
  },
  data() {
    return {
      idSale: "",
      getAllSales: null,
    };
  },
};
</script>
<style scoped>
.container-geral {
  width: 100% !important;
}

.container-components {
  width: 85%;
  transition: all 0.7s ease-in-out;
}

@media screen and (max-width: 768px) {
  .container-components {
    width: 95%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1200px) {
  .totals {
    font-size: 12px !important;
  }
  .dataSaleItems {
    font-size: 11px;
    margin-top: 9px;
  }
}

@media screen and (max-width: 700px) {
  .container-components {
    width: 100%;
  }
}
</style>