import api from "./axios";

class ServiceSale {
  async save(dataSale) {
    const { data } = await api.post('/sales', dataSale)
    return data
  }

  async createNewCompletedSale(dataSale) {
    const { data } = await api.post('/sales/create-completed-sale', dataSale)
    return data
  }

  async updateCompletedSale(dataSale) {
    const { data } = await api.put(`/sales/update-new-completed-sale/${dataSale.sale.id}`, dataSale)
    return data
  }

  async createWithoutProduct(dataSale) {
    const { data } = await api.post('/sales/create/without-product', dataSale)
    return data
  }

  async updateWithoutProduct(dataSale) {
    const { data } = await api.put(`/sales/update/without-product/${dataSale.sale.id}`, dataSale)
    return data
  }

  async update(dataSale) {
    await api.put(`/sales/${dataSale.id}`, dataSale);
  }

  async delete(id) {
    await api.delete(`/sales/${id}`)
  }

  async findById(id) {
    const { data } = await api.get(`/sales/${id}`);
    return data;
  }

  async transforBudgetToSale(id) {
    const { data } = await api.get(`/sales/transform/budget/to/sale/${id}`)
    return data
  }

  async deleteAll(dataSaleUnique) {
    await api.delete(`/sales/all/${dataSaleUnique}`)
  }

  async transformWithNota(idSale) {
    const { data } = await api.get(`/sales/transform/orcamento/venda/${idSale}`)
    return data
  }

  async getAllBillsById(id) {
    const { data } = await api.get(`/sales/${id}`);
    return data;
  }

  async filterSalePerMonth(month, year) {
    const { data } = await api.get(
      `/sales/per/month/search/sum/group/six?month=${month}&year=${year}`
    );

    return data
  }

  async cancel(id, status) {
    await api.post(`/sales/cancel/registers`, { id, status })
  }

  async reOpen(id) {
    await api.post(`/sales/reopen/after/cancel`, { id })
  }

  async duplicate(id) {
    const { data } = await api.get(`/sales/duplicate/${id}`)

    return data
  }
}

export default new ServiceSale()