import api from "./axios";

class ServiceSafe {
  async save(dataSafe) {
    const { data } = await api.post("/safe", dataSafe)
    return data
  }

  async findById(idSafe) {
    const { data } = await api.get(`/safe/id/${idSafe}`)
    return data
  }

  async findSafeById(idSafe) {
    const { data } = await api.get(`/safe/${idSafe}`)
    return data
  }

  async findPaymentsUsedBySafeRegisterId(idSafeRegister) {
    const { data } = await api.get(`/safe/find/records/${idSafeRegister}`)
    return data
  }

  async findRemainingValue() {
    const {data} = await api.get("/safe/find/remaining/value/safe")
    return data
  }
  
  async findDate(date) {
    const { data } = await api.get(`/safe/search/for/cash/movement/safe/by/date?date=${date}`)
    return data
  }

  async sumPerAccount() {
    const { data } = await api.get("/safe/find/an/account/and/add/the/equals")
    return data
  }

  async findRemainingDrawerBoxValue(idSafeRegister) {
    const { data } = await api.get(`/safe/find/remaining/drawer/box/value/type/money/${idSafeRegister}`)
    return data
  }

  async generalBalance() {
    const { data } = await api.get("/safe/get/general/balance")
    return data
  }
}

export default new ServiceSafe()