import Joi from "joi";

export const ValidadeProductOfSaleSquareMeter = (product) => {
  const schema = Joi.object({
    idVendaMtq: Joi.string().required().messages({
      "string.empty": "(Venda) deve ser salva!",
      "any.required": "(Venda) deve ser salva!",
    }),
    idProduto: Joi.string().required().messages({
      "string.empty": "(Produto) deve ser informado!",
      "any.required": "(Produto) deve ser informado!",
    }),
  })

  const { error } = schema.validate(product, {
    allowUnknown: true,
  })

  if (error?.details[0]?.message || error?.details[0]?.path[0]) {
    return {
      message: error?.details[0]?.message,
      keyError: error?.details[0]?.path[0]
    }
  }
}
