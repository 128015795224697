import { mapState, mapMutations } from "vuex";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";

import ModalCreateCustomer from "../../components/Modals/ModalCreateCustomer.vue";
import ModalCreateEmployees from "../../components/Modals/ModalCreateEmployees.vue";
import ModalCreateProducts from "../../components/Modals/ModalCreateProduct.vue";
import ModalCreateAccountReceiptBill from "../../components/Modals/ModalCreateAccountReceiptBill.vue";
import ModalCreateColors from "../../components/Modals/ModalCreateColors.vue";
import ModalCreateRooms from "../../components/Modals/ModalCreateRooms.vue";
import ModalBillsSquareMeter from "../../components/SaleSquareMeter/Modal-Bills-SaleSquareMeter.vue";
import ModalSaleSquareMeter from "../../components/SaleSquareMeter/Modal-SaleSquareMeter.vue";
import ModalComposition from "../../components/Sale/Modal-Composition.vue"

import InputSelect from "../../components/UI/CustomInputs/InputSelect.vue";
import Input from "../../components/UI/CustomInputs/Input.vue";
import InputMoney from "../../components/UI/CustomInputs/InputMoney.vue";
import InputQuant from "../../components/UI/CustomInputs/InputQuant.vue";
import InputPercentage from "../../components/UI/CustomInputs/InputPercentage.vue";
import InputInfinit from "../../components/UI/CustomInputs/InputInfinit.vue";
import Tabs from "../../components/UI/Tabs/Tab.vue"

import api from "../../services/axios";
import ServiceCustomer from "../../services/serviceCustomer";
import ServiceProducts from "../../services/serviceProducts";
import ServiceSaleSquareMeter from "../../services/serviceSaleSquareMeter";
import ServiceProductSaleSquareMeter from "../../services/serviceProductSaleSquareMeter";
import ServicePaymentForm from "../../services/servicePaymentForm";
import ServiceAccountReceiptBill from "../../services/serviceAccountReceiptBill";
import ServiceSituation from "../../services/serviceSituation";
import ServiceColors from "../../services/serviceColors"
import ServiceRooms from "../../services/serviceRooms"

import { ValidateBillsWhenGenerating, ValidateBillsWhitinSimpleSafe } from "../../validators/bills";
import { ValidadeProductOfSaleSquareMeter } from "../../validators/productOfSaleSquareMeter";

import { INITIAL_STATE_PRODUCTS } from "../../initialStates/saleSquareMeter";
import { SquareMeterSituationType } from "../../utils/enums/SquareMeterSituationType";
import { SalesSquareMeterType } from "../../utils/enums/SalesSquareMeterType";
import { mascaraMoeda, maskCurrency, maskPercentage, } from "../../utils/maskMoney";
import toastAlertErros from "../../utils/toastAlertErros";
import { removeReference } from "../../utils/removeReference";
import { HandleErrors } from "../../utils/handleErrors";

export const mixinSalePerMeterSquare = {
  props: {
    statusSquareMeter: {
      type: String,
      default: SalesSquareMeterType.venda
    }
  },
  components: {
    ModalBillsSquareMeter,
    ModalSaleSquareMeter,
    InputSelect,
    Input,
    InputMoney,
    InputQuant,
    InputPercentage,
    InputInfinit,
    ModalCreateCustomer,
    ModalCreateEmployees,
    ModalCreateProducts,
    ModalCreateAccountReceiptBill,
    ModalCreateColors,
    ModalCreateRooms,
    ModalComposition,
    Tabs,
  },
  data() {
    return {
      saleSquareMeter: {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dataVencimento: null,
        dataEntrega: null,
        dadosAdicionais: "",
        observacoesNf: "",
        status: this.statusSquareMeter,
        frete: 0,
        outrosValores: 0,
        tipoDesconto: "Valor Monetário", // ou Porcentagem
        bills: [],
      },
      blocked: false,
      dataCustomers: [],
      dataEmployee: [],
      allSituations: [],
      products: [],
      providers: [],
      comissao: "",
      productUnitaryValue: "",
      fields: ["Produto", "Quantidade", "Valor"],
      dataBillSquareMeter: {
        tipo: "entrada",
        idCliente: "",
        idFuncionario: "",
        idFormaPagamento: "",
        idContaRecebimento: "",
        idVendaMtq: "",
        valorUnitario: 0.0,
        valorPago: 0.0,
        valorRestante: 0.0,
        data: moment().format("YYYY-MM-DD"),
      },
      totalParcelas: 1,
      intervaloDias: 0,
      allPaymentsTypes: [],
      total: "0,00",
      totalOfItens: "R$ 0,00",
      totalOfDiscount: "R$ 0,00",
      additionalValues: "R$ 0,00",
      otherValues: "R$ 0,00",
      totalValue: 0,
      discountToAllItems: false,
      discountIsCheked: false,
      billsFromModal: [],
      allAccountReceiptBills: [],
      paymentSelected: "",
      receiptBillSelected: "",
      isPaydBill: "",
      isDropBills: false,
      saleSquareMeterFromModal: {},
      squareMeterForModal: {
        customerSelected: {
          nome: "",
        },
        employeeSelected: {
          nomeFuncionario: "",
        },
        formattedProductsSale: [],
        data: "",
      },
      numberSaleSquareMeter: "",
      verifyBillIsPaid: false,
      paymentMoneyIsSelected: false,
      paymentsDataInfo: {
        receiptAccount: "",
        typePayment: "",
        expiredFirst: "",
        installmentsNumber: 0,
        installmentsInterval: 0,
      },
      squareMeterSituationType: SquareMeterSituationType,
      saleIsCanceled: false,
      situationSelected: null,
      createdNewCustomer: false,
      createdNewEmployee: null,
      createdNewProducts: null,
      createdNewAccountReceiptBill: null,
      maskCurrency: maskCurrency,
      salesSquareMeterType: SalesSquareMeterType,
      idProductFromSearch: "",
      colors: [],
      rooms: [],
      budgetToSale: false,
      lineProduct: {
        id: "",
        idVendaMtq: "",
        idFornecedor: "",
        idProduto: "",
        quantidade: 1,
        altura: 0,
        largura: 0,
        metragem: 0,
        valorUnitario: 0,
        valorTotal: 0,
        desconto: 0,
        dadosAdicionais: "",
        porcentagemPerda: 0,
        idCor: "",
        idAmbiente: "",
        linear: false,
        tipoPreco: "P1",
        tipoProduto: 'normal',
        composicao: [],
        estoque: 0,
      },
      compositionItems: [],
      toggleToUpdate: false,
      selectedItemIdToReplace: null,
      productLoading: false,
      toggleClearSelect: false,
      tableProducts: [],
      prevSelectedProduct: null,
      maskPercentage: maskPercentage,
      isLoadingToDelete: false,
      isLoadingToReplace: false,
      fixedSalary: false,
      oldProductId: null,
      editProd: {
        edit: false,
        prod: null
      },
      typeCashier: "",
    };
  },
  methods: {
    ...mapMutations(["SET_DATA_SEARCH_FROM_SALE_SQUARE_METER",]),
    async handleAddMoreItens() {
      if (!this.saleSquareMeter.idCliente) {
        return this.$toast.open({
          message: "(Cliente) deve ser selecionado para prosseguir",
          type: "info",
        });
      }

      await this.saveSaleSquareMeter();

      this.lineProduct.id
        ? await this.updateProductOfSale()
        : await this.saveProductOfSale();

      this.handleCalculateTotalsValues();
      this.editProd = {
        edit: false,
        prod: null
      };
    },

    async saveProductOfSale() {
      try {
        this.lineProduct.idVendaMtq = this.saleSquareMeter.id;

        if (!this.validadeProductOfSaleSquareMeter(this.lineProduct)) return;

        await ServiceProductSaleSquareMeter.save({...this.lineProduct, createdAt: null});
        await this.findTableProductsOfSaleSquareMeter();
        this.clearLineProduct();

        return this.$toast.open({
          message: "Produto adicionado",
          type: "success",
        });
      } catch (error) {
        const typeError = HandleErrors(error?.response?.data?.message);

        this.$toast.open({
          message: Object.keys(error?.response?.data?.erros)?.length
            ? error?.response?.data?.erros
            : typeError?.message ? typeError?.message : error?.response?.data?.message ||
              "Ocorreu um problema ao salvar o produto",
          type: "info",
        });
      } finally {
        this.productLoading = false;
      }
    },

    async updateProductOfSale() {
      try {
        this.lineProduct.idVendaMtq = this.saleSquareMeter.id;

        if (!this.validadeProductOfSaleSquareMeter(this.lineProduct)) return;

        await ServiceProductSaleSquareMeter.update(this.lineProduct.id, this.lineProduct);
        await this.findTableProductsOfSaleSquareMeter();
        this.clearLineProduct();

        return this.$toast.open({
          message: "Produto atualizado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message: Object.keys(error?.response?.data?.erros)?.length
            ? error?.response?.data?.erros
            : error?.response?.data?.message ||
            "Ocorreu um problema ao salvar o produto",
          type: "info",
        });
      } finally {
        this.productLoading = false;
      }
    },

    editProductsOfSaleSquareMeter(prod) {
      try {
        this.editProd = {
          edit: true,
          prod,
        };

        const newProd = removeReference(prod);
        this.assignProduct(newProd);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao editar o produto",
          type: "info",
        });
      }
    },

    handleAddItensAndHandleAssignValues() {
      this.addMoreItens();
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    addMoreItens() {
      const idList = uuidv4();
      const newProducts = { ...INITIAL_STATE_PRODUCTS, idList };
      this.saleSquareMeter.products.push(newProducts);
      this.enableTooltipDiscount();

      this.cleanTotalValueLastProduct();
      this.cancelReplaceAll()
    },

    async cleanTotalValueLastProduct() {
      await this.$nextTick();
      this.$refs.valorTotal[
        this.saleSquareMeter.products.length - 1
      ].valueInput = 0;
    },

    assignTotalItensValues() {
      const valorTotal = this.tableProducts.reduce((oldValue, product) => {
        // const valorCorrigido = Math.floor(parseFloat(product.valorTotal) * 100) / 100;
        return oldValue + +(+product.valorTotal.toFixed(2));
      }, 0
      );

      this.totalOfItens = maskCurrency(valorTotal)
    },

    assignTotalDiscount() {
      if (this.discountIsCheked) {
        this.totalOfDiscount = this.calculateTotalDiscount();
        return;
      }

      this.totalOfDiscount = this.calculateTotalDiscountWithMonetaryValue();
    },

    cleanCurrency(value) {
      if (typeof value === "number") return value;
      if (!value) return 0;
      let cleaned = String(value).replace(/[^\d,.-]/g, "");
      if (cleaned.indexOf(",") !== -1) {
        cleaned = cleaned.replace(/\./g, "");
        cleaned = cleaned.replace(",", ".");
      }
      return parseFloat(cleaned) || 0;
    },

    assignTotalValue() {
      let totalRaw = !this.dataConfig.venda_mtq.arredondar_mt2
        ? this.totalOfItens
        : mascaraMoeda(this.totalOfItens);
      let discountRaw = !this.dataConfig.venda_mtq.arredondar_mt2
        ? this.totalOfDiscount
        : mascaraMoeda(this.totalOfDiscount);

      let formattedTotal = this.cleanCurrency(totalRaw);
      let formattedTotalDiscount = this.cleanCurrency(discountRaw);
      let frete = this.cleanCurrency(this.saleSquareMeter.frete);
      let outrosValores = this.cleanCurrency(this.saleSquareMeter.outrosValores);

      const newTotal = formattedTotal - formattedTotalDiscount + frete + outrosValores;

      this.totalValue = maskCurrency(newTotal);
    },

    calculateTotalDiscount() {
      const discounts = this.tableProducts.map((prod) => {
        const desc = prod.desconto;
        const totalValue = prod.valorTotal;

        return (totalValue * desc) / 100;
      });

      const totalValueWithDiscount = discounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalValueWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    calculateTotalDiscountWithMonetaryValue() {
      const monetaryDiscounts = this.tableProducts.map(
        (prod) => {
          if (prod.desconto) {
            const desc = prod.desconto;

            return desc;
          }

          return 0;
        }
      );

      const totalMonetaryWithDiscount = monetaryDiscounts.reduce(
        (oldValue, desc) => oldValue + desc,
        0
      );

      return totalMonetaryWithDiscount.toLocaleString("pt-br", {
        style: "currency",
        currency: "BRL",
      });
    },

    async removeItens(id) {
      try {
        this.isLoadingToDelete = true
        await ServiceProductSaleSquareMeter.delete(id);
        await this.findTableProductsOfSaleSquareMeter();

        this.handleCalculateTotalsValues();
        return this.$toast.open({
          message: "Produto removido",
          type: "success",
        });
      } catch (error) {
        return this.$toast.open({
          message:
            `${error.response.data.message}` ||
            "Ocorreu um erro ao remover o produto!",
          type: "info",
        });
      } finally {
        this.isLoadingToDelete = false
      }
    },

    async cancelSaleSquareMeter() {
      try {
        await ServiceSaleSquareMeter.cancel(this.saleSquareMeter.id, this.saleSquareMeter.status);

        this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
          SquareMeterSituationType.cancelado
        );

        this.saleIsCanceled = !this.saleIsCanceled;
        this.clearAllBills();

        return this.$toast.open({
          message: "Venda cancelada!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    async reOpen() {
      try {
        await ServiceSaleSquareMeter.reOpen(this.saleSquareMeter.id);

        if (this.saleSquareMeter.status === SalesSquareMeterType.venda) {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.aprovado
          );
        } else {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.emAberto
          );
        }

        return this.$toast.open({
          message: this.statusSquareMeter === SalesSquareMeterType.orcamento ? "Orçamento reaberto!" : "Venda reaberta!",
          type: "success",
        });
      } catch (error) {
        toastAlertErros.validateMessage(error, this.$toast);
      }
    },

    handleCalculateTotal() {
      const amount = Number(this.lineProduct.quantidade);
      const unitaryValue = this.lineProduct.valorUnitario;
      let meter = 0

      if (!this.$refs.valorTotal) return;

      if (!this.enableEditMt2) {
        if (!this.lineProduct.linear) {
          meter = Number(parseFloat(this.lineProduct.altura.toFixed(this.decimalPlaces) * this.lineProduct.largura.toFixed(this.decimalPlaces)).toFixed(this.decimalPlaces))
        }
        else
          meter = Number((parseFloat(this.lineProduct.altura.toFixed(this.decimalPlaces)) + parseFloat(this.lineProduct.largura.toFixed(this.decimalPlaces))).toFixed(this.decimalPlaces))
      } else {
        meter = this.lineProduct.metragem
      }

      const totalPerda = (meter * this.lineProduct.porcentagemPerda) / 100 || 0
      if (totalPerda > 0) {
        meter = Number(parseFloat(parseFloat(meter) + parseFloat(totalPerda.toFixed(this.decimalPlaces))).toFixed(this.decimalPlaces))
      }

      const unit = +(+(+(meter.toFixed(this.decimalPlaces)) || 1) * unitaryValue).toFixed(2)

      const totalValue = Number(amount * unit)

      this.lineProduct.metragem = parseFloat(meter);
      this.lineProduct.valorTotal = totalValue;

      let discountValue = 0;

      if (this.saleSquareMeter.tipoDesconto === "Porcentagem") {
        const result = (
          this.lineProduct.valorTotal *
          (this.lineProduct.desconto / 100)
        ).toFixed(2);

        discountValue = parseFloat(result);
      } else {
        discountValue = this.lineProduct.desconto;
      }

      this.$refs.valorTotal.valueInput = totalValue - discountValue;
      this.$refs.totalMeter.valueInput = meter;
    },

    clearDataPaymentsSquareMeter() {
      this.dataBillSquareMeter.idCliente = "";
      this.dataBillSquareMeter.idFuncionario = "";
      this.dataBillSquareMeter.idFormaPagamento = "";
      this.dataBillSquareMeter.idContaRecebimento = "";
      this.dataBillSquareMeter.idVendaMtq = "";
      this.dataBillSquareMeter.idCor = "";
      this.dataBillSquareMeter.idAmbiente = "";
      this.dataBillSquareMeter.linear = false;
      this.dataBillSquareMeter.valorUnitario = "";
      this.dataBillSquareMeter.valorPago = "";
      this.dataBillSquareMeter.valorRestante = "";
      this.dataBillSquareMeter.data = moment().format("YYYY-MM-DD");
      this.totalParcelas = 1;
      this.intervaloDias = 30;
      this.saleSquareMeter.bills = [];
    },

    async getProductById(idProduct) {
      const data = await ServiceProducts.getProductById(idProduct);
      return data;
    },

    async updateProductValue(selected) {
      const valueMapping = {
        P1: this.prevSelectedProduct.valorVenda,
        P2: this.prevSelectedProduct.valorVenda2,
        P3: this.prevSelectedProduct.valorVenda3,
      };

      const currencyValueProduct = valueMapping[selected];

      this.lineProduct.valorUnitario = currencyValueProduct;

      if (this.$refs.refVlUnit) {
        this.$refs.refVlUnit.valueInput = currencyValueProduct;
      }

      this.handleCalculateTotal();
    },

    async handleSetProductValues(product) {
      if (product) {
        if (this.prevSelectedProduct && (product.id !== this.prevSelectedProduct.id)) {
          this.lineProduct.valorUnitario = this.editProd.edit ? this.editProd.prod.valorUnitario : product.valorVenda;
          this.$refs.refVlUnit.valueInput = this.editProd.edit ? this.editProd.prod.valorUnitario : product.valorVenda

          this.handleCalculateTotal();
          this.editProd = {
            edit: false,
            prod: null
          };
        }

        this.prevSelectedProduct = product;
        this.lineProduct.estoque = await ServiceProducts.findStock(product.id);

        if (!this.lineProduct.id) {
          this.lineProduct.valorUnitario = product.valorVenda;
          this.$refs.refVlUnit.valueInput = product.valorVenda;

          const amount = Number(this.lineProduct.quantidade);
          const unitaryValue = this.lineProduct.valorUnitario;
          const total = amount * unitaryValue;

          this.lineProduct.valorTotal = total;
          this.$refs.valorTotal.valueInput = total;

          if (product?.composicao) {
            this.lineProduct.composicao = product?.composicao?.map((item) => {
              const newInitialState = {
                ...INITIAL_STATE_PRODUCTS,
                idList: uuidv4(),
                dadosAdicionais: item.dadosAdicionais,
                idProduto: item.idProduto,
                tipoProduto: "composicao",
                tipo: "Venda",
                quantidade: item.quantidade,
              };

              Reflect.deleteProperty(item, "idRefProduto");

              return {
                ...item,
                ...newInitialState,
              };
            });
          }

          this.updateProductValue(this.lineProduct.tipoPreco || "P1");
        }
      }
    },


    async handleItemSelected(product) {
      this.selectedItemIdToReplace = product.idProduto;

      this.editProductsOfSaleSquareMeter(product)
    },

    validadeProductOfSaleSquareMeter(prod) {
      const isNotValid = ValidadeProductOfSaleSquareMeter(prod);

      if (isNotValid && Object.keys(isNotValid).length) {
        this.$toast.open({
          message: isNotValid.message,
          type: "info",
        });

        return false;
      }

      return true;
    },

    cancelReplaceAll() {
      this.selectedItemIdToReplace = null;
      this.clearLineProduct();
    },

    async applyReplaceAll() {
      try {
        this.isLoadingToReplace = true
        await ServiceProductSaleSquareMeter.replaceAll(
          this.lineProduct,
          this.selectedItemIdToReplace,
          this.saleSquareMeter.id,
          this.lineProduct.tipoPreco
        );

        this.cancelReplaceAll();
        this.clearLineProduct();
        await this.findTableProductsOfSaleSquareMeter();

        this.handleCalculateTotalsValues();
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao substituir os itens",
          type: "info",
        });
      } finally {
        this.isLoadingToReplace = false
      }
    },

    async handleDuplicate(productOfSaleSquare) {
      try {
        const newProd = removeReference(productOfSaleSquare);
        newProd.id = "";
        newProd.createdAt = null

        await ServiceProductSaleSquareMeter.save(newProd);
        await this.findTableProductsOfSaleSquareMeter();

        this.$toast.open({
          message: "Item Clonado",
          type: "success",
        });
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao clonar o item",
          type: "info",
        });
      }
    },

    handleCalculateTotalInTable(prod) {
      let newTotalValue = 0;

      if (this.saleSquareMeter.tipoDesconto === "Porcentagem") {
        const result = (prod.valorTotal * (prod.desconto / 100)).toFixed(2);

        newTotalValue = parseFloat(prod.valorTotal - result);
      } else {
        newTotalValue = prod.valorTotal - prod.desconto;
      }

      return maskCurrency(newTotalValue);
    },

    async findTableProductsOfSaleSquareMeter() {
      const result = await ServiceProductSaleSquareMeter.findByIdSale(this.saleSquareMeter.id);

      this.tableProducts = result || [];
    },

    alterStatusSale() {
      this.saleSquareMeter.status = "Venda";
    },

    assignProduct(prod) {
      this.lineProduct = prod;

      this.$refs.refColors.valueItems = prod.idCor;
      this.$refs.refRooms.valueItems = prod.idAmbiente;
      this.$refs.refQtd.valueInput = prod.quantidade;
      this.$refs.refVlUnit.valueInput = prod.valorUnitario;
      this.$refs.refAlt.valueInput = prod.altura;
      this.$refs.refLarg.valueInput = prod.largura;
      this.$refs.refPorcPerda.valueInput = prod.porcentagemPerda;
      this.$refs.valorTotal.valueInput = prod.valorTotal;
      this.$refs.discount.valueInput = prod.desconto;
      this.$refs.dadosAdicionais.valueInput = prod.dadosAdicionais;

      this.handleCalculateTotal();
    },


    clearLineProduct() {
      this.lineProduct = {
        ...this.lineProduct,
        id: "",
        idVendaMtq: "",
        idFornecedor: "",
        quantidade: 1,
        dadosAdicionais: "",
        tipoProduto: 'normal',
        composicao: [],
        estoque: 0,
      };

      if (!this.keepItems) {
        this.lineProduct = {
          ...this.lineProduct,
          idProduto: "",
          altura: 0,
          largura: 0,
          metragem: 0,
          valorUnitario: 0,
          valorTotal: 0,
          porcentagemPerda: 0,
          idCor: "",
          idAmbiente: "",
          linear: false,
          tipoPreco: "P1",
        }

        this.$refs.refColors.valueItems = ""
        this.$refs.refRooms.valueItems = ""

        this.$refs.refAlt.valueInput = 0
        this.$refs.refLarg.valueInput = 0
        this.$refs.refPorcPerda.valueInput = 0
        this.$refs.totalMeter.valueInput = 0

        if (!this.discountToAllItems) {
          this.lineProduct.desconto = 0;
          this.$refs.discount.valueInput = 0;
        }

        this.toggleClearSelect = !this.toggleClearSelect;
      }

      this.$refs.refQtd.valueInput = 1;
      this.$refs.refVlUnit.valueInput = this.lineProduct.valorUnitario;
      this.$refs.valorTotal.valueInput = this.lineProduct.valorTotal;
      this.$refs.dadosAdicionais.valueInput = "";
      // this.toggleClearSelect = !this.toggleClearSelect;
      this.prevSelectedProduct = null;
    },

    async fillProductsComboBoxWithPaginate(id, q, filterType) {
      try {
        return await ServiceProducts.fillComboBoxWithPaginate(id, q, filterType);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os produtos",
          type: "info",
        });
      }
    },


    async listEmployeesSelectBox() {
      try {
        const { data } = await api.get("/employees/combobox/fill");
        this.dataEmployee = data.data;
      } catch (error) {
        console.log(error.response);
      }
    },

    readComissao() {
      const employee = this.dataEmployee.find(
        (emp) => this.saleSquareMeter.idFuncionario === emp.id
      );

      this.comissao = employee?.comissao;
      this.dataBillSquareMeter.idFuncionario =
        this.saleSquareMeter.idFuncionario;
    },

    async getProviders() {
      const { data } = await api.get("/providers/fill/combobox");
      this.providers = data;
    },

    async getAllColors() {
      try {
        this.colors = await ServiceColors.fillComobobox()
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message || "Ocorreu um problema ao buscar as cores",
          type: "info"
        })
      }
    },

    async getAllRooms() {
      try {
        this.rooms = await ServiceRooms.fillComobobox()
      } catch (error) {
        this.$toast.open({
          message: error?.response?.data?.message || "Ocorreu um problema ao buscar os ambientes",
          type: "info"
        })
      }
    },

    handleGenerateBillsAndOpenModalBills() {
      if (
        (this.saleSquareMeter.bills.length &&
          this.saleSquareMeter.bills[0]?.id) ||
        (this.billsFromModal.length && this.billsFromModal[0]?.id)
      ) {
        this.verifyBillIsPaid = !this.verifyBillIsPaid;
        this.getPaymentInformation();
        return this.openModals("modalBillsSquareMeter");
      }

      const isNotValid = this.validateBills();
      if (isNotValid) {
        return this.$toast.open({
          message: isNotValid,
          type: "info",
        });
      }

      this.verifyBillIsPaid = !this.verifyBillIsPaid;
      this.handleGetTypePaymentAndReceiptBill();
      this.makingThePayment();
      this.getPaymentInformation();
      this.openModals("modalBillsSquareMeter");
    },

    handleGetTypePaymentAndReceiptBill() {
      if (this.dataBillSquareMeter.idFormaPagamento) {
        const typePayment = this.allPaymentsTypes.filter(
          (payment) => payment.id === this.dataBillSquareMeter.idFormaPagamento
        );

        const receiptBill = this.allAccountReceiptBills.filter(
          (receiptBills) =>
            receiptBills.id === this.dataBillSquareMeter.idContaRecebimento
        );

        this.paymentSelected = typePayment[0].tipo;
        if (!this.typeCashier) {
          this.receiptBillSelected = receiptBill[0].nome;
        }
      }
    },

    async handleDataToSendModalSquareMeter() {
      this.assingValuesSquareMeterForModal();
      this.getPaymentInformation();
      this.openModals("modalSaleSquareMeder");
    },
    assingValuesSquareMeterForModal() {
      this.squareMeterForModal.formattedProductsSale = this.tableProducts

      this.squareMeterForModal.customerSelected.nome =
        this.dataCustomers.filter(
          (customer) => customer?.id === this.saleSquareMeter?.idCliente
        )[0]?.nome;

      this.squareMeterForModal.employeeSelected.nomeFuncionario =
        this.dataEmployee.filter(
          (employee) => employee?.id === this.saleSquareMeter?.idFuncionario
        )[0]?.nomeFuncionario;

      this.squareMeterForModal.data = this.saleSquareMeter.data;
    },

    getPaymentInformation() {
      if (this.billsFromModal.length) {
        if (!this.typeCashier) {
          this.paymentsDataInfo.receiptAccount =
            this.allAccountReceiptBills.find(
              (receiptBills) =>
                receiptBills.id === this.billsFromModal[0].idContaRecebimento
            ).nome;
        }

        this.paymentsDataInfo.typePayment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.billsFromModal[0].idFormaPagamento
        ).tipo;

        this.paymentsDataInfo.expiredFirst = moment(
          this.billsFromModal.sort((a, b) => a.data - b.data)[0].data
        ).format("DD/MM/YYYY");

        this.paymentsDataInfo.installmentsNumber = this.billsFromModal.length;

        if (this.billsFromModal.length >= 2) {
          this.paymentsDataInfo.installmentsInterval = moment(
            this.billsFromModal[1].data
          ).diff(this.billsFromModal[0].data, "days");
        }
      }
    },

    clearPaymentsDataInfo() {
      this.paymentsDataInfo.receiptAccount = "";
      this.paymentsDataInfo.typePayment = "";
      this.paymentsDataInfo.expiredFirst = "";
      this.paymentsDataInfo.installmentsNumber = 0;
      this.paymentsDataInfo.installmentsInterval = 0;
    },

    handleModalCreateProduct(nameModal, idProduct) {
      this.idProductFromSearch = idProduct;
      this.openModals(nameModal);
    },

    openModals(idModal) {
      this.$bvModal.show(idModal);
    },

    validateBills() {
      if (this.typeCashier) {
        const dataFromGenerateBill = {
          idCliente: this.saleSquareMeter.idCliente,
          idFormaDePagamento: this.dataBillSquareMeter.idFormaPagamento,
          idContaRecebimento: this.dataBillSquareMeter.idContaRecebimento,
          intervaloDias: this.fixedSalary ? 0 : this.intervaloDias,
          vencimento: this.dataBillSquareMeter.data,
          quantidadeParcelas: this.totalParcelas,
        };

        return ValidateBillsWhitinSimpleSafe(dataFromGenerateBill);

      } else {
        const dataFromGenerateBill = {
          idCliente: this.saleSquareMeter.idCliente,
          idFormaDePagamento: this.dataBillSquareMeter.idFormaPagamento,
          idContaRecebimento: this.dataBillSquareMeter.idContaRecebimento,
          intervaloDias: this.fixedSalary ? 0 : this.intervaloDias,
          vencimento: this.dataBillSquareMeter.data,
          quantidadeParcelas: this.totalParcelas,
        };

        return ValidateBillsWhenGenerating(dataFromGenerateBill);

      }
    },

    async saveSaleSquareMeter() {
      try {
        this.productLoading = true;
        if (this.saleSquareMeter.id) return;

        const result = await ServiceSaleSquareMeter.createWithoutProduct({
          saleSquareMeter: this.saleSquareMeter,
        });

        this.saleSquareMeter.id = result.id;
        this.numberSaleSquareMeter = result.number;
      } catch (error) {
        console.log(error)
        this.$toast.open({
          message:
            error?.response?.data?.message || "Ocorreu um problema ao salvar",
          type: "info",
        });

        this.productLoading = false;
      }
    },

    async updateSaleSquareMeter() {
      try {
        await ServiceSaleSquareMeter.updateWithoutProduct({
          saleSquareMeter: this.saleSquareMeter,
        });
      } catch (error) {
        console.log(error)
      }
    },

    async fillCustomerComboBoxWithPaginate(id, q, filterType) {
      try {
        return await ServiceCustomer.fillComboBoxWithPaginate(id, q, filterType);
      } catch (error) {
        this.$toast.open({
          message:
            error?.response?.data?.message ||
            "Ocorreu um problema ao listar os clientes",
          type: "info",
        });
      }
    },

    handleDataCustomers(event) {
      this.dataCustomers = [event];
    },

    handleCreatedNewCustomer() {
      this.createdNewCustomer = !this.createdNewCustomer;
    },

    async handleSituation() {
      const allSituation = await this.getAllSituation();
      this.toAssignDefaultSituation(allSituation);
    },

    async getAllPayment() {
      const result = await ServicePaymentForm.getAllPayment();
      this.allPaymentsTypes = result.sort((a, b) => a.cod - b.cod);
    },

    async getAllSituation() {
      try {
        const result = await ServiceSituation.getAllSituation();
        return result;
      } catch (error) {
        return this.$toast.open({
          message: `${error.response.data.message ||
            "Ocorreu um erro ao carregar todas as situações"
            }`,
          type: "info",
        });
      }
    },

    async getAllReceiptBill() {
      const result = await ServiceAccountReceiptBill.fillComobobox();
      this.allAccountReceiptBills = result;
      const accountPayment = this.allAccountReceiptBills.find(
        (account) => account.nome === "CAIXA GAVETA"
      );
      if (this.typeCashier) {
        this.blocked = true;

        if (this.dataBillSquareMeter)
          this.dataBillSquareMeter.idContaRecebimento = accountPayment.id;

        if (this.$refs.refContaDeRecebimento)
          this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }
    },

    clearAll() {
      this.saleSquareMeter = {
        id: "",
        idCliente: "",
        idFuncionario: "",
        idSituacao: "",
        data: moment().format("YYYY-MM-DD"),
        dataVencimento: null,
        dataEntrega: null,
        dadosAdicionais: "",
        observacoesNf: "",
        status: this.statusSquareMeter,
        frete: 0,
        outrosValores: 0,
        tipoDesconto: "Valor Monetário", // ou Porcentagem
        bills: [],
      }

      this.numberSaleSquareMeter = ""
      this.saleSquareMeterFromModal = []
      this.tableProducts = [];

      this.totalOfItens = "R$ 0,00";
      this.totalOfDiscount = "R$ 0,00";
      this.additionalValues = "R$ 0,00";
      this.totalValue = "R$ 0,00";

      this.clearAllBills();
      this.clearPaymentsDataInfo();
      this.clearAllRefs();
    },

    clearAllRefs() {
      this.$refs.refResponsavel.valueItems = "";
      this.$refs.valorTotal.valueInput = 0;
      this.$refs.refVlUnit.valueInput = 0;
    },

    clearAllBills() {
      this.clearDataPaymentsSquareMeter();
      this.clearInputForGenerateBills();
      this.billsFromModal = [];
      this.saleSquareMeter.bills = [];
      this.paymentSelected = "";
      this.receiptBillSelected = "";
    },

    clearInputForGenerateBills() {
      if (this.saleSquareMeter.status === SalesSquareMeterType.venda) {
        this.$refs.refContaDeRecebimento.valueItems = ""
        this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;
        this.$refs.refVencimentoPrimeiraParcela.valueInput =
          this.dataBillSquareMeter.data;
        this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
      }
    },

    makingThePayment() {
      if (this.paymentMoneyIsSelected) {
        return this.handleMoneyPaymentSelected();
      }

      this.billsFromModal = [];
      const formattedTotalValue = mascaraMoeda(this.totalValue);

      const valorPorDuplicata = (formattedTotalValue / this.totalParcelas)
        .toFixed(2)
        .toLocaleString("pt-br", { minimumFractionDigits: 2 });

      for (let i = 0; i < this.totalParcelas; i++) {
        let dataVencimento = i == 0
          ? this.dataBillSquareMeter.data
          : moment(dataVencimento)
            .add(this.intervaloDias, "days")
            .format("YYYY-MM-DD");

        if (this.fixedSalary) {
          dataVencimento =
            i === 0
              ? this.dataBillSquareMeter.data
              : moment(this.dataBillSquareMeter.data).add(i, "months").format("YYYY-MM-DD");
        }

        this.billsFromModal.push({
          id: "",
          tipo: "entrada",
          idCliente: this.saleSquareMeter.idCliente,
          idFuncionario: this.saleSquareMeter.idFuncionario,
          idFormaPagamento: this.dataBillSquareMeter.idFormaPagamento,
          idVendaMtq: this.saleSquareMeter.id,
          valorTotal: valorPorDuplicata,
          valorPago: 0.0,
          valorRestante: valorPorDuplicata,
          data: dataVencimento,
          dataCompetencia: moment().format("YYYY-MM-DD"),
          dataPagamento: null,
          descricao: "",
          idContaRecebimento: this.dataBillSquareMeter.idContaRecebimento,
        });
      }
    },

    enableTooltipDiscount() {
      if (
        this.$refs.typeDiscount[0].checked &&
        this.saleSquareMeter.products.length > 1
      ) {
        return (this.$refs.enableLabelApplyAll[0].style.display = "block");
      }

      this.discountToAllItems = false;
      this.$refs.enableLabelApplyAll[0].style.display = "none";
    },

    async alterDiscountIsCheked() {
      this.discountIsCheked = !this.discountIsCheked;

      if (this.discountIsCheked) {
        this.saleSquareMeter.tipoDesconto = "Porcentagem";
        this.$refs.enableLabelApplyAll.style.display = "block";
      } else {
        this.saleSquareMeter.tipoDesconto = "Valor Monetário";
        this.$refs.enableLabelApplyAll.style.display = "none";
        this.discountToAllItems = false;
      }

      if (this.saleSquareMeter.id) await this.updateSaleSquareMeter();

      this.handleCalculateTotalsValues();
      this.handleCalculateTotal();
    },

    alterAllChecksDiscount() {
      if (this.discountToAllItems) {
        this.$refs.typeDiscount.checked = true;

        this.assignTotalItensValues();
        this.assignTotalDiscount();
        this.assignTotalValue();
      }
    },

    handleSquareMeterValues() {
      this.handleBillsIfAny();
      this.handleSaleProductsIfAny();
      this.assignDataSearchFromSquareMeter();
    },

    handleBillsIfAny() {
      if (!this.dataSearchFromSquareMeter?.bills?.length) {
        return Reflect.deleteProperty(this.dataSearchFromSquareMeter, "bills");
      }

      this.billsFromModal = this.dataSearchFromSquareMeter.bills.map((bill) => {
        return {
          ...bill,
          data: bill.data.split(" ")[0],
        };
      });
    },

    handleSaleProductsIfAny() {
      if (!this.dataSearchFromSquareMeter.products.length) {
        Reflect.deleteProperty(this.dataSearchFromSquareMeter, "products");
      }
    },

    assignDataSearchFromSquareMeter() {
      Object.assign(this.saleSquareMeter, this.dataSearchFromSquareMeter);

      this.tableProducts = this.dataSearchFromSquareMeter.products || [];
      Reflect.deleteProperty(this.saleSquareMeter, "products");

      if (this.saleSquareMeter.tipoDesconto === "Porcentagem") {
        this.discountIsCheked = true;
        this.$nextTick(() => {
          this.$refs.enableLabelApplyAll.style.display = "block";
        });
        this.assignTotalDiscount();
      }

      this.numberSaleSquareMeter = this.dataSearchFromSquareMeter.numero;

      this.$refs.refResponsavel.valueItems =
        this.dataSearchFromSquareMeter.idFuncionario;
      this.$refs.data.valueInput = this.dataSearchFromSquareMeter.data;

      if (this.$refs.dataVencimento)
        this.$refs.dataVencimento.valueInput =
          this.dataSearchFromSquareMeter.dataVencimento;

      if (this.$refs.dataEntrega)
        this.$refs.dataEntrega.valueInput =
          this.dataSearchFromSquareMeter.dataEntrega;
    },

    toAssignDefaultSituation(situations) {
      if (this.budgetToSale || (this.statusSquareMeter === this.salesSquareMeterType.venda)) {
        this.allSituations = situations.filter((situation) => {
          if (
            [
              SquareMeterSituationType.aprovado,
              SquareMeterSituationType.faturado,
              SquareMeterSituationType.finalizado,
              SquareMeterSituationType.cancelado,
            ].includes(situation.nome)
          ) {
            return situation;
          }
        });
      } else {
        this.allSituations = situations
          .filter((situation) => {
            if (
              [SquareMeterSituationType.emAberto, SquareMeterSituationType.cancelado].includes(
                situation.nome
              )
            ) {
              return situation;
            }
          })
          .reverse();
      }


      if (!this.saleSquareMeter.id) {
        if (this.statusSquareMeter === this.salesSquareMeterType.venda) {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.aprovado
          );
        } else if (this.statusSquareMeter === this.salesSquareMeterType.orcamento) {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.emAberto
          );
        }
      } else {
        if (this.budgetToSale) {
          this.saleSquareMeter.idSituacao = this.getSpecificSituantionId(
            SquareMeterSituationType.aprovado
          );
        }
      }
    },

    handleTransformToSale() {
      this.budgetToSale = true
    },

    handleFreteMonetaryValue(event) {
      this.saleSquareMeter.frete = mascaraMoeda(event.target.value);
    },

    handleOtherValuesMonetaryValue(event) {
      this.saleSquareMeter.outrosValores = mascaraMoeda(event.target.value);
    },

    getSpecificSituantionId(squareMeterSituationType) {
      const situation = this.allSituations.find(
        (situation) => situation.nome === squareMeterSituationType
      );

      return situation?.id;
    },

    getNameSituantionById(idSituation) {
      const situation = this.allSituations.find(
        (situation) => situation.id === idSituation
      );

      return situation?.nome?.toUpperCase();
    },

    handleMoneyPaymentSelected() {
      this.billsFromModal = [];

      const formattedTotalValue = mascaraMoeda(this.totalValue);
      const valorPorDuplicata = formattedTotalValue.toLocaleString("pt-br", {
        minimumFractionDigits: 2,
      });

      this.billsFromModal.push({
        id: "",
        tipo: "entrada",
        idCliente: this.saleSquareMeter.idCliente,
        idFuncionario: this.saleSquareMeter.idFuncionario,
        idFormaPagamento: this.dataBillSquareMeter.idFormaPagamento,
        idVendaMtq: this.saleSquareMeter.id,
        valorTotal: valorPorDuplicata,
        valorPago: 0.0,
        valorRestante: valorPorDuplicata,
        data: moment().format("YYYY-MM-DD"),
        dataPagamento: moment().format("YYYY-MM-DD"),
        dataCompetencia: moment().format("YYYY-MM-DD"),
        descricao: "",
        idContaRecebimento: this.dataBillSquareMeter.idContaRecebimento,
      });
    },

    handleCreatedNewProduct() {
      this.createdNewProducts = !this.createdNewProducts;
    },

    handleCalculateTotalsValues() {
      this.assignTotalItensValues();
      this.assignTotalDiscount();
      this.assignTotalValue();
    },

    selectPayment() {
      this.blocked = false;
      const moneyPayment = this.allPaymentsTypes.find(
        (formPayment) => formPayment.id === this.dataBillSquareMeter.idFormaPagamento
      );

      if (moneyPayment.tipo === "Dinheiro") {
        this.blocked = true;
        const accountPayment = this.allAccountReceiptBills.find(
          (account) => account.nome === "CAIXA GAVETA"
        );

        this.dataBillSquareMeter.idContaRecebimento = accountPayment.id;
        this.$refs.refContaDeRecebimento.valueItems = accountPayment.id;
      }
      if (this.typeCashier) {
        this.blocked = true;
      }
    },

    handleShowCompositionItem(idProduct, composition) {
      if (idProduct) {
        this.compositionItems = composition;
        this.toggleToUpdate = !this.toggleToUpdate;
        this.$bvModal.show("modalProductSaleComposition");
      }
    },

    handleItemFromModalComposition(payload) {
      this.lineProduct.composicao = payload.composition;
    },
  },
  async mounted() {
    this.listEmployeesSelectBox();
    this.getProviders();
    this.getAllPayment();
    this.getAllReceiptBill();
    this.getAllColors();
    this.getAllRooms();
    if (
      !this.saleSquareMeter.dadosAdicionais &&
      this.dataConfig &&
      Object.keys(this.dataConfig.venda_mtq).length &&
      this.dataConfig.venda_mtq.dados_adicionais_padrao && this.statusSquareMeter === "Venda"
    ) {

      this.saleSquareMeter.dadosAdicionais =
        this.dataConfig.venda_mtq.dados_adicionais_padrao;
    }
    if (
      !this.saleSquareMeter.dadosAdicionais &&
      this.dataConfig &&
      Object.keys(this.dataConfig.orcamento_mtq).length &&
      this.dataConfig.orcamento_mtq.dados_adicionais_padrao && this.statusSquareMeter === "Orçamento"
    ) {

      this.saleSquareMeter.dadosAdicionais =
        this.dataConfig.orcamento_mtq.dados_adicionais_padrao;
    }

    if (this.dataSearchFromSquareMeter.idCliente) {
      this.handleSquareMeterValues();
    }

    if (this.dataConfig.caixa_simples) {
      this.typeCashier = this.dataConfig.caixa_simples
    }

    this.handleSituation();
    this.assignTotalItensValues();
    this.assignTotalDiscount();
    this.assignTotalValue();
  },
  filters: {
    moment(data) {
      return moment(data).format("DD/MM/YYYY");
    },
  },
  computed: {
    ...mapState({
      dataSearchFromSquareMeter: (state) => state.dataSearchFromSquareMeter,
      dataConfig: (state) => state.dataConfig,
    }),
    decimalPlaces() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.casas_decimais)
      }

      return 2;
    },
    enableEditMt2() {
      if (Object.keys(this.dataConfig).length) {
        return this.dataConfig?.venda_mtq?.edicao_mt2
      }

      return false;
    },
    lossPercentage() {
      if (this.dataConfig) {
        return Number(this.dataConfig.venda_mtq.porcentagem_perda)
      }

      return 2
    },
    keepItems() {
      if (Object.keys(this.dataConfig).length) {
        return Number(this.dataConfig?.venda_mtq?.manter_itens)
      }

      return false;
    },
    applyDiscountToAllItems() {
      if (
        this.saleSquareMeter.products &&
        this.saleSquareMeter.products.length
      ) {
        return this.saleSquareMeter.products[0].desconto;
      }

      return 0;
    },
    typePaymentSelected() {
      return this.dataBillSquareMeter.idFormaPagamento;
    },
    freteToAdditionalValues() {
      return this.saleSquareMeter.frete;
    },
    otherValuesToAdditionalValues() {
      return this.saleSquareMeter.outrosValores;
    },
    objectTotal() {
      return {
        totalItems: this.totalOfItens,
        additionalValues: this.additionalValues,
        otherValues: this.otherValues,
        totalDiscount: this.totalOfDiscount,
        total: this.totalValue,
      };
    },
    verifyIfBillIsRegistred() {
      if (this.billsFromModal.length && this.billsFromModal[0]?.id)
        return "Ver Parcelas";
      else return "Gerar Parcelas";
    },
    existsBillPaid() {
      if (this.billsFromModal.length) {
        const billIsPayd = this.billsFromModal.find(
          (bill) => bill.dataPagamento
        );

        return !!billIsPayd;
      }

      return false;
    },
    existsNf() {
      if (this.dataSearchFromSquareMeter?.id && this.dataSearchFromSquareMeter?.nf?.id) {
        return this.dataSearchFromSquareMeter?.nf?.status === "Emitida" ||
          this.dataSearchFromSquareMeter?.nf?.status === "Cancelada"
          ? true
          : false;
      }

      return false;
    },
    handleBlock() {
      return this.existsBillPaid || this.existsNf ? true : false;
    },
    alterColorSituation() {
      const situation = this.allSituations.find(
        (situation) => situation.id === this.saleSquareMeter.idSituacao
      );

      if (situation && situation.nome === SquareMeterSituationType.aprovado) {
        return "orange";
      } else if (situation && situation.nome === SquareMeterSituationType.faturado) {
        return "#3e66ae";
      }
      else if (situation && situation.nome === SquareMeterSituationType.finalizado) {
        return "blue";
      } else if (situation && situation.nome === SquareMeterSituationType.emAberto) {
        return "";
      }

      return "#9f1313";
    },
    showTextSaleOrBudget() {
      return this.statusSquareMeter === SalesSquareMeterType.orcamento ? SalesSquareMeterType.orcamento : SalesSquareMeterType.venda
    },
    isSaleOrBudgetSquareMeter() {
      return this.saleSquareMeter.status
    },
    validatorDesc() {
      let existsDiscount = false;

      if (this.tableProducts.length) {
        for (const product of this.tableProducts) {
          if (product.desconto) {
            existsDiscount = true;
            break;
          }
        }
      }

      return existsDiscount;
    },
    isBlockStock() {
      return this.dataConfig?.venda?.bloqueio_estoque
    },
    isActiveReplaceAll() {
      return !!this.selectedItemIdToReplace
    }
  },
  watch: {
    prevSelectedProduct() {
      if (this.isActiveReplaceAll)
        this.updateProductValue("P1")
    },
    isSaleOrBudgetSquareMeter() {
      this.handleSituation()
    },
    typePaymentSelected() {
      if (this.typePaymentSelected) {
        const payment = this.allPaymentsTypes.find(
          (payment) => payment.id === this.typePaymentSelected
        );
        if (payment.tipo === "Dinheiro") {
          this.paymentMoneyIsSelected = true;
          this.intervaloDias = 0;
          this.dataBillSquareMeter.data = moment().format("YYYY-MM-DD");
          this.totalParcelas = 1;

          if (this.$refs.refIntervaloDePagamento)
            this.$refs.refIntervaloDePagamento.valueInput = this.intervaloDias;

          this.$refs.refVencimentoPrimeiraParcela.valueInput =
            this.dataBillSquareMeter.data;
          this.$refs.refTotalParcelas.valueInput = this.totalParcelas;
        } else {
          this.paymentMoneyIsSelected = false;
        }
      }
    },
    applyDiscountToAllItems() {
      if (this.discountToAllItems) {
        const refsDiscount = this.$refs.discount;

        for (var i = 0; i < refsDiscount.length; i++) {
          this.$refs.discount[i].valueInput =
            this.saleSquareMeter.products[0].desconto;

          this.saleSquareMeter.products[i].desconto =
            this.$refs.discount[i].valueInput;
        }
      }
    },
    freteToAdditionalValues() {
      this.additionalValues = "R$ 0,00";
      const formattedFrete = this.saleSquareMeter.frete;

      if (formattedFrete) {
        this.additionalValues = parseFloat(formattedFrete).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },
    otherValuesToAdditionalValues() {
      this.otherValues = "R$ 0,00";
      const formattedOtherVales = this.saleSquareMeter.outrosValores;

      if (formattedOtherVales) {
        this.otherValues = parseFloat(formattedOtherVales).toLocaleString(
          "pt-br",
          {
            style: "currency",
            currency: "BRL",
          }
        );
      }

      this.assignTotalValue();
    },
    isDropBills() {
      this.clearAllBills();
      this.clearPaymentsDataInfo();
    },
    saleSquareMeterFromModal() {
      if (this.saleSquareMeterFromModal.id) {
        this.saleSquareMeter.id = this.saleSquareMeterFromModal.id;
      }

      if (this.saleSquareMeterFromModal.productsOfSale) {
        const newProductsFromTable =
          this.saleSquareMeterFromModal.productsOfSale.map((prod, indice) => {
            return {
              ...prod,
              idList: this.saleSquareMeter.products[indice].idList,
            };
          });

        this.saleSquareMeter.products = newProductsFromTable;
      }

      if (this.saleSquareMeterFromModal.billsOfSaleSquareMeter) {
        const newBills = this.saleSquareMeterFromModal.billsOfSaleSquareMeter.map((bill) => {
          return { ...bill };
        });

        this.saleSquareMeter.bills = newBills;
        this.billsFromModal = newBills;
      }
    },
    createdNewEmployee() {
      this.listEmployeesSelectBox();
    },
    createdNewAccountReceiptBill() {
      this.getAllReceiptBill();
    },
  },
  beforeDestroy() {
    this.SET_DATA_SEARCH_FROM_SALE_SQUARE_METER({})
    this.budgetToSale = false
  }
}
