<template>
  <div style="position: relative">
    <div :class="`iconFilterSelectInfinit`" @click="toggleFilter" ref="boxClick">
      <div role="button">
        <b-icon-filter scale="2"></b-icon-filter>
      </div>
    </div>

    <div class="boxFilter" ref="box" :style="!filterIsOpen && 'display: none'">
      <div class="containerRadio">
        <label class="option">
          <input type="radio" :name="`radio`" checked value="includes" @click="handleEvent('hoje', 'Hoje')" />
          <span>Hoje</span>
        </label>
        <label class="option">
          <input type="radio" :name="`radio`" value="start" @click="handleEvent('esteMes', 'Este mês')" />
          <span>Esse Mês</span>
        </label>

        <label class="option">
          <input type="radio" :name="`radio`" value="start" @click="handleEvent('ontem', 'Ontem')" />
          <span>Ontem</span>
        </label>

        <label class="option">
          <input type="radio" :name="`radio`" value="start" @click="handleEvent('7dias', '7 Dias')" />
          <span>7 dias</span>
        </label>

        <label class="option">
          <input type="radio" :name="`radio`" value="start" @click="handleEvent('30dias', '30 Dias')" />
          <span>30 dias</span>
        </label>
        <label class="option">
          <input type="radio" :name="`radio`" value="start" @click="handleEvent('mesPassado', 'Mês Passado')" />
          <span>Mês passado</span>
        </label>

        <label class="option">
          <input type="radio" :name="`radio`" value="start" @click="handleEvent('ultimos3meses', 'Ultimos 3 meses')" />
          <span>Últimos 3 meses</span>
        </label>

        <label class="option">
          <input type="radio" :name="`radio`" value="start" @click="handleEvent('personalizado', 'Personalizado')" />
          <span>Personalizado</span>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      filterIsOpen: false,
    };
  },
  methods: {
    toggleFilter () {
      this.filterIsOpen = !this.filterIsOpen;
    },

    handleClickOutside (event) {
      const box = this.$refs.box;
      const boxClick = this.$refs.boxClick;

      if (
        box &&
        !box.contains(event.target) &&
        boxClick &&
        !boxClick.contains(event.target)
      ) {
        this.filterIsOpen = false;
      }
    },
    handleEvent (valueInput, textInput) {
      this.$emit('handleFilterDays', valueInput, textInput)
    }
  },
  mounted () {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy () {
    document.removeEventListener("click", this.handleClickOutside);
  },
};
</script>

<style scoped>
.iconFilterSelectInfinit {
  position: absolute;
  right: -38px !important;
  top: 0 !important;
  z-index: 10;
  color: black;
  height: 100%;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-left: solid 1px rgba(129, 129, 129, 0.26);
}

.iconDisabledInfinit {
  pointer-events: none !important;
}

.containerRadio {
  display: flex;
  gap: 1rem;
  position: relative;
  min-width: 250px;
  flex-wrap: wrap;
}

.containerRadio label {
  display: flex;
  cursor: pointer;
  font-weight: 500;
  position: relative;
  overflow: hidden;
  margin-bottom: 0;
  border-radius: 5px;
}

.containerRadio label input {
  position: absolute;
  left: -9999px;
  display: none;
}

.containerRadio label:has(input:checked) {
  box-shadow: 0 0 1px 1px rgb(37, 240, 91);
  background-color: rgb(37, 240, 91);
  color: white;
}

.containerRadio label input:checked+span {
  color: white;
}

.containerRadio label input+span {
  background-color: #c1c1c1a8;
  color: rgb(0, 0, 0);
}

.containerRadio label span {
  display: flex;
  align-items: center;
  transition: 0.25s ease;
  font-size: 11px;
  white-space: nowrap;
  padding: 0.3rem 0.5rem !important;
}

.containerRadio label span:hover {
  background-color: #8e8e8ea8;
}

.containerRadio label span:before {
  flex-shrink: 0;
  content: "";
  background-color: #fff;
  width: 1em;
  height: 1em;
  margin-right: 0.375em;
  transition: 0.25s ease;
  display: none;
}

.boxFilter {
  display: flex;
  height: auto;
  justify-content: space-between;
  background-color: white;
  position: absolute;
  top: 15px;
  z-index: 500;
  border-radius: 5px;
  box-shadow: 0 0 3px 0 #0000006b;
  padding: 0.5rem;
}

.boxFilter::before {
  content: "";
  position: absolute;
  top: -8px;
  right: 3px;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 10px solid white;
  z-index: -99999;
}
</style>