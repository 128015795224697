import api from "./axios";
class ServiceProductSaleSquareMeter {
  async save(product) {
    const { data } = await api.post(`/products-of-sale-square-meter/`, product)
    return data
  }

  async update(id, product) {
    const { data } = await api.put(`/products-of-sale-square-meter/${id}`, product)
    return data
  }

  async findByIdSale(idSale) {
    const { data } = await api.get(`/products-of-sale-square-meter/by/sale-square-meter/${idSale}`)
    return data
  }

  async delete(id) {
    await api.delete(`/products-of-sale-square-meter/${id}`)
  }

  async deleteComposition(id) {
    await api.delete(`/products-of-sale-square-meter/composition/${id}`)
  }

  async replaceAll(currentProd, prevProdId, saleId, priceType) {
    await api.post(`/products-of-sale-square-meter/replace-all/${prevProdId}/${saleId}?priceType=${priceType}`, currentProd)
  }
}

export default new ServiceProductSaleSquareMeter()